import * as React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'

import { IProps } from './formatters/cell-formatter'
import { print } from '../../../store/actions/print'
import { Column, Row, TableCellFormatter } from '../../../types'
import PrintOnly from '../print-only'
import { formatCell } from './table'

type ColumnGroup = Column[]
type TProps = GridProps & ReduxDispatchProps

const PORTRAIT_PRINT_WIDTH = 600 // px

export interface GridProps {
  columns: Column[]
  rows: Row[]
  className?: string
}

interface ReduxActions {
  print: typeof print
}

interface ReduxDispatchProps {
  actions: ReduxActions
}

export class PrintableGridComponent extends React.Component<TProps> {
  componentDidMount() {
    this.props.actions.print()
  }

  /**
   * Render table header cell
   */
  renderHeaderCell = (column: Column, index: number): JSX.Element => {
    return (
      <th
        style={{ width: column.width, maxWidth: column.width }}
        key={`printable-grid-header-cell-${index}`}
      >
        {column.name}
      </th>
    )
  }

  /**
   * Render table header
   */
  renderHeader = (columns: Column[]): JSX.Element => {
    return <tr>{columns.map(this.renderHeaderCell)}</tr>
  }

  /**
   * Render table cell
   */
  renderCell = (
    width: number,
    rowIdx: number,
    row: {},
    colIdx: number,
    column: Column
  ): React.ReactElement<HTMLTableCellElement> => {
    const columnFormatter: TableCellFormatter = column.formatter
    const props: IProps = {
      column,
      rowIdx,
      value: row[column.key],
      dependentValues: row,
    }
    return (
      <th
        style={{ width, maxWidth: width }}
        key={`printable-grid-row-${rowIdx}-col-${colIdx}`}
      >
        {formatCell(columnFormatter, props.value, row, column)}
      </th>
    )
  }

  /**
   * Render table cells
   */
  renderCells = (columns: Column[], row: {}, rowIdx: number): JSX.Element[] => {
    return columns.map((column, colIndex) =>
      this.renderCell(column.width, rowIdx, row, colIndex, column)
    )
  }

  /**
   * Render table row
   */
  renderRow = (row: {}, rowIndex: number, columns: Column[]) => {
    let cells = []
    cells = cells.concat(this.renderCells(columns, row, rowIndex))
    return <tr key={`printable-grid-row-${rowIndex}`}>{cells}</tr>
  }

  /**
   * Render table rows
   */
  renderRows = (columns) => {
    return this.props.rows.map((row, rowIndex) =>
      this.renderRow(row, rowIndex, columns)
    )
  }

  /**
   * Slice array of columns that will fit into available width
   */
  fitColumns = (columns: Column[], widthAvailable: number): Column[] => {
    let fittedColumns = []
    if (columns.length && widthAvailable - columns[0].width > 0) {
      const column = columns.splice(0, 1)[0]
      widthAvailable -= column.width
      fittedColumns.push(column)
      fittedColumns = fittedColumns.concat(
        this.fitColumns(columns, widthAvailable)
      )
      return fittedColumns
    } else {
      return fittedColumns
    }
  }

  /**
   * Split columns into chunks (ColumnGroups) that will fit into available width
   * @param columns - columns to fit
   * @param widthAvailable - max width of fitted columns
   */
  splitColumnsByWidth = (
    columns: Column[],
    widthAvailable: number
  ): ColumnGroup[] => {
    let columnGroups: ColumnGroup[] = []
    if (columns.length) {
      let columnGroup: ColumnGroup = []
      const nextColumnsWillNotFit = columns[0].width > widthAvailable
      if (nextColumnsWillNotFit) {
        columnGroup.push(columns.splice(0, 1)[0])
      } else {
        const columnsWillFit: Column[] = this.fitColumns(
          columns,
          widthAvailable
        )
        columnGroup = columnGroup.concat(columnsWillFit)
      }
      columnGroups.push(columnGroup)
      columnGroups = columnGroups.concat(
        this.splitColumnsByWidth(columns, widthAvailable)
      )
      return columnGroups
    } else {
      return columnGroups
    }
  }

  /**
   * Get the width of fixed columns
   * @param columns - fixed columns
   */
  getFixedColumnsWidth = (columns: Column[]): number => {
    return columns.reduce((width, column) => {
      return width + (column.width ? column.width : 200)
    }, 0)
  }

  /**
   * Split table columns into chunks that will fit into fixed screen width,
   * each chunk has fixed columns (id) and set on fitted columns
   * @param columns - columns to split
   * @param splitWidth - width to split
   */
  splitTableByWidth = (columns: Column[], splitWidth): ColumnGroup[] => {
    const fixedColumns = columns.filter((column) => column.locked)
    const normalColumns = columns.filter((column) => !column.locked)
    const fixedColumnsWidth = this.getFixedColumnsWidth(fixedColumns)
    const widthAvailable = splitWidth - fixedColumnsWidth
    const normalColumnGroups: ColumnGroup[] = this.splitColumnsByWidth(
      normalColumns,
      widthAvailable
    )
    return normalColumnGroups.map((columnGroup: ColumnGroup) =>
      fixedColumns.concat(columnGroup)
    )
  }

  /**
   * Render table into div that will be printed in the separate page
   * @param columnGroup - columns to render
   * @param idnex - table index
   */
  renderTableToDiv = (columnGroup: ColumnGroup, index: number): JSX.Element => {
    return (
      <div
        key={`column-group-${index}`}
        style={{
          overflow: 'hidden',
          width: PORTRAIT_PRINT_WIDTH,
          pageBreakBefore: index === 0 ? 'auto' : 'always',
        }}
      >
        {this.renderTable(columnGroup)}
      </div>
    )
  }

  renderTable = (columnGroup: ColumnGroup): JSX.Element => (
    <table>
      <thead>{this.renderHeader(columnGroup)}</thead>
      <tbody>{this.renderRows(columnGroup)}</tbody>
    </table>
  )

  render() {
    // If decide to print all columns, change to this
    // const columnGroups = this.splitTableByWidth(this.props.columns.slice(), PORTRAIT_PRINT_WIDTH);
    // const tables: JSX.Element[] = columnGroups.map((columnGroup: ColumnGroup, index: number) =>
    //   this.renderTableToDiv(columnGroup, index),
    // );
    const table: JSX.Element = this.renderTable(this.props.columns)
    return <div>{table}</div>
  }
}

function mapDispatchToProps(dispatch: Dispatch): ReduxDispatchProps {
  const actions = {
    print,
  }
  return {
    actions: bindActionCreators(actions, dispatch),
  }
}

export const PrintableGrid = connect(
  null,
  mapDispatchToProps
)(PrintableGridComponent)

export function withPrintOption<P extends GridProps>(
  WrappedGrid: React.ComponentType<P>
) {
  return (props: P) => (
    <>
      <WrappedGrid className="no-print" {...props} />
      <PrintOnly>
        <PrintableGrid {...props} />
      </PrintOnly>
    </>
  )
}
