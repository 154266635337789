import { createSelector } from 'reselect'
import { AppState } from '../../types/app-state'
import { getMetricsSettings } from '../../components/common/metrics'

export const getMetrics = createSelector(
  (state: AppState) => state.user.profile,
  getMetricsSettings
)

export const getUsersArray = createSelector(
  (state: AppState) =>
    (state.blueprint.users && state.blueprint.users.data) || {},
  (users) => {
    const list = Object.values(users)

    list.sort((a, b) => {
      return a.name > b.name ? 1 : b.name > a.name ? -1 : 0
    })

    return list
  }
)
