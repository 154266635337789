import * as React from 'react'
import Toggle from 'react-toggle'

interface IProps {
  enabled: boolean
  onChange: (show: boolean) => void
}

export const MapGeofenceToggleComponent = (props: IProps) => {
  return (
    <div className="toggle-wrapper">
      <Toggle
        id="map=show-geofences"
        icons={false}
        checked={props.enabled}
        onChange={(e) => props.onChange(e.currentTarget.checked)}
      />
      <span className="toggle-label">Show Geofences</span>
    </div>
  )
}
export default MapGeofenceToggleComponent
