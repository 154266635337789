import * as React from 'react'
import { useLocalApi, useLocalApiFetch } from 'client/lib/api'
import { Alert, Button, Table } from 'react-bootstrap'
import { Loading } from '../common/Loading'
import { AutomaticPromotionListItem } from './AutomaticPromotionListItem'
import { Promotion } from 'client/types'
import { useHistory } from 'react-router'
import useSession from 'client/lib/hooks/useSession'
import { HistoryDialog } from './HistoryDialog'

interface PromotionsResponse {
  data: Promotion[]
}

export const AutomaticPromotions: React.FC = () => {
  const history = useHistory()
  const { selectedGroupId } = useSession()

  const {
    data: promotions,
    error,
    mutate,
  } = useLocalApi<PromotionsResponse>(
    `/promotions/${selectedGroupId}?type=auto`
  )

  const { apiDelete } = useLocalApiFetch()

  const [selectedPromotionId, setSelectedPromotionId] = React.useState(null)

  const handleDelete = async (id: number) => {
    await apiDelete(`/promotions/${id}`)
    mutate()
  }

  return (
    <div className="w-75">
      <HistoryDialog
        show={!!selectedPromotionId}
        promotionId={selectedPromotionId}
        onHide={() => setSelectedPromotionId(null)}
      />
      <div className="text-right mb-2">
        <Button onClick={() => history.push('/promotions/auto/create')}>
          Create new notification
        </Button>
      </div>
      {!promotions && !error && <Loading block />}
      {promotions?.data.length === 0 && (
        <Alert variant="info" className="text-center">
          No notifications found.
        </Alert>
      )}
      {promotions?.data.length > 0 && (
        <Table striped borderless responsive className="custom-table">
          <thead>
            <tr>
              <th>Trigger type</th>
              <th>Geofence</th>
              <th>Message</th>
              <th>Sent notifications</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {promotions.data.map((entry, index) => {
              return (
                <AutomaticPromotionListItem
                  key={index}
                  data={entry}
                  selected={entry && entry.id === selectedPromotionId}
                  onSelect={() => setSelectedPromotionId(entry.id)}
                  onDelete={() => handleDelete(entry.id)}
                />
              )
            })}
          </tbody>
        </Table>
      )}
    </div>
  )
}
