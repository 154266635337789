export const constants = {
  BATCH: 'blueprint/BATCH',
  BATCH_START: 'blueprint/BATCH_START',
  BATCH_END: 'blueprint/BATCH_END',
  BATCH_ERROR: 'blueprint/BATCH_ERROR',
  ORGANIZATIONS: 'blueprint/ORGANIZATIONS',
  ORGANIZATION_CHANGE: 'blueprint/ORGANIZATION_CHANGE',
  DEVICES: 'blueprint/DEVICES',
  USERS: 'blueprint/USERS',
  USER_DELETE: 'blueprint/USER_DELETE',
  USER_SAVE: 'blueprint/USER_SAVE',
}
