import * as React from 'react'
import { Row, Col, Button } from 'react-bootstrap'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Column } from 'client/types'

interface Props {
  item: Column
  nameProp: string
  addItemHandler: (item: Column) => void
}

export default function AvailableItem(props: Props) {
  return (
    <Row noGutters className="mb-2 bg-white align-items-center">
      <Col className="pl-2">{props.item.name}</Col>
      <Col className="text-right">
        <Button variant="link" onClick={() => props.addItemHandler(props.item)}>
          <FontAwesomeIcon icon={faPlus} className="text-success" />
        </Button>
      </Col>
    </Row>
  )
}
