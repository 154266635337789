import { AppState, DeviceView } from 'client/types'
import { useSelector } from 'react-redux'

const useDeviceEdition = (deviceId: string) => {
  const devices = useSelector<AppState, Record<string, Partial<DeviceView>>>(
    (state) => state.pageAdminDevices?.editedDevices
  )
  return devices[deviceId] ?? null
}

export default useDeviceEdition
