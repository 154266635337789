import * as React from 'react'
import { AppState, UserProfile } from 'client/types'
import { connect } from 'react-redux'
import { Button } from 'react-bootstrap'
import { bindActionCreators } from 'redux'
import { modalShow } from '../../../store/actions/page-admin-users'
import { User } from 'rest-api'
import { faPenToSquare } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

interface OwnProps {
  user: User
}

interface ReduxStateProps {
  currentUser: UserProfile
}

interface Props extends OwnProps, ReduxStateProps {
  actions: any
}

function mapStateToProps(state: AppState): ReduxStateProps {
  return {
    currentUser: state.user.profile,
  }
}

function mapDispatchToProps(dispatch) {
  const actions = { modalShow }
  return { actions: bindActionCreators(actions, dispatch) }
}

class UserEditButtonComponent extends React.Component<Props> {
  handleClick = () => {
    this.props.actions.modalShow(this.props.user)
  }

  render() {
    const disabled = this.props.currentUser?.id === this.props.user?.id

    return (
      <Button
        variant="outline-secondary"
        onClick={this.handleClick}
        disabled={disabled}
      >
        <FontAwesomeIcon icon={faPenToSquare} />
      </Button>
    )
  }
}

export const UserEditButton = connect(
  mapStateToProps,
  mapDispatchToProps
)(UserEditButtonComponent)
export default UserEditButton
