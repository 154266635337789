import {
  DEVICE_FIELDS,
  DEVICE_STATUS,
  DEVICE_STATUS_NAMES,
  MOVEMENT,
} from '../../../../common/consts'
import { DeviceView } from 'client/types'
import { cssVar } from 'client/lib/utilities'

export const DEVICE_CLUSTER_STATUS_COLORS = {
  Driving: cssVar('--cluster-status-riding'),
  Idle: cssVar('--cluster-status-parked'),
  Offline: cssVar('--cluster-status-unknown'),
}

export const DEVICE_STATUS_COLORS = {
  Driving: 'green',
  Idle: 'yellow',
  Offline: 'gray',
}

export const getDeviceStatus = (device: DeviceView): DEVICE_STATUS => {
  const online = device.online

  if (!online) {
    return DEVICE_STATUS[DEVICE_STATUS_NAMES.OFFLINE]
  } else if (device[DEVICE_FIELDS.MOVEMENT] === MOVEMENT.startOfDrive) {
    return DEVICE_STATUS[DEVICE_STATUS_NAMES.DRIVING]
  } else if (device[DEVICE_FIELDS.MOVEMENT] === MOVEMENT.driving) {
    return DEVICE_STATUS[DEVICE_STATUS_NAMES.DRIVING]
  } else if (device[DEVICE_FIELDS.MOVEMENT] === MOVEMENT.endOfDrive) {
    return DEVICE_STATUS[DEVICE_STATUS_NAMES.IDLE]
  } else if (device[DEVICE_FIELDS.MOVEMENT] === MOVEMENT.idle) {
    return DEVICE_STATUS[DEVICE_STATUS_NAMES.IDLE]
  } else if (device[DEVICE_FIELDS.MOVEMENT] !== undefined) {
    console.warn('Device status is incorrect', device)
    return DEVICE_STATUS[DEVICE_STATUS_NAMES.OFFLINE]
  }
}

export const getDeviceStatusName = (deviceStatusId: number): string => {
  if (deviceStatusId === DEVICE_STATUS[DEVICE_STATUS_NAMES.OFFLINE]) {
    return DEVICE_STATUS_NAMES.OFFLINE
  } else if (deviceStatusId === DEVICE_STATUS[DEVICE_STATUS_NAMES.DRIVING]) {
    return DEVICE_STATUS_NAMES.DRIVING
  } else if (deviceStatusId === DEVICE_STATUS[DEVICE_STATUS_NAMES.IDLE]) {
    return DEVICE_STATUS_NAMES.IDLE
  } else {
    return DEVICE_STATUS_NAMES.OFFLINE
  }
}

export const getStatusColor = (status: DEVICE_STATUS): string => {
  return (
    DEVICE_STATUS_COLORS[DEVICE_STATUS[status]] ??
    DEVICE_STATUS_COLORS[DEVICE_STATUS_NAMES.OFFLINE]
  )
}

export const getClusterColor = (status: DEVICE_STATUS): string => {
  return (
    DEVICE_CLUSTER_STATUS_COLORS[DEVICE_STATUS[status]] ??
    DEVICE_CLUSTER_STATUS_COLORS[DEVICE_STATUS_NAMES.OFFLINE]
  )
}

export const getDeviceIconColor = (device: DeviceView): string => {
  const deviceStatus = getDeviceStatus(device)
  return getStatusColor(deviceStatus)
}
