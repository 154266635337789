import * as React from 'react'
import { InfoWindow } from '@react-google-maps/api'
import Moment from 'react-moment'
import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'
import { DEVICE_FIELDS, USER_FIELDS } from '../../../../common/consts'
import {
  getTemperatureValue,
  getMetricsAbbreviation,
  Metrics,
} from '../../common/metrics'
import { loadStatusForReport } from '../../../store/actions/status-history'
import { AppState, ReportStatusHistory } from '../../../types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons'

interface OwnProps {
  deviceId: string
  dateTime: string
  reportId?: number
  userMetrics: Metrics
  closeTooltip: any
  position: google.maps.LatLngLiteral
}

interface ReduxStateProps {
  statusHistory: ReportStatusHistory
}

interface ReduxActions {
  loadStatusForReport: typeof loadStatusForReport
}

interface ReduxDispatchProps {
  actions: ReduxActions
}

interface Props extends OwnProps, ReduxStateProps, ReduxDispatchProps {}

const FieldWrapper = ({ children }) => (
  <div style={{ marginBottom: 5 }}>{children}</div>
)

export class BreadcrumbPopupComponent extends React.Component<Props> {
  componentDidMount() {
    const { statusHistory, reportId, actions, deviceId, dateTime } = this.props
    if (!statusHistory && reportId) {
      actions.loadStatusForReport(reportId, deviceId, dateTime)
    }
  }

  private renderReportTime = () => (
    <FieldWrapper>
      <span className="info-label">Report: </span>
      <Moment format="M/D/YY h:mm A (z)">{this.props.dateTime}</Moment>
    </FieldWrapper>
  )

  private renderPower = () => {
    const { POWER } = DEVICE_FIELDS
    const { data = {} } = this.props.statusHistory

    return (
      <FieldWrapper>
        <span className="info-label">Refrigeration: </span>
        <span>{data[POWER]}</span>
      </FieldWrapper>
    )
  }

  private renderOperation = () => {
    const { OPERATION } = DEVICE_FIELDS
    const { data = {} } = this.props.statusHistory

    return (
      <FieldWrapper>
        <span className="info-label">Operation: </span>
        <span>{data[OPERATION]}</span>
      </FieldWrapper>
    )
  }

  private renderZone1SetPoint = () => {
    const { DISPLAY_UNITS_TEMPERATURE } = USER_FIELDS
    const { ZONE_1_SPT } = DEVICE_FIELDS
    const {
      statusHistory: { data = {} },
      userMetrics,
    } = this.props
    const tempAbbreviation = getMetricsAbbreviation(
      DISPLAY_UNITS_TEMPERATURE,
      userMetrics
    )
    const temp =
      Math.round(
        getTemperatureValue(parseInt(data[ZONE_1_SPT], 10), userMetrics) * 10
      ) / 10

    return (
      <FieldWrapper>
        <span className="info-label">Zone 1 Set Point: </span>
        {temp}
        {tempAbbreviation}
      </FieldWrapper>
    )
  }

  private renderZone1Temp = () => {
    const { DISPLAY_UNITS_TEMPERATURE } = USER_FIELDS
    const { ZONE_1_TEMP } = DEVICE_FIELDS
    const {
      statusHistory: { data = {} },
      userMetrics,
    } = this.props
    const value = getTemperatureValue(
      parseInt(data[ZONE_1_TEMP], 10),
      userMetrics
    )
    const tempAbbreviation = getMetricsAbbreviation(
      DISPLAY_UNITS_TEMPERATURE,
      userMetrics
    )

    return (
      <FieldWrapper>
        <span className="info-label">Zone 1 Temp: </span>
        {Math.round(value * 10) / 10}
        {tempAbbreviation}
      </FieldWrapper>
    )
  }

  render() {
    if (!this.props.statusHistory) {
      return null
    }

    const { POWER } = DEVICE_FIELDS
    const { data = {}, error, loading, loaded } = this.props.statusHistory
    const isTurnedOn = data[POWER] === 'On'

    return (
      <InfoWindow
        onCloseClick={this.props.closeTooltip}
        position={this.props.position}
      >
        <div className="device-popup">
          {loading && (
            <FieldWrapper>
              <FontAwesomeIcon icon={faCircleNotch} spin />
            </FieldWrapper>
          )}
          {error && <FieldWrapper>Error loading data: {error}</FieldWrapper>}
          {loaded && (
            <div>
              {this.renderReportTime()}
              {this.renderPower()}
              {isTurnedOn && this.renderOperation()}
              {isTurnedOn && this.renderZone1SetPoint()}
              {isTurnedOn && this.renderZone1Temp()}
            </div>
          )}
        </div>
      </InfoWindow>
    )
  }
}

const mapStateToProps = (
  state: AppState,
  ownProps?: OwnProps
): ReduxStateProps => {
  return {
    statusHistory: state.statusHistory[ownProps.reportId],
  }
}

const mapDispatchToProps = (dispatch: Dispatch): ReduxDispatchProps => {
  const actions = {
    loadStatusForReport,
  }
  return {
    actions: bindActionCreators(actions, dispatch),
  }
}

export const BreadcrumbPopup = connect(
  mapStateToProps,
  mapDispatchToProps
)(BreadcrumbPopupComponent)
export default BreadcrumbPopup
