import { constants } from './constants'

type BatchRequest = ReduxActions.Action<{
  entities: string[]
}>

export const usersRequest = () => ({
  type: constants.USERS,
})

export const organizationsRequest = () => ({
  type: constants.ORGANIZATIONS,
})

export const batchRequest = (): BatchRequest => ({
  type: constants.BATCH,
  payload: {
    entities: [constants.DEVICES, constants.ORGANIZATIONS, constants.USERS],
  },
})

export function batchError(error: Error) {
  return {
    type: constants.BATCH_ERROR,
    payload: error,
  }
}
