import { actionTypes } from '../actions/status-history'
import { Action, handleActions, ReducerMap } from 'redux-actions'
import { StatusHistoryState } from '../../types'
import getInitialState from '../initial-state'
import { ReportStatusHistory } from '../../types/status-history-state'

const initialState: StatusHistoryState = getInitialState().statusHistory

interface LoadingPayload {
  reportId: number
}

type LoadingEndPayload = LoadingPayload & {
  data: any
}

type LoadingErrorPayload = LoadingPayload & {
  message: string
}

type ReducerPayload = LoadingPayload | LoadingEndPayload | LoadingErrorPayload
type LocationHistoryAction = Action<ReducerPayload>
type Reducer = (
  state: StatusHistoryState,
  action: LocationHistoryAction
) => StatusHistoryState

const loadingStart: Reducer = (state, action) => {
  const { reportId } = action.payload as LoadingPayload
  let reportData: ReportStatusHistory = { ...state[reportId] } || {}
  reportData = {
    ...reportData,
    loading: true,
    loaded: false,
    error: null,
    data: undefined,
  }
  return { ...state, [reportId]: reportData }
}

const loadingEnd: Reducer = (state, action) => {
  const { reportId, data } = action.payload as LoadingEndPayload
  let reportData: ReportStatusHistory = { ...state[reportId] } || {}
  reportData = {
    ...reportData,
    loading: false,
    loaded: true,
    error: null,
    data,
  }
  return { ...state, [reportId]: reportData }
}

const loadingError: Reducer = (state, action) => {
  const { reportId, message } = action.payload as LoadingErrorPayload
  let reportData: ReportStatusHistory = { ...state[reportId] } || {}
  reportData = {
    ...reportData,
    loading: false,
    loaded: false,
    error: message,
    data: undefined,
  }
  return { ...state, [reportId]: reportData }
}

const reducerMap: ReducerMap<StatusHistoryState, ReducerPayload> = {
  [actionTypes.LOAD_REPORT_START]: loadingStart,
  [actionTypes.LOAD_REPORT_END]: loadingEnd,
  [actionTypes.LOAD_REPORT_ERROR]: loadingError,
}
export const reducer: Reducer = handleActions(reducerMap, initialState)
export default reducer
