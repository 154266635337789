import ApiCommon from './common'
import {
  ResetPasswordRequest,
  User,
  UserListResponse,
  UserResponse,
} from 'rest-api'

class IDM {
  user: UserIDM

  constructor(comm: ApiCommon) {
    this.user = new UserIDM(comm)
  }
}

class UserIDM {
  comm: ApiCommon
  apiUrl: string

  constructor(comm: ApiCommon) {
    this.apiUrl = process.env.API_BASE_URL
    this.comm = comm
  }

  async resetPassword(userId: string): Promise<{ resetToken: string }> {
    return this.comm.getJsonWithAdminToken({
      url: `${this.apiUrl}/users/password-reset-token`,
      method: 'POST',
      body: {
        userId,
      },
    })
  }

  async changePasswordWithToken(
    userId: string,
    body: ResetPasswordRequest
  ): Promise<void> {
    return this.comm.getJsonWithAdminToken({
      url: `${this.apiUrl}/users/${userId}/reset-password`,
      body,
      method: 'POST',
    })
  }

  async getUserByEmail(email: string): Promise<User | null> {
    const url = new URL(`${this.apiUrl}/users`)
    url.searchParams.append('email', email)
    const options = {
      url: url.toString(),
      method: 'GET',
    }
    const res: UserListResponse = await this.comm.getJsonWithAdminToken(options)
    return res.users.results[0] || null
  }

  async getUserById(userId: string): Promise<UserResponse> {
    return this.comm.getJsonWithAdminToken({
      url: this.apiUrl + '/users/' + userId,
      method: 'GET',
    })
  }
}

export default IDM
