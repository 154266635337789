import { HttpError } from 'client/lib/api'
import { Dispatch } from 'redux'
import { logout } from './login'

export const localApiError = (error: HttpError) => {
  return async (dispatch: Dispatch<any>) => {
    if (error.statusCode === 403) {
      dispatch(logout())
    }
  }
}
