import * as React from 'react'
import { connect } from 'react-redux'
import { concat } from 'lodash'
import memoizeOne from 'memoize-one'
import {
  Dropdown,
  DropdownButton,
  DropdownButtonProps,
  Spinner,
} from 'react-bootstrap'
import { Group } from 'rest-api'
import { bindActionCreators } from 'redux'
import { changeGroup } from 'client/store/blueprint/groups/actions'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleRight } from '@fortawesome/free-solid-svg-icons'
import { GroupHeirarchy } from 'client/store/blueprint/groups/reducers'
import { getHierarchy } from 'client/store/selectors/groups'
import { AppState } from 'client/types'

interface ReduxStateProps {
  hierarchy: GroupHeirarchy[]
  groups: Record<string, Group>
}

interface ReduxDispatchProps {
  actions: { changeGroup: typeof changeGroup }
}

interface Props extends ReduxStateProps, ReduxDispatchProps {
  value?: string
  onChange: (groupId: string) => void
  variant?: DropdownButtonProps['variant']
  size?: DropdownButtonProps['size']
}

function mapStateToProps(state: AppState): ReduxStateProps {
  return {
    hierarchy: getHierarchy(state),
    groups: state.blueprint?.organizations?.data,
  }
}

function mapDispatchToProps(dispatch): ReduxDispatchProps {
  const actions = { changeGroup }
  return { actions: bindActionCreators(actions, dispatch) }
}

function getOptionsForHierarchy(
  root: GroupHeirarchy,
  depth: number,
  lastChild?: boolean,
  lastParent?: boolean
) {
  let options = []

  const label = root.organization.name

  options.push({
    label,
    value: root.organization.id,
    depth,
    lastParent,
    className: lastChild ? 'last-child' : 'child',
  })

  root.children.forEach((child: GroupHeirarchy, index, arr) => {
    options = concat(
      options,
      getOptionsForHierarchy(
        child,
        depth + 1,
        index === arr.length - 1,
        lastChild
      )
    )
  })

  return options
}

const getDropdownItems = memoizeOne(
  (groups: GroupHeirarchy[], selectedValue: string) => {
    let options = null

    if (groups) {
      options = []
      groups.forEach((entry: GroupHeirarchy) => {
        options = concat(options, getOptionsForHierarchy(entry, 0))
      })
    }

    return options.map((item) => {
      const { depth, value, label } = item

      return (
        <Dropdown.Item
          key={value}
          eventKey={value}
          active={value === selectedValue}
        >
          {depth > 0 ? (
            <span>
              {depth > 1 &&
                Array(depth - 1)
                  .fill(null)
                  .map((v, i) => {
                    return (
                      <FontAwesomeIcon
                        key={i}
                        icon={faAngleRight}
                        className="mr-2"
                      />
                    )
                  })}
              <FontAwesomeIcon icon={faAngleRight} className="mr-2" /> {label}
            </span>
          ) : (
            label
          )}
        </Dropdown.Item>
      )
    })
  }
)

class DealerSelectorComponent extends React.PureComponent<Props> {
  render() {
    if (!this.props.groups) {
      return <Spinner animation="border" />
    }

    const items = getDropdownItems(
      this.props.hierarchy,
      this.props.value ?? null
    )

    const selectedGroup = this.props.groups[this.props.value] ?? null

    return (
      <DropdownButton
        title={selectedGroup?.name ?? '-- Select dealership --'}
        menuAlign="right"
        size={this.props.size}
        variant={this.props.variant ?? 'primary'}
        onSelect={(key) => this.props.onChange(key)}
      >
        {items}
      </DropdownButton>
    )
  }
}

export const DealerSelector = connect(
  mapStateToProps,
  mapDispatchToProps
)(DealerSelectorComponent)
export default DealerSelector
