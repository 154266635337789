import { combineReducers } from 'redux'
import { reducer as devices } from './devices/reducers'
import { reducer as organizations } from './groups/reducers'
import { reducer as users } from './users/reducers'
import { GroupsState } from '../../types/groups-state'
import { DevicesState } from '../../types/devices-state'
import { UsersState } from '../../types/users-state'

export interface BlueprintState {
  organizations: GroupsState
  devices: DevicesState
  users: UsersState
}

export const reducer = combineReducers({
  devices,
  organizations,
  users,
})
