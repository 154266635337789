import * as React from 'react'
import { renderToStaticMarkup } from 'react-dom/server'
import { Dispatch, bindActionCreators } from 'redux'
import { connect, Provider } from 'react-redux'
import { Button } from 'react-bootstrap'

import { AppState, Column } from '../../../types'
import { exportXLS } from '../../../store/actions/exports'
import { store } from '../../../store/configure-store'
import { formatCell } from './table'
import UserPrefContext from '../../providers/user-preferences-context'
import OrgsContext from '../../providers/organizations-context'
import { BrowserRouter } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileExport } from '@fortawesome/free-solid-svg-icons'

interface OwnProps {
  data: any[]
  fileName: string
  columns: Column[]
}

interface ReduxStateProps {
  shouldExport: boolean
}

interface ReduxActions {
  exportXLS: typeof exportXLS
}

interface ReduxDispatchProps {
  actions: ReduxActions
}

interface Props extends OwnProps, ReduxStateProps, ReduxDispatchProps {}

function formatValue(value: any, data: any, column: Column) {
  const cell = (
    <Provider store={store}>
      <UserPrefContext.Provider
        value={{ profile: store.getState().user.profile }}
      >
        <OrgsContext.Provider
          value={{
            organizations: store.getState().blueprint.organizations.data,
            selectedGroupId: store.getState().blueprint.organizations.current,
          }}
        >
          <BrowserRouter>
            {formatCell(column.formatter, value, data, column)}
          </BrowserRouter>
        </OrgsContext.Provider>
      </UserPrefContext.Provider>
    </Provider>
  )

  const stringValue =
    cell !== undefined && cell !== null ? renderToStaticMarkup(cell) : ''

  // Remove html tags
  const strippedValue = stringValue.replace(/<.+?>/g, '')

  // Sanitize table content to csv export
  if (strippedValue.indexOf('"') !== -1) {
    return `"${strippedValue.replace('"', '""')}"`
  } else if (
    strippedValue.indexOf(',') !== -1 ||
    strippedValue.indexOf('\r\n') !== -1 ||
    strippedValue.indexOf('\n') !== -1
  ) {
    return `"${strippedValue}"`
  } else {
    return strippedValue
  }
}

function makeRow(columns: Column[], data: any): Record<string, any> {
  const row = {}
  const fixedColumns = columns.filter((column: Column) => column.locked)
  const normalColumns = columns.filter((column: Column) => !column.locked)
  fixedColumns.forEach((column) => {
    row[column.name] = formatValue(data[column.key], data, column)
  })
  normalColumns.forEach((column) => {
    row[column.name] = formatValue(data[column.key], data, column)
  })
  return row
}

export class DeviceExportButtonComponent extends React.PureComponent<Props> {
  getCsvData = () => {
    const csvdata = []
    if (this.props.data) {
      this.props.data.forEach((data) => {
        const row = makeRow(this.props.columns, data)
        csvdata.push(row)
      })
    }
    return csvdata
  }

  export = () => {
    const data = this.getCsvData()
    this.props.actions.exportXLS(data, this.props.fileName)
  }

  render() {
    return (
      <Button
        onClick={this.export}
        variant="secondary"
        disabled={!this.props.data}
        title="Export as CSV"
      >
        <FontAwesomeIcon icon={faFileExport} /> Export
      </Button>
    )
  }
}

const mapStateToProps = (state: AppState): ReduxStateProps => ({
  shouldExport: state.export.shouldExport,
})

const mapDispatchToProps = (dispatch: Dispatch): ReduxDispatchProps => {
  const actions = {
    exportXLS,
  }
  return { actions: bindActionCreators(actions, dispatch) }
}

export const DeviceExportButton = connect(
  mapStateToProps,
  mapDispatchToProps
)(DeviceExportButtonComponent)

export default DeviceExportButton
