import * as XLSX from 'xlsx'
import * as fileSaver from 'file-saver'

function s2ab(s) {
  const buf = new ArrayBuffer(s.length)
  const view = new Uint8Array(buf)
  for (let i = 0; i !== s.length; ++i) {
    view[i] = s.charCodeAt(i) & 0xff
  }
  return buf
}

export function exportXLS(data: any, fileName: string): Promise<any> {
  // Need to remove any special characters from the fileName or XLSX will choke when creating the sheet name
  fileName = fileName.replace(/[^\w\s]/gi, '')
  const wb = { SheetNames: ['Sheet 1'], Sheets: {} }
  wb.Sheets['Sheet 1'] = XLSX.utils.json_to_sheet(data)
  const wbout = XLSX.write(wb, {
    bookType: 'xlsx',
    bookSST: true,
    type: 'binary',
  })
  try {
    fileSaver.saveAs(
      new Blob([s2ab(wbout)], { type: 'application/octet-stream' }),
      `${fileName}.xlsx`
    )
  } catch (e) {
    console.error(e, wbout)
  }
  return wbout
}
