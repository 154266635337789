import { constants } from './reducers'
import api from '../../../api'
import { logout } from '../../actions/login'
import * as mqtt from '../../actions/mqtt'
import { getGeofences } from '../../actions/geofences'
import { Device } from 'rest-api'

export function upsert(devices: Device[]) {
  return {
    type: constants.UPSERT,
    payload: devices,
  }
}

export function remove(deviceId: string) {
  return {
    type: constants.REMOVE,
    payload: deviceId,
  }
}

export function initialState() {
  return {
    type: constants.INITIAL_STATE,
  }
}

export function loadingStart() {
  return {
    type: constants.LOADING_START,
  }
}

export function loadingFail(error) {
  return {
    type: constants.LOADING_ERROR,
    payload: error,
  }
}

export function loadReset() {
  return {
    type: constants.LOAD_RESET,
  }
}

export function loadingEnd() {
  return {
    type: constants.LOADING_END,
  }
}

export function loadingChildStart() {
  return {
    type: constants.LOADING_CHILD_START,
  }
}

export function loadingChildEnd() {
  return {
    type: constants.LOADING_CHILD_END,
  }
}

export function setError(message: string) {
  return {
    type: constants.ERROR,
    payload: message,
  }
}

export function loadDevices(groupId: string) {
  return async (dispatch, getState) => {
    dispatch(loadingStart())

    try {
      const devices = await api.getAllDevices(groupId)
      dispatch(upsert(devices))
      dispatch(loadingEnd())

      // subscribe to reflections for all devices (check for duplicates downstream)
      const allDeviceIds = devices.map((v) => v.id)
      dispatch(mqtt.addDevices(allDeviceIds))
    } catch (error) {
      dispatch(loadingFail(error))
      if (error && error.error && error.error.message === 'Unauthorized') {
        dispatch(logout())
      }
    }
  }
}

export function clear() {
  return {
    type: constants.CLEAR,
  }
}

export function mqttRemoveAll() {
  return {
    type: mqtt.constants.REMOVE_ALL_DEVICES,
  }
}

export function clearAndLoad(organizationId: string) {
  return async (dispatch) => {
    dispatch(clear())
    dispatch(mqttRemoveAll())

    const loadDevicesAndGeofences = () => {
      dispatch(loadDevices(organizationId))
      dispatch(getGeofences(organizationId))
    }

    // Delay the load of the devices to give the mqtt client time to unsubscribe all of the old devices
    setTimeout(loadDevicesAndGeofences, 1500)
  }
}
