import * as localAPI from '../../../lib/local-api/index'
import * as React from 'react'
import { Link } from 'react-router-dom'
import { RequestStatus } from '../../../types/index'
import { Alert, Form, Button, Spinner } from 'react-bootstrap'
import Landing from '../landing'

interface ResetPasswordState {
  email?: string
  error?: string
  requestStatus: RequestStatus
}

export class ResetPassword extends React.Component<never, ResetPasswordState> {
  state: ResetPasswordState = {
    email: '',
    requestStatus: RequestStatus.REQUEST_NOT_SENT,
  }

  handleChange(fieldName, event) {
    const value = event.target.value

    this.setState((state) => {
      state[fieldName] = value
      return state
    })
  }

  autocontrol(fieldName: string) {
    return {
      name: fieldName,
      value: this.state[fieldName] || '',
      onChange: this.handleChange.bind(this, fieldName),
      containerClassName: 'form-group',
      className: 'form-control',
    }
  }

  render() {
    let form = (
      <Form
        method="POST"
        onSubmit={this.handleSubmit.bind(this)}
        id="reset-password-form"
      >
        <h3 className="text-center">Reset Password</h3>

        <Form.Group>
          <Form.Control
            placeholder="Email"
            required
            type="email"
            size="lg"
            {...this.autocontrol('email')}
          />
        </Form.Group>

        {this.state.error && <Alert variant="danger">{this.state.error}</Alert>}

        <Form.Group>
          <Button
            block
            variant="primary"
            type="submit"
            disabled={this.state.requestStatus === RequestStatus.REQUEST_SENT}
          >
            Reset Password
            {this.state.requestStatus === RequestStatus.REQUEST_SENT && (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
                className="ml-2"
              />
            )}
          </Button>
        </Form.Group>

        <div className="mt-2 text-center">
          <Link to="/login">Remember Password?</Link>
        </div>
      </Form>
    )

    if (this.state.requestStatus === RequestStatus.REQUEST_SUCCESS) {
      form = (
        <Alert variant="success">
          <p>Please check your email account.</p>
          <p>
            We have sent an email with the instructions on how to reset the
            password.
          </p>
        </Alert>
      )
    }
    return <Landing>{form}</Landing>
  }

  async handleSubmit(event: any) {
    event.preventDefault()

    this.setState({
      requestStatus: RequestStatus.REQUEST_SENT,
    })

    try {
      await localAPI.user.resetPassword(this.state.email)

      this.setState({
        requestStatus: RequestStatus.REQUEST_SUCCESS,
      })
    } catch (err) {
      this.setState({
        error: err.message,
        requestStatus: RequestStatus.REQUEST_ERROR,
      })
    }
  }
}

export default ResetPassword
