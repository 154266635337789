import * as React from 'react'
import { Polyline } from '@react-google-maps/api'

interface OwnProps {
  visible?: boolean
  path?: google.maps.LatLng[] | google.maps.LatLngLiteral[]
  strokeColor?: string
  strokeOpacity?: number
  strokeWeight?: number
  zIndex?: number
}

const MapPathComponent = (props: OwnProps) => {
  return (
    <Polyline
      options={{
        visible: props.visible,
        zIndex: props.zIndex,
        path: props.path,
        strokeColor: props.strokeColor,
        strokeOpacity: props.strokeOpacity,
        strokeWeight: props.strokeWeight,
      }}
    />
  )
}

export const MapPath = MapPathComponent
export default MapPath
