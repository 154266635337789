import * as React from 'react'
import { MapLegend, ILegendSymbol } from './legend'
import { LEGEND_SYMBOLS } from '../../../../common/consts'
import { LegendFilter } from '../../../types'

interface IProps {
  symbols?: ILegendSymbol[]
  allowLegendFilter?: boolean
  individualTrailerMap?: boolean
  legendFilter: LegendFilter
  onChange: (legendFilter: LegendFilter) => void
}

interface IState {
  open: boolean
}

export class DeviceLegend extends React.PureComponent<IProps, IState> {
  static defaultProps = {
    symbols: LEGEND_SYMBOLS,
  }

  state = {
    open: false,
  }

  toggleLegend = () => {
    this.setState({ open: !this.state.open })
  }

  render() {
    return (
      <MapLegend
        symbols={this.props.symbols}
        legendFilter={this.props.legendFilter}
        open={this.state.open}
        toggleLegend={this.toggleLegend}
        individualTrailerMap={this.props.individualTrailerMap}
        updateLegendFilter={(legendFilter) => {
          if (this.props.allowLegendFilter) {
            this.props.onChange(legendFilter)
          }
        }}
      />
    )
  }
}

export default DeviceLegend
