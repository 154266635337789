import { actionTypes } from '../actions/location-history'
import { Action, handleActions } from 'redux-actions'
import { LocationHistoryState } from '../../types/location-history'
import { LocationHistoryItem } from 'common/types/local-api'
import getInitialState from '../initial-state'

const initialState: LocationHistoryState = getInitialState().locationHistory

interface LoadingEndPayload {
  id: string
  data: LocationHistoryItem[]
}
type ReducerPayload = LoadingEndPayload | string
type LocationHistoryAction = Action<ReducerPayload>
type Reducer = (
  state: LocationHistoryState,
  action: LocationHistoryAction
) => LocationHistoryState

const loadingEnd: Reducer = (state, action) => {
  const { id, data } = action.payload as LoadingEndPayload
  const newHistory = [...data]
  const newData = { ...state.data, [id]: newHistory }
  return { ...state, data: newData, loading: false }
}

const setTimeFrame: Reducer = (state, action) => {
  return { ...state, timeframe: action.payload as string }
}

const reducerMap = {
  [actionTypes.LOADING_START]: (state, action) => ({
    ...state,
    loading: true,
    error: null,
  }),
  [actionTypes.LOADING_END]: loadingEnd,
  [actionTypes.LOADING_FAIL]: (state, action) => ({
    ...state,
    loading: false,
    error: action.payload,
  }),
  [actionTypes.TIMEFRAME_SET]: setTimeFrame,
}

export const reducer: Reducer = handleActions(reducerMap, initialState)
export default reducer
