import { deviceReports } from '../actions/index'
import getInitialState from '../initial-state'
import { DeviceReportsState } from '../../types/index'
import { AppState } from '../../types/app-state'

const { constants } = deviceReports
const initialState: DeviceReportsState = getInitialState().deviceReports

export function hasNewData(state: AppState, deviceId: string): boolean {
  return (
    state.deviceReports.newDataAlert &&
    state.deviceReports.newDataAlert.deviceId === deviceId &&
    state.deviceReports.newDataAlert.hasNewData
  )
}

export default function (
  state: DeviceReportsState = initialState,
  action: ReduxActions.Action<any>
): DeviceReportsState {
  let reports
  let data
  switch (action.type) {
    case constants.LOADING_START:
      return Object.assign({}, state, {
        error: null,
        loading: true,
        newDataAlert: {
          deviceId: action.payload.deviceId,
          hasNewData: false,
        },
      })

    case constants.LOADING_FAIL:
      reports = {}
      reports[action.payload.deviceId] = []
      data = Object.assign({}, state.data, reports)

      return Object.assign({}, state, {
        error: action.payload.message,
        data,
        loading: false,
        loaded: false,
        newDataAlert: {
          deviceId: action.payload.deviceId,
          hasNewData: false,
        },
      })

    case constants.LOADING_END:
      reports = {}
      reports[action.payload.deviceId] = action.payload.response
      data = Object.assign({}, state.data, reports)

      return Object.assign({}, state, {
        data,
        loading: false,
        loaded: true,
        newDataAlert: {
          deviceId: action.payload.deviceId,
          hasNewData: false,
        },
      })

    case constants.ENABLE_NEW_DATA_REPORT:
      return Object.assign({}, state, {
        newDataAlertEnabled: true,
        newDataAlert: Object.assign({}, state.newDataAlert, {
          hasNewData: false,
        }),
      })

    case constants.SET_NEW_DATA_ALERT:
      // Only update the state if we are clearing it (hasNewData = false) or if the deviceId matches
      if (
        (state.newDataAlert &&
          state.newDataAlert.deviceId === action.payload.deviceId) ||
        !action.payload.hasNewData
      ) {
        return Object.assign({}, state, {
          newDataAlert: action.payload,
        })
      } else {
        return state
      }

    case constants.DEVICE_REPORT_FILTER_TERM:
      return Object.assign({}, state, {
        filterTerm: action.payload.term,
      })

    case constants.DEVICE_REPORT_CLEAR_FILTERS:
      return {
        ...state,
        filterTerm: null,
      }

    default:
      return state
  }
}
