import './main.scss'

import * as React from 'react'
import { Provider } from 'react-redux'

import { store } from './store/configure-store'

import { App, Login, ResetPassword, ChangePassword } from './components'
import { render } from 'react-dom'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import { SWRConfig } from 'swr'

const Root = () => {
  return (
    <main className="app">
      <Provider store={store}>
        <BrowserRouter>
          <SWRConfig
            value={{
              fetcher: window.fetch,
              errorRetryCount: 3,
            }}
          >
            <Switch>
              <Route path="/login" component={Login} />
              <Route path="/reset-password" component={ResetPassword} />
              <Route
                path="/change-password/:userId/:passwordResetToken"
                component={ChangePassword}
              />
              <App />
            </Switch>
          </SWRConfig>
        </BrowserRouter>
      </Provider>
    </main>
  )
}
render(<Root />, document.getElementById('app'))
