import * as React from 'react'
import { connect } from 'react-redux'
import { Button } from 'react-bootstrap'
import { bindActionCreators } from 'redux'
import { modalMapShow } from '../../store/actions/grid'
import { DeviceView } from 'client/types'

interface OwnProps {
  device: DeviceView
  btnLabel: React.ReactNode
  disableLink?: boolean
  className?: string
}

interface Props extends OwnProps {
  actions: any
}

function mapDispatchToProps(dispatch) {
  const actions = { modalMapShow }
  return { actions: bindActionCreators(actions, dispatch) }
}

export class DeviceMapButtonComponent extends React.Component<Props> {
  handleClick = () => {
    this.props.actions.modalMapShow(this.props.device.id)
  }

  render() {
    if (!this.props.device.id || this.props.disableLink) {
      return <span className={this.props.className}>{this.props.btnLabel}</span>
    }

    return (
      <Button
        variant="link"
        className={this.props.className}
        onClick={this.handleClick}
      >
        {this.props.btnLabel}
      </Button>
    )
  }
}

export const DeviceMapButton = connect(
  null,
  mapDispatchToProps
)(DeviceMapButtonComponent)
export default DeviceMapButton
