import { createSelector } from 'reselect'

import { AppState } from '../../types'
import { filterDevicesByTerm } from '../reducers/grid'
import { getFlattenedHierarchy } from './groups'
import { DeviceReport } from '../../lib/local-api'
import { gridColumns } from 'client/components/grid/columns'

export const getFilteredReports = createSelector(
  (state: AppState, deviceId: string) =>
    state.deviceReports.data && deviceId
      ? state.deviceReports.data[deviceId]
      : [],
  (state: AppState) => state.deviceReports.filterTerm,
  (state: AppState) => state.user && state.user.profile,
  getFlattenedHierarchy,
  (reports, filterTerm, userProfile, orgHierarchy): DeviceReport[] => {
    return filterTerm
      ? filterDevicesByTerm(
          reports,
          filterTerm,
          userProfile,
          orgHierarchy,
          gridColumns
        )
      : reports
  }
)
