import { USER_ROLES } from '../../consts'
import { UserRole } from 'rest-api'

type UserWithRole = { role: UserRole }

export default class Acl {
  private hasAdminPermissions(role: string): boolean {
    return (
      role === USER_ROLES.ADMIN ||
      role === USER_ROLES.DEALER_ADMIN ||
      role === USER_ROLES.DEALER_GROUP_ADMIN
    )
  }

  private hasReadPermissions(role: string): boolean {
    return (
      role === USER_ROLES.ADMIN ||
      role === USER_ROLES.DEALER_ADMIN ||
      role === USER_ROLES.DEALER_GROUP_ADMIN ||
      role === USER_ROLES.READ
    )
  }

  public hasPermissions(role: string, userRole: string): boolean {
    if (userRole === USER_ROLES.ADMIN) {
      return true
    }
    switch (role) {
      case USER_ROLES.DEALER_ADMIN:
      case USER_ROLES.DEALER_GROUP_ADMIN:
        return this.hasAdminPermissions(userRole)
      case USER_ROLES.READ:
        return this.hasReadPermissions(userRole)
      default:
        return false
    }
  }

  public hasAdmin(user: UserWithRole): boolean {
    return this.hasAdminPermissions(user.role)
  }

  public hasAccessToExtendedFeatures(user: UserWithRole): boolean {
    return (
      user.role === USER_ROLES.ADMIN ||
      user.role === USER_ROLES.DEALER_GROUP_ADMIN
    )
  }
}
