import * as React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Button, Alert } from 'react-bootstrap'

import { GeofenceControlPanel } from './GeofenceControlPanel'
import GeofenceMap from './GeofenceMap'
import { GeofenceEditPanel } from './GeofenceEditPanel'
import { GeofenceCreatePanel } from './GeofenceCreatePanel'
import { AppState } from 'client/types'
import { Geofence } from 'common/types/local-api'
import { create } from 'client/store/actions/page-admin-geofences'

interface ReduxActions {
  create: typeof create
}

interface ReduxDispatchProps {
  actions: ReduxActions
}

interface ReduxStateProps {
  geofence: Geofence
  editing: boolean
  step: number
  startSave?: boolean
  error: string
}

function mapStateToProps(state: AppState): ReduxStateProps {
  return {
    geofence: state.pageAdminGeofences.geofence,
    editing: state.pageAdminGeofences.editing,
    step: state.pageAdminGeofences.step,
    startSave: state.pageAdminGeofences.startSave,
    error: state.geofences.error,
  }
}

function mapDispatchToProps(dispatch): ReduxDispatchProps {
  const actions = { create }
  return { actions: bindActionCreators(actions, dispatch) }
}

interface OwnProps extends ReduxStateProps, ReduxDispatchProps {}

export class GeofencesComponent extends React.Component<OwnProps> {
  handleCreate = () => {
    this.props.actions.create()
  }

  render() {
    const Error = () => {
      if (!this.props.error) {
        return null
      }

      return (
        <Alert variant="danger">
          There was an error while loading the alerts: {this.props.error}
        </Alert>
      )
    }

    const mapProps = {
      editing: this.props.editing,
      geofence: this.props.geofence,
      startSave: this.props.startSave,
      step: this.props.step,
    }

    return (
      <div className="d-flex flex-column h-100">
        <div className="d-flex mb-2">
          <div className="flex-grow-1">
            <h1 style={{ fontSize: '20px' }}>Geofences</h1>
          </div>
          <div className="flex-justify-end">
            <Button onClick={this.handleCreate}>Create new geofence</Button>
          </div>
        </div>
        <Error />
        <div className="d-flex flex-grow-1">
          <div className="mr-3 w-25">
            {!this.props.editing && <GeofenceControlPanel />}
            {this.props.editing && this.props.step > 1 && <GeofenceEditPanel />}
            {this.props.editing && this.props.step < 2 && (
              <GeofenceCreatePanel />
            )}
          </div>
          <div className="flex-fill">
            <GeofenceMap {...mapProps} />
          </div>
        </div>
      </div>
    )
  }
}
export const GeofenceEditor = connect(
  mapStateToProps,
  mapDispatchToProps
)(GeofencesComponent)

export default GeofenceEditor
