import { connect } from 'react-redux'
import * as React from 'react'
import { bindActionCreators } from 'redux'
import { Button, Alert, Table } from 'react-bootstrap'

import { AppState } from '../../../types'
import { usersRequest } from '../../../store/blueprint/actions'
import { getUsersArray } from '../../../store/selectors/user'
import { modalShow } from '../../../store/actions/page-admin-users'
import { UserModal } from './EditModal'
import { User } from 'rest-api'
import { Loading } from 'client/components/common/Loading'
import UserDeleteButton from './user-delete-button'
import UserEditButton from './user-edit-button'
import { UserRole } from './user-role'

interface ReduxActions {
  usersRequest: typeof usersRequest
  modalShow: typeof modalShow
}

interface ReduxDispatchProps {
  actions: ReduxActions
}

interface ReduxStateProps {
  loaded: boolean
  loading: boolean
  deleting: boolean
  users: User[]
  currentUser: User
}

function mapStateToProps(state: AppState): ReduxStateProps {
  return {
    users: getUsersArray(state),
    deleting: state.user.deleting,
    loaded: state.blueprint.users.loaded,
    loading: state.blueprint.users.loading,
    currentUser: state.user.profile,
  }
}

function mapDispatchToProps(dispatch): ReduxDispatchProps {
  const actions = { usersRequest, modalShow }
  return { actions: bindActionCreators(actions, dispatch) }
}

interface OwnProps extends ReduxStateProps, ReduxDispatchProps {}

export class UsersComponent extends React.PureComponent<OwnProps> {
  handleAddClick = () => {
    this.props.actions.modalShow({})
  }

  componentDidMount() {
    if (!this.props.loaded) {
      this.props.actions.usersRequest()
    }
  }

  render() {
    const hasWritePermissions = this.props.currentUser.role !== 'read_only'

    return (
      <div className="w-75">
        {this.props.loading && <Loading block />}

        <UserModal />

        {hasWritePermissions && (
          <div className="text-right mb-2">
            <Button onClick={this.handleAddClick}>Add User</Button>
          </div>
        )}

        {this.props.loaded && this.props.users.length === 0 && (
          <Alert variant="warning">
            There are no users associated with this account.
          </Alert>
        )}

        {this.props.users.length > 0 && (
          <Table striped borderless responsive className="custom-table">
            <thead>
              <tr>
                <th>Name</th>
                <th>Email</th>
                <th>Role</th>
                <th className="text-right">Actions</th>
              </tr>
            </thead>
            <tbody>
              {this.props.users.map((entry) => {
                return (
                  <tr key={entry.id}>
                    <td>{entry.name}</td>
                    <td>{entry.email}</td>
                    <td>
                      <UserRole user={entry} />
                    </td>
                    <td className="text-right">
                      {hasWritePermissions && (
                        <>
                          <span className="mr-2">
                            <UserEditButton user={entry} />
                          </span>
                          <UserDeleteButton user={entry} />
                        </>
                      )}
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </Table>
        )}
      </div>
    )
  }
}

export const Users = connect(
  mapStateToProps,
  mapDispatchToProps
)(UsersComponent)
export default Users
