import * as React from 'react'
import { findDOMNode } from 'react-dom'
import { DragSource, DropTarget } from 'react-dnd'
import { flow } from 'lodash'
import { Button, Col, Container, Row } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMinus, faSort } from '@fortawesome/free-solid-svg-icons'

interface OwnProps {
  item: any
  nameProp: string
  removeItemHandler: (item: any) => null
  reorderHandler: (from: number, to: number) => void
}

interface ReactDndProps {
  connectDragSource: any
  connectDragPreview: any
  connectDropTarget: any
  isDragging: boolean
}

interface Props extends OwnProps, ReactDndProps {}

const itemSource = {
  beginDrag(props) {
    return {
      index: props.index,
    }
  },
}

/**
 * @link https://github.com/react-dnd/react-dnd/blob/master/examples/04%20Sortable/Simple/Card.js
 */
const itemTarget = {
  hover(props, monitor, component) {
    const dragIndex = monitor.getItem().index
    const hoverIndex = props.index

    // Don't replace items with themselves
    if (dragIndex === hoverIndex) {
      return
    }

    // Determine rectangle on screen
    const domNode = findDOMNode(component) as Element
    const hoverBoundingRect = domNode.getBoundingClientRect()

    // Get vertical middle
    const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2

    // Determine mouse position
    const clientOffset = monitor.getClientOffset()

    // Get pixels to the top
    const hoverClientY = clientOffset.y - hoverBoundingRect.top

    // Only perform the move when the mouse has crossed half of the items height
    // When dragging downwards, only move when the cursor is below 50%
    // When dragging upwards, only move when the cursor is above 50%

    // Dragging downwards
    if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
      return
    }

    // Dragging upwards
    if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
      return
    }

    // Time to actually perform the action
    props.reorderHandler(dragIndex, hoverIndex)

    // Note: we're mutating the monitor item here!
    // Generally it's better to avoid mutations,
    // but it's good here for the sake of performance
    // to avoid expensive index searches.
    monitor.getItem().index = hoverIndex
  },
}

function SelectedItemComponent(props: Props) {
  const {
    isDragging,
    connectDragSource,
    connectDragPreview,
    connectDropTarget,
  } = props
  return connectDragPreview(
    connectDropTarget(
      <div className={`bg-white mb-2 ${isDragging ? 'column-draging' : ''}`}>
        <Container fluid className="p-0">
          <Row noGutters className="align-items-center">
            {connectDragSource(
              <div className="col-lg-1 column-sort pl-2">
                <FontAwesomeIcon title="Reorder" icon={faSort} />
              </div>
            )}
            <Col>{props.item[props.nameProp]}</Col>
            <Col className="text-right">
              <Button
                variant="link"
                onClick={() => props.removeItemHandler(props.item)}
              >
                <FontAwesomeIcon
                  title="Remove"
                  icon={faMinus}
                  className="text-danger"
                />
              </Button>
            </Col>
          </Row>
        </Container>
      </div>
    )
  )
}

function connectTarget(connect) {
  return {
    connectDropTarget: connect.dropTarget(),
  }
}

function connectSource(connect, monitor) {
  return {
    connectDragSource: connect.dragSource(),
    connectDragPreview: connect.dragPreview(),
    isDragging: monitor.isDragging(),
  }
}

// @ts-ignore
export const SelectedItem = flow(
  DragSource('selected-column', itemSource, connectSource),
  DropTarget('selected-column', itemTarget, connectTarget)
)(SelectedItemComponent)
