import * as React from 'react'
import { Button } from 'react-bootstrap'
import { Geofence } from 'common/types/local-api'
import { Confirm } from 'client/components/common/confirm'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faMapPin,
  faPenToSquare,
  faTrash,
} from '@fortawesome/free-solid-svg-icons'
import { css } from 'client/lib/utilities'

import styles from './GeofenceItem.module.scss'

interface Props {
  geofence: Geofence
  onClick: any
  onEdit: any
  onDelete: any
  selected: boolean
}

export const GeofenceItem: React.FC<Props> = ({
  selected,
  geofence,
  onClick,
  onDelete,
  onEdit,
}) => {
  return (
    <tr className={css(selected && styles.selected)}>
      <td
        className="align-middle"
        style={{ cursor: 'pointer' }}
        onClick={(e) => {
          e.preventDefault()
          onClick(geofence)
        }}
      >
        <div>
          {geofence.isPrimary && (
            <FontAwesomeIcon
              icon={faMapPin}
              className="mr-2 text-primary align-middle"
            />
          )}
          <span className="align-middle">{geofence.name}</span>
        </div>
      </td>
      <td className="align-middle text-nowrap">
        <Button
          variant="outline-secondary"
          onClick={(e) => {
            e.preventDefault()
            onEdit(geofence)
          }}
          className="mr-2"
          title="Edit"
        >
          <FontAwesomeIcon icon={faPenToSquare} />
        </Button>
        {!geofence.isPrimary && (
          <Confirm
            body="Are you sure you want to delete this geofence?"
            title="Delete Geofence?"
            confirmText="Delete Geofence"
            onConfirm={() => {
              onDelete(geofence)
            }}
          >
            <Button variant="outline-danger" title="Delete">
              <FontAwesomeIcon icon={faTrash} />
            </Button>
          </Confirm>
        )}
      </td>
    </tr>
  )
}
