import decode from 'jwt-decode'
import { JWT_KEY } from 'common/services/api/common'
import api from 'client/api'
import { AppState } from 'client/types'
import { JwtPayload } from 'rest-api'

export const getOrganizationId = async (state: AppState) => {
  if (state.blueprint.organizations.current) {
    return state.blueprint.organizations.current
  }
  let groupId
  const user = state.user
  const encodedJWT = localStorage.getItem(JWT_KEY)

  // Load the user/profile from the api if we don't have it yet
  if (!user || !user.profile || !user.profile.groupId) {
    const decodedJWT = decode(encodedJWT) as JwtPayload
    const endUserResponse = await api.blueprint.endUsers.getUserById(
      decodedJWT.id
    )
    groupId = endUserResponse.user.groupId
  } else {
    groupId = state.user.profile.groupId
  }
  return groupId
}
