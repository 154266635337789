import { Action, handleActions } from 'redux-actions'
import { actionTypes } from '../actions/print'
import getInitialState from '../initial-state'
import { PrintState } from '../../types/print-state'

const initialState: PrintState = getInitialState().print

type ReducerPayload = void
type PrintAction = Action<ReducerPayload>
type Reducer = (state: PrintState, action: PrintAction) => PrintState

const reducerMap = {
  [actionTypes.PRINT_ONLY_SHOW]: (state, action) => ({
    ...state,
    showPrintOnly: action.payload,
  }),
}

export const reducer: Reducer = handleActions(reducerMap, initialState)
export default reducer
