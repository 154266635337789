import * as React from 'react'

interface IProps {
  value: any
}

export class DefaultFormatter extends React.PureComponent<IProps> {
  render() {
    const { value } = this.props
    if (value === null || value === undefined || value === '') {
      return '--'
    }

    return String(value)
  }
}
