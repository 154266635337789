import { PageAdminGeofencesState } from '../../types/index'
import { pageAdminGeofences } from '../actions/index'
import getInitialState from '../initial-state'
import { SHAPE_ID_CIRCLE } from 'common/consts'

const { constants } = pageAdminGeofences
const initialState: PageAdminGeofencesState = getInitialState()
  .pageAdminGeofences

export default function (
  state: PageAdminGeofencesState = initialState,
  action: ReduxActions.Action<any>
): PageAdminGeofencesState {
  switch (action.type) {
    /**
     * The user has selected a geofence from the list of available ones on the right.
     */
    case constants.SELECT:
      return Object.assign({}, state, {
        geofence: action.payload,
      })

    /**
     * The user has chosen to edit the geofence from the list on the right.
     */
    case constants.EDIT:
      return Object.assign({}, state, {
        geofence: action.payload,
        originalGeofence: action.payload,
        editing: true,
        step: 2,
      })

    /**
     * The user has chosen to create a new geofence
     */
    case constants.CREATE:
      return Object.assign({}, state, {
        geofence: null,
        editing: true,
        step: 1,
      })

    /**
     * Kick off the saving from the save button.  This tells the map to pull the coordinates off of the shapes
     * and then fire the save action on the api
     */
    case constants.SAVE_START:
      const geofenceToSave = Object.assign({}, state.geofence, action.payload)
      // Clear out the shapes on it so that we can set them from the map
      geofenceToSave.circle = null
      geofenceToSave.bounds = null
      geofenceToSave.polygon = null
      return Object.assign({}, state, {
        startSave: true,
        geofenceToSave,
      })

    case constants.SAVE_STARTED:
      return Object.assign({}, state, {
        startSave: false,
      })

    /**
     * We are creating a new geofence and are moving onto step 2
     */
    case constants.STEP_2: {
      const geofence = { ...state.geofence, shapeId: SHAPE_ID_CIRCLE }
      return Object.assign({}, state, {
        editing: true,
        step: 2,
        geofence,
      })
    }

    case constants.UPDATE_GEOFENCE:
      return Object.assign({}, state, {
        geofence: action.payload,
      })

    /**
     * User has clicked the cancel button on the edit panel
     */
    case constants.CANCEL_CREATE_EDIT:
      return Object.assign({}, state, {
        geofence: state.originalGeofence,
        geofenceToSave: null,
        startSave: false,
        editing: false,
        bounds: null,
      })

    case constants.BOUNDS_CHANGED:
      return { ...state, bounds: action.payload }

    case constants.SET_TAGS: {
      const geofence = { ...state.geofence, tags: action.payload }
      return { ...state, geofence }
    }

    case constants.SAVED: {
      return {
        ...state,
        geofence: null,
        originalGeofence: action.payload,
        geofenceToSave: null,
        startSave: false,
        editing: false,
        bounds: null,
      }
    }

    default:
      return state
  }
}
