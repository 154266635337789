import * as React from 'react'
import { Modal, Button, Alert, FormGroup, FormLabel } from 'react-bootstrap'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import Formsy from 'formsy-react'
import { cloneDeep } from 'lodash'

import { AppState } from '../../../types'
import { InputText } from '../../common/form/input-text'
import { modalHide } from '../../../store/actions/page-admin-users'
import { saveUser, cleanStatusMessages } from '../../../store/actions/user'
import { UserRoleSelect } from './user-role-select'
import { User, PartialUser } from 'rest-api'

interface ReduxActions {
  modalHide: typeof modalHide
  saveUser: typeof saveUser
  cleanStatusMessages: typeof cleanStatusMessages
}

interface ReduxDispatchProps {
  actions: ReduxActions
}

interface ReduxStateProps {
  showModal: boolean
  user: User
  saving: boolean
  saved: boolean
  error?: string
}

function mapStateToProps(state: AppState): ReduxStateProps {
  return {
    showModal: state.pageAdminUsers.showModal,
    user: state.pageAdminUsers.currentUser,
    saving: state.user.saving,
    saved: state.user.saved,
    error: state.user.saveError,
  }
}

function mapDispatchToProps(dispatch) {
  const actions = { modalHide, saveUser, cleanStatusMessages }
  return { actions: bindActionCreators(actions, dispatch) }
}

interface Props extends ReduxStateProps, ReduxDispatchProps {}

interface OwnState {
  fields: PartialUser
  invalidSubmit: boolean
  fieldsToUpdate: any
}

class UserModalComponent extends React.Component<Props, OwnState> {
  constructor(props) {
    super(props)

    let fields = {}
    if (this.props.user) {
      fields = cloneDeep(this.props.user)
    }
    this.state = {
      fields,
      invalidSubmit: false,
      // A small object to hold only the values that are changing for update requests
      fieldsToUpdate: {},
    }
  }

  static getDerivedStateFromProps(props, state) {
    if (props.user && props.user.id !== state.fields.id) {
      const fields = cloneDeep(props.user)
      return {
        ...state,
        fields,
        fieldsToUpdate: {},
      }
    }
    return null
  }

  handleCancel = () => {
    this.props.actions.cleanStatusMessages()
    this.props.actions.modalHide()
  }

  handleSubmit = () => {
    this.props.actions.cleanStatusMessages()
    this.props.actions.saveUser(this.state.fields.id, this.state.fieldsToUpdate)
  }

  render() {
    const { fields } = this.state

    if (!fields) {
      return null
    }

    const setValue = (field, value) => {
      this.setState((state) => {
        state.fields[field] = value
        state.fieldsToUpdate[field] = value
        return state
      })
    }

    const Error = () => {
      const { error } = this.props

      if (!error) {
        return null
      }

      return <Alert variant="danger">{error}</Alert>
    }

    return (
      <Modal show={this.props.showModal} onHide={this.handleCancel}>
        <Formsy
          className={this.state.invalidSubmit ? 'form invalid' : 'form'}
          onInvalidSubmit={() => {
            this.setState({ invalidSubmit: true })
          }}
          onValidSubmit={() => {
            this.setState({ invalidSubmit: false })
            this.handleSubmit()
          }}
        >
          <Modal.Header closeButton>
            {this.props.user?.id ? 'Edit User' : 'Add User'}
          </Modal.Header>

          <Modal.Body>
            <div>
              <Error />
              <FormGroup>
                <FormLabel>Name:</FormLabel>
                <InputText
                  type="text"
                  name="name"
                  value={fields.name}
                  required
                  validations="minLength:1"
                  validationErrors={{
                    isDefaultRequiredValue: 'Please enter a name',
                  }}
                  placeholder="Name"
                  onChange={(e) => {
                    const target = e.target as HTMLInputElement
                    setValue('name', target.value)
                  }}
                />
              </FormGroup>

              <FormGroup>
                <FormLabel>Email:</FormLabel>
                <InputText
                  type="email"
                  name="email"
                  placeholder="Email"
                  required
                  validations="isEmail,minLength:1"
                  validationErrors={{
                    isDefaultRequiredValue: 'Please enter an email address',
                    isEmail: 'Please enter a valid email address',
                  }}
                  value={fields.email}
                  onChange={(e) => {
                    const target = e.target as HTMLInputElement
                    setValue('email', target.value)
                  }}
                />
              </FormGroup>

              <FormGroup>
                <FormLabel>Password:</FormLabel>
                <InputText
                  placeholder="Password"
                  name="password"
                  type="password"
                  required={!this.state.fields.id}
                  autoComplete="off"
                  validations={
                    this.state.fields.id ? 'minLength:0' : 'minLength:8'
                  }
                  validationErrors={
                    this.state.fields.id
                      ? {}
                      : {
                          isDefaultRequiredValue:
                            'Please enter at least 8 characters',
                          minLength: 'Please enter at least 8 characters',
                        }
                  }
                  onChange={(e) => {
                    const target = e.target as HTMLInputElement
                    setValue('password', target.value)
                  }}
                />
                {this.state.fields.id && (
                  <p className="help-block">
                    <em>
                      Only enter a password if you want to set a new password
                      for this user
                    </em>
                  </p>
                )}
              </FormGroup>

              <FormGroup>
                <FormLabel>Role:</FormLabel>
                <UserRoleSelect
                  role={fields.role}
                  placeholder="Choose Role"
                  onChange={(option) => {
                    setValue('role', option ? option.value : null)
                  }}
                />
              </FormGroup>
            </div>
          </Modal.Body>

          <Modal.Footer>
            <Button variant="danger" onClick={this.handleCancel}>
              Close
            </Button>
            <Button
              type="submit"
              variant="success"
              disabled={this.props.saving}
            >
              Save
            </Button>
          </Modal.Footer>
        </Formsy>
      </Modal>
    )
  }
}

export const UserModal = connect(
  mapStateToProps,
  mapDispatchToProps
)(UserModalComponent)
export default UserModal
