import { css } from 'client/lib/utilities'
import * as React from 'react'

import {
  Row,
  Column,
  TableCellFormatter,
  IFormatterProps,
} from '../../../types'
import { DefaultFormatter } from './formatters/default-formatter'

export type SortDir = 'DESC' | 'ASC' | 'NONE'

export interface TableProps {
  rows: Row[]
  columns: Column[]
  sortColumn?: string | null
  sortDirection?: SortDir
  defaultColumnWidth?: number
  rowHeight?: number
  height?: number
  className?: string
  onSort?: (sortColumnKey: string, sortDirection: SortDir) => void
  onResize?: (columnIndex: number, width: number, columns: Column[]) => void
  rowRenderer?: (
    row: Row,
    columns: Column[],
    columnCacheKey: string
  ) => React.ReactElement<RowComponent>
}

export const formatCell = (
  formatter: TableCellFormatter,
  value: any,
  row: Row,
  column: Column
) => {
  const props = {
    value,
    column,
    dependentValues: { ...row },
  }

  if (React.isValidElement(formatter)) {
    return React.cloneElement<IFormatterProps>(formatter, props)
  }

  const FormatterElement =
    (column.formatter as React.ComponentType<any>) || DefaultFormatter
  return React.createElement(FormatterElement, props)
}

export class Table extends React.PureComponent<TableProps> {
  headerWidth: number
  mouseStartX: number
  resizingColumnNode: HTMLElement
  resizingColumnIndex: number

  static defaultProps = {
    defaultColumnWidth: 125,
    rowHeight: 30,
    className: '',
  }

  handleSort = (e: React.MouseEvent) => {
    const node = e.target as HTMLElement
    if (node.matches('.table-col-resizer')) {
      return
    }
    const target = node.closest('.table-col-sortable') as HTMLElement
    if (this.props.onSort && target) {
      const columnKey = target.dataset.key
      const { sortColumn, sortDirection } = this.getNextSort(columnKey)
      this.props.onSort(sortColumn, sortDirection)
    }
  }

  handleResizeMouseDown = (e: React.MouseEvent, index: number) => {
    this.resizingColumnNode = (e.target as HTMLElement).closest('div')
    this.resizingColumnIndex = index
    this.mouseStartX = e.clientX
  }

  handleResizeMouseMove = (e: MouseEvent) => {
    if (!this.resizingColumnNode) {
      return
    }
    const baseWidth = this.resizingColumnNode.offsetWidth
    const width = Math.max(baseWidth + e.movementX, 100)
    this.resizingColumnNode.style.width = `${width}px`
  }

  handleResizeMouseUp = () => {
    if (!this.resizingColumnNode) {
      return
    }
    const width = Math.round(this.resizingColumnNode.offsetWidth)
    this.props.onResize?.(this.resizingColumnIndex, width, this.props.columns)
    this.resizingColumnNode = null
  }

  componentDidMount() {
    document.body.addEventListener('mousemove', this.handleResizeMouseMove)
    document.body.addEventListener('mouseup', this.handleResizeMouseUp, true)
  }

  componentWillUnmount() {
    document.body.removeEventListener('mousemove', this.handleResizeMouseMove)
    document.body.removeEventListener('mouseup', this.handleResizeMouseUp, true)
  }

  render() {
    const cssClasses = css('table-wrapper', this.props.className)
    return (
      <div
        className={cssClasses}
        style={{ height: this.props.height ?? undefined }}
      >
        {this.buildHeader()}
        <div className="table-scroller">
          <div
            className="table-main"
            style={{ width: `${this.headerWidth}px` }}
          >
            {this.buildRows()}
          </div>
        </div>
        {this.buildFixedColumns()}
      </div>
    )
  }

  private getNextSort(columnKey: string) {
    let sortDirection: SortDir
    let sortColumn = columnKey
    if (!this.props.sortDirection || columnKey !== this.props.sortColumn) {
      sortDirection = 'ASC'
    } else if (this.props.sortDirection === 'ASC') {
      sortDirection = 'DESC'
    } else {
      sortColumn = null
      sortDirection = 'NONE'
    }
    return { sortColumn, sortDirection }
  }

  private buildHeader() {
    this.headerWidth = 0
    const cells = this.props.columns.map((col, index) => {
      const width = col.width || this.props.defaultColumnWidth
      this.headerWidth += width
      const sortDirectionLabel =
        col.sortable &&
        this.props.sortColumn &&
        this.props.sortDirection === 'ASC'
          ? '▲'
          : '▼'
      const sortNode =
        col.sortable && this.props.sortColumn === col.key ? (
          <span className="table-col-sort-dir">{sortDirectionLabel}</span>
        ) : undefined
      const cssClasses = ['table-header-cell']
      if (col.sortable) {
        cssClasses.push('table-col-sortable')
      }
      if (col.locked) {
        cssClasses.push('table-header-cell-locked')
      }
      const style = {
        position: col.locked ? 'sticky' : 'relative',
        width: `${width}px`,
      } as React.CSSProperties
      return (
        <div
          key={col.key}
          className={cssClasses.join(' ')}
          style={style}
          data-key={col.key}
        >
          <div className="table-col-label">
            {col.name}
            {sortNode}
          </div>
          {this.props.onResize && col.resizable && (
            <span
              className="table-col-resizer"
              onMouseDown={(e: React.MouseEvent) => {
                this.handleResizeMouseDown(e, index)
              }}
            />
          )}
        </div>
      )
    })
    return (
      <div
        className="table-header"
        onClick={this.handleSort}
        style={{ width: `${this.headerWidth}px` }}
      >
        {cells}
      </div>
    )
  }

  private buildFixedColumns() {
    const cols = this.props.columns.filter((col) => col.locked)
    return cols.map((col) => {
      return (
        <div
          key={col.key}
          className="table-col-fixed"
          style={{ width: `${col.width || this.props.defaultColumnWidth}px` }}
        >
          {this.props.rows.map((row) => {
            return (
              <div key={row.id} style={{ height: `${this.props.rowHeight}px` }}>
                {formatCell(col.formatter, row[col.key], row, col)}
              </div>
            )
          })}
        </div>
      )
    })
  }

  private buildRows() {
    const columnOrderKey = this.props.columns.map((col) => col.key).toString()
    const columnWidthKey = this.props.columns.map((col) => col.width).toString()
    const columnCacheKey = `${columnOrderKey}-${columnWidthKey}`

    if (this.props.rowRenderer) {
      return this.props.rows.map((row) =>
        this.props.rowRenderer(row, this.props.columns, columnCacheKey)
      )
    }

    return this.props.rows.map((row, key) => {
      return (
        <RowComponent
          key={row.id || key}
          columnCacheKey={columnCacheKey}
          data={row}
          columns={this.props.columns}
          defaultColumnWidth={this.props.defaultColumnWidth}
          rowHeight={this.props.rowHeight}
        />
      )
    })
  }
}

interface RowProps {
  data: Row
  columns: Column[]
  columnCacheKey: string
  className?: string
  defaultColumnWidth?: number
  rowHeight?: number
}

export class RowComponent extends React.Component<RowProps> {
  static defaultProps = {
    className: '',
    defaultColumnWidth: 125,
    rowHeight: 30,
  }

  shouldComponentUpdate(props: RowProps) {
    // for performance reasons, only re-render when certain column properties change
    const shouldUpdate =
      this.props.columnCacheKey !== props.columnCacheKey ||
      this.props.data !== props.data ||
      this.props.className !== props.className ||
      this.props.rowHeight !== props.rowHeight ||
      this.props.defaultColumnWidth !== props.defaultColumnWidth
    return shouldUpdate
  }

  render() {
    const row = this.props.data
    const height = `${this.props.rowHeight}px`
    const cssClasses = ['table-row']
    if (this.props.className) {
      cssClasses.push(this.props.className)
    }
    const cells = this.props.columns.map((col) => {
      const value = row[col.key]
      const width = `${col.width || this.props.defaultColumnWidth}px`
      return (
        <div key={col.key} style={{ width }}>
          {formatCell(col.formatter, value, row, col)}
        </div>
      )
    })
    return (
      <div key={row.id} className={cssClasses.join(' ')} style={{ height }}>
        {cells}
      </div>
    )
  }
}

export default Table
