import { Column } from 'client/types'
import * as React from 'react'
import { Container } from 'react-bootstrap'
import AvailableItem from './available-item'

interface Props {
  items: Column[]
  addItemHandler: (item: Column) => void
}

export function AvailableItems(props: Props) {
  return (
    <Container fluid className="p-0">
      {props.items.map((item) => {
        return (
          <AvailableItem
            key={`column-${item.name}`}
            item={item}
            nameProp="name"
            addItemHandler={props.addItemHandler}
          />
        )
      })}
    </Container>
  )
}
