import * as React from 'react'
import styles from './CustomTooltip.module.scss'

export const CustomTooltip: React.FC<{
  itemName: React.ReactNode
  itemValue: React.ReactNode
  footer?: React.ReactNode
}> = ({ itemName, itemValue, footer }) => {
  return (
    <div className={styles.root}>
      <div className={styles.label}>
        <span className={styles.name}>{itemName}</span>:{' '}
        <span className={styles.value}>{itemValue}</span>
      </div>
      {footer && (
        <div className={styles.footer}>
          <em>{footer}</em>
        </div>
      )}
    </div>
  )
}
