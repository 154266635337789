import * as React from 'react'
import { Marker } from '@react-google-maps/api'
import { BreadcrumbPopup } from './breadcrumb-popup'
import BreadcrumbHoverOverlay from './device-breadcrumb-hover'
import { Metrics } from '../../common/metrics'

interface Props {
  position: google.maps.LatLngLiteral
  speed: number
  userMetrics: Metrics
  icon: google.maps.Icon
  visible: boolean
  deviceId: string
  dateTime: string
  reportId?: number
  direction?: string
}

interface State {
  showTooltip: boolean
  showHoverOverlay: boolean
}

export class DeviceBreadcrumb extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      showTooltip: false,
      showHoverOverlay: false,
    }
  }

  showTooltip = () => {
    this.setState({
      showTooltip: !this.state.showTooltip,
      showHoverOverlay: false,
    })
  }

  closeTooltip = () => {
    this.setState({
      showTooltip: false,
    })
  }

  handleMouseOver = () => {
    this.setState({
      showHoverOverlay: true,
    })
  }

  handleMouseOut = () => {
    this.setState({
      showHoverOverlay: false,
    })
  }

  render() {
    return (
      <Marker
        onClick={this.showTooltip}
        onMouseOver={this.handleMouseOver}
        onMouseOut={this.handleMouseOut}
        position={this.props.position}
        icon={this.props.icon}
        visible={this.props.visible}
      >
        {this.state.showTooltip && (
          <BreadcrumbPopup
            deviceId={this.props.deviceId}
            dateTime={this.props.dateTime}
            reportId={this.props.reportId}
            userMetrics={this.props.userMetrics}
            closeTooltip={this.closeTooltip}
            position={this.props.position}
          />
        )}
        {this.state.showHoverOverlay && !this.state.showTooltip && (
          <BreadcrumbHoverOverlay
            position={this.props.position}
            direction={this.props.direction}
            speed={this.props.speed}
            userMetrics={this.props.userMetrics}
            dateTime={this.props.dateTime}
          />
        )}
      </Marker>
    )
  }
}

export default DeviceBreadcrumb
