import * as React from 'react'
import { GoogleMap } from '@react-google-maps/api'
import { connect } from 'react-redux'

import { AppState, DeviceView } from '../../../types'
import { MAP_MAX_INITIAL_ZOOM } from '../../../../common/consts'

interface OwnProps {
  center: google.maps.LatLng | google.maps.LatLngLiteral
  zoom?: number
  height: number
  deviceId?: string
  children?: React.ReactNode
  onCenterChanged?: (
    center: google.maps.LatLng | google.maps.LatLngLiteral
  ) => void
  onZoomChanged?: (zoom: number) => void
}

interface ReduxStateProps {
  device: DeviceView
}

interface IProps extends OwnProps, ReduxStateProps {}

interface IState {
  zoom?: number
  mapTypeId: google.maps.MapTypeId | string
}

interface GoogleMapProps {
  bindMap: (map: google.maps.Map) => void | Promise<void>
  mapTypeId: google.maps.MapTypeId | string
  onZoomChanged: () => void
  onCenterChanged: () => void
  onMapTypeIdChanged: () => void
  center: google.maps.LatLng | google.maps.LatLngLiteral
  zoom?: number
  children?: React.ReactNode
  height: number
}

const DeviceGoogleMap = React.forwardRef<GoogleMap, GoogleMapProps>(
  (props, ref) => {
    const {
      height,
      bindMap,
      zoom,
      center,
      mapTypeId,
      onZoomChanged,
      onCenterChanged,
      onMapTypeIdChanged,
    } = props
    return (
      <GoogleMap
        ref={ref}
        mapContainerStyle={{ width: '100%', height }}
        options={{
          controlSize: 24,
          streetView: null,
          streetViewControl: false,
        }}
        onLoad={bindMap}
        zoom={zoom}
        center={center}
        mapTypeId={mapTypeId}
        onZoomChanged={onZoomChanged}
        onCenterChanged={onCenterChanged}
        onMapTypeIdChanged={onMapTypeIdChanged}
      >
        {props.children}
      </GoogleMap>
    )
  }
)

export class DeviceMapComponent extends React.PureComponent<IProps, IState> {
  constructor(props: IProps) {
    super(props)
    this.state = {
      zoom: MAP_MAX_INITIAL_ZOOM,
      mapTypeId: google.maps.MapTypeId.ROADMAP,
    }
  }

  map: google.maps.Map

  resizeMap = (bounds) => {
    if (this.map) {
      this.map.fitBounds(bounds)
    }
  }

  bindMap = (map: google.maps.Map) => {
    if (map) {
      this.map = map
    }
  }

  /**
   * Marker Clusterer listen to zoom changed event to clean all clusters and for idle event to draw them again
   * If fit bounds is called, but zoom didn't changed idle event is not fired
   * @see https://github.com/googlemaps/v3-utility-library/issues/252
   */
  handleZoomChanged = () => {
    const newZoom = this.map ? this.map.getZoom() : null
    if (
      this.state.zoom !== null &&
      newZoom !== null &&
      this.state.zoom === newZoom
    ) {
      setTimeout(() => {
        google.maps.event.trigger(this.map, 'idle')
      })
    }
    if (newZoom !== null) {
      this.setState({ ...this.state, zoom: newZoom })
      if (this.props.onZoomChanged) {
        this.props.onZoomChanged(newZoom)
      }
    }
  }

  handleCenterChanged = () => {
    if (this.map && this.props.onCenterChanged) {
      const center = this.map.getCenter()
      this.props.onCenterChanged({
        lat: center.lat(),
        lng: center.lng(),
      })
    }
  }

  handleMapTypeIdChanged = () => {
    const newMapTypeId = this.map ? this.map.getMapTypeId() : null
    if (newMapTypeId) {
      this.setState({
        ...this.state,
        mapTypeId: newMapTypeId,
      })
    }
  }

  getMapZoom = () => {
    return this.map ? this.map.getZoom() : this.state.zoom
  }

  render() {
    return (
      <DeviceGoogleMap
        height={this.props.height}
        bindMap={this.bindMap}
        zoom={this.state.zoom}
        center={this.props.center}
        mapTypeId={this.state.mapTypeId}
        onZoomChanged={this.handleZoomChanged}
        onCenterChanged={this.handleCenterChanged}
        onMapTypeIdChanged={this.handleMapTypeIdChanged}
      >
        {this.props.children}
      </DeviceGoogleMap>
    )
  }
}

const mapStateToProps = (
  state: AppState,
  ownProps?: OwnProps
): ReduxStateProps => {
  const device = state.blueprint.devices.data[ownProps.deviceId]
  return { device }
}

export const DeviceGMap = connect(mapStateToProps, null, null, {
  forwardRef: true,
})(DeviceMapComponent)

export default DeviceGMap
