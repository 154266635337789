import { connect } from 'react-redux'
import * as React from 'react'
import Select from 'react-select'

import { AppState, ISelect } from 'client/types'
import { getOptions } from '../../../store/selectors/groups'

import styles from './parent-organization-select.module.scss'

interface OwnProps {
  parentId: string
  placeholder: string
  onChange: (...args: any[]) => void
  className?: string
}

interface ReduxStateProps {
  groupOptions: ISelect[]
}

interface Props extends OwnProps, ReduxStateProps {}

function mapStateToProps(state: AppState): ReduxStateProps {
  return {
    groupOptions: getOptions(state),
  }
}

export function ParentOrganizationSelectComponent(props: Props) {
  if (!props.groupOptions || !props.parentId) {
    return null
  }

  let groupId = null
  const current = props.groupOptions.find((option) => {
    return option.value === props.parentId
  })

  if (current) {
    groupId = current.value
  }

  return (
    <Select
      placeholder={props.placeholder}
      value={groupId}
      options={props.groupOptions}
      onChange={props.onChange}
      className={props.className || styles.root}
    />
  )
}

export const ParentOrganizationSelect = connect<ReduxStateProps, {}, OwnProps>(
  mapStateToProps
)(ParentOrganizationSelectComponent)
export default ParentOrganizationSelect
