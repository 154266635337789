import { createAction } from 'redux-actions'

export const constants = {
  SELECT: 'pageAdminGeofences/SELECT',
  CREATE: 'pageAdminGeofences/CREATE',
  CANCEL_CREATE_EDIT: 'pageAdminGeofences/CANCEL_CREATE_EDIT',
  UPDATE_GEOFENCE: 'pageAdminGeofences/UPDATE_GEOFENCE',
  EDIT: 'pageAdminGeofences/EDIT',
  DESTROY: 'pageAdminGeofences/DELETE',
  SET_CENTER: 'pageAdminGeofences/SET_CENTER',
  STEP_2: 'pageAdminGeofences/STEP_2',
  SAVE_START: 'pageAdminGeofences/START_SAVE',
  SAVE_STARTED: 'pageAdminGeofences/SAVE_STARTED',
  BOUNDS_CHANGED: 'pageAdminGeofences/BOUNDS_CHANGED',
  SET_TAGS: 'pageAdminGeofences/SET_TAGS',
  SAVED: 'pageAdminGeofences/GEOFENCE_SAVED',
}

export const saveStart = createAction(constants.SAVE_START)
export const saveStarted = createAction(constants.SAVE_STARTED)
export const select = createAction(constants.SELECT)
export const create = createAction(constants.CREATE)
export const edit = createAction(constants.EDIT)
export const destroy = createAction(constants.DESTROY)
export const cancelCreateEdit = createAction(constants.CANCEL_CREATE_EDIT)
export const updateGeofence = createAction(constants.UPDATE_GEOFENCE)
export const createStep2 = createAction(constants.STEP_2)
export const changeBounds = createAction(constants.BOUNDS_CHANGED)
export const setTags = createAction(constants.SET_TAGS)
export const geofenceSaved = createAction(
  constants.SAVED,
  (geofence) => geofence
)
