import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as React from 'react'
import { Button, InputGroup, FormControl, Form } from 'react-bootstrap'

interface Props {
  onChange: (value: string) => void
  width?: number
}

export const SearchField = (props: Props) => {
  const [value, setValue] = React.useState('')

  return (
    <Form
      onSubmit={(e) => {
        e.preventDefault()
        props.onChange(value)
      }}
    >
      <InputGroup>
        <FormControl
          type="search"
          value={value}
          placeholder="Search..."
          onChange={(e) => {
            setValue(e.target.value)
            props.onChange(e.target.value)
          }}
          style={{ width: `${props.width}px` }}
        />
        <InputGroup.Append>
          <Button variant="primary" type="submit" title="Search">
            <FontAwesomeIcon icon={faMagnifyingGlass} />
          </Button>
        </InputGroup.Append>
      </InputGroup>
    </Form>
  )
}
