import { Geofence } from 'common/types/local-api'
import { localApiFetchWithToken } from '../api'

const BASE_URL = '/geofences'

export async function getGeofences(
  jwt: string,
  groupId: string
): Promise<Geofence[]> {
  const url = `${BASE_URL}/${groupId}`
  const data = await localApiFetchWithToken(url, jwt)
  return data as Geofence[]
}

export async function saveGeofence(
  jwt: string,
  geofence: Geofence
): Promise<Geofence> {
  const url = geofence.id ? `${BASE_URL}/${geofence.id}` : BASE_URL
  const data = await localApiFetchWithToken(url, jwt, {
    method: geofence.id ? 'PUT' : 'POST',
    body: JSON.stringify(geofence),
    headers: {
      'Content-Type': 'application/json',
    },
  })

  return data as Geofence
}

export async function deleteGeofence(
  jwt: string,
  id: number
): Promise<Geofence> {
  const url = `${BASE_URL}/${id}`
  const data = await localApiFetchWithToken(url, jwt, {
    method: 'DELETE',
  })
  return data as Geofence
}
