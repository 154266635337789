import * as React from 'react'
import { DropdownButton, Dropdown } from 'react-bootstrap'

interface Props {
  onChange: (value: string) => void
  value: string
  className?: string
}

export const TimeSelect = (props: Props) => {
  const options = [
    { value: `${30}`, label: 'Last 30 Minutes' },
    { value: `${60}`, label: 'Last Hour' },
    { value: `${120}`, label: 'Last 2 Hours' },
    { value: `${60 * 6}`, label: 'Last 6 Hours' },
    { value: `${60 * 12}`, label: 'Last 12 Hours' },
    { value: `${60 * 24}`, label: 'Last 24 Hours' },
    { value: `${60 * 48}`, label: 'Last 2 Days' },
    { value: `${60 * 24 * 7}`, label: 'Last 7 Days' },
    { value: `${60 * 24 * 14}`, label: 'Last 14 Days' },
    { value: `${60 * 24 * 30}`, label: 'Last 30 Days' },
  ]
  const selectedLabel = options.find(
    (entry) => entry.value === props.value
  )?.label

  return (
    <DropdownButton
      title={`Timeframe: ${selectedLabel}`}
      onSelect={(key) => props.onChange(key)}
      variant="light"
      className={props.className}
    >
      {options.map((entry) => {
        return (
          <Dropdown.Item
            key={entry.value}
            eventKey={entry.value}
            active={props.value === entry.value}
          >
            {entry.label}
          </Dropdown.Item>
        )
      })}
    </DropdownButton>
  )
}
