import { faArrowTrendUp } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { cssVar } from 'client/lib/utilities'
import * as React from 'react'

import styles from './InfoChart.module.scss'
import { TimeframeSelector } from './TimeframeSelector'

interface Props {
  title: string
  value: number
  arrow?: boolean
  showTimeframeSelector?: boolean
  timeframe?: string
  onTimeframeChange?: (v: string) => void
}

export const InfoChart: React.FC<Props> = ({
  arrow,
  title,
  value,
  timeframe,
  showTimeframeSelector = false,
  onTimeframeChange,
}) => {
  return (
    <div className={styles.root}>
      <h3>{title}</h3>
      <div className="d-flex justify-content-center">
        {showTimeframeSelector && (
          <TimeframeSelector
            value={timeframe}
            onChange={(value) => {
              onTimeframeChange(value)
            }}
          />
        )}
      </div>
      <div className={styles.value}>
        {arrow && (
          <FontAwesomeIcon
            icon={faArrowTrendUp}
            style={{ color: cssVar('--green'), marginRight: '8px' }}
          />
        )}
        {value}
      </div>
    </div>
  )
}
