import * as React from 'react'

import Select from 'react-select'
import { UserRole } from 'rest-api'

interface OwnProps {
  role: UserRole
  placeholder: string
  onChange: (value: any) => void
}

export const userRoles = [
  { key: 'dealer_group_admin', label: 'Dealer Group Admin' },
  { key: 'dealer_admin', label: 'Dealer Admin' },
  { key: 'read_only', label: 'User' },
]

export const UserRoleSelect: React.FC<OwnProps> = ({
  onChange,
  placeholder,
  role,
}) => {
  const defaultOptions = userRoles.map(({ key: value, label }) => ({
    value,
    label,
  }))

  const template = userRoles.find((v) => {
    return v.key === role
  })

  return (
    <Select
      placeholder={placeholder}
      value={template ? { value: template.key, label: template.label } : null}
      options={defaultOptions}
      onChange={onChange}
    />
  )
}
