import * as React from 'react'
import { Button } from 'react-bootstrap'
import { faTable } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

interface Props extends React.HTMLAttributes<HTMLButtonElement> {
  onClick: () => void
}

export const ColumnSelectorButton: React.FC<Props> = (props) => {
  return (
    <Button
      onClick={props.onClick}
      variant="secondary"
      title="Edit columns"
      {...props}
    >
      <FontAwesomeIcon icon={faTable} /> Edit columns
    </Button>
  )
}
