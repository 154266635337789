import * as React from 'react'
import { Modal, Row, Col, Button } from 'react-bootstrap'

interface Props {
  showModal: boolean
  onDecline: any
  onAccept: any
}
export const TermsConditionsModal = (props: Props) => {
  return (
    <Modal
      show={props.showModal}
      onHide={props.onDecline}
      className="terms-conditions-modal device-modal-component"
    >
      <Modal.Header>
        <Row>
          <Col lg={12} className="title">
            Motosense Terms &amp; Conditions
          </Col>
        </Row>
      </Modal.Header>
      <Modal.Body>
        <iframe
          src={window.appConfig.tcsUrl}
          height="600"
          width="100%"
        ></iframe>
      </Modal.Body>
      <Modal.Footer>
        <Button variant={'default'} onClick={props.onDecline}>
          Decline
        </Button>
        <Button variant={'primary'} onClick={props.onAccept}>
          Accept
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default TermsConditionsModal
