// cause we are in a browser
const logger = console

interface CommOptions {
  url: string
  method: string
  headers?: Record<string, string>
  body?: unknown
}

export class HttpError extends Error {
  statusCode!: number
  validationErrors!: string[]
}

export async function getJson<T = unknown>(
  fetchOptions: CommOptions
): Promise<T> {
  logger.debug('Running HTTP request with opts:', fetchOptions)

  const { url, method, headers, body } = fetchOptions

  const res = await fetch(url, {
    method,
    body: JSON.stringify(body),
    headers,
  })

  if (res.ok) {
    return res.json() as T
  }

  const err = new HttpError(`Request failed with status code ${res.status}`)
  err.statusCode = res.status
  err.validationErrors = []

  throw err
}
