import * as React from 'react'
import { AppState } from '../../types/app-state'
import { connect } from 'react-redux'
import { UserProfile } from 'client/types'

interface Props {
  user: UserProfile
}

export const UserEmailComponent: React.SFC<Props> = (
  props: Props
): JSX.Element => (
  <span>{props.user && props.user.email && props.user.email}</span>
)

const mapStateToProps = (state: AppState): Props => ({
  user: state.user.profile,
})

export const UserEmail = connect(mapStateToProps)(UserEmailComponent)
