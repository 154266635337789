import { FlatGroupHierarchy } from 'client/store/blueprint/groups/reducers'
import { filterDevicesByTerm } from 'client/store/reducers/grid'
import { getFlattenedHierarchy } from 'client/store/selectors/groups'
import { AppState, Column, DeviceView } from 'client/types'
import { DEVICE_FIELDS } from 'common/consts'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import useDeviceList from './useDeviceList'
import useSession from './useSession'

const useFilteredDeviceList = (
  filterTags: string[],
  filterTerm: string,
  columns: Column[]
) => {
  const devices = useDeviceList()
  const session = useSession()
  const hierarchy = useSelector<AppState, FlatGroupHierarchy[]>((state) =>
    getFlattenedHierarchy(state)
  )

  const filteredDevices: DeviceView[] = useMemo(() => {
    let filteredDevices = [...devices]

    // Filter by Group
    if (filterTags.length > 0) {
      filteredDevices = filteredDevices.filter((device) => {
        if (!device[DEVICE_FIELDS.TAGS]) {
          return false
        }
        const tags = String(device[DEVICE_FIELDS.TAGS]).split(',')

        if (tags.length === 0) {
          return true
        }

        let found = false
        tags.forEach((tag) => {
          const matching = filterTags.find((filter) => filter === tag)
          if (matching) {
            found = true
          }
        })

        return found
      })
    }

    // Filter by term
    if (filterTerm) {
      filteredDevices = filterDevicesByTerm(
        filteredDevices,
        filterTerm,
        session.user.profile,
        hierarchy,
        columns
      )
    }

    // Filter devices with 0 activation attempts
    filteredDevices = filteredDevices.filter(
      (device) => device.ActivationStatus !== ''
    )

    return filteredDevices
  }, [devices, session, hierarchy])

  return filteredDevices
}

export default useFilteredDeviceList
