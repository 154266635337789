import * as React from 'react'

import { useRestApi } from 'client/lib/api'
import { Cell } from 'recharts'
import { Legend, Tooltip, PieChart, Pie } from 'recharts'
import { Loading } from '../common/Loading'

import styles from './CustomPieChart.module.scss'
import { CustomTooltip } from './CustomTooltip'
import { TimeframeSelector } from './TimeframeSelector'
import { Alert } from 'react-bootstrap'
import { DateTime, Duration } from 'luxon'
import useSession from 'client/lib/hooks/useSession'

export interface PieChartData {
  name: string
  value: number
  range?: [number, number?]
}

interface ChartDataResponse {
  data: PieChartData[]
  totalEntries: number
}

interface PieChartProps {
  title: string
  subtitle?: string
  fetchUrl: string | null
  showTimeframeSelector?: boolean
  chartControl?: React.ReactNode
  noDataLabel?: React.ReactNode
  onCellClick?: (entry: PieChartData, dateStart: DateTime) => void
}

const defaultTimeframe = Duration.fromObject({ days: 30 }).toISO()

export const CustomPieChart: React.FC<PieChartProps> = ({
  title,
  subtitle,
  fetchUrl,
  showTimeframeSelector = false,
  chartControl = null,
  noDataLabel,
  onCellClick,
}) => {
  const { selectedGroupId } = useSession()

  const [timeframe, setTimeframe] = React.useState(defaultTimeframe)

  const dateStart = React.useMemo(
    () => DateTime.local().minus(Duration.fromISO(timeframe)).startOf('day'),
    [timeframe]
  )

  let url: URL
  if (fetchUrl) {
    url = new URL(fetchUrl, window.origin)
    url.searchParams.set('groupId', selectedGroupId)
    if (showTimeframeSelector) {
      url.searchParams.set('since', dateStart.toISO())
    }
  }

  const { data: dataSource, error } = useRestApi<ChartDataResponse>(
    url && `${url.pathname}${url.search}`
  )

  const colors = ['#008AFB', '#00C4A0', '#FFBB28', '#FF6C09', '#8884D8']
  const totalEntries =
    dataSource?.data?.reduce((prev, curr) => (prev += curr.value), 0) ?? 0

  return (
    <>
      <div>
        <div>
          <div className="d-flex flex-row align-items-center justify-content-center mb-2">
            <h3 className={styles.title}>{title}</h3>
            {chartControl}
          </div>
          {subtitle && <h4 className={styles.subtitle}>{subtitle}</h4>}
        </div>
        <div className="d-flex justify-content-center">
          {showTimeframeSelector && (
            <TimeframeSelector
              value={timeframe}
              onChange={(value) => {
                setTimeframe(value ?? defaultTimeframe)
              }}
            />
          )}
        </div>
      </div>
      <div className="d-flex flex-column justify-content-center">
        {dataSource && (
          <PieChart
            width={128}
            height={200}
            className={styles.container}
            margin={{ top: 0, left: 0, right: 0, bottom: 0 }}
          >
            <Tooltip
              content={({ active, payload }) => {
                if (!active || payload.length === 0) {
                  return null
                }
                return (
                  <CustomTooltip
                    itemName={payload[0].name}
                    itemValue={`${payload[0].value} (${Math.round(
                      (+payload[0].value * 100) / totalEntries
                    )}%)`}
                    footer={onCellClick && 'Click graph to send notification'}
                  />
                )
              }}
            />
            <Pie
              data={dataSource.data}
              dataKey="value"
              nameKey="name"
              innerRadius={38}
              outerRadius={64}
              cx={64}
              cy={64}
              paddingAngle={0}
            >
              {dataSource.data.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={colors[index]}
                  onClick={() => onCellClick?.(entry, dateStart)}
                  style={onCellClick && { cursor: 'pointer' }}
                />
              ))}
            </Pie>
            <Legend
              verticalAlign="bottom"
              align="center"
              layout="horizontal"
              iconType="circle"
              iconSize={12}
            />
          </PieChart>
        )}
        {url && !dataSource && <Loading block height={208} />}
        {!url && <div className={styles.noDataLabel}>{noDataLabel}</div>}
        {error && (
          <Alert variant="danger">{error.validationErrors.join('.\n')}</Alert>
        )}
      </div>
    </>
  )
}
