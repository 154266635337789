import * as React from 'react'
import { user } from '../../../lib/local-api/index'
import { Alert, Form, Button, Spinner } from 'react-bootstrap'
import { Link, RouteComponentProps } from 'react-router-dom'
import { RequestStatus } from '../../../types/index'
import Landing from '../landing'

type Props = RouteComponentProps<any, any>

interface State {
  error?: string
  requestStatus: RequestStatus
  password?: string
  confirmPassword?: string
}

export class ChangePassword extends React.Component<Props, State> {
  state: State = {
    password: '',
    confirmPassword: '',
    requestStatus: RequestStatus.REQUEST_NOT_SENT,
  }

  handleChange(fieldName, event) {
    const value = event.target.value

    this.setState((state) => {
      state[fieldName] = value
      return state
    })
  }

  autocontrol(fieldName: string) {
    return {
      name: fieldName,
      value: this.state[fieldName] || '',
      onChange: this.handleChange.bind(this, fieldName),
      className: 'form-control',
    }
  }

  render() {
    let form = (
      <Form method="POST" onSubmit={this.handleSubmit} id="reset-password-form">
        <h3>Change Password</h3>

        <Form.Group>
          <Form.Control
            type="password"
            placeholder="Password"
            autoComplete="new-password"
            required
            {...this.autocontrol('password')}
          />
        </Form.Group>
        <Form.Group>
          <Form.Control
            type="password"
            placeholder="Confirm Password"
            autoComplete="new-password"
            required
            {...this.autocontrol('passwordConfirm')}
          />
        </Form.Group>
        {this.state.error && <Alert variant="danger">{this.state.error}</Alert>}

        <Form.Group>
          <Button
            block
            variant="primary"
            type="submit"
            disabled={this.state.requestStatus === RequestStatus.REQUEST_SENT}
          >
            Change Password
            {this.state.requestStatus === RequestStatus.REQUEST_SENT && (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
                className="ml-2"
              />
            )}
          </Button>
        </Form.Group>
      </Form>
    )

    if (this.state.requestStatus === RequestStatus.REQUEST_SUCCESS) {
      form = (
        <Alert variant="success">
          <p>You have changed your password successfully.</p>
          <p>
            Now you can <Link to="/login">login</Link>
          </p>
        </Alert>
      )
    }

    return <Landing>{form}</Landing>
  }

  handleSubmit = async (event: any) => {
    event.preventDefault()

    this.setState({
      requestStatus: RequestStatus.REQUEST_SENT,
    })
    try {
      await user.changePassword(
        this.state.password,
        this.props.match.params.passwordResetToken,
        this.props.match.params.userId
      )

      this.setState({
        requestStatus: RequestStatus.REQUEST_SUCCESS,
      })
    } catch (error) {
      console.error(error)
      let errorMsg = 'An error has occurred. Please try again later.'
      if (error.response && error.response.status === 422) {
        errorMsg = 'Please try again with a stronger password.'
      }
      this.setState({
        error: errorMsg,
        requestStatus: RequestStatus.REQUEST_ERROR,
      })
    }
  }
}

export default ChangePassword
