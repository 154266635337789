import { createAction } from 'redux-actions'
import { locationHistory } from '../../lib/local-api'
import { JWT_KEY } from 'common/services/api/common'
import { localApiError } from './local-api'
import { DeviceType } from 'rest-api'

export const actionTypes = {
  LOADING_START: 'locationHistory/LOADING_START',
  LOADING_END: 'locationHistory/LOADING_END',
  LOADING_FAIL: 'locationHistory/LOADING_FAIL',
  TIMEFRAME_SET: 'locationHistory/TIMEFRAME_SET',
}

const startLoading = createAction(actionTypes.LOADING_START)
const endLoading = createAction(actionTypes.LOADING_END, (payload) => payload)
const failLoading = createAction(actionTypes.LOADING_FAIL, (payload) => payload)

export function loadLocationHistory(
  deviceId: string,
  deviceType: DeviceType,
  timeframe: string
) {
  return async (dispatch) => {
    dispatch(startLoading())
    try {
      const encodedJWT = localStorage.getItem(JWT_KEY)
      const response = await locationHistory.getLocationHistory(
        encodedJWT,
        deviceId,
        deviceType,
        timeframe
      )
      dispatch(endLoading({ id: deviceId, data: response }))
    } catch (error) {
      dispatch(localApiError(error))
      const payload = error.message
      dispatch(failLoading(payload))
    }
  }
}

export const setTimeFrame = createAction(actionTypes.TIMEFRAME_SET)
