import * as React from 'react'
import { AppState } from '../../types/app-state'
import { connect } from 'react-redux'
import {
  DeviceMapModalComponent,
  ReduxActions,
} from '../common/map/device-modal'
import { bindActionCreators, Dispatch } from 'redux'
import { hideDeviceMapModal } from '../../store/actions/map'
import { getMetrics } from '../../store/selectors/user'
import { Metrics } from '../common/metrics'
import { DeviceView } from 'client/types'
import { useWindowSize } from 'client/lib/hooks/useWindowSize'

interface ReduxStateProps {
  device: DeviceView
  showModal: boolean
  userMetrics?: Metrics
}

interface ReduxDispatchProps {
  actions: ReduxActions
}

type Props = ReduxStateProps & ReduxDispatchProps

function mapStateToProps(state: AppState): ReduxStateProps {
  const device = state.blueprint.devices.data[state.map.currentDeviceId]
  return {
    device,
    showModal: state.map.showDeviceMapModal,
    userMetrics: getMetrics(state),
  }
}

const mapDispatchToProps = (dispatch: Dispatch): ReduxDispatchProps => {
  const actions = {
    modalMapHide: hideDeviceMapModal,
    modalAlarmsShow: () => void 0,
  }
  return { actions: bindActionCreators(actions, dispatch) }
}

const Wrapper: React.FC<Props> = (props) => {
  const { height } = useWindowSize()
  if (!props.showModal) {
    return null
  }
  return <DeviceMapModalComponent windowHeight={height} {...props} />
}

export const MapDeviceMapModal = connect(
  mapStateToProps,
  mapDispatchToProps
)(Wrapper)
export default MapDeviceMapModal
