import { Action, handleActions, ReducerMap } from 'redux-actions'
import getInitialState from '../initial-state'
import { ExportState } from '../../types/export-state'

const initialState: ExportState = getInitialState().export

type ReducerPayload = boolean
type ExportAction = Action<ReducerPayload>
type Reducer = (state: ExportState, action: ExportAction) => ExportState

const reducerMap: ReducerMap<ExportState, ReducerPayload> = {}
export const reducer: Reducer = handleActions(reducerMap, initialState)
export default reducer
