import { constants } from './constants'
import { User } from 'rest-api'

export function upsert(users: User[]) {
  return {
    type: constants.UPSERT,
    payload: users,
  }
}

export function remove(userId: string) {
  return {
    type: constants.REMOVE,
    payload: userId,
  }
}

export function initialState() {
  return {
    type: constants.INITIAL_STATE,
  }
}

export function loadingStart() {
  return {
    type: constants.LOADING_START,
  }
}

export function loadReset() {
  return {
    type: constants.LOAD_RESET,
  }
}

export function loadingEnd() {
  return {
    type: constants.LOADING_END,
  }
}

export function setLoadError(message: string) {
  return {
    type: constants.LOAD_ERROR,
    payload: message,
  }
}

export function clear() {
  return {
    type: constants.CLEAR,
  }
}
