import * as React from 'react'
import { CustomPieChart } from '../charts/CustomPieChart'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faDownLeftAndUpRightToCenter,
  faUpRightAndDownLeftFromCenter,
} from '@fortawesome/free-solid-svg-icons'

import styles from './Stats.module.scss'

import { InfoChart } from '../charts/InfoChart'
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { connect } from 'react-redux'
import { getDevices } from 'client/store/selectors/devices'
import { AppState, DeviceView } from 'client/types'
import { DateTime, Duration } from 'luxon'
import { css } from 'client/lib/utilities'
import {
  ManualPromotionsModal,
  ManualPromotionsModalProps,
} from '../promotions/ManualPromotionsModal'
import GeofenceSelector from '../common/GeofenceSelector'

interface Props extends ReduxStateProps {
  onExpandCollapseClick: () => void
  expanded: boolean
}

interface ReduxStateProps {
  devices: DeviceView[]
}

interface NotificationModalParams
  extends Pick<
    ManualPromotionsModalProps,
    'minDate' | 'minValue' | 'maxValue' | 'triggerType'
  > {
  valueLabel: string
}

const defaultTimeframe = Duration.fromObject({ days: 30 }).toISO()

export const StatsComponent: React.FC<Props> = ({
  devices,
  onExpandCollapseClick,
  expanded,
}) => {
  const [showNotificationsModal, setShowNotificationsModal] =
    React.useState(false)
  const [selectedGeofenceId, setSelectedGeofenceId] = React.useState<
    number | null
  >(null)
  const [customersAddedTimeframe, setCustomersAddedTimeframe] =
    React.useState<string>(defaultTimeframe)
  const [notificationsModalParams, setNotificationsModalParams] =
    React.useState<NotificationModalParams>({
      minDate: DateTime.local().toISO(),
      minValue: 0,
      maxValue: 0,
      triggerType: 'dealer_visits',
      valueLabel: '',
    })

  const customersAdded = React.useMemo(() => {
    return devices.filter((entry) => {
      return (
        DateTime.fromISO(entry.createdAt).toMillis() >=
        DateTime.local()
          .minus(Duration.fromISO(customersAddedTimeframe))
          .startOf('day')
          .toMillis()
      )
    }).length
  }, [customersAddedTimeframe])

  return (
    <>
      <ManualPromotionsModal
        show={showNotificationsModal}
        triggerType={notificationsModalParams.triggerType}
        minDate={notificationsModalParams.minDate}
        minValue={notificationsModalParams.minValue}
        maxValue={notificationsModalParams.maxValue}
        valueLabel={notificationsModalParams.valueLabel}
        onHide={() => setShowNotificationsModal(false)}
      />
      <section className={css(styles.root, expanded && 'expanded')}>
        <div className={styles.top}>
          <h1 className={styles.header}>
            <span>Statistics</span>
            <OverlayTrigger
              placement="bottom"
              delay={{ show: 50, hide: 50 }}
              trigger={['hover', 'focus']}
              overlay={
                <Tooltip id={`tooltip-expand-collapse`}>
                  {expanded ? 'Collapse' : 'Expand'}
                </Tooltip>
              }
            >
              <Button
                variant="light"
                size="sm"
                onClick={() => onExpandCollapseClick()}
              >
                <FontAwesomeIcon
                  icon={
                    expanded
                      ? faDownLeftAndUpRightToCenter
                      : faUpRightAndDownLeftFromCenter
                  }
                />
              </Button>
            </OverlayTrigger>
          </h1>
        </div>
        <div className={styles.content}>
          <div className={css(styles.card, 'justify-content-around')}>
            <InfoChart
              value={customersAdded}
              title="Added Customers"
              arrow={customersAdded > 0}
              showTimeframeSelector
              timeframe={customersAddedTimeframe}
              onTimeframeChange={(v) =>
                setCustomersAddedTimeframe(v ?? defaultTimeframe)
              }
            />
            <InfoChart value={devices.length} title="Total Customers" />
          </div>
          <div className={styles.card}>
            <CustomPieChart
              fetchUrl="/stats/geofence-visits"
              title="Dealer Visits"
              showTimeframeSelector
              onCellClick={(entry, dateStart) => {
                setNotificationsModalParams({
                  triggerType: 'dealer_visits',
                  minDate: dateStart.toISO(),
                  minValue: entry.range[0],
                  maxValue: entry.range[1],
                  valueLabel: entry.name,
                })
                setShowNotificationsModal(true)
              }}
            />
          </div>
          <div className={styles.card}>
            <CustomPieChart
              fetchUrl={
                selectedGeofenceId
                  ? `/stats/geofence-visits?geofenceId=${selectedGeofenceId}`
                  : null
              }
              title="Geofence/POI Visits"
              chartControl={
                <GeofenceSelector
                  value={selectedGeofenceId}
                  size="sm"
                  variant="light"
                  includePrimary={false}
                  onChange={(value) => setSelectedGeofenceId(value)}
                />
              }
              showTimeframeSelector
              noDataLabel="Select a geofence"
              onCellClick={(entry, dateStart) => {
                setNotificationsModalParams({
                  triggerType: 'geolocation_visits',
                  minDate: dateStart.toISO(),
                  minValue: entry.range[0],
                  maxValue: entry.range[1],
                  valueLabel: entry.name,
                })
                setShowNotificationsModal(true)
              }}
            />
          </div>
          <div className={styles.card}>
            <CustomPieChart
              fetchUrl="/stats/geofence-distances"
              title="Distance From Dealership"
              onCellClick={(entry) => {
                setNotificationsModalParams({
                  triggerType: 'distance_from_dealership',
                  minDate: null,
                  minValue: entry.range[0],
                  maxValue: entry.range[1],
                  valueLabel: entry.name,
                })
                setShowNotificationsModal(true)
              }}
            />
          </div>
          <div className={styles.card}>
            <CustomPieChart
              fetchUrl="/stats/ride-frequency"
              title="Ride Frequency"
              showTimeframeSelector
              onCellClick={(entry, dateStart) => {
                setNotificationsModalParams({
                  triggerType: 'ride_frequency',
                  minDate: dateStart.toISO(),
                  minValue: entry.range[0],
                  maxValue: entry.range[1],
                  valueLabel: entry.name,
                })
                setShowNotificationsModal(true)
              }}
            />
          </div>
          <div className={styles.card}>
            <CustomPieChart
              fetchUrl="/stats/days-parked"
              title="Days Parked"
              onCellClick={(entry) => {
                setNotificationsModalParams({
                  triggerType: 'days_parked',
                  minDate: null,
                  minValue: entry.range[0],
                  maxValue: entry.range[1],
                  valueLabel: entry.name,
                })
                setShowNotificationsModal(true)
              }}
            />
          </div>
        </div>
      </section>
    </>
  )
}

function mapStateToProps(state: AppState): ReduxStateProps {
  return {
    devices: getDevices(state),
  }
}

export const Stats = connect(mapStateToProps)(StatsComponent)
