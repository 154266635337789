import { connect } from 'react-redux'
import { Dispatch, bindActionCreators } from 'redux'
import { showDeviceMapModal } from '../../store/actions/map'
import { DeviceMapButtonComponent } from '../grid/device-map-button'

interface ReduxActions {
  modalMapShow: typeof showDeviceMapModal
}

interface ReduxDispatchProps {
  actions: ReduxActions
}

const mapDispatchToProps = (dispatch: Dispatch): ReduxDispatchProps => {
  const actions = {
    modalMapShow: showDeviceMapModal,
  }
  return { actions: bindActionCreators(actions, dispatch) }
}

export const MapDeviceMapButton = connect(
  null,
  mapDispatchToProps
)(DeviceMapButtonComponent)
export default MapDeviceMapButton
