import * as Redux from 'redux'
import { AppState } from '../../types'
import api from 'client/api'
import * as blueprintActions from './actions'
import * as devicesActions from './devices/actions'
import * as groupsActions from './groups/actions'
import * as usersActions from './users/actions'
import { getGeofences } from '../actions/geofences'
import * as mqtt from '../actions/mqtt'
import { login } from '../actions/index'
import { enableNewDataReport } from '../actions/device-reports'
import { getOrganizationId } from '../util'
import { constants } from './constants'

type MiddlewareActionHandler = (store: Redux.Store<any>, action) => void

type BatchRequest = ReduxActions.Action<{
  entities: string[]
}>

const batchRequest: MiddlewareActionHandler = async (
  store: Redux.Store<AppState, any>,
  action: BatchRequest
) => {
  store.dispatch(devicesActions.loadingStart())
  store.dispatch(groupsActions.loadingStart())
  store.dispatch(usersActions.loadingStart())

  try {
    const selectedGroupId = store.getState().blueprint.organizations.current
    const rootGroupId = store.getState().user.profile.groupId
    const responses = await Promise.all([
      api.getGroup(rootGroupId),
      api.getAllDevices(selectedGroupId),
      api.getAllGroups(),
      api.getAllUsers(selectedGroupId),
    ])
    const [userGroupRes, devices, groups, users] = responses

    store.dispatch(devicesActions.upsert(devices))
    store.dispatch(devicesActions.loadingEnd())

    store.dispatch(groupsActions.upsert([userGroupRes.group, ...groups]))
    store.dispatch(groupsActions.loadingEnd())

    store.dispatch(usersActions.upsert(users))
    store.dispatch(usersActions.loadingEnd())

    store.dispatch(getGeofences(selectedGroupId))

    // subscribe to reflections for all devices
    const allDeviceIds = devices.map((device) => device.id)
    store.dispatch(mqtt.addDevices(allDeviceIds))

    // On the reports grid for a specific device, need to delay enabling the new data report
    // in order to give the system time to finish the mqtt connections.
    setTimeout(() => store.dispatch(enableNewDataReport(true)), 20000)
  } catch (error) {
    console.error(error)
    store.dispatch(blueprintActions.batchError(error))
    if (error && error.status === 403) {
      store.dispatch(login.logout())
    }
  }
}

const userRequest: MiddlewareActionHandler = async (store, action) => {
  store.dispatch(usersActions.loadingStart())
  try {
    const groupId = await getOrganizationId(store.getState())
    const response = await api.blueprint.endUsers.getUsersByGroup(groupId)
    const usersArray = response.users.results
    store.dispatch(usersActions.upsert(usersArray))
    store.dispatch(usersActions.loadingEnd())
  } catch (error) {
    console.error(error)
    if (error && error.error && error.error.message) {
      store.dispatch(usersActions.setLoadError(error.error.message))
    } else {
      store.dispatch(usersActions.setLoadError('Unknown Error'))
    }
  }
}

export default function createBlueprintMiddleware() {
  const middleware: Redux.Middleware =
    (store: Redux.Store<AppState>) => (next) => (action) => {
      const actionMap = {
        [constants.BATCH]: batchRequest,
        [constants.USERS]: userRequest,
      }

      if (actionMap[action.type]) {
        actionMap[action.type](store, action)
      }

      return next(action)
    }

  return middleware
}
