import { createAction } from 'redux-actions'
import { Dispatch } from 'redux'

export const actionTypes = {
  PRINT_ONLY_SHOW: 'print/PRINT_ONLY_SHOW',
  PRINT_ONLY_HIDE: 'print/PRINT_ONLY_HIDE',
}

export const shouldPrint = createAction(
  actionTypes.PRINT_ONLY_SHOW,
  (payload) => payload
)

export function print() {
  return (dispatch: Dispatch) => {
    window.print()
    dispatch(shouldPrint(false))
  }
}
