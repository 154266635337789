import { Group } from 'rest-api'
import { DeviceView } from './devices-state'

export enum RequestStatus {
  REQUEST_NOT_SENT,
  REQUEST_SENT,
  REQUEST_SUCCESS,
  REQUEST_ERROR,
}

export interface ISelect {
  value: any
  label: string
}

export type DeviceMap = Record<string, DeviceView>
export type GroupMap = Record<string, Group>

export * from './app-state'
export * from './device-reports-state'
export * from './devices-state'
export * from './export-state'
export * from './geofences-state'
export * from './grid'
export * from './location-history'
export * from './map-state'
export * from './page-admin-devices-state'
export * from './page-admin-fleets-state'
export * from './page-admin-geofences-state'
export * from './page-admin-organizations-state'
export * from './page-admin-users-state'
export * from './grid-state'
export * from './print-state'
export * from './status-history-state'
export * from './user-state'
export * from './promotions'
