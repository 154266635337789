import * as React from 'react'
import { connect } from 'react-redux'
import memoizeOne from 'memoize-one'
import {
  MAP_DEFAULT_LATITUDE,
  MAP_DEFAULT_LONGITUDE,
  MAP_MAX_INITIAL_ZOOM,
} from 'common/consts'
import { AppState } from '../../types/app-state'
import { DeviceMap } from '../../types'
import { VanillaMap as VanillaMap } from '../common/map/vanilla-map'
import MapGeofenceToggle from '../common/map/geofence-toggle'
import { Metrics } from '../common/metrics'
import {
  markersFromDevices,
  shapesFromGeofences,
} from '../../store/selectors/map'
import { filterDevices } from '../../store/reducers/map'
import { filterGeofencesByName } from '../../store/reducers/geofences'
import { getMetrics } from '../../store/selectors/user'
import { Geofence } from '../../lib/local-api'
import { Legends } from './Legends'
import { GeofenceSelector } from '../common/map/geofence-selector'

interface ReduxStateProps {
  devices: DeviceMap
  geofences: Record<string, Geofence>
  userMetrics: Metrics
  timeZone: string | null
  dealerGeofence: Geofence | null
}

type IProps = ReduxStateProps

interface IState {
  selectedGeofence: string | null
  showGeofences: boolean
}

const mapStateToProps = (state: AppState): ReduxStateProps => {
  const geofences = (state.geofences && state.geofences.data) || {}
  return {
    userMetrics: getMetrics(state),
    devices: state.blueprint.devices.data,
    geofences,
    timeZone:
      state.user && state.user.profile
        ? state.user.profile.properties.defaultTimezone
        : null,
    dealerGeofence:
      getGeofenceArray(geofences).find((entry) => entry.isPrimary) ?? null,
  }
}

const getDeviceArray = memoizeOne((devices: DeviceMap) =>
  Object.values(devices)
)
const getGeofenceArray = memoizeOne((geofences: Record<string, Geofence>) =>
  Object.values(geofences)
)
const filterDevicesMemoized = memoizeOne(filterDevices)
const filterGeofencesByNameMemoized = memoizeOne(filterGeofencesByName)

export class MapComponent extends React.PureComponent<IProps, IState> {
  private defaultCenter = {
    lat: MAP_DEFAULT_LATITUDE,
    lng: MAP_DEFAULT_LONGITUDE,
  }
  private map = React.createRef<VanillaMap>()

  state = {
    selectedGeofence: null,
    showGeofences: true,
  }

  private fitMap = () => {
    this.map.current.fitBoundsDebounced()
  }

  private onGeofenceToggle = (showGeofences: boolean) => {
    this.setState({ showGeofences })
  }

  private onGeofenceSelect = (value: string) => {
    this.setState({
      selectedGeofence: value === this.state.selectedGeofence ? '' : value,
    })
  }

  componentDidUpdate(prevProps: IProps, prevState: IState) {
    if (prevState.selectedGeofence !== this.state.selectedGeofence) {
      this.fitMap()
    }
  }

  render() {
    const devices = filterDevicesMemoized(
      getDeviceArray(this.props.devices),
      this.state.selectedGeofence
    )
    const geofences = this.state.showGeofences
      ? filterGeofencesByNameMemoized(
          getGeofenceArray(this.props.geofences),
          this.state.selectedGeofence
        )
      : []
    const markers = markersFromDevices(devices)
    const shapes = shapesFromGeofences(geofences)
    return (
      <div className="mb-4">
        <h1 style={{ fontSize: '20px' }}>Customer Location</h1>
        <div className="d-flex mb-2 align-items-center justify-content-between">
          <div>
            <MapGeofenceToggle
              enabled={this.state.showGeofences}
              onChange={this.onGeofenceToggle}
            />
          </div>
          <div className="flex-justify-center">
            <Legends />
          </div>
          <div className="flex-justify-end">
            <GeofenceSelector
              value={this.state.selectedGeofence}
              onSelect={this.onGeofenceSelect}
            />
          </div>
        </div>
        <VanillaMap
          ref={this.map}
          height={400}
          center={this.defaultCenter}
          maxZoom={this.state.selectedGeofence ? MAP_MAX_INITIAL_ZOOM : 12}
          markers={markers}
          shapes={shapes}
        />
      </div>
    )
  }
}

export const MapWidget = connect(mapStateToProps)(MapComponent)
