import * as React from 'react'
import { connect } from 'react-redux'

import { AppState } from '../../types'

interface OwnProps {
  children?: React.ReactNode
}

interface ReduxStateProps {
  showPrintOnly: boolean
}

interface Props extends OwnProps, ReduxStateProps {}

const PrintOnlyComponent = (props: Props) => {
  if (!props.showPrintOnly) {
    return null
  }
  return <div className="print-only">{props.children}</div>
}

function mapStateToProps(state: AppState): ReduxStateProps {
  return {
    showPrintOnly: state.print.showPrintOnly,
  }
}

export const PrintOnly = connect(mapStateToProps)(PrintOnlyComponent)
export default PrintOnly
