import * as Redux from 'redux'

import { mqtt } from '../actions/index'
import Listener from '../../lib/listener'
import * as devicesActions from '../blueprint/devices/actions'
import {
  getDeviceFromActiveOrgOnly,
  getDeviceView,
} from '../blueprint/devices/reducers'
import * as consts from 'common/consts'
import { AppState, DeviceView } from '../../types'
import { DEVICE_FIELDS } from 'common/consts'
import api from '../../api'
import { setNewDataAlert } from '../actions/device-reports'

// Redux to device listener interface
export default function createMqttMiddleware() {
  const { CONNECT, ADD_DEVICES, REMOVE_ALL_DEVICES } = mqtt.constants

  const mqttMiddleware: Redux.Middleware = (
    store: Redux.Store<AppState, any>
  ) => {
    const listener = new Listener()

    let deviceQueue = []

    setInterval(() => {
      const devices = deviceQueue.splice(0, deviceQueue.length)
      if (devices.length > 0) {
        console.debug(
          `dispatching pending upserts (${devices.length}):`,
          devices
        )
        store.dispatch(devicesActions.upsert(devices))
        store.dispatch(devicesActions.loadingEnd())
      }
    }, 3000)

    const addPendingUpsert = (device: DeviceView) => {
      deviceQueue.push(device)
    }

    // Listen to the '_updates/fields' channel for updates to the device
    listener.use(consts.MQTT_CHANNEL_UPDATES_FIELDS, (messageData) => {
      console.debug(consts.MQTT_CHANNEL_UPDATES_FIELDS, messageData)

      const deviceId = messageData.deviceId

      if (deviceId) {
        const notifyNewData = () => {
          store.dispatch(
            setNewDataAlert({
              deviceId,
              hasNewData: true,
            })
          )
        }
        setTimeout(notifyNewData, 2000)
      }

      // if this is the first reflections update, ignore it (just overwrites blueprint request data)
      if (deviceId && listener.checkDeviceReadyForUpsert(deviceId)) {
        const payload = JSON.parse(messageData.payload).state

        // If the payload contains the id, it's not a change to the device, so we don't care about it.
        if (payload.id) {
          return
        }

        let device = getDeviceFromActiveOrgOnly(store.getState(), deviceId)
        if (!device) {
          return
        }
        device = Object.assign({}, device, payload)

        // If the device has turned on, we need to get the latest properties from blueprint since we've nulled out
        // many of the values on the device for display on the grid
        if (
          payload[DEVICE_FIELDS.POWER] &&
          payload[DEVICE_FIELDS.POWER] === consts.ON
        ) {
          api.blueprint.devices.getDevice(deviceId).then((deviceResponse) => {
            addPendingUpsert(getDeviceView(deviceResponse.device))
          })
        }

        // ignore updates for devices in other fleets (when switching between fleets)
        if (device.id && device.id === deviceId) {
          // Add to pending upsert queue
          addPendingUpsert(device)
        }
      } else {
        if (deviceId) {
          // set the device to upsert on its next reflections update
          listener.setDeviceReadyForUpsert(deviceId)
        }
      }
    })

    return (next) => (action) => {
      switch (action.type) {
        case CONNECT:
          listener.connect(() => {
            store.dispatch(mqtt.connected())
          })
          break

        case ADD_DEVICES:
          action.payload.forEach((deviceId) => {
            listener.addDevice(deviceId)
          })
          break

        case REMOVE_ALL_DEVICES:
          listener.removeAllDevices()
          deviceQueue = []
          break

        default:
      }
      return next(action)
    }
  }

  return mqttMiddleware
}
