import { PageAdminOrganizationsState } from '../../types/index'
import getInitialState from '../initial-state'
import { handleActions, Reducer } from 'redux-actions'
import { actionTypes } from '../actions/page-admin-organizations'
import { Group } from 'rest-api'

type GenericPayload = string | void | Group
type OrganizationsReducer<Payload> = Reducer<
  PageAdminOrganizationsState,
  Payload
>

const initialState: PageAdminOrganizationsState =
  getInitialState().pageAdminOrganizations

const showModal: OrganizationsReducer<Group> = (state, action) => {
  return {
    ...state,
    showModal: true,
    currentOrganization: action.payload,
  }
}

const hideModal: OrganizationsReducer<void> = (state, action) => {
  return {
    ...state,
    showModal: false,
    currentOrganization: null,
  }
}

const startSave: OrganizationsReducer<void> = (state, action) => {
  return {
    ...state,
    saving: true,
    saved: false,
    saveError: null,
  }
}

const endSave: OrganizationsReducer<void> = (state, action) => {
  return {
    ...state,
    saving: false,
    saved: true,
    saveError: null,
  }
}

const failSave: OrganizationsReducer<string> = (state, action) => {
  return {
    ...state,
    saving: false,
    saved: false,
    saveError: action.payload,
  }
}

const startDelete: OrganizationsReducer<void> = (state, action) => {
  return {
    ...state,
    deleting: true,
    deleted: false,
    deleteError: null,
  }
}

const endDelete: OrganizationsReducer<void> = (state, action) => {
  return {
    ...state,
    deleting: false,
    deleted: true,
    deleteError: null,
  }
}

const failDelete: OrganizationsReducer<string> = (state, action) => {
  return {
    ...state,
    deleting: false,
    deleted: false,
    deleteError: action.payload,
  }
}

const cleanStatusMessages: OrganizationsReducer<void> = (state, action) => {
  return {
    ...state,
    saving: false,
    saved: false,
    saveError: null,
    deleting: false,
    deleted: false,
    deleteError: null,
    currentOrganization: null,
  }
}

const reducerMap: ReduxActions.ReducerMap<
  PageAdminOrganizationsState,
  GenericPayload
> = {
  [actionTypes.MODAL_SHOW]: showModal,
  [actionTypes.MODAL_HIDE]: hideModal,
  [actionTypes.ORGANIZATION_SAVE_START]: startSave,
  [actionTypes.ORGANIZATION_SAVE_END]: endSave,
  [actionTypes.ORGANIZATION_SAVE_FAIL]: failSave,
  [actionTypes.ORGANIZATION_DELETE_START]: startDelete,
  [actionTypes.ORGANIZATION_DELETE_END]: endDelete,
  [actionTypes.ORGANIZATION_DELETE_FAIL]: failDelete,
  [actionTypes.CLEAN_STATUS_MESSAGES]: cleanStatusMessages,
}

export const reducer: OrganizationsReducer<GenericPayload> = handleActions(
  reducerMap,
  initialState
)
export default reducer
