import * as React from 'react'
import { Button } from 'react-bootstrap'
import { shouldPrint } from '../../store/actions/print'
import { connect } from 'react-redux'
import { AppState } from '../../types/app-state'
import { bindActionCreators, Dispatch } from 'redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPrint } from '@fortawesome/free-solid-svg-icons'

interface OwnProps extends React.HTMLAttributes<HTMLElement> {
  disabled?: boolean
}

interface ReduxStateProps {
  showPrintOnly: boolean
}

interface ReduxActions {
  shouldPrint: (should: boolean) => void
}

interface ReduxDispatchProps {
  actions: ReduxActions
}

interface Props extends OwnProps, ReduxStateProps, ReduxDispatchProps {}

const PrintButtonComponent = (props: Props) => {
  const { showPrintOnly, actions, ...rest } = props

  return (
    <Button
      onClick={() => {
        if (!showPrintOnly) {
          actions.shouldPrint(true)
        }
      }}
      variant="secondary"
      title="Print"
      {...rest}
    >
      <FontAwesomeIcon icon={faPrint} /> Print
    </Button>
  )
}

function mapStateToProps(state: AppState): ReduxStateProps {
  return {
    showPrintOnly: state.print.showPrintOnly,
  }
}

function mapDispatchToProps(dispatch: Dispatch): ReduxDispatchProps {
  const actions = {
    shouldPrint,
  }
  return {
    actions: bindActionCreators(actions, dispatch),
  }
}

export const PrintButton = connect(
  mapStateToProps,
  mapDispatchToProps
)(PrintButtonComponent)
export default PrintButton
