import * as ReduxActions from 'redux-actions'
import { AppState } from '../../../types'
import { keyBy } from 'lodash'
import { UsersState } from '../../../types/users-state'
import { constants } from './constants'
import { User } from 'rest-api'

type Action = ReduxActions.Action<string> | ReduxActions.Action<User[]>
type Reducer = (state: UsersState, action: Action) => UsersState

const initialState: UsersState = {
  loading: false,
  loaded: false,
  loadError: '',
  data: {},
}

const upsert: Reducer = (state, action) => {
  const newUsersArray = action.payload as User[]
  const newUsers: Record<string, User> = keyBy(newUsersArray, 'id')
  const oldUsers = state.data
  const users = { ...oldUsers, ...newUsers }
  return { ...state, data: users }
}

const remove: Reducer = (state, action) => {
  const userId = action.payload as string
  const users = { ...state.data }
  delete users[userId]
  return { ...state, data: users }
}

const loadingStart: Reducer = (state, action) => {
  // Persists existing state, sets loading true, clears errors
  return { ...state, loading: true, loaded: false, error: '' }
}

const loadingEnd: Reducer = (state, action) => {
  return { ...state, loading: false, loaded: true }
}

const clear: Reducer = (state, action) => {
  return { ...state, loading: false, loaded: false, data: null }
}

const loadingError: Reducer = (state, action) => {
  const error = action.payload as string
  return { ...state, loadError: error }
}

export const reducer: Reducer = (state = initialState, action) => {
  const actionMap = {
    [constants.UPSERT]: upsert,
    [constants.REMOVE]: remove,
    [constants.LOADING_START]: loadingStart,
    [constants.LOADING_END]: loadingEnd,
    [constants.LOAD_ERROR]: loadingError,
    [constants.CLEAR]: clear,
    [constants.INITIAL_STATE]: () => initialState,
  }

  if (actionMap[action.type]) {
    return actionMap[action.type](state, action)
  }
  return state
}

export function getUser(state: AppState, userId) {
  const user = state.blueprint.users.data[userId]
  if (!user) {
    return
  }

  return user
}

export function getUsers(state: AppState) {
  const users = Object.keys(state.blueprint.users.data).map(
    (key) => state.blueprint.users.data[key]
  )
  return users
}

export function getUserArray(state: UsersState) {
  const users = Object.keys(state.data).map((key) => state.data[key])
  return users
}
