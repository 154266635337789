import * as React from 'react'
import { LiteGrid } from '../grid/lite-grid'
import { MapWidget } from '../map/MapWidget'

import styles from './Home.module.scss'

export const Home: React.FC = () => {
  return (
    <section className={styles.root}>
      <MapWidget />
      <LiteGrid />
    </section>
  )
}
