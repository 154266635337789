import { createSelector } from 'reselect'

import * as consts from 'common/consts'
import { AppState, Column, DeviceMap, DeviceView } from '../../types'
import { filterDevicesByTerm } from '../reducers/grid'
import { getFlattenedHierarchy } from './groups'

export const getDevices = createSelector(
  (state: AppState) => state.blueprint.devices && state.blueprint.devices.data,
  (devices: DeviceMap) => Object.values(devices)
)

export const getFilteredDevices = (columns: Column[]) => {
  return createSelector(
    (state: AppState) => state.blueprint.devices.data,
    getFlattenedHierarchy,
    (state: AppState) => state.user && state.user.profile,
    (state: AppState) => state.pageGrid.filterModel,
    (state: AppState) => state.pageGrid.filterGeofence,
    (state: AppState) => state.pageGrid.filterTags,
    (state: AppState) => state.pageGrid.filterTerm,
    (
      devices,
      orgHierarchy,
      userProfile,
      filterModel,
      filterGeofence,
      filterTags,
      filterTerm
    ): DeviceView[] => {
      let filteredDevices: DeviceView[] = Object.values(devices)

      // Filter by model
      if (filterModel) {
        filteredDevices = filteredDevices.filter(
          (device) =>
            device[consts.DEVICE_FIELDS.MODEL] &&
            device[consts.DEVICE_FIELDS.MODEL] === filterModel
        )
      }

      // Filter by geofence
      if (filterGeofence) {
        filteredDevices = filteredDevices.filter(
          (device) =>
            device[consts.DEVICE_FIELDS.GEOFENCE] &&
            device[consts.DEVICE_FIELDS.GEOFENCE] === filterModel
        )
      }

      // Filter by Group
      if (filterTags && filterTags.length > 0) {
        filteredDevices = filteredDevices.filter((device) => {
          if (!device[consts.DEVICE_FIELDS.TAGS]) {
            return false
          }
          const tags = String(device[consts.DEVICE_FIELDS.TAGS]).split(',')

          if (tags.length === 0) {
            return true
          }

          let found = false
          tags.forEach((tag) => {
            const matching = filterTags.find((filter) => filter === tag)
            if (matching) {
              found = true
            }
          })

          return found
        })
      }

      // Filter by term
      if (filterTerm) {
        filteredDevices = filterDevicesByTerm(
          filteredDevices,
          filterTerm,
          userProfile,
          orgHierarchy,
          columns
        )
      }

      // Filter devices with 0 activation attempts
      filteredDevices = filteredDevices.filter(
        (device) => device.ActivationStatus !== ''
      )

      return filteredDevices
    }
  )
}
