import * as React from 'react'
import { Row, Col, Container, Tabs, Tab } from 'react-bootstrap'
import { Users, Dealerships } from '..'
import { Redirect, Route, useHistory, useLocation } from 'react-router-dom'
import Profile from './Profile'

import styles from './Index.module.scss'
import { AdminGrid } from './configurations/list'

export const Admin: React.FC = () => {
  const location = useLocation()
  const history = useHistory()

  const activeKey = location.pathname

  return (
    <Container fluid className="p-0">
      <Row noGutters className="mb-2">
        <Col lg={1}>
          <h1 style={{ fontSize: '20px' }}>Admin</h1>
        </Col>
      </Row>
      <Row noGutters className="mb-4">
        <Col>
          <Tabs activeKey={activeKey} onSelect={(k) => history.push(k)}>
            <Tab
              title="Dealer Groups"
              eventKey="/admin/configurations"
              tabClassName={styles.tab}
            />
            <Tab
              title="Dealerships"
              eventKey="/admin/dealerships"
              tabClassName={styles.tab}
            />
            <Tab
              title="Users"
              eventKey="/admin/users"
              tabClassName={styles.tab}
            />
            <Tab
              title="Profile"
              eventKey="/admin/profile"
              tabClassName={styles.tab}
            />
          </Tabs>
        </Col>
      </Row>
      <Row noGutters>
        <Col>
          <Route path="/admin/configurations" component={AdminGrid} />
          <Route path="/admin/dealerships" component={Dealerships} />
          <Route path="/admin/users" component={Users} />
          <Route path="/admin/profile" component={Profile} />
          <Redirect from="/admin" exact to="/admin/configurations" />
        </Col>
      </Row>
    </Container>
  )
}
