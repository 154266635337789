import * as React from 'react'
import { connect } from 'react-redux'
import { Heading } from 'aritronix-protocol'

import { DeviceBreadcrumb } from './device-breadcrumb'
import { AppState, DeviceView } from '../../../types'
import { MINIMUM_SPEED_TO_BE_CONSIDERED_MOVING } from '../../../../common/consts'
import { Metrics } from '../../common/metrics'
import { LocationHistoryItem } from '../../../../common/types/local-api'

interface OwnProps {
  device: DeviceView
  userMetrics: Metrics
}

interface ReduxStateProps {
  showBreadcrumbs: boolean
  locationHistory: LocationHistoryItem[]
}

interface IProps extends OwnProps, ReduxStateProps {}

function isSpeedEnoughToConsiderMoving(speed: number): boolean {
  return speed > MINIMUM_SPEED_TO_BE_CONSIDERED_MOVING
}

function getMovingDirection(item: LocationHistoryItem): string {
  return isSpeedEnoughToConsiderMoving(item.value) &&
    Heading[item.heading] !== null
    ? Heading[item.heading]
    : null
}

function getBreadcrumbIcon(item: LocationHistoryItem): google.maps.Icon {
  const url =
    isSpeedEnoughToConsiderMoving(item.value) && Heading[item.heading] !== null
      ? `/images/pins/${String(Heading[item.heading]).toLowerCase()}.png`
      : '/images/pins/stopped.png'
  return {
    anchor: new google.maps.Point(10, 10),
    url,
  }
}

class DeviceBreadcrumbsComponent extends React.PureComponent<IProps> {
  render() {
    const { device, locationHistory, userMetrics, showBreadcrumbs } = this.props

    if (!device || !locationHistory) {
      return null
    }

    const locationItems: LocationHistoryItem[] = locationHistory
      ? locationHistory.slice()
      : []

    return (
      <div>
        {locationItems.map(
          (locationHistoryItem: LocationHistoryItem, index: number) => {
            const { position, value, dateTime, reportId } = locationHistoryItem
            return (
              <DeviceBreadcrumb
                key={`device_${device.id}_breadcrumb_${index}`}
                position={position}
                speed={value}
                userMetrics={userMetrics}
                icon={getBreadcrumbIcon(locationHistoryItem)}
                visible={showBreadcrumbs}
                deviceId={device.id}
                dateTime={dateTime}
                reportId={reportId}
                direction={getMovingDirection(locationHistoryItem)}
              />
            )
          }
        )}
      </div>
    )
  }
}

function mapStateToProps(state: AppState, ownProps: OwnProps): ReduxStateProps {
  return {
    showBreadcrumbs: state.map.showBreadcrumbs,
    locationHistory: ownProps.device
      ? state.locationHistory.data[ownProps.device.id]
      : null,
  }
}

export const DeviceBreadcrumbs = connect(mapStateToProps)(
  DeviceBreadcrumbsComponent
)
export default DeviceBreadcrumbs
