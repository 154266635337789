import { useContext } from 'react'

import UserContext from '../../components/providers/user-preferences-context'
import OrgsContext from '../../components/providers/organizations-context'

const useSession = () => {
  const user = useContext(UserContext)
  const { organizations: groups, selectedGroupId } = useContext(OrgsContext)
  return { user, groups, selectedGroupId }
}

export default useSession
