import * as React from 'react'
import { OverlayTrigger, OverlayTriggerProps, Tooltip } from 'react-bootstrap'

export const OverlayText: React.FC<{
  value: string
  placement?: OverlayTriggerProps['placement']
}> = ({ children, value, placement = 'right' }) => {
  return (
    <OverlayTrigger
      placement={placement}
      delay={{ show: 50, hide: 100 }}
      overlay={<Tooltip id="grid-overlay-tooltip">{value}</Tooltip>}
    >
      <div>{children}</div>
    </OverlayTrigger>
  )
}
