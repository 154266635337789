import { UserProfile } from '../../types/user-state'
import { getMetricsSettings, Metrics } from './metrics'

interface ChartData {
  value: number
}

type Converter = (value: number, metrics: Metrics) => number

export function convertMetrics<Data extends ChartData>(
  chartData: Data[],
  converter: Converter,
  profile: UserProfile
): Data[] {
  const metrics = getMetricsSettings(profile)
  const convertedData = []
  chartData.forEach((data: Data) => {
    const out = { ...(data as ChartData) }
    out.value = converter(out.value, metrics)
    convertedData.push(out)
  })

  return convertedData
}
