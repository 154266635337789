import React from 'react'
import { useLocalApi } from 'client/lib/api'
import { Alert, Modal, Table } from 'react-bootstrap'
import { capitalize } from 'lodash'
import { Loading } from '../common/Loading'

interface Props {
  show: boolean
  promotionId: number
  onHide: () => void
}

interface HistoryResponse {
  data: Record<string, unknown>[]
}

export const HistoryDialog: React.FC<Props> = ({
  promotionId,
  show,
  onHide,
}) => {
  const { data: results, error } = useLocalApi<HistoryResponse>(
    show && `/promotions/${promotionId}/history`
  )

  return (
    <Modal
      show={show}
      onHide={() => onHide()}
      size="lg"
      backdrop="static"
      centered
    >
      <Modal.Header closeButton>Notification details</Modal.Header>
      <Modal.Body>
        {!results && !error && <Loading block />}
        {results?.data.length === 0 && (
          <Alert variant="info" className="text-center">
            List is empty
          </Alert>
        )}
        {results?.data.length > 0 && (
          <Table striped borderless responsive className="custom-table">
            <thead>
              <tr>
                <th>Customer</th>
                <th>Phone number</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {results.data.map((entry, index) => {
                return (
                  <tr key={index}>
                    <td>{entry.emailAddress}</td>
                    <td>{entry.recipient}</td>
                    <td>{capitalize(entry.status as string)}</td>
                  </tr>
                )
              })}
            </tbody>
          </Table>
        )}
      </Modal.Body>
    </Modal>
  )
}
