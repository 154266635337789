import * as React from 'react'

import { AppState } from '../../types/app-state'
import { connect } from 'react-redux'
import { createSelector } from 'reselect'
import { ISelect } from '../../types/index'

import { Dropdown, DropdownButton } from 'react-bootstrap'

interface OwnProps<T> {
  value: T
  onChange: (value: T) => void
}

interface ReduxStateProps {
  options: ISelect[]
}

interface Props extends OwnProps<string | null>, ReduxStateProps {}

const MapTagFilterComponent: React.FC<Props> = ({
  options,
  value,
  onChange,
}) => {
  return (
    <DropdownButton variant="light" title={value ? `Group: ${value}` : `Group`}>
      {options.map((entry) => {
        return (
          <Dropdown.Item
            key={entry.value}
            eventKey={entry.value}
            active={value === entry.value}
            onSelect={(key) => {
              onChange(value === key ? '' : key)
            }}
          >
            {entry.label}
          </Dropdown.Item>
        )
      })}
    </DropdownButton>
  )
}

const getTagOptions = createSelector(
  (state: AppState) => state.blueprint.devices.tags,
  (tags: string[]) => {
    let options: ISelect[] = null
    if (tags) {
      options = []
      tags.forEach((tag: string) => {
        options.push({
          label: tag,
          value: tag,
        })
      })
    }
    return options
  }
)

function mapStateToProps() {
  return (state: AppState): ReduxStateProps => ({
    options: getTagOptions(state),
  })
}

export const MapTagFilter = connect(mapStateToProps)(MapTagFilterComponent)
export default MapTagFilter
