import React, { Dispatch, ReducerAction } from 'react'
import { Column } from 'client/types'

interface CustomGridColumns {
  showColumnSelector: boolean
  columns: Column[]
  selectedColumns: Column[]
  availableColumns: Column[]
}

interface UserColumnConfigItem {
  key: string
  width: number
}

type ReducerActionType =
  | 'showSelector'
  | 'hideSelector'
  | 'saveColumns'
  | 'addColumn'
  | 'removeColumn'
  | 'addAllColumns'
  | 'removeAllColumns'
  | 'reorderColumns'
  | 'resizeColumns'
type Reducer = React.Reducer<
  CustomGridColumns,
  { type: ReducerActionType; payload?: unknown }
>
type ReturnType = [CustomGridColumns, Dispatch<ReducerAction<Reducer>>]

export const useCustomGridColumns = (
  configKey = 'columnConfig',
  defaultColumns: Column[]
): ReturnType => {
  const fixedColumns = defaultColumns.filter((v) => v.locked)
  const unselectableColumns = defaultColumns.filter(
    (v) => !v.selectable && !v.locked
  )
  const defaultSelectableColumns = defaultColumns.filter(
    (v) => !v.locked && v.selectable
  )
  const initialState = {
    selectedColumns: defaultSelectableColumns,
    availableColumns: [],
    columns: defaultColumns,
    showColumnSelector: false,
  }

  const saveColumnConfig = (columns: Column[]) => {
    const columnConfig: UserColumnConfigItem[] = columns.map(
      ({ key, width }) => ({ key, width })
    )
    window.localStorage[configKey] = JSON.stringify(columnConfig)
  }

  const value = React.useReducer<Reducer, CustomGridColumns>(
    (state, action) => {
      switch (action.type) {
        case 'showSelector':
          return { ...state, showColumnSelector: true }
        case 'hideSelector':
          return { ...state, showColumnSelector: false }
        case 'saveColumns': {
          const newColumns = mergeColumnsFromUserConfig(configKey, [
            ...fixedColumns,
            ...state.selectedColumns,
            ...unselectableColumns,
          ])
          saveColumnConfig(newColumns)
          return {
            ...state,
            columns: newColumns,
            showColumnSelector: false,
          }
        }
        case 'addColumn': {
          const addedColumn = action.payload as Column
          const availableColumns = state.availableColumns.filter((column) => {
            return column.id !== addedColumn.id
          })
          return {
            ...state,
            selectedColumns: [...state.selectedColumns, addedColumn],
            availableColumns,
          }
        }
        case 'removeColumn': {
          const removedColumn = action.payload as Column
          const selectedColumns = state.selectedColumns.filter((column) => {
            return column.id !== removedColumn.id
          })
          return {
            ...state,
            selectedColumns,
            availableColumns: [...state.availableColumns, removedColumn],
          }
        }
        case 'addAllColumns': {
          const selectedColumns = [
            ...state.selectedColumns,
            ...state.availableColumns,
          ]
          return {
            ...state,
            selectedColumns,
            availableColumns: [],
          }
        }
        case 'removeAllColumns': {
          const availableColumns = [
            ...state.selectedColumns,
            ...state.availableColumns,
          ]
          return {
            ...state,
            selectedColumns: [],
            availableColumns,
          }
        }
        case 'reorderColumns': {
          const params = action.payload as Record<string, number>
          const selectedColumns = state.selectedColumns.slice()
          const deleted = selectedColumns.splice(params.from, 1)
          selectedColumns.splice(params.to, 0, ...deleted)
          return { ...state, selectedColumns }
        }
        case 'resizeColumns': {
          const params = action.payload as Record<string, number>
          const resizedColumns = state.columns.map((entry, index) => ({
            ...entry,
            width: index === params.index ? params.width : entry.width,
          }))
          saveColumnConfig(resizedColumns)
          return { ...state, columns: resizedColumns }
        }
        default:
          return state
      }
    },
    initialState,
    (state) => {
      const columns = buildColumnsFromUserConfig(configKey, defaultColumns)
      const selectedColumns = columns.filter((v) => !v.locked && v.selectable)
      const selectedColumnKeys = selectedColumns.map((v) => v.key)
      return {
        ...state,
        selectedColumns,
        availableColumns: defaultSelectableColumns.filter((v) => {
          return !selectedColumnKeys.includes(v.key)
        }),
        columns,
      }
    }
  )

  return value
}

function parseUserConfig(configKey: string): UserColumnConfigItem[] | null {
  const configValue = window.localStorage[configKey]

  if (!configValue) {
    return null
  }

  try {
    const savedColumns = JSON.parse(configValue) as UserColumnConfigItem[]
    return savedColumns
  } catch (err) {
    console.warn('Failed to parse columns configuration', err)
    return null
  }
}

function mergeColumnsFromUserConfig(configKey: string, columns: Column[]) {
  const savedColumns = parseUserConfig(configKey)

  if (!savedColumns) {
    return columns
  }

  return columns.map((column) => {
    const userColumn = savedColumns.find((v) => v.key === column.key)
    const newWidth = userColumn?.width ?? column.width
    return { ...column, width: newWidth }
  })
}

function buildColumnsFromUserConfig(
  configKey: string,
  defaultColumns: Column[]
): Column[] {
  const savedColumns = parseUserConfig(configKey)

  if (!savedColumns) {
    return defaultColumns
  }

  // TODO: add support for multiple fixed columns
  const fixedColumn = defaultColumns.find((v) => v.locked)
  const fixedColumnWidth =
    savedColumns.find((v) => v.key === fixedColumn?.key)?.width ??
    fixedColumn?.width
  const allColumns = [
    { ...fixedColumn, width: fixedColumnWidth },
    ...savedColumns
      .filter((v) => v.key !== fixedColumn?.key)
      .map((entry) => {
        const columnData = defaultColumns.find((v) => v.key === entry.key)
        return { ...columnData, width: entry.width }
      }),
  ]
  return allColumns
}
