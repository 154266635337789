import { GeofencesState } from '../../types/index'
import { keyBy, sortBy, isEmpty } from 'lodash'
import { geofences } from '../actions/index'
import { AppState } from '../../types'
import getInitialState from '../initial-state'
import { Geofence } from 'common/types/local-api'

type Action = ReduxActions.Action<string> | ReduxActions.Action<Geofence[]>
type Reducer = (state: GeofencesState, action: Action) => GeofencesState

const { constants } = geofences
const initialState: GeofencesState = getInitialState().geofences

function getGeofenceTags(geofences) {
  let allTags: string[] = []
  for (const id in geofences) {
    const geofence = geofences[id]
    const tags: string[] = geofence.tags ? geofence.tags.split(',') : []
    allTags = allTags.concat(tags)
  }
  // Have to cast set to array for typescript
  return [...Array.from(new Set(allTags))].filter((tag) => !isEmpty(tag))
}

const upsert: Reducer = (state, action) => {
  const newGeofencesArray = action.payload as Geofence[]
  const newGeofences: Record<string, Geofence> = keyBy(newGeofencesArray, 'id')
  const oldGeofences = state.data
  const geofences = { ...oldGeofences, ...newGeofences }
  const tags = getGeofenceTags(geofences)

  return { ...state, data: geofences, tags }
}

const remove: Reducer = (state, action) => {
  const geofenceId = action.payload as string
  const geofences = { ...state.data }
  delete geofences[geofenceId]
  const tags = getGeofenceTags(geofences)

  return { ...state, data: geofences, tags }
}

export function getGeofencesArray(state: AppState): Geofence[] {
  if (!state.geofences || !state.geofences.data) {
    return []
  }

  const geofences = Object.keys(state.geofences.data).map(
    (key) => state.geofences.data[key]
  )
  return sortBy(geofences, [
    (geofence: Geofence) => geofence.name.toLowerCase(),
  ])
}

export function filterGeofencesByName(list: Geofence[], name = '') {
  if (!name) {
    return list
  }
  return list.filter((item) => item.name === name)
}

export default function (
  state: GeofencesState = initialState,
  action: ReduxActions.Action<any>
): GeofencesState {
  switch (action.type) {
    case constants.LOADING_START:
      return Object.assign({}, state, {
        error: null,
        loading: true,
      })

    case constants.LOADING_FAIL:
      return Object.assign({}, state, {
        error: action.payload.message,
        loading: false,
        loaded: false,
      })

    case constants.LOADING_END:
      return Object.assign({}, state, {
        loading: false,
        loaded: true,
      })

    case constants.SAVING_START:
      return Object.assign({}, state, {
        saving: true,
      })

    case constants.SAVING_END:
      return Object.assign({}, state, {
        saving: false,
        saved: true,
      })

    case constants.DELETING_END:
      return Object.assign({}, state, {
        deleting: false,
        deleted: true,
      })

    case constants.CLEAR:
      return Object.assign({}, state, {
        data: {},
        loaded: false,
      })

    case constants.UPSERT:
      return upsert(state, action)

    case constants.REMOVE:
      return remove(state, action)

    default:
      return state
  }
}
