import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'
import { AppState, DeviceView } from '../../types'
import { modalMapHide } from '../../store/actions/grid'
import {
  DeviceMapModalComponent,
  ReduxActions,
} from '../common/map/device-modal'
import { getMetrics } from '../../store/selectors/user'
import { Metrics } from '../common/metrics'

interface ReduxStateProps {
  device: DeviceView
  timezone: string
  userMetrics?: Metrics
}

interface ReduxDispatchProps {
  actions: ReduxActions
}

function mapStateToProps(state: AppState): ReduxStateProps {
  const device = state.blueprint.devices.data[state.pageGrid.currentDeviceId]
  return {
    device,
    timezone:
      state.user && state.user.profile
        ? state.user.profile.properties.defaultTimezone
        : null,
    userMetrics: getMetrics(state),
  }
}

function mapDispatchToProps(dispatch: Dispatch): ReduxDispatchProps {
  const actions = {
    modalMapHide,
  }
  return { actions: bindActionCreators(actions, dispatch) }
}

export const DeviceMapModal = connect(
  mapStateToProps,
  mapDispatchToProps
)(DeviceMapModalComponent)

export default DeviceMapModal
