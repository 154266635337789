import * as React from 'react'
import { AppState, UserProfile } from 'client/types'
import { connect } from 'react-redux'
import { Button } from 'react-bootstrap'
import { bindActionCreators } from 'redux'
import { deleteUser } from '../../../store/actions/user'
import { Confirm } from '../../common/confirm'
import { User } from 'rest-api'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash } from '@fortawesome/free-solid-svg-icons'

interface OwnProps {
  user: User
}

interface ReduxStateProps {
  currentUser: UserProfile
}

interface Props extends OwnProps, ReduxStateProps {
  actions: {
    deleteUser: typeof deleteUser
  }
}

interface State {
  confirmVisible: boolean
}

function mapStateToProps(state: AppState): ReduxStateProps {
  return {
    currentUser: state.user.profile,
  }
}

function mapDispatchToProps(dispatch) {
  const actions = { deleteUser }
  return { actions: bindActionCreators(actions, dispatch) }
}

class UserDeleteButtonComponent extends React.Component<Props, State> {
  render() {
    const disabled = this.props.currentUser?.id === this.props.user?.id

    return (
      <Confirm
        body="Are you sure you want to delete this user?"
        title="Delete User?"
        confirmText="Delete User"
        onConfirm={() => {
          this.props.actions.deleteUser(this.props.user.id)
        }}
      >
        <Button variant="outline-danger" disabled={disabled}>
          <FontAwesomeIcon icon={faTrash} />
        </Button>
      </Confirm>
    )
  }
}

export const UserDeleteButton = connect(
  mapStateToProps,
  mapDispatchToProps
)(UserDeleteButtonComponent)
export default UserDeleteButton
