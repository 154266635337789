import * as React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { CellFormatter } from './cell-formatter'
import { AppState, DeviceView } from '../../../../types/index'
import { updateDevice } from '../../../../store/actions/page-admin-devices'
import { FormControl } from 'react-bootstrap'

interface ReduxStateProps {
  editedDevices: Record<string, Partial<DeviceView>>
}

function mapStateToProps(state: AppState): ReduxStateProps {
  return {
    editedDevices: state.pageAdminDevices.editedDevices,
  }
}

function mapDispatchToProps(dispatch) {
  const actions = { updateDevice }
  return { actions: bindActionCreators(actions, dispatch) }
}

class CellTextFormatterComponent extends CellFormatter {
  render() {
    if (!this.isEditing()) {
      return <span>{this.props.value}</span>
    }

    return (
      <FormControl
        value={this.state.value}
        onChange={(e) => {
          const target = e.target as HTMLInputElement

          let formattedValue = target.value
          if (this.props.valueFormatter) {
            formattedValue = this.props.valueFormatter(
              this.props.dependentValues.type,
              target.value
            )
          }
          let formattedCell = target.value
          if (this.props.cellFormatter) {
            formattedCell = this.props.cellFormatter(
              this.props.dependentValues.type,
              target.value
            )
          }
          this.setState({
            value: formattedCell,
          })
          this.handleChange(formattedValue)
        }}
      />
    )
  }
}

export const CellTextFormatter = connect(
  mapStateToProps,
  mapDispatchToProps
)(CellTextFormatterComponent)
export default CellTextFormatter
