import { Column } from '../../types'
import { UserProfile } from '../../types/user-state'
import {
  getMetricsSettings,
  getTemperatureValue,
  getDistanceValue,
  getMetricsAbbreviation,
} from './metrics'

export function convertMetrics<T = Record<string, any>>(
  device: T,
  profile: UserProfile
): T {
  const metrics = getMetricsSettings(profile)
  const convertedDevice = Object.create(null)
  for (const key in device) {
    if (device[key] !== null && device[key] !== undefined) {
      switch (key) {
        case 'Ambient Temp':
        case 'Zone 1 SPT':
        case 'Zone 1 Temp':
        case 'Zone 2 SPT':
        case 'Zone 2 Temp':
        case 'Zone 3 SPT':
        case 'Zone 3 Temp':
        case 'Tire 1 Temp':
        case 'Tire 2 Temp':
        case 'Tire 3 Temp':
        case 'Tire 4 Temp':
        case 'Tire 5 Temp':
        case 'Tire 6 Temp':
        case 'Tire 7 Temp':
        case 'Tire 8 Temp':
        case 'Spare 1 Temp':
        case 'Spare 2 Temp':
        case 'Spare 3 Temp':
        case 'Spare 4 Temp':
        case 'Spare 5 Temp':
        case 'Spare 6 Temp':
          convertedDevice[key] = getTemperatureValue(
            Number(device[key]),
            metrics
          )
          break
        case 'Speed':
        case 'Distance':
          convertedDevice[key] = Math.ceil(
            getDistanceValue(Number(device[key]), metrics)
          )
          break
        default:
          convertedDevice[key] = device[key]
          break
      }
    } else {
      convertedDevice[key] = device[key]
    }
  }
  return convertedDevice
}

export function convertColumnNames(
  columns: Column[],
  userProfile: UserProfile
): Column[] {
  const metrics = getMetricsSettings(userProfile)
  const convertedColumns = columns.map((column: Column): Column => {
    const convertedColumn = { ...column }
    switch (convertedColumn.key) {
      case 'Ambient Temp':
      case 'Zone 1 SPT':
      case 'Zone 1 Temp':
      case 'Zone 2 SPT':
      case 'Zone 2 Temp':
      case 'Zone 3 SPT':
      case 'Zone 3 Temp':
      case 'Spare 1 Temp':
      case 'Spare 2 Temp':
      case 'Spare 3 Temp':
      case 'Spare 4 Temp':
      case 'Spare 5 Temp':
      case 'Spare 6 Temp':
        {
          const tempAbbreviation = getMetricsAbbreviation(
            'displayUnitsTemperature',
            metrics
          )
          convertedColumn.name = `${convertedColumn.name} (${tempAbbreviation})`
        }
        break
      case 'Speed':
        {
          const speedAbbreviation = getMetricsAbbreviation(
            'displayUnitsDistance',
            metrics,
            '',
            'h'
          )
          convertedColumn.name = `${convertedColumn.name} (${speedAbbreviation})`
        }
        break
      case 'Distance':
        {
          const distanceAbbreviation = getMetricsAbbreviation(
            'displayUnitsDistance',
            metrics
          )
          convertedColumn.name = `${convertedColumn.name} (${distanceAbbreviation})`
        }
        break
      // case 'Drive Time':
      //   const timeAbbreviation = 'H:mm'
      //   convertedColumn.name = `${convertedColumn.name} (${timeAbbreviation})`
      //   break
      // case 'Idle':
      // case 'Recent Idle':
      //   const daysAbbreviation = 'days'
      //   convertedColumn.name = `${convertedColumn.name} (${daysAbbreviation})`
      //   break
      default:
    }

    return convertedColumn
  })

  return convertedColumns
}
