import { PageAdminUsersState } from '../../types/index'
import { pageAdminUsers } from '../actions/index'
import getInitialState from '../initial-state'

const { constants } = pageAdminUsers
const initialState: PageAdminUsersState = getInitialState().pageAdminUsers

export default function (
  state: PageAdminUsersState = initialState,
  action: ReduxActions.Action<any>
): PageAdminUsersState {
  switch (action.type) {
    case constants.MODAL_HIDE:
      return Object.assign({}, state, {
        currentUser: null,
        showModal: false,
      })

    case constants.MODAL_SHOW:
      return Object.assign({}, state, {
        currentUser: action.payload,
        showModal: true,
      })

    default:
      return state
  }
}
