import * as React from 'react'
import moment from 'moment'
import { Row, Col, Container, Alert } from 'react-bootstrap'
import { bindActionCreators, Dispatch } from 'redux'
import { connect } from 'react-redux'
import { debounce } from 'lodash'
import memoizeOne from 'memoize-one'

import { AppState, Column, DeviceView } from '../../types'
import { DeviceMapModal } from './device-map-modal'
import * as deviceTableActions from '../../store/actions/grid'
import { SelectFilterContainer } from '../common/select-filter-container'
import { SearchField } from '../common/search-field'
import { sortDevices } from '../../store/reducers/grid'
import PrintOnly from '../common/print-only'
import PrintButton from '../common/print-button'
import { ColumnSelectorButton } from '../common/grid/column-select/column-selector-button'
import { DEVICE_FIELDS } from 'common/consts'
import { UserEmail } from '../common/user-email'
import { withPrintOption } from '../common/grid/printable-grid'
import DeviceExportButton from '../common/grid/grid-export-button'
import { getFilteredDevices } from '../../store/selectors/devices'
import { SortDir, TableProps, Table } from '../common/grid/table'

interface ReduxStateProps {
  devices: DeviceView[]
  tags: string[]
  filterTerm: string
  filterTags: string[]
  showMapModal: boolean
  loadingChild: boolean
}

interface ReduxDispatchProps {
  actions: Record<string, (...args: any[]) => void>
}

interface OwnProps {
  columns: Column[]
  height?: number
  onEditColumnsClick?: () => void
  onResizeColumn?: (index: number, width: number, columns: Column[]) => void
}

type TProps = OwnProps & ReduxStateProps & ReduxDispatchProps

interface OwnState {
  rows?: Array<Record<string, any>>
  columns?: Column[]
  sort: {
    col: string
    dir: SortDir
  }
}

const sortDevicesMemoized = memoizeOne(sortDevices)

const PrintableGrid = withPrintOption<TableProps>(Table)

const mapStateToProps = (
  state: AppState,
  ownProps: OwnProps
): ReduxStateProps => {
  return {
    devices: getFilteredDevices(ownProps.columns)(state),
    tags: state.blueprint.devices.tags,
    filterTerm: state.pageGrid.filterTerm,
    filterTags: state.pageGrid.filterTags,
    showMapModal: state.pageGrid.showMapModal,
    loadingChild: state.blueprint.devices.loadingChild,
  }
}

const mapDispatchToProps = (dispatch: Dispatch): ReduxDispatchProps => {
  const actions = {
    filterDevicesTags: deviceTableActions.filterDevicesTags,
    filterDevicesTerm: deviceTableActions.filterDevicesTerm,
    clearFilters: deviceTableActions.clearFilters,
  }
  return { actions: bindActionCreators(actions, dispatch) }
}

export class DeviceTableComponent extends React.PureComponent<
  TProps,
  OwnState
> {
  static getDerivedStateFromProps(
    props: TProps,
    state: OwnState
  ): Partial<OwnState> {
    const rows = state.sort
      ? sortDevicesMemoized<Record<string, any>>(
          props.devices,
          state.sort.col,
          state.sort.dir,
          props.columns
        )
      : props.devices
    return { rows }
  }

  state: OwnState = {
    sort: {
      col:
        window.sessionStorage.getItem('gridSortColumn') ??
        DEVICE_FIELDS.TRAILER_ID,
      dir:
        (window.sessionStorage.getItem('gridSortDirection') as SortDir) ??
        'ASC',
    },
  }

  delayedSearch = debounce((value: string) => {
    this.props.actions.filterDevicesTerm(value)
  }, 500)

  handleSearchChange = (value: string) => {
    this.delayedSearch(value)
  }

  handleGridSort = (sortColumn: string, sortDirection: SortDir) => {
    this.setState({
      sort: {
        col: sortColumn,
        dir: sortDirection,
      },
    })
  }

  handleTagFilterSelect = (options: any[]) => {
    const values = options.map((option) => option.value)
    this.props.actions.filterDevicesTags(values)
  }

  componentDidMount() {
    this.props.actions.clearFilters()
  }

  componentWillUnmount() {
    this.delayedSearch.cancel()
    window.sessionStorage.setItem('gridSortColumn', this.state.sort.col)
    window.sessionStorage.setItem('gridSortDirection', this.state.sort.dir)
  }

  render() {
    if (!this.state.rows) {
      return <Alert variant="warn">No customers found.</Alert>
    }

    const tagOptions = this.props.tags.map((tag: string) => ({
      label: tag,
      value: tag,
    }))
    const rows = this.state.rows

    return (
      <>
        {this.props.showMapModal && <DeviceMapModal />}
        <Container fluid className="device-table-component p-0">
          <Row
            noGutters
            className="mb-2 align-items-center justify-content-end"
          >
            <Col className="flex-justify-start mr-auto">
              <h1 className="title">
                Customer Summary <small>({rows.length})</small>
                {this.props.loadingChild && (
                  <i className="fa fa-spin fa-spinner" />
                )}
              </h1>
            </Col>
            <Col className="flex-justify-center no-print mr-2">
              <SearchField onChange={this.handleSearchChange} />
            </Col>
            <Col className="flex-justify-center mr-2">
              <SelectFilterContainer
                multi
                placeholder="Groups..."
                value={this.props.filterTags.map((v) => ({
                  value: v,
                  label: v,
                }))}
                options={tagOptions}
                onChange={this.handleTagFilterSelect}
              />
            </Col>
            <Col className="flex-justify-end" lg="auto">
              <span className="mr-2">
                <DeviceExportButton
                  data={rows}
                  columns={this.state.columns}
                  fileName="Trailer Summary"
                />
              </span>
              <span className="mr-2">
                <PrintButton />
              </span>
              <ColumnSelectorButton onClick={this.props.onEditColumnsClick} />
            </Col>
            <PrintOnly>
              <Col>
                Printed
                <p>
                  by: <UserEmail />
                </p>
                <p>at {moment().format()}</p>
              </Col>
            </PrintOnly>
          </Row>
          <PrintOnly>
            <Row>
              {this.props.filterTerm ? (
                <Col xs={3}>
                  <span>
                    Filter:{' '}
                    <span className="bold">{this.props.filterTerm}</span>
                  </span>
                </Col>
              ) : null}
              {this.props.filterTags ? (
                <Col xs={3}>
                  <span>
                    Tags: <span className="bold">{this.props.filterTags}</span>
                  </span>
                </Col>
              ) : null}
            </Row>
          </PrintOnly>
          <Row className="device-table-grid-container">
            <Col className="d-flex flex-column">
              <PrintableGrid
                sortColumn={this.state.sort.col}
                sortDirection={this.state.sort.dir}
                columns={this.props.columns}
                rows={rows}
                onSort={this.handleGridSort}
                onResize={this.props.onResizeColumn}
                height={this.props.height}
              />
            </Col>
          </Row>
        </Container>
      </>
    )
  }
}

export const DeviceTable = connect(
  mapStateToProps,
  mapDispatchToProps
)(DeviceTableComponent)

export default DeviceTable
