import { getJwt } from 'common/services/api/common'
import { useHistory } from 'react-router-dom'
import useSWR, { SWRConfiguration } from 'swr'

const isJson = (contentType: string) => /application\/json/i.test(contentType)

const BASE_URL = '/api'

const genericApiFetch = async (
  url: string,
  accessToken: string | null,
  fetchOptions: RequestInit = {}
) => {
  console.debug('genericApiFetch', { url, accessToken, fetchOptions })

  const res = await fetch(url, {
    ...fetchOptions,
    headers: {
      ...fetchOptions.headers,
      Authorization: accessToken ? `Bearer ${accessToken}` : '',
    },
  })

  const contentType = res.headers.get('Content-Type') as string

  if (res.ok) {
    if (isJson(contentType)) {
      return res.json()
    }

    return res.blob()
  }

  const err = new HttpError(`Request failed with status code ${res.status}`)
  err.statusCode = res.status
  err.validationErrors = []

  if (isJson(contentType)) {
    const jsonResponse = await res.json()
    err.validationErrors = [jsonResponse.message].flat()
  }

  throw err
}

export const localApiFetchWithToken = async (
  path: string,
  accessToken: string | null,
  fetchOptions: RequestInit = {}
) => {
  return genericApiFetch(`${BASE_URL}${path}`, accessToken, fetchOptions)
}

export const restApiFetchWithToken = async (
  path: string,
  accessToken: string | null,
  fetchOptions: RequestInit = {}
) => {
  return genericApiFetch(
    `${process.env.API_BASE_URL}${path}`,
    accessToken,
    fetchOptions
  )
}

export class HttpError extends Error {
  statusCode!: number
  validationErrors!: string[]
}

export const useRestApi = <R = unknown>(
  url: string | null | undefined,
  fetchOptions?: RequestInit,
  swrConfig?: SWRConfiguration
) => {
  const token = getJwt()
  const history = useHistory()

  return useSWR<R, HttpError>(
    () => (token && url ? [url, token, fetchOptions] : null),
    restApiFetchWithToken,
    {
      onError: (err) => {
        if (err.statusCode === 401) {
          history.push('/login')
        } else {
          // TODO: show global error message
          console.error(err)
        }
      },
      revalidateOnFocus: false,
      ...swrConfig,
    }
  )
}

export const useLocalApi = <R = unknown>(
  url: string | null | undefined,
  fetchOptions?: RequestInit,
  swrConfig?: SWRConfiguration
) => {
  const token = getJwt()
  const history = useHistory()

  return useSWR<R, HttpError>(
    () => (token && url ? [url, token, fetchOptions] : null),
    localApiFetchWithToken,
    {
      onError: (err) => {
        if (err.statusCode === 401) {
          history.push('/login')
        } else {
          // TODO: show global error message
          console.error(err)
        }
      },
      revalidateOnFocus: false,
      ...swrConfig,
    }
  )
}

export const useLocalApiFetch = () => {
  return {
    apiGet: (path: string, fetchOptions?: RequestInit) => {
      return localApiFetchWithToken(path, getJwt(), fetchOptions)
    },
    apiPost: (
      path: string,
      body: Record<string, unknown>,
      fetchOptions?: RequestInit
    ) => {
      return localApiFetchWithToken(path, getJwt(), {
        method: 'POST',
        body: JSON.stringify(body),
        headers: {
          'Content-Type': 'application/json',
        },
        ...fetchOptions,
      })
    },
    apiPostForm: (path: string, data: FormData, fetchOptions?: RequestInit) => {
      return localApiFetchWithToken(path, getJwt(), {
        method: 'POST',
        body: data,
        ...fetchOptions,
      })
    },
    apiPut: (
      path: string,
      body: Record<string, unknown>,
      fetchOptions?: RequestInit
    ) => {
      return localApiFetchWithToken(path, getJwt(), {
        method: 'PUT',
        body: JSON.stringify(body),
        headers: {
          'Content-Type': 'application/json',
        },
        ...fetchOptions,
      })
    },
    apiDelete: (path: string, fetchOptions?: RequestInit) => {
      return localApiFetchWithToken(path, getJwt(), {
        method: 'DELETE',
        ...fetchOptions,
      })
    },
  }
}
