import { DeviceReport } from 'common/types/local-api'
import { localApiFetchWithToken } from '../api'

export async function getForDevice(
  jwt: string,
  deviceId: string,
  timeframe: string
): Promise<DeviceReport[]> {
  const url = `/reports/${deviceId}?timeframe=${timeframe}`
  const data = await localApiFetchWithToken(url, jwt)
  return data as DeviceReport[]
}
