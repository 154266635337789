import * as ReduxActions from 'redux-actions'

import { PageAdminDevicesState } from '../../types'
import { Device } from 'rest-api'

interface SortPayload {
  column: string
  data: SortData[]
  direction: 'desc' | 'asc' | 'NONE'
  initialIds: string[]
}

interface UpdatePayload {
  id: string
  key: string
  value: any
}

interface SortData {
  id: string
  data: any
}

type ReducersPayload =
  | string
  | string[]
  | SortPayload
  | UpdatePayload
  | Device
  | Device[]
type Action = ReduxActions.Action<ReducersPayload>
type Reducer = (
  state: PageAdminDevicesState,
  action: Action
) => PageAdminDevicesState
type SortDirection = 'desc' | 'asc'

export const constants = {
  DEVICE_EDIT: 'pageAdminDevices/DEVICE_EDIT',
  DEVICE_UPDATE: 'pageAdminDevices/DEVICE_UPDATE',
  DEVICE_ADD: 'pageAdminDevices/DEVICE_ADD',
  DEVICE_SAVE_START: 'pageAdminDevices/DEVICE_SAVE_START',
  DEVICE_SAVE_END: 'pageAdminDevices/DEVICE_SAVE_END',
  DEVICE_SAVE_ERROR: 'pageAdminDevices/DEVICE_SAVE_ERROR',
  DEVICE_CANCEL: 'pageAdminDevices/DEVICE_CANCEL',
}

const initialState: PageAdminDevicesState = {
  editedDevices: {},
}

const editDevice: Reducer = (state, action) => {
  const deviceId = action.payload as string
  const editedDevices = { ...state.editedDevices, [deviceId]: { id: deviceId } }
  return { ...state, editedDevices }
}

const updateDevice: Reducer = (state, action) => {
  const payload = action.payload as UpdatePayload
  const editedDevice = state.editedDevices[payload.id]
  const newDevice = { ...editedDevice, [payload.key]: payload.value }
  const editedDevices = { ...state.editedDevices, [payload.id]: newDevice }
  return { ...state, editedDevices }
}

const startSaveDevice: Reducer = (state, action) => ({ ...state })
const endSaveDevice: Reducer = (state, action) => {
  const device = action.payload as Device
  const editedDevices = { ...state.editedDevices }
  delete editedDevices[device.id]
  return { ...state, editedDevices }
}
const errorSaveDevice: Reducer = (state, action) => ({ ...state })
const cancelDevice: Reducer = (state, action) => {
  const id = action.payload as string
  const editedDevices = { ...state.editedDevices }
  delete editedDevices[id]
  return { ...state, editedDevices }
}

const reducerMap: ReduxActions.ReducerMap<
  PageAdminDevicesState,
  ReducersPayload
> = {
  [constants.DEVICE_EDIT]: editDevice,
  [constants.DEVICE_UPDATE]: updateDevice,
  [constants.DEVICE_SAVE_START]: startSaveDevice,
  [constants.DEVICE_SAVE_END]: endSaveDevice,
  [constants.DEVICE_SAVE_ERROR]: errorSaveDevice,
  [constants.DEVICE_CANCEL]: cancelDevice,
}

export const reducer: Reducer = ReduxActions.handleActions(
  reducerMap,
  initialState
)
export default reducer
