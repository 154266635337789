import * as mqtt from 'mqtt'
import { JwtPayload } from 'rest-api'
import decode from 'jwt-decode'

export default class MqttListener {
  private client: mqtt.Client

  // devices to subscribe to
  // object properties are device ids
  // property values are booleans for "Ready for Upsert" status
  // devices set to false when added to skip the first reflection update
  // at which point value is changed to true
  // and device will be updated on subsequent reflection updates
  private devices = {}

  // channels to subscribe to per device
  private channels: string[] = []

  // list of topics we should be subscribed to
  private topics: string[] = []

  // list of topics we are not subscribed to yet
  private toSubscribe: string[] = []

  // Object of arrays that represent callbacks to call
  private callbacks: {
    [channelName: string]: Array<(...args: any[]) => any>
  } = {}

  // subscription state machine.
  private subscriberState: 'working' | 'finished' = 'finished'

  private options: { onConnect?: () => any } = {}

  private isConnected = false

  public connect(onConnect: () => any) {
    // don't try to connect if we already have a connected client
    if (this.client === undefined || !this.isConnected) {
      // Setup callback to run when connect succeeds
      // TODO: optional onConnect
      this.options.onConnect = onConnect

      const mqttHost = process.env.BROKER_URL

      const jwt = localStorage.getItem('user-jwt')
      const payload = decode(jwt) as JwtPayload

      this.client = mqtt.connect(mqttHost, {
        username: 'Auth:JWT',
        password: jwt,
        keepalive: 120,
        clean: true,
        clientId: payload.email,
      })

      // Register callbacks. (Not 100% sure .bind() is the cleanest way around this)
      this.client.on('error', this.onError.bind(this))
      this.client.on('reconnect', this.onReconnect.bind(this))
      this.client.on('close', this.onClose.bind(this))
      this.client.on('offline', this.onOffline.bind(this))
      this.client.on('message', this.onMessage.bind(this))
      this.client.on('connect', this.onConnect.bind(this))
    } else {
      console.debug('*** Already Connected ***', this.client)
    }
  }

  public addDevice(deviceId) {
    if (this.devices[deviceId] !== undefined) {
      return
    }
    // boolean = ready for upsert?
    this.devices[deviceId] = false

    // console.log('Added device, now adding all channels to subscription list');
    this.channels.forEach((channel) => {
      this.addTopic(deviceId, channel)
    })
  }

  public removeAllDevices() {
    this.topics.forEach((topic) => {
      this.client.unsubscribe(topic)
    })
    this.topics = []
    this.devices = {}
  }

  public addChannel(channel) {
    if (this.channels.indexOf(channel) !== -1) {
      // Attempted add of channel to listener when it already was being listened to
      return
    }
    this.channels.push(channel)
    this.callbacks[channel] = []

    for (const deviceId in this.devices) {
      this.addTopic(deviceId, channel)
    }
  }

  public publish(deviceId, channel, data, callback) {
    const topic = this.createTopic(deviceId, channel)
    console.debug(`[MQTT] publish to topic ${topic}`, data)
    this.client.publish(topic, data, callback)
  }

  public use(channel, callback: (messageData: any) => void) {
    console.debug(
      'Setting up middleware for MQTT listener on channel "%s"',
      channel
    )
    // Try to add channel (even if it already exists - dupe check happens downstream)
    this.addChannel(channel)
    this.callbacks[channel].push(callback)
  }

  private createTopic(deviceId, channel) {
    return `${deviceId}/${channel}`
  }

  private addTopic(deviceId, channel) {
    const topic = this.createTopic(deviceId, channel)
    // If this topic already is in the list, skip
    if (this.topics.indexOf(topic) === -1) {
      this.topics.push(topic)
    }

    if (this.toSubscribe.indexOf(topic) === -1) {
      this.toSubscribe.push(topic)
      this.subscribeAll()
    }
  }

  private subscribeAll() {
    if (typeof this.client === 'undefined') {
      return
    }
    // don't clobber the subscription server
    // fraiser once said a wise man borked the broker this way
    const subscribeNext = () => {
      if (this.toSubscribe.length === 0) {
        // If we have nothing else to subscribe to
        this.subscriberState = 'finished'
        // log('info', 'MQTT listener subscribed to %s channels', this.topics.length);
      } else {
        const topic = this.toSubscribe[0]
        this.client.subscribe(topic, undefined, (error, granted) => {
          if (error) {
            console.error(
              'MQTT listener encountered an error when subscribing to all device topics:',
              error
            )
          }

          if (granted && granted.length > 0 && granted[0].qos === 128) {
            // TODO: Remove topic from list of topics if denied?
            console.error('MQTT subscription denied for %s', topic)
          }

          this.toSubscribe.shift()
          setTimeout(subscribeNext, 0)
        })
      }
    }
    // Prevent subscriptions from happening concurrently
    if (this.subscriberState === 'finished' && this.toSubscribe.length > 0) {
      this.subscriberState = 'working'
      subscribeNext()
    }
  }

  private onMessage(topic: string, messageBuffer: Buffer) {
    // Get device and channel details
    const [deviceId, channel] = parseTopic(topic)
    const payload = messageBuffer.toString()

    // console.debug('onMessage()', { deviceId, channel, payload })

    if (this.channels.indexOf(channel) !== -1) {
      const data = {
        buffer: messageBuffer,
        deviceId,
        channel,
        payload,
        topic,
      }
      // Iterates one at a time each time next is called. Starting at -1 brings us to zero
      let currentIndex = -1
      const next = () => {
        currentIndex++
        if (typeof this.callbacks[channel][currentIndex] !== 'undefined') {
          // If we have a callback to run..
          try {
            this.callbacks[channel][currentIndex](data, next)
          } catch (error) {
            console.error(error)
            return setTimeout(next, 0)
          }
        } else {
          // we already ran our last callback, so we..
          // cleanup memory?
        }
      }

      return next()
    }
  }

  private onConnect() {
    console.debug('MQTT client connected')

    this.isConnected = true
    // MQTT listener reconnecting
    this.subscriberState = 'finished'
    // Clear out existing pending subscriptions in case we disconnected mid-subscribe
    this.toSubscribe = []
    // Copy the values of topics into subscribe
    this.toSubscribe.push(...this.topics)

    this.subscribeAll()

    if (typeof this.options.onConnect === 'function') {
      this.options.onConnect()
    }
  }

  private onError(error) {
    console.error('MQTT client error', error)
    // most likely an authentication issue so close
    // connection and create new client with updated jwt
    // force closing the client allows us to bypass the reconnect
    // attempt where we enter an infinite loop of closing and
    // reconnecting due to an invalid jwt at each attempt
    this.client.end(true, () => {
      console.debug('MQTT client forced end', error)
    })

    this.client = undefined

    // We need to delay the reconnect action of the mqtt client
    const fn = () => {
      console.debug('*** Delay 3000 for reconnect ***')
      this.connect(this.options.onConnect)
    }
    setTimeout(fn, 3000)
  }

  private onOffline() {
    console.debug('MQTT client offline', this.client)
    this.isConnected = false
  }

  private onClose() {
    console.debug('MQTT client closed', this.client)
  }

  private onReconnect() {
    console.debug('MQTT client reconnecting...', this.client)
  }

  public checkDeviceReadyForUpsert(deviceId: string) {
    return this.devices[deviceId]
  }

  public setDeviceReadyForUpsert(deviceId: string) {
    this.devices[deviceId] = true
  }
}

const parseTopic = (topic: string): [string, string] => {
  const slashIndex = topic.indexOf('/')
  return [topic.substring(0, slashIndex), topic.substring(slashIndex + 1)]
}
