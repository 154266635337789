import * as React from 'react'
import SelectFilterContainer from '../../common/select-filter-container'
import { ISelect } from '../../../types/index'
import { AppState } from '../../../types/app-state'
import { useSelector } from 'react-redux'

interface Props {
  value: string[]
  onChange: (value: string[]) => void
}

export const SearchTagsControl: React.FC<Props> = ({ value, onChange }) => {
  const tags = useSelector<AppState, string[]>(
    (state) => state.blueprint.devices.tags
  )
  const options = tags.map((tag) => ({
    label: tag,
    value: tag,
  }))
  return (
    <SelectFilterContainer
      placeholder="Groups..."
      value={value.map((v) => ({ value: v, label: v }))}
      multi={true}
      options={options}
      onChange={(opts: ISelect[]) => {
        const values = opts.map((option) => {
          return option.value
        })
        onChange(values)
      }}
    />
  )
}
