import { localApiError } from './local-api'
import * as localAPI from '../../lib/local-api/index'

export function exportXLS(data: any, fileName: string) {
  return async function (dispatch) {
    try {
      await localAPI.exp.exportXLS(data, fileName)
    } catch (err) {
      dispatch(localApiError(err))
    }
  }
}
