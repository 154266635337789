import * as ReduxActions from 'redux-actions'
import { keyBy } from 'lodash'
import { GroupMap, AppState } from 'client/types'
import { GroupsState } from 'client/types/groups-state'
import { Group } from 'rest-api'

type Action = ReduxActions.Action<string> | ReduxActions.Action<Group[]>
type Reducer = (state: GroupsState, action: Action) => GroupsState

export interface GroupHeirarchy {
  organization: Group
  children: GroupHeirarchy[]
}

export interface FlatGroupHierarchy extends Group {
  depth: number
  childCount: number
}

const initialState: GroupsState = {
  loading: false,
  loaded: false,
  error: '',
  current: null,
  data: {},
}

export const constants = {
  UPSERT: 'groups/UPSERT',
  REMOVE: 'groups/REMOVE',
  SET: 'groups/SET',
  INITIAL_STATE: 'groups/INITIAL_STATE',
  LOADING_START: 'groups/LOADING_START',
  LOADING_END: 'groups/LOADING_END',
  LOAD_RESET: 'devices/LOAD_RESET',
  ERROR: 'groups/ERROR',
}

const upsert: Reducer = (state, action) => {
  const newOrgsArray = action.payload as Group[]
  const newOrgs: GroupMap = keyBy(newOrgsArray, 'id')
  const oldOrgs = state.data
  const orgs = { ...oldOrgs, ...newOrgs }
  return { ...state, data: orgs }
}

const remove: Reducer = (state, action) => {
  const deviceId = action.payload as string
  const orgs = { ...state.data }
  delete orgs[deviceId]
  return { ...state, data: orgs }
}

const set: Reducer = (state, action) => {
  return { ...state, current: action.payload as string }
}

const loadingStart: Reducer = (state, action) => {
  return { ...state, loading: true, loaded: false, error: '' }
}

const loadingEnd: Reducer = (state, action) => {
  return { ...state, loading: false, loaded: true }
}

const loadingError: Reducer = (state, action) => {
  const error = action.payload as string
  return { ...state, error }
}

export const reducer: Reducer = (state = initialState, action) => {
  const actionMap = {
    [constants.UPSERT]: upsert,
    [constants.REMOVE]: remove,
    [constants.SET]: set,
    [constants.LOADING_START]: loadingStart,
    [constants.LOADING_END]: loadingEnd,
    [constants.ERROR]: loadingError,
    [constants.INITIAL_STATE]: () => initialState,
  }

  if (actionMap[action.type]) {
    return actionMap[action.type](state, action)
  }
  return state
}

export function getAllChildren(
  groupMap: GroupMap,
  parentGroupId: string
): GroupHeirarchy[] {
  const orgs: GroupHeirarchy[] = []

  for (const orgId in groupMap) {
    if (Object.prototype.hasOwnProperty.call(groupMap, orgId)) {
      const org = groupMap[orgId]
      if (org.parentId === parentGroupId) {
        orgs.push({
          organization: org,
          children: getAllChildren(groupMap, org.id),
        })
      }
    }
  }
  return orgs
}
