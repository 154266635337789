import decode from 'jwt-decode'
import { createAction } from 'redux-actions'

import { upsert, remove } from '../blueprint/users/actions'
import { modalHide } from './page-admin-users'
import { localApiError } from './local-api'
import { getOrganizationId } from '../util'
import { batchRequest } from '../blueprint/actions'
import * as consts from 'common/consts'
import { JWT_KEY } from 'common/services/api/common'
import { constants as groupConstants } from 'client/store/blueprint/groups/reducers'
import api from 'client/api'
import { JwtPayload, PartialUser, UserResponse } from 'rest-api'
import { AppState } from 'client/types'

export const constants = {
  USER_SET_USER_ID: 'user/SET_USER_ID',
  USER_LOAD_START: 'user/LOAD_START',
  USER_LOAD_END: 'user/LOAD_END',
  USER_LOAD_FAIL: 'user/LOAD_FAIL',
  USER_SAVE_START: 'user/SAVE_START',
  USER_SAVE_END: 'user/SAVE_END',
  USER_SAVE_FAIL: 'user/SAVE_FAIL',
  USER_DELETE_CONFIRM: 'user/DELETE_CONFIRM',
  USER_DELETE_START: 'user/DELETE_START',
  USER_DELETE_END: 'user/DELETE_END',
  USER_DELETE_FAIL: 'user/DELETE_FAIL',
  LOAD_RESET: 'user/LOAD_RESET',
  PROFILE_SAVE_START: 'user/PROFILE_SAVE_START',
  PROFILE_SAVE_END: 'user/PROFILE_SAVE_END',
  PROFILE_SAVE_FAIL: 'user/PROFILE_SAVE_FAIL',
  CLEAN_STATUS_MESSAGES: 'user/CLEAN_STATUS_MESSAGES',
}

const userLoadStart = createAction(constants.USER_LOAD_START)
const userLoadEnd = createAction(constants.USER_LOAD_END, (payload) => payload)
const userLoadFail = createAction(
  constants.USER_LOAD_FAIL,
  (payload) => payload
)
const userSaveStart = createAction(constants.USER_SAVE_START)
const userSaveEnd = createAction(constants.USER_SAVE_END)
const userSaveFail = createAction(
  constants.USER_SAVE_FAIL,
  (payload) => payload
)
const userDeleteStart = createAction(constants.USER_DELETE_START)
const userDeleteEnd = createAction(constants.USER_DELETE_END)
const userDeleteFail = createAction(
  constants.USER_DELETE_FAIL,
  (payload) => payload
)
const profileSaveStart = createAction(constants.PROFILE_SAVE_START)
const profileSaveEnd = createAction(
  constants.PROFILE_SAVE_END,
  (payload) => payload
)
const profileSaveFail = createAction(
  constants.PROFILE_SAVE_FAIL,
  (payload) => payload
)

export const userDeleteConfirm = createAction(constants.USER_DELETE_CONFIRM)
export const cleanStatusMessages = createAction(constants.CLEAN_STATUS_MESSAGES)

export const saveUser = (userId: string | null, updatedFields: PartialUser) => {
  return async (dispatch, getState: () => AppState) => {
    dispatch(userSaveStart())

    try {
      if (!updatedFields.groupId) {
        updatedFields.groupId = await getOrganizationId(getState())
      }

      let response: UserResponse
      if (userId) {
        const newProps: PartialUser & { newPassword?: string } = {
          ...updatedFields,
        }
        if (updatedFields.password) {
          newProps.newPassword = updatedFields.password
        }
        response = await api.blueprint.endUsers.updateUser(userId, newProps)
      } else {
        response = await api.blueprint.endUsers.createUser(updatedFields)
      }

      const updatedUser = response.user
      dispatch(userSaveEnd())
      dispatch(modalHide())
      dispatch(cleanStatusMessages())

      if (updatedUser) {
        // If the user chose to change their role, we deleted their old blueprint user, so get rid of it from the grid
        if (userId && updatedUser.id && updatedUser.id !== userId) {
          dispatch(remove(userId))
        }
        if (updatedUser) {
          dispatch(upsert([updatedUser]))
        }
      }
    } catch (err) {
      console.error(err)
      dispatch(localApiError(err))
      const payload = err.message
      dispatch(userSaveFail(payload))
    }
  }
}

export const deleteUser = (userId: string) => {
  return async (dispatch, getState: () => AppState) => {
    dispatch(userDeleteStart())

    try {
      await api.blueprint.endUsers.deleteUser(userId)
      dispatch(userDeleteEnd())
      dispatch(remove(userId))
    } catch (err) {
      console.error(err)
      dispatch(localApiError(err))
      const payload = err.message
      dispatch(userDeleteFail(payload))
    }
  }
}

export const loadUser = () => {
  return async (dispatch) => {
    const decodedJWT = decode(localStorage.getItem(JWT_KEY)) as JwtPayload
    dispatch(userLoadStart())
    try {
      const endUserResponse = await api.blueprint.endUsers.getUserById(
        decodedJWT.id
      )
      const user = endUserResponse.user
      const groupId =
        user.properties[consts.USER_FIELDS.SELECTED_FLEET] || user.groupId
      if (groupId) {
        dispatch({
          type: groupConstants.SET,
          payload: groupId,
        })
      }
      dispatch(userLoadEnd(user))
      dispatch(batchRequest())
    } catch (err) {
      console.error(err)
      dispatch(localApiError(err))
      const payload = err.message
      dispatch(userLoadFail(payload))
    }
  }
}

export const editProfile = (userId: string, newValues: PartialUser) => {
  return async (dispatch) => {
    dispatch(profileSaveStart())
    try {
      const endUserResponse = await api.blueprint.endUsers.updateUser(
        userId,
        newValues
      )
      const user = endUserResponse.user
      dispatch(profileSaveEnd(user))
    } catch (err) {
      console.error(err)
      dispatch(localApiError(err))
      const payload = err.message
      dispatch(profileSaveFail(payload))
    }
  }
}
