import { createAction } from 'redux-actions'
import { Dispatch } from 'redux'
import { JWT_KEY } from 'common/services/api/common'

export const constants = {
  LOGIN: 'login/LOGIN',
  LOGOUT: 'login/LOGOUT',
  CHECK_JWT_START: 'login/CHECK_JWT_START',
  CHECK_JWT_END: 'login/CHECK_JWT_END',
}

export const login = createAction(constants.LOGIN)

export const logout = () => {
  return (dispatch: Dispatch) => {
    dispatch(createAction(constants.LOGOUT)())
    localStorage.removeItem(JWT_KEY)
    window.location.href = '/login'
  }
}
