import * as React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Button, Alert, Table } from 'react-bootstrap'

import { AppState } from '../../../types'
import { modalShow } from '../../../store/actions/page-admin-organizations'
import { organizationsRequest } from '../../../store/blueprint/actions'
import { FlatGroupHierarchy } from '../../../store/blueprint/groups/reducers'
import { getFlattenedHierarchy } from '../../../store/selectors/groups'
import { EditModal } from './EditModal'
import OrganizationDeleteButton from './organization-delete-button'
import OrganizationEditButton from './organization-edit-button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleRight } from '@fortawesome/free-solid-svg-icons'
import { Loading } from 'client/components/common/Loading'
import { User } from 'rest-api'

interface ReduxActions {
  organizationsRequest: typeof organizationsRequest
  modalShow: typeof modalShow
}

interface ReduxDispatchProps {
  actions: ReduxActions
}

interface ReduxStateProps {
  groupHierarchy: FlatGroupHierarchy[]
  loaded: boolean
  loading: boolean
  deleting: boolean
  deleteError: string
  currentUser: User
}

function mapStateToProps(state: AppState): ReduxStateProps {
  return {
    groupHierarchy: getFlattenedHierarchy(state),
    loaded: state.blueprint.organizations.loaded,
    loading: state.blueprint.organizations.loading,
    deleting: state.pageAdminOrganizations.deleting,
    deleteError: state.pageAdminOrganizations.deleteError,
    currentUser: state.user.profile,
  }
}

function mapDispatchToProps(dispatch): ReduxDispatchProps {
  const actions = { organizationsRequest, modalShow }
  return { actions: bindActionCreators(actions, dispatch) }
}

interface OwnProps extends ReduxStateProps, ReduxDispatchProps {}

export class DealershipsComponent extends React.PureComponent<OwnProps> {
  handleAddClick = () => {
    this.props.actions.modalShow({})
  }

  componentDidMount() {
    if (!this.props.loaded) {
      this.props.actions.organizationsRequest()
    }
  }

  render() {
    const hasWritePermissions = this.props.currentUser.role !== 'read_only'

    return (
      <div className="w-50">
        {this.props.deleteError && (
          <Alert variant="danger">{this.props.deleteError}</Alert>
        )}

        {this.props.loading && <Loading block />}

        <EditModal />

        {hasWritePermissions && (
          <div className="mb-2 text-right">
            <Button onClick={this.handleAddClick}>Add Dealership</Button>
          </div>
        )}

        {this.props.loaded && this.props.groupHierarchy.length === 0 && (
          <Alert variant="info">
            There are no organizations associated with this account.
          </Alert>
        )}

        {this.props.groupHierarchy.length > 0 && (
          <Table striped borderless responsive className="custom-table">
            <thead>
              <tr>
                <th>Name</th>
                <th className="text-right">Actions</th>
              </tr>
            </thead>
            <tbody>
              {this.props.groupHierarchy
                .filter((entry) => entry.role !== 'device')
                .map((entry) => {
                  return (
                    <tr key={entry.id}>
                      <td>
                        {'\u00a0'.repeat(entry.depth * 4)}
                        {entry.depth > 0 ? (
                          <FontAwesomeIcon
                            icon={faAngleRight}
                            className="mr-2"
                          />
                        ) : (
                          ''
                        )}
                        {entry.name}
                      </td>
                      <td className="text-right">
                        {hasWritePermissions && (
                          <>
                            <span className="mr-2">
                              <OrganizationEditButton organization={entry} />
                            </span>
                            <OrganizationDeleteButton organization={entry} />
                          </>
                        )}
                      </td>
                    </tr>
                  )
                })}
            </tbody>
          </Table>
        )}
      </div>
    )
  }
}

export const Dealerships = connect(
  mapStateToProps,
  mapDispatchToProps
)(DealershipsComponent)

export default Dealerships
