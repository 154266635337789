import { Column } from 'client/types'
import * as React from 'react'
import { DragDropContext } from 'react-dnd'
import HTML5Backend from 'react-dnd-html5-backend'

import { SelectedItem } from './selected-item'

interface OwnProps {
  items: Column[]
  removeItemHandler: (item: Column) => void
  reorderHandler: (from: number, to: number) => void
}

function SelectedItemsComponent(props: OwnProps) {
  return (
    <div>
      {props.items.map((item, index) => {
        return (
          // @ts-ignore
          <SelectedItem
            key={`column-${item.name}`}
            index={index}
            item={item}
            nameProp="name"
            removeItemHandler={props.removeItemHandler}
            reorderHandler={props.reorderHandler}
          />
        )
      })}
    </div>
  )
}

export const SelectedItems = DragDropContext(HTML5Backend)(
  SelectedItemsComponent
)
export default SelectedItems
