import * as React from 'react'
import { LocationHistoryItem } from '../../../../common/types/local-api'
import * as consts from '../../../../common/consts'
import {
  LineChart,
  ResponsiveContainer,
  CartesianGrid,
  XAxis,
  YAxis,
  Line,
  Legend,
  Tooltip,
} from 'recharts'
import { AppState } from '../../../types/app-state'
import { connect } from 'react-redux'
import { convertMetrics } from '../../common/chart-metrics'
import {
  getDistanceValue,
  getMetricsAbbreviation,
  getMetricsSettings,
} from '../../common/metrics'
import { isEqual } from 'lodash'
import moment from 'moment'
import { DeviceView } from 'client/types'

interface OwnProps {
  device: DeviceView
}

interface ReduxStateProps {
  showSpeedGraph: boolean
  loading: boolean
  error: string
  locationHistory: LocationHistoryItem[]
  metric: string
  timezone: string
}

interface Props extends OwnProps, ReduxStateProps {}

export class SpeedGraphComponent extends React.Component<Props> {
  shouldComponentUpdate(nextProps, nextState) {
    if (
      this.props.showSpeedGraph !== nextProps.showSpeedGraph ||
      this.props.loading !== nextProps.loading ||
      this.props.error !== nextProps.error
    ) {
      return true
    }
    if (isEqual(this.props.locationHistory, nextProps.locationHistory)) {
      return false
    }
    return true
  }

  render() {
    const { locationHistory, loading, error, showSpeedGraph } = this.props

    if (!showSpeedGraph) {
      return null
    }

    if (loading) {
      return <div className="loading-text">Loading...</div>
    }

    if (error) {
      return <div className="error-text">{error}</div>
    }

    const data = []
    const lines = []

    if (locationHistory) {
      if (locationHistory.length > 0) {
        // Massage the data into
        if (this.props.timezone) {
          moment.tz.setDefault(this.props.timezone)
        }
        locationHistory.forEach((item) => {
          const label = `${consts.SPEED_FIELD_NAME} ${this.props.metric}`
          const dateTime = moment(item.dateTime).format('MMM D h:mm a')
          const existing = data.find((d) => {
            return d.dateTime === dateTime
          })
          if (existing) {
            existing[label] = item.value
          } else {
            const dataItem = { dateTime }
            dataItem[label] = item.value
            data.push(dataItem)
          }

          // Add a line for the speed item if necessary
          const line = lines.find((l) => {
            return l.dataKey === label
          })
          if (!line) {
            const length = lines.length
            lines.push({
              dataKey: label,
              stroke: consts.CHART_COLORS[length],
            })
          }
        })
      } else {
        return <div className="nodata-text">No Results</div>
      }
    }

    return (
      <div className="speed-chart-component mt-2">
        <ResponsiveContainer height={300} width="100%">
          <LineChart data={data}>
            <XAxis dataKey="dateTime" />
            <YAxis />
            {lines.map((line, index) => {
              return (
                <Line
                  key={index}
                  type="monotone"
                  dataKey={line.dataKey}
                  stroke={line.stroke}
                />
              )
            })}
            <Legend />
            <Tooltip />
            <CartesianGrid strokeDasharray="3 3" />
          </LineChart>
        </ResponsiveContainer>
      </div>
    )
  }
}

function mapStateToProps(state: AppState, props: OwnProps): ReduxStateProps {
  const metrics = getMetricsSettings(state.user.profile)
  const metricPrefix = ''
  const metricPerTime = 'h'
  const metric = getMetricsAbbreviation(
    'displayUnitsDistance',
    metrics,
    metricPrefix,
    metricPerTime
  )
  const locationHistory =
    props.device &&
    state.user.profile &&
    state.locationHistory.data[props.device.id]
      ? convertMetrics(
          state.locationHistory.data[props.device.id],
          getDistanceValue,
          state.user.profile
        )
      : []
  return {
    showSpeedGraph: state.pageGrid.showSpeedGraph,
    loading: state.locationHistory.loading,
    error: state.locationHistory.error,
    locationHistory,
    metric,
    timezone:
      state.user && state.user.profile
        ? state.user.profile.properties.defaultTimezone
        : null,
  }
}

export const SpeedGraph = connect(mapStateToProps)(SpeedGraphComponent)
export default SpeedGraph
