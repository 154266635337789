import { Dispatch } from 'redux'
import { createAction } from 'redux-actions'
import { upsert, remove } from '../blueprint/groups/actions'
import { localApiError } from './local-api'
import { Group, PartialGroup, GroupResponse } from 'rest-api'
import api from 'client/api'

export const actionTypes = {
  MODAL_HIDE: 'pageAdminOrganiations/MODAL_HIDE',
  MODAL_SHOW: 'pageAdminOrganiations/MODAL_SHOW',
  ORGANIZATION_SAVE_START: 'pageAdminOrganiations/SAVE_START',
  ORGANIZATION_SAVE_END: 'pageAdminOrganiations/SAVE_END',
  ORGANIZATION_SAVE_FAIL: 'pageAdminOrganiations/SAVE_FAIL',
  ORGANIZATION_DELETE_CONFIRM: 'pageAdminOrganiations/DELETE_CONFIRM',
  ORGANIZATION_DELETE_START: 'pageAdminOrganiations/DELETE_START',
  ORGANIZATION_DELETE_END: 'pageAdminOrganiations/DELTE_END',
  ORGANIZATION_DELETE_FAIL: 'pageAdminOrganiations/DELETE_FAIL',
  CLEAN_STATUS_MESSAGES: 'pageAdminOrganiations/CLEAN_STATUS_MESSAGES',
}

export const modalShow = createAction(
  actionTypes.MODAL_SHOW,
  (payload) => payload
)
export const modalHide = createAction(actionTypes.MODAL_HIDE)
export const organizationSaveStart = createAction(
  actionTypes.ORGANIZATION_SAVE_START
)
export const organizationSaveEnd = createAction(
  actionTypes.ORGANIZATION_SAVE_END
)
export const organizationSaveFail = createAction(
  actionTypes.ORGANIZATION_SAVE_FAIL,
  (payload) => payload
)
export const organizationDeleteConfirm = createAction(
  actionTypes.ORGANIZATION_DELETE_CONFIRM
)
export const organizationDeleteStart = createAction(
  actionTypes.ORGANIZATION_DELETE_START
)
export const organizationDeleteEnd = createAction(
  actionTypes.ORGANIZATION_DELETE_END
)
export const organizationDeleteFail = createAction(
  actionTypes.ORGANIZATION_DELETE_FAIL,
  (payload) => payload
)
export const cleanStatusMessages = createAction(
  actionTypes.CLEAN_STATUS_MESSAGES
)

export const saveOrganization = (
  groupId: string | null,
  group: PartialGroup
) => {
  return async (dispatch: Dispatch<any>) => {
    dispatch(organizationSaveStart())

    try {
      let response: GroupResponse
      if (groupId) {
        response = await api.blueprint.organizations.updateGroup(groupId, group)
      } else {
        response = await api.blueprint.organizations.createGroup({
          ...group,
          role: 'rever',
        })
      }

      dispatch(organizationSaveEnd())
      dispatch(modalHide())
      dispatch(cleanStatusMessages())
      dispatch(upsert([response.group]))
    } catch (err) {
      dispatch(localApiError(err))
      const payload = err.message
      dispatch(organizationSaveFail(payload))
    }
  }
}

export const deleteOrganization = (organization: Group) => {
  return async (dispatch) => {
    dispatch(organizationDeleteStart())

    try {
      await api.blueprint.organizations.deleteGroup(organization.id)
      dispatch(organizationDeleteEnd())
      dispatch(remove(organization.id))
    } catch (err) {
      dispatch(localApiError(err))
      const payload = err.message
      dispatch(organizationDeleteFail(payload))
    }
  }
}
