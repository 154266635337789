import getInitialState from '../initial-state'
import { MapState, DeviceView } from '../../types'
import * as ReduxActions from 'redux-actions'
import { LegendFilter } from '../../types/map-state'
import { DEVICE_FIELDS } from 'common/consts'
import {
  getDeviceStatus,
  getDeviceStatusName,
} from '../../components/common/map/marker-icon'

export const constants = {
  TRAVEL_LINE_SHOW: 'device_map/TRAVEL_LINE_SHOW',
  BREADCRUMBS_SHOW: 'device_map/BREADCRUMBS_SHOW',
  DEVICE_MAP_SHOW_DEVICE_MAP_MODAL: 'device_map/SHOW_DEVICE_MAP_MODAL',
  DEVICE_MAP_HIDE_DEVICE_MAP_MODAL: 'device_map/HIDE_DEVICE_MAP_MODAL',
  DEVICE_MAP_SET_FILTER: 'device_map/DEVICE_MAP_SET_FILTERS',
  DEVICE_MAP_CLEAR_FILTERS: 'device_map/DEVICE_MAP_CLEAR_FILTERS',
}

type Action = ReduxActions.Action<any>
type Reducer = (state: MapState, action: Action) => MapState

const initialState: MapState = getInitialState().map

export function filterDevicesByGeofence(
  devices: DeviceView[],
  geofenceName = ''
): DeviceView[] {
  if (!geofenceName) {
    return devices
  }

  return devices.filter((device) => {
    let currentGeofences: DeviceView[]
    try {
      currentGeofences = JSON.parse(
        String(device[DEVICE_FIELDS.CURRENT_GEOFENCES])
      ) // array of geofence objects
    } catch (err) {
      console.warn(
        `Invalid property {${DEVICE_FIELDS.CURRENT_GEOFENCES}} for device #${
          device.id
        }: ${device[DEVICE_FIELDS.CURRENT_GEOFENCES]}`
      )
      currentGeofences = []
    }
    return currentGeofences.some((item) => item.name === geofenceName)
  })
}

export function filterDevicesByTag(devices: DeviceView[], tag = '') {
  if (!tag) {
    return devices
  }
  return devices.filter((device) => {
    if (!device[DEVICE_FIELDS.TAGS]) {
      return false
    }
    const deviceTags = String(device[DEVICE_FIELDS.TAGS]).split(',')
    return deviceTags.findIndex((item) => item === tag) >= 0
  })
}

export function filterDevicesByLegend(
  devices: DeviceView[],
  legendFilter: LegendFilter
) {
  if (
    legendFilter &&
    legendFilter.activeStates &&
    legendFilter.activeStates.length > 0
  ) {
    return devices.filter((device) => {
      const status = getDeviceStatusName(getDeviceStatus(device))
      return legendFilter.activeStates.indexOf(status) >= 0
    })
  }
  return devices
}

/**
 * Filter the local list of devices to be just those that match the set filters
 */
export function filterDevices(
  devices: DeviceView[],
  geofence: string,
  tag?: string,
  legendFilter?: LegendFilter
): DeviceView[] {
  const filteredDevices = devices.filter((device) => {
    return (
      device.latitude !== null &&
      device.latitude !== undefined &&
      device.longitude !== null &&
      device.longitude !== undefined &&
      device.ActivationStatus !== ''
    )
  })
  return filterDevicesByGeofence(
    filterDevicesByTag(
      filterDevicesByLegend(filteredDevices, legendFilter),
      tag
    ),
    geofence
  )
}

const showTravelLine: Reducer = (state, action) => {
  const payload = action.payload as boolean
  return { ...state, showTravelLine: payload }
}

const showBreadcrumbs: Reducer = (state, action) => {
  const payload = action.payload as boolean
  return { ...state, showBreadcrumbs: payload }
}

const showDeviceMapModal: Reducer = (state, action) => ({
  ...state,
  showDeviceMapModal: true,
  currentDeviceId: action.payload as string,
})

const hideDeviceMapModal: Reducer = (state) => ({
  ...state,
  showDeviceMapModal: false,
  currentDeviceId: null,
})

const setFilters: Reducer = (state, action) => {
  const { payload } = action
  return {
    ...state,
    filters: {
      ...state.filters,
      [payload.name]: payload.value,
    },
  }
}

const clearFilters: Reducer = (state) => {
  return {
    ...state,
    filters: initialState.filters,
  }
}

const reducerMap: ReduxActions.ReducerMap<MapState, any> = {
  [constants.TRAVEL_LINE_SHOW]: showTravelLine,
  [constants.BREADCRUMBS_SHOW]: showBreadcrumbs,
  [constants.DEVICE_MAP_SHOW_DEVICE_MAP_MODAL]: showDeviceMapModal,
  [constants.DEVICE_MAP_HIDE_DEVICE_MAP_MODAL]: hideDeviceMapModal,
  [constants.DEVICE_MAP_SET_FILTER]: setFilters,
  [constants.DEVICE_MAP_CLEAR_FILTERS]: clearFilters,
}

export const reducer: Reducer = ReduxActions.handleActions(
  reducerMap,
  initialState
)
export default reducer
