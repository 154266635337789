import * as React from 'react'

import { GroupMap } from '../../types'

interface IConsumerValue {
  organizations: GroupMap
  selectedGroupId: string | null
}

export default React.createContext<IConsumerValue>({
  organizations: {},
  selectedGroupId: null,
})
