import * as React from 'react'
import { connect } from 'react-redux'
import { Button } from 'react-bootstrap'
import { bindActionCreators } from 'redux'

import { AppState } from '../../../types'
import { showSpeedGraph } from '../../../store/actions/grid'
import {
  HIDE_SPEED_GRAPH_TEXT,
  SHOW_SPEED_GRAPH_TEXT,
} from '../../../../common/consts'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGaugeSimple } from '@fortawesome/free-solid-svg-icons'

interface ReduxStateProps {
  showSpeedGraph: boolean
}

interface ReduxActions {
  showSpeedGraph: (show: boolean) => void
}

interface Props extends ReduxStateProps {
  actions: ReduxActions
}

function mapStateToProps(state: AppState): ReduxStateProps {
  return {
    showSpeedGraph: state.pageGrid.showSpeedGraph,
  }
}

function mapDispatchToProps(dispatch) {
  const actions = {
    showSpeedGraph,
  }
  return { actions: bindActionCreators(actions, dispatch) }
}

const SpeedGraphButtonComponent = (props: Props) => {
  function handleClick() {
    props.actions.showSpeedGraph(!props.showSpeedGraph)
  }

  const btnLabel = props.showSpeedGraph
    ? HIDE_SPEED_GRAPH_TEXT
    : SHOW_SPEED_GRAPH_TEXT

  return (
    <Button variant="info" size="sm" onClick={handleClick}>
      <FontAwesomeIcon icon={faGaugeSimple} /> {btnLabel}
    </Button>
  )
}

export const SpeedGraphButton = connect(
  mapStateToProps,
  mapDispatchToProps
)(SpeedGraphButtonComponent)
export default SpeedGraphButton
