import * as React from 'react'
import { connect } from 'react-redux'
import { cloneDeep } from 'lodash'
import { bindActionCreators } from 'redux'
import { AppState } from 'client/types/index'
import { Geofence } from 'common/types/local-api'
import {
  Button,
  FormGroup,
  FormControl,
  FormLabel,
  Form,
} from 'react-bootstrap'
import Toggle from 'react-toggle'
import {
  cancelCreateEdit,
  createStep2,
  updateGeofence,
  saveStart,
} from 'client/store/actions/page-admin-geofences'
import * as consts from 'common/consts'

interface ReduxActions {
  cancelCreateEdit: any
  createStep2: any
  updateGeofence: any
  saveStart: any
}

interface ReduxDispatchProps {
  actions: ReduxActions
}

interface ReduxStateProps {
  geofence: Geofence
}

function mapStateToProps(state: AppState): ReduxStateProps {
  return {
    geofence: state.pageAdminGeofences.geofence,
  }
}

function mapDispatchToProps(dispatch): ReduxDispatchProps {
  const actions = { cancelCreateEdit, createStep2, saveStart, updateGeofence }
  return { actions: bindActionCreators(actions, dispatch) }
}

interface IProps extends ReduxStateProps, ReduxDispatchProps {}

interface IOwnState {
  fields: Geofence
  maxNameLength: boolean
}

export class GeofenceEditPanelComponent extends React.Component<
  IProps,
  IOwnState
> {
  // FIXME: Unnecessary state from props, move Redux state here instead!
  static getDerivedStateFromProps(nextProps: IProps, prevState: IOwnState) {
    if (nextProps.geofence) {
      return {
        fields: {
          ...nextProps.geofence,
          name: prevState.fields.name,
          isPrimary: prevState.fields.isPrimary,
        },
      }
    }
  }

  handleSave = (): void => {
    if (this.state.fields.name && this.state.fields.shapeId) {
      this.props.actions.saveStart(this.state.fields)
    }
  }

  handleShapeChange = (shapeId): void => {
    const geofence = cloneDeep(this.state.fields)
    geofence.shapeId = shapeId
    this.props.actions.updateGeofence(geofence)
  }

  handleCancel = (): void => {
    this.props.actions.cancelCreateEdit()
  }

  constructor(props) {
    super(props)

    let fields: Geofence
    if (this.props.geofence) {
      fields = cloneDeep(this.props.geofence)
    }
    this.state = {
      fields,
      maxNameLength: false,
    }
  }

  render() {
    const fields = this.state.fields
    return (
      <>
        <div>
          <h4>Edit Geofence</h4>
          <h5>Step 2 of 2: Configuration</h5>
          <p>
            To change the geofence, drag pins the shape to the desired location.
          </p>
          <p>(For polygons drag a center pin to add, right click to remove)</p>

          <div className="toggle-wrapper mb-2">
            <Toggle
              id="my-dealership"
              icons={false}
              checked={fields.isPrimary}
              onChange={(e) => {
                const isPrimary = e.target.checked
                this.setState((state) => {
                  return {
                    fields: { ...state.fields, isPrimary },
                  }
                })
              }}
            />
            <span className="toggle-label">My Dealership</span>
          </div>

          <FormGroup>
            <FormLabel>Geofence Shape</FormLabel>
            <div className="toggle-wrapper">
              <Toggle
                id="geofence-shape-circular"
                icons={false}
                checked={fields.shapeId === consts.SHAPE_ID_CIRCLE}
                onChange={(e) => {
                  if (e.target.checked) {
                    this.handleShapeChange(consts.SHAPE_ID_CIRCLE)
                  }
                }}
              />
              <span className="toggle-label">Circular</span>
            </div>
            <div className="toggle-wrapper">
              <Toggle
                id="geofence-shape-rectangular"
                icons={false}
                checked={fields.shapeId === consts.SHAPE_ID_RECTANGLE}
                onChange={(e) => {
                  if (e.target.checked) {
                    this.handleShapeChange(consts.SHAPE_ID_RECTANGLE)
                  }
                }}
              />
              <span className="toggle-label">Rectangular</span>
            </div>
            <div className="toggle-wrapper">
              <Toggle
                id="geofence-shape-polygon"
                icons={false}
                checked={fields.shapeId === consts.SHAPE_ID_POLYGON}
                onChange={(e) => {
                  if (e.target.checked) {
                    this.handleShapeChange(consts.SHAPE_ID_POLYGON)
                  }
                }}
              />
              <span className="toggle-label">Polygon</span>
            </div>
          </FormGroup>

          <FormGroup>
            <FormLabel>Geofence Name</FormLabel>
            <FormControl
              placeholder="Enter Name..."
              value={fields.name}
              maxLength={120}
              onChange={(e) => {
                const target = e.target as HTMLInputElement
                this.setState((state) => {
                  state.fields.name = target.value
                  const maxNameLength = target.value.length === 120
                  return {
                    fields: { ...state.fields, name: target.value },
                    maxNameLength,
                  }
                })
              }}
            />
            {this.state.maxNameLength && (
              <Form.Control.Feedback type="invalid">
                Maximum geofence name length has been reached.
              </Form.Control.Feedback>
            )}
          </FormGroup>
        </div>

        <div className="mt-4">
          <Button variant="danger" onClick={this.handleCancel} className="mr-2">
            Cancel
          </Button>
          <Button variant="success" onClick={this.handleSave}>
            Save
          </Button>
        </div>
      </>
    )
  }
}
export const GeofenceEditPanel = connect(
  mapStateToProps,
  mapDispatchToProps
)(GeofenceEditPanelComponent)
export default GeofenceEditPanel
