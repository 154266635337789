import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button } from 'react-bootstrap'
import { faTrash } from '@fortawesome/free-solid-svg-icons'
import Confirm from './confirm'

interface Props {
  title: string
  confirmMessage: string
  onClick: () => void
}

export const DeleteButton: React.FC<Props> = ({
  title,
  confirmMessage,
  onClick,
}) => {
  return (
    <Confirm
      body={confirmMessage}
      title={title}
      confirmText="Delete"
      onConfirm={() => {
        onClick()
      }}
    >
      <Button variant="outline-danger" title="Delete">
        <FontAwesomeIcon icon={faTrash} />
      </Button>
    </Confirm>
  )
}
