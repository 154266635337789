import * as React from 'react'
import useDeviceEdition from '../../../../lib/hooks/useDeviceEdition'
import { updateDevice } from '../../../../store/actions/page-admin-devices'
import { DeviceView } from 'client/types'
import { useDispatch } from 'react-redux'

import { FormControl } from 'react-bootstrap'
import { useRestApi } from 'client/lib/api'
import { Loading } from '../../Loading'

interface Props {
  device: DeviceView
}

export const TypeFormatter: React.FC<Props> = ({ device }) => {
  const editedDevice = useDeviceEdition(device.id)
  const dispatch = useDispatch()
  const type = editedDevice?.Type ?? device.Type ?? ''
  const { data: response, error } = useRestApi<any>(
    editedDevice && `/devices/models`
  )

  if (!editedDevice) {
    return <span>{type}</span>
  }

  if (error) {
    return null
  }

  if (!response) {
    return <Loading block />
  }

  const types: string[] =
    response.data.results.find((entry) => entry.key === 'type')?.options ?? []

  return (
    <FormControl
      as="select"
      value={type}
      onChange={(e) =>
        dispatch(
          updateDevice({ id: device.id, key: 'Type', value: e.target.value })
        )
      }
    >
      <option value="">--</option>
      {types.map((v) => {
        return (
          <option key={v} value={v}>
            {v}
          </option>
        )
      })}
    </FormControl>
  )
}
