import { useSelector } from 'react-redux'
import * as React from 'react'
import { Row, Col, Container } from 'react-bootstrap'
import { debounce } from 'lodash'
import memoizeOne from 'memoize-one'

import { getDeviceColumns } from './columns'
import { DEVICE_FIELDS } from '../../../../common/consts'
import { SearchField } from '../../common/search-field'
import { SearchTagsControl } from './search-tags-control'
import {
  AppState,
  Column as TableColumn,
  Row as TableRow,
  DeviceView,
} from '../../../types'
import { Table, RowComponent, SortDir } from '../../common/grid/table'
import { useCallback, useMemo, useState } from 'react'
import { sortDevices } from 'client/store/reducers/grid'
import { useCustomGridColumns } from 'client/lib/hooks/useCustomGridColumns'
import useFilteredDeviceList from 'client/lib/hooks/useFilteredDeviceList'
import { ColumnSelectorButton } from 'client/components/common/grid/column-select/column-selector-button'
import { ColumnSelectorModal } from 'client/components/common/grid/column-select/column-selector-modal'

const sortDevicesMemoized = memoizeOne(sortDevices)

export const AdminGrid: React.FC = () => {
  const [sortColumn, setSortColumn] = useState<string>(
    DEVICE_FIELDS.EMAIL_ADDRESS
  )
  const [sortDirection, setSortDirection] = useState<SortDir>('ASC')
  const [filterTerm, setFilterTerm] = useState<string>('')
  const [filterTags, setFilterTags] = useState<string[]>([])
  const editedDevices = useSelector<
    AppState,
    Record<string, Partial<DeviceView>>
  >((state) => state.pageAdminDevices.editedDevices)
  const [columnConfig, columnConfigDispatch] = useCustomGridColumns(
    'adminGridColumnConfig',
    getDeviceColumns()
  )

  const originalDevices = useFilteredDeviceList(
    filterTags,
    filterTerm,
    columnConfig.columns
  )

  const devices = useMemo(
    () =>
      originalDevices.map((item) =>
        editedDevices[item.id]
          ? {
              ...item,
              ...editedDevices[item.id],
              _editMode: true,
            }
          : item
      ),
    [originalDevices, editedDevices]
  )

  const rows = sortDevicesMemoized(
    devices,
    sortColumn,
    sortDirection,
    columnConfig.columns
  )

  const delayedSearch = useCallback(
    debounce((value: string) => {
      setFilterTerm(value)
    }, 500),
    [setFilterTerm]
  )

  const handleGridSort = (sortColumn: string, sortDirection: SortDir) => {
    setSortColumn(sortColumn)
    setSortDirection(sortDirection)
  }

  const handleColumnResize = (index: number, width: number) => {
    columnConfigDispatch({ type: 'resizeColumns', payload: { index, width } })
  }

  const handleSearchChange = (value: string) => {
    delayedSearch(value)
  }

  const rowRenderer = (
    row: TableRow,
    columns: TableColumn[],
    columnCacheKey: string
  ) => {
    return (
      <RowComponent
        key={row.id}
        className={row._editMode ? 'row-edit-mode' : undefined}
        data={row}
        columns={columns}
        columnCacheKey={columnCacheKey}
        rowHeight={40}
      />
    )
  }

  return (
    <>
      <ColumnSelectorModal
        show={columnConfig.showColumnSelector}
        onCancel={() => columnConfigDispatch({ type: 'hideSelector' })}
        onSave={() => columnConfigDispatch({ type: 'saveColumns' })}
        onAdd={(item) =>
          columnConfigDispatch({ type: 'addColumn', payload: item })
        }
        onRemove={(item) =>
          columnConfigDispatch({ type: 'removeColumn', payload: item })
        }
        onAddAllClick={() => columnConfigDispatch({ type: 'addAllColumns' })}
        onRemoveAllClick={() =>
          columnConfigDispatch({ type: 'removeAllColumns' })
        }
        onReorder={({ from, to }) =>
          columnConfigDispatch({
            type: 'reorderColumns',
            payload: { from, to },
          })
        }
        availableColumns={columnConfig.availableColumns}
        selectedColumns={columnConfig.selectedColumns}
      />
      <Container fluid className="p-0">
        <Row className="no-print mb-2 justify-content-end">
          <Col>
            <SearchField onChange={handleSearchChange} />
          </Col>
          <Col>
            <SearchTagsControl
              value={filterTags}
              onChange={(values) => setFilterTags(values)}
            />
          </Col>
          <Col className="flex-justify-end" lg="auto">
            <ColumnSelectorButton
              onClick={() => {
                columnConfigDispatch({ type: 'showSelector' })
              }}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <Table
              rows={rows}
              columns={columnConfig.columns}
              sortColumn={sortColumn}
              sortDirection={sortDirection}
              onSort={handleGridSort}
              onResize={handleColumnResize}
              rowRenderer={rowRenderer}
              rowHeight={40}
              height={400}
            />
          </Col>
        </Row>
        <Row>
          <Col className="text-right">{rows.length}</Col>
        </Row>
      </Container>
    </>
  )
}
