import { createAction } from 'redux-actions'

export const constants = {
  DEVICE_MODELS_LOAD: 'device_table/LOAD_MODELS',
  DEVICE_TAGS_LOAD: 'device_table/LOAD_TAGS',
  DEVICE_TABLE_LOAD: 'device_table/LOAD',
  DEVICE_TABLE_FILTER_MODEL: 'device_table/FILTER_MODEL',
  DEVICE_TABLE_FILTER_GEOFENCE: 'device_table/FILTER_GEOFENCE',
  DEVICE_TABLE_FILTER_TAGS: 'device_table/FILTER_TAGS',
  DEVICE_TABLE_FILTER_TERM: 'device_table/FILTER_TERM',
  DEVICE_TABLE_CLEAR_FILTERS: 'device_table/CLEAR_FILTERS',
  MODAL_MAP_HIDE: 'device_table/MODAL_MAP_HIDE',
  MODAL_MAP_SHOW: 'device_table/MODAL_MAP_SHOW',
  MODAL_MAP_SPEED_GRAPH_SHOW: 'device_table/MODAL_MAP_SPEED_GRAPH_SHOW',
  MODAL_GRID_COLUMNS_SHOW: 'device_table/MODAL_GRID_COLUMNS_SHOW',
  MODAL_GRID_COLUMNS_HIDE: 'device_table/MODAL_GRID_COLUMNS_HIDE',
}

/**
 * Generic function for dispatching a filter devices action
 */
function filterDevices(type, filter) {
  return (dispatch): void => {
    dispatch({
      type,
      payload: {
        filter,
      },
    })
  }
}

export const filterDevicesModel = (filter) => {
  return filterDevices(constants.DEVICE_TABLE_FILTER_MODEL, filter)
}

export const filterDevicesGeofence = (filter) => {
  return filterDevices(constants.DEVICE_TABLE_FILTER_GEOFENCE, filter)
}

export const filterDevicesTags = (filter) => {
  return filterDevices(constants.DEVICE_TABLE_FILTER_TAGS, filter)
}

export const filterDevicesTerm = (filter) => {
  return filterDevices(constants.DEVICE_TABLE_FILTER_TERM, filter)
}

export const modalMapShow = (deviceId) => {
  return {
    type: constants.MODAL_MAP_SHOW,
    payload: deviceId,
  }
}

export const modalMapHide = () => {
  return {
    type: constants.MODAL_MAP_HIDE,
  }
}

export const showSpeedGraph = createAction(constants.MODAL_MAP_SPEED_GRAPH_SHOW)

export const showGridColumnsModal = createAction(
  constants.MODAL_GRID_COLUMNS_SHOW
)

export const hideGridColumnModal = createAction(
  constants.MODAL_GRID_COLUMNS_HIDE
)

export const clearFilters = createAction(constants.DEVICE_TABLE_CLEAR_FILTERS)
