import * as React from 'react'
import { RouteComponentProps } from 'react-router'

import { Form, Alert, Button, Spinner } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { login } from '../../../store/actions/login'
import { RequestStatus } from '../../../types'
import { bindActionCreators } from 'redux'
import TermsConditionsModal from './terms-conditions-modal'
import api from 'client/api'
import decode from 'jwt-decode'
import { JwtPayload, User } from 'rest-api'
import Landing from '../landing'

interface ReduxDispatchProps {
  actions: {
    login: typeof login
  }
}

interface LoginState {
  requestStatus?: RequestStatus
  errors?: string
  username?: string
  password?: string
  passwordType?: string
  rememberMe?: boolean
  showTCs?: boolean
  redirect?: string
  decodedJWT: JwtPayload | null
  jwt: string
}

const mapDispatchToProps = (dispatch): ReduxDispatchProps => {
  const actions = { login }
  return { actions: bindActionCreators(actions, dispatch) }
}

interface LoginProps
  extends ReduxDispatchProps,
    RouteComponentProps<any, any>,
    React.Props<LoginComponent> {
  location: any
  loginError?: any
  redirect?: any
  params: any
}

export class LoginComponent extends React.Component<LoginProps, LoginState> {
  state: LoginState = {
    requestStatus: RequestStatus.REQUEST_NOT_SENT,
    errors: '',
    username: '',
    password: '',
    rememberMe: false,
    showTCs: false,
    decodedJWT: null,
    jwt: null,
  }

  finishLogin(jwt: string, user: User, redirect: string) {
    this.props.actions.login({ user, jwt })
    this.setState({ requestStatus: RequestStatus.REQUEST_SUCCESS })
    this.props.history.push(redirect)
  }

  async submit(event: any) {
    event.preventDefault()

    let redirect = ''
    if (
      this.props.location &&
      this.props.location.query &&
      this.props.location.query.redirect
    ) {
      redirect = this.props.location.query.redirect
    }

    this.setState({
      requestStatus: RequestStatus.REQUEST_SENT,
    })

    try {
      const loginResponse = await api.login({
        emailAddress: this.state.username,
        password: this.state.password,
        renewalType: this.state.rememberMe ? 'extended' : 'short',
      })
      const jwt = loginResponse.jwt
      const decodedJWT = decode(jwt) as JwtPayload
      const userResponse = await api.blueprint.endUsers.getUserById(
        decodedJWT.id
      )

      let acceptedTCs = true
      if (userResponse) {
        acceptedTCs = userResponse.user.properties.acceptedTCs === 'true'
      }

      if (acceptedTCs) {
        this.finishLogin(jwt, userResponse.user, redirect)
      } else {
        this.setState({
          showTCs: true,
          redirect,
          decodedJWT,
          jwt,
        })
      }
    } catch (err) {
      this.setState({
        requestStatus: RequestStatus.REQUEST_ERROR,
      })
      if (err.status === 403) {
        this.setState({
          errors:
            'Login unsuccessful: the provided username and password combination could not be found. Forgot password?',
        })
      } else {
        this.setState({ errors: err.message })
      }
    }
  }

  handleChange(fieldName, event) {
    const value = event.target.value

    this.setState((state) => {
      state[fieldName] = value
      return state
    })
  }

  declineTCs() {
    this.setState({
      showTCs: false,
      requestStatus: RequestStatus.REQUEST_NOT_SENT,
    })
  }

  async acceptTCs() {
    this.setState({
      showTCs: false,
    })

    const userResponse = await api.blueprint.endUsers.updateUser(
      this.state.decodedJWT.id,
      {
        properties: {
          acceptedTCs: 'true',
        },
      }
    )
    this.finishLogin(this.state.jwt, userResponse.user, this.state.redirect)
  }

  render() {
    const autocontrol = (fieldName) => ({
      name: fieldName,
      value: this.state[fieldName] || '',
      onChange: this.handleChange.bind(this, fieldName),
      className: 'form-control',
    })

    return (
      <Landing>
        <div className="m-auto">
          <TermsConditionsModal
            showModal={this.state.showTCs}
            onAccept={this.acceptTCs.bind(this)}
            onDecline={this.declineTCs.bind(this)}
          />

          <Form
            method="POST"
            onSubmit={this.submit.bind(this)}
            id="sign-in-form"
          >
            <h3 className="text-center">Sign In</h3>

            <Form.Group>
              <Form.Control
                size="lg"
                placeholder="Email"
                required
                type="email"
                autoComplete="email"
                {...autocontrol('username')}
              />
              <Form.Control.Feedback type="invalid">
                Please enter a valid email
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group>
              <Form.Control
                size="lg"
                placeholder="Password"
                required
                type="password"
                autoComplete="current-password"
                {...autocontrol('password')}
              />
              <Form.Control.Feedback type="invalid">
                Please enter password
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group>
              <Form.Check
                type="checkbox"
                id="rememberme-checkbox"
                label="Stay signed in"
                onChange={(e) => {
                  const checked = e.target.checked
                  this.setState((state) => {
                    const rememberMe = checked
                    return { ...state, rememberMe }
                  })
                }}
              />
            </Form.Group>

            <div className="mt-2 text-right">
              <Link to="/reset-password">Forgot Password</Link>
            </div>

            {this.state.errors && (
              <Alert variant="danger">{this.state.errors}</Alert>
            )}

            <Button
              block
              variant="primary"
              type="submit"
              disabled={this.state.requestStatus === RequestStatus.REQUEST_SENT}
              className="mt-2"
            >
              Submit
              {this.state.requestStatus === RequestStatus.REQUEST_SENT && (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                  className="ml-2"
                />
              )}
            </Button>
          </Form>
        </div>
      </Landing>
    )
  }
}

export const Login = connect(null, mapDispatchToProps)(LoginComponent)

export default Login
