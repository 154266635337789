import { localApiFetchWithToken } from '../api'

export async function resetPassword(email: string) {
  const data = await localApiFetchWithToken(`/user/reset-password`, null, {
    method: 'POST',
    body: JSON.stringify({
      emailAddress: email,
    }),
  })
  return data
}

export async function changePassword(
  newPassword: string,
  passwordResetToken: string,
  userId: string
) {
  const data = await localApiFetchWithToken(`/user/change-password`, null, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      newPassword,
      passwordResetToken,
      userId,
    }),
  })
  return data
}
