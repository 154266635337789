import { compact } from 'lodash'

// takes a device's "Current Geofences" JSON stringified array
export function getGeofenceString(currentGeofences: string): string {
  const geofences = currentGeofences ? JSON.parse(currentGeofences) : null
  if (!geofences || !geofences.length) {
    return null
  } else {
    return geofences.reduce((geofenceString, geofence) => {
      if (geofenceString) {
        geofenceString += `, ${geofence.name}`
        return geofenceString
      }
      return geofence.name
    }, null)
  }
}

export function css(...args: string[]): string {
  return compact(args).join(' ')
}

export function cssVar(value: string): string {
  return window
    .getComputedStyle(document.documentElement)
    .getPropertyValue(value)
}
