import * as React from 'react'
import {
  Row,
  Col,
  ButtonToolbar,
  Button,
  ButtonGroup,
  Alert,
  Container,
} from 'react-bootstrap'
import { RouteComponentProps, Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import moment from 'moment'
import { debounce, range, once } from 'lodash'
import memoizeOne from 'memoize-one'

import {
  AppState,
  UserProfile,
  DeviceMap,
  Column,
  DeviceView as DeviceViewType,
} from '../../types'
import { DeviceReport } from 'common/types/local-api'
import { getFilteredReports } from '../../store/selectors/reports'
import { SearchField } from '../common/search-field'
import { TimeSelect } from '../common/time-select'
import { gridColumns } from '../grid/columns'
import {
  clearDeviceReportsFilters,
  filterDeviceReportsTerm,
  getForDevice,
  setNewDataAlert,
} from '../../store/actions/device-reports'
import { DEVICE_FIELDS } from 'common/consts'
import { withPrintOption } from '../common/grid/printable-grid'
import PrintButton from '../common/print-button'
import PrintOnly from '../common/print-only'
import { UserEmail } from '../common/user-email'
import DeviceExportButton from '../common/grid/grid-export-button'
import { convertColumnNames } from '../common/device-metrics'
import { sortDevices } from '../../store/reducers/grid'
import { ReportStatusFormatter } from '../grid/formatters'
import { hasNewData } from '../../store/reducers/device-reports'
import NewDataAlert from './new-data-alert'
import { SortDir, Table, TableProps } from '../common/grid/table'
import { Group } from 'rest-api'
import { Loading } from '../common/Loading'

type OwnProps = RouteComponentProps<any, any>

interface ReduxStateProps {
  device: DeviceViewType
  reports: DeviceReport[]
  userProfile: UserProfile
  loading: boolean
  hasNewData: boolean
  newDataAlertEnabled: boolean
  organization: Group
}

interface ReduxDispatchProps {
  actions: {
    getForDevice: typeof getForDevice
    setNewDataAlert: typeof setNewDataAlert
    clearFilters: typeof clearDeviceReportsFilters
    filterDeviceReportsTerm: typeof filterDeviceReportsTerm
  }
}

interface Props extends OwnProps, ReduxStateProps, ReduxDispatchProps {}

interface State {
  timeframe: string
  reports: DeviceReport[]
  columns: Column[]
  sort: {
    col: string
    dir: SortDir
  }
  page: number
  pageCount: number
}

const getColumns = memoizeOne(
  (device: DeviceViewType, userProfile: UserProfile): Column[] => {
    let columns: Column[] = []

    gridColumns.forEach((column) => {
      if (column.key === DEVICE_FIELDS.LAST_EVENT) {
        columns.push({ ...column, name: 'Event' })
      } else if (column.key === DEVICE_FIELDS.CONNECTED) {
        columns.push({ ...column, formatter: ReportStatusFormatter })
      } else if (
        column.key !== DEVICE_FIELDS.TAGS &&
        column.key !== DEVICE_FIELDS.ORGANIZATION_ID
      ) {
        columns.push({ ...column })
      }
    })

    // Hide Spare Temp columns based on # of sensors
    const sensorCount = Number(device[DEVICE_FIELDS.SENSORS_INSTALLED])

    columns = columns.filter((column) => {
      switch (column.key) {
        case DEVICE_FIELDS.SPARE_1_TEMP:
          return sensorCount >= 1
        case DEVICE_FIELDS.SPARE_2_TEMP:
          return sensorCount >= 2
        case DEVICE_FIELDS.SPARE_3_TEMP:
          return sensorCount >= 3
        case DEVICE_FIELDS.SPARE_4_TEMP:
          return sensorCount >= 4
        case DEVICE_FIELDS.SPARE_5_TEMP:
          return sensorCount >= 5
        case DEVICE_FIELDS.SPARE_6_TEMP:
          return sensorCount >= 6
        default:
          return true
      }
    })

    // Add units to column headers
    if (userProfile) {
      columns = convertColumnNames(columns, userProfile)
    }

    // Indicate that this is a report so we don't show links in the formatters
    columns.forEach((column) => {
      column.isReport = true
    })

    return columns
  }
)

const mapStateToProps = (initialState: AppState, props: OwnProps) => {
  const deviceId = props.match.params.deviceId
  const getDeviceOnce = once((devices: DeviceMap) => devices[deviceId])

  return (state: AppState): ReduxStateProps => {
    const devices =
      (state.blueprint.devices && state.blueprint.devices.data) || {}
    const currentOrgId = state.blueprint.organizations.current
    return {
      device: getDeviceOnce(devices),
      reports: getFilteredReports(state, deviceId),
      hasNewData: hasNewData(state, deviceId),
      organization: state.blueprint.organizations.data[currentOrgId],
      userProfile: state.user.profile,
      loading: state.deviceReports.loading,
      newDataAlertEnabled: state.deviceReports.newDataAlertEnabled,
    }
  }
}

const mapDispatchToProps = (dispatch): ReduxDispatchProps => {
  const actions = {
    getForDevice,
    setNewDataAlert,
    clearFilters: clearDeviceReportsFilters,
    filterDeviceReportsTerm,
  }
  return { actions: bindActionCreators(actions, dispatch) }
}

const sortDevicesMemoized = memoizeOne(sortDevices)

const getPage = memoizeOne((reports: DeviceReport[], page: number) => {
  const startIndex = ROWS_PER_PAGE * (page - 1)
  const endIndex = Math.min(startIndex + ROWS_PER_PAGE, reports.length)
  const rows = reports.slice(startIndex, endIndex)
  return { rows, startIndex, endIndex }
})
const ROWS_PER_PAGE = 1000

const PrintableGrid = withPrintOption<TableProps>(Table)

export class DeviceViewComponent extends React.PureComponent<Props, State> {
  static getDerivedStateFromProps(props: Props, state: State): Partial<State> {
    if (!props.device || !props.reports) {
      return null
    }
    const columns = getColumns(props.device, props.userProfile)
    const reports = sortDevicesMemoized(
      props.reports,
      state.sort.col,
      state.sort.dir,
      columns
    ) as DeviceReport[]
    const pageCount = Math.ceil(reports.length / ROWS_PER_PAGE)
    return { reports, columns, pageCount }
  }

  state: State = {
    timeframe: '30',
    reports: [],
    columns: [],
    sort: {
      col: DEVICE_FIELDS.LAST_REPORT_TIME,
      dir: 'DESC',
    },
    page: 1,
    pageCount: 1,
  }

  delayedSearch = debounce((value: string) => {
    if (this.props.device) {
      this.props.actions.filterDeviceReportsTerm(value)
    }
  }, 500)

  handleTimeChange = (timeframe: string) => {
    this.setState({ timeframe })
    if (timeframe && this.props.device) {
      this.props.actions.getForDevice(this.props.device, timeframe)
    }
  }

  handleSearchChange = (value: string) => {
    this.delayedSearch(value)
  }

  handleGridSort = (sortColumn: string, sortDirection: SortDir) => {
    this.setState({
      sort: {
        col: sortColumn,
        dir: sortDirection,
      },
    })
  }

  componentDidMount() {
    this.init()
  }

  init() {
    this.props.actions.clearFilters()

    const { device, actions } = this.props

    if (!device) {
      return
    }

    actions.getForDevice(device, this.state.timeframe)
    actions.setNewDataAlert({
      deviceId: device.id,
      hasNewData: false,
    })
  }

  renderDeviceGrid(device: DeviceViewType | null) {
    if (!device) {
      return (
        <Alert variant="danger">
          Customer not found. <Link to="/grid">Return to grid</Link>
        </Alert>
      )
    }

    return (
      <Container fluid className="p-0">
        <Row className="header align-items-center flex-nowrap">
          <Col>
            <h1 className="title">
              Customer: {device[DEVICE_FIELDS.TRAILER_ID]}
            </h1>

            <PrintOnly>
              <p className="title">
                Since:{' '}
                {moment()
                  .subtract(this.state.timeframe, 'minutes')
                  .format('YYYY-MM-DD HH:mm::ss A')}
              </p>
            </PrintOnly>
          </Col>

          <Col lg="auto" className="no-print d-flex justify-content-end">
            <TimeSelect
              onChange={this.handleTimeChange}
              value={this.state.timeframe}
              className="mr-2"
            />
            <div className="mr-2">
              <SearchField width={300} onChange={this.handleSearchChange} />
            </div>
            <div className="mr-2">
              <DeviceExportButton
                data={this.state.reports}
                columns={this.state.columns}
                fileName={`${device[DEVICE_FIELDS.TRAILER_ID]} Reports`}
              />
            </div>
            <PrintButton disabled={!this.state.reports} />
          </Col>

          <PrintOnly>
            <Col>
              Printed
              <p>
                by: <UserEmail />
              </p>
              <p>at {moment().format()}</p>
            </Col>
          </PrintOnly>
        </Row>

        <Row className="mt-2">
          <Col>
            {this.props.loading ? <Loading block /> : this.renderGrid()}
          </Col>
        </Row>
      </Container>
    )
  }

  render() {
    const device = this.props.device
    const showAlert =
      this.props.hasNewData &&
      !this.props.loading &&
      this.props.newDataAlertEnabled

    return (
      <section className="device-view-component">
        <NewDataAlert
          shown={showAlert}
          onClick={() => {
            this.props.actions.setNewDataAlert({
              deviceId: this.props.device ? this.props.device.id : null,
              hasNewData: false,
            })
            this.props.actions.getForDevice(
              this.props.device,
              this.state.timeframe
            )
          }}
        />
        {this.renderDeviceGrid(device)}
      </section>
    )
  }

  private renderGrid() {
    if (!this.props.loading && this.state.reports.length === 0) {
      return (
        <Alert variant="warning">No history found for selected timeframe</Alert>
      )
    }

    const page = getPage(this.state.reports, this.state.page)

    return (
      <>
        <PrintableGrid
          sortColumn={this.state.sort.col}
          sortDirection={this.state.sort.dir}
          columns={this.state.columns}
          rows={page.rows}
          onSort={this.handleGridSort}
          height={window.innerHeight * 0.8}
        />
        {page.rows.length
          ? this.renderPagination(page.startIndex, page.endIndex)
          : null}
      </>
    )
  }

  private renderPagination(startIndex: number, endIndex: number) {
    return (
      <div className="device-view-pagination">
        <div className="device-view-pagination-info">
          Showing reports {startIndex + 1} - {endIndex}
        </div>
        <ButtonToolbar className="device-view-pagination-btntoolbar">
          <ButtonGroup>
            {this.state.page > 1 && (
              <Button
                size="sm"
                onClick={() => this.setState({ page: this.state.page - 1 })}
              >
                ← prev
              </Button>
            )}
            {range(this.state.pageCount).map((n) => {
              const page = n + 1
              return (
                <Button
                  variant={this.state.page === page ? 'primary' : undefined}
                  size="sm"
                  key={page}
                  onClick={() => this.setState({ page })}
                >
                  {page}
                </Button>
              )
            })}
            {this.state.page < this.state.pageCount && (
              <Button
                size="sm"
                onClick={() => this.setState({ page: this.state.page + 1 })}
              >
                next →
              </Button>
            )}
          </ButtonGroup>
        </ButtonToolbar>
      </div>
    )
  }
}

export const DeviceView = connect(
  mapStateToProps,
  mapDispatchToProps
)(DeviceViewComponent)
export default DeviceView
