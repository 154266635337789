import * as React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'
import memoizeOne from 'memoize-one'
import { MAP_DEFAULT_LATITUDE, MAP_DEFAULT_LONGITUDE } from 'common/consts'
import { AppState } from '../../types/app-state'
import { LegendFilter, MapFilters } from '../../types/map-state'
import { DeviceMap } from '../../types'
import {
  VanillaMap as VanillaMap,
  IMarker,
  IShape,
} from '../common/map/vanilla-map'
import MapGeofenceToggle from '../common/map/geofence-toggle'
import DevicePopup from '../common/map/device-popup'
import { Metrics } from '../common/metrics'
import { setFilter } from '../../store/actions/map'
import {
  markersFromDevices,
  shapesFromGeofences,
} from '../../store/selectors/map'
import { filterDevices } from '../../store/reducers/map'
import { filterGeofencesByName } from '../../store/reducers/geofences'
import { getMetrics } from '../../store/selectors/user'
import MapDeviceMapModal from './device-map-modal-container'
import { Geofence } from '../../lib/local-api'
import DeviceLegend from '../common/map/device-legend'
import MapTagFilter from './tag-filter'
import { GeofenceSelector } from '../common/map/geofence-selector'
import { Button } from 'react-bootstrap'

interface ReduxActions {
  setFilter: typeof setFilter
}

interface ReduxDispatchProps {
  actions: ReduxActions
}

interface ReduxStateProps {
  devices: DeviceMap
  geofences: Record<string, Geofence>
  userMetrics: Metrics
  timeZone: string | null
  filters: MapFilters
  currentDeviceId: string
}

interface IProps extends ReduxStateProps, ReduxDispatchProps {}

interface IState {
  currentMarkerId: string
  markers: IMarker[]
  shapes: IShape[]
  windowWidth: number
  windowHeight: number
}

const mapDispatchToProps = (dispatch: Dispatch): ReduxDispatchProps => {
  const actions = {
    setFilter,
  }
  return { actions: bindActionCreators(actions, dispatch) }
}

const mapStateToProps = (state: AppState): ReduxStateProps => {
  const geofences = (state.geofences && state.geofences.data) || {}
  return {
    userMetrics: getMetrics(state),
    devices: state.blueprint.devices.data,
    geofences,
    timeZone:
      state.user && state.user.profile
        ? state.user.profile.properties.defaultTimezone
        : null,
    filters: state.map.filters,
    currentDeviceId: state.map.currentDeviceId,
  }
}

const getDeviceArray = memoizeOne((devices: DeviceMap) =>
  Object.values(devices)
)
const getGeofenceArray = memoizeOne((geofences: Record<string, Geofence>) =>
  Object.values(geofences)
)
const filterDevicesMemoized = memoizeOne(filterDevices)
const filterGeofencesByNameMemoized = memoizeOne(filterGeofencesByName)

export class MapComponent extends React.PureComponent<IProps, IState> {
  private defaultCenter = {
    lat: MAP_DEFAULT_LATITUDE,
    lng: MAP_DEFAULT_LONGITUDE,
  }
  private map = React.createRef<VanillaMap>()
  private devicePopupRef = React.createRef<DevicePopup>()
  private infoWindow = new google.maps.InfoWindow()
  private infoWindowRootNode: HTMLElement

  state = {
    currentMarkerId: null,
    markers: [],
    shapes: [],
    windowWidth: window.innerWidth,
    windowHeight: window.innerHeight,
  }

  constructor(props) {
    super(props)
    this.infoWindow.addListener('closeclick', () => {
      this.setState({ currentMarkerId: null })
    })
  }

  static getDerivedStateFromProps(props: IProps) {
    const { showGeofences, geofence, tag, legend } = props.filters
    const devices = filterDevicesMemoized(
      getDeviceArray(props.devices),
      geofence,
      tag,
      legend
    )
    const geofences = showGeofences
      ? filterGeofencesByNameMemoized(
          getGeofenceArray(props.geofences),
          geofence
        )
      : []
    return {
      markers: markersFromDevices(devices),
      shapes: shapesFromGeofences(geofences),
    }
  }

  private onTagSelect = (value: string) => {
    this.props.actions.setFilter('tag', value)
  }

  private onLegendSelect = (value: LegendFilter) => {
    this.props.actions.setFilter('legend', value)
  }

  private onGeofenceSelect = (value: string) => {
    this.props.actions.setFilter(
      'geofence',
      value === this.props.filters.geofence ? '' : value
    )
  }

  private fitMap = () => {
    this.map.current.fitBoundsDebounced()
  }

  private resizeListener = () => {
    this.setState({
      windowWidth: window.innerWidth,
      windowHeight: window.innerHeight,
    })
  }

  private onMarkerClick = (marker: google.maps.Marker) => {
    this.infoWindowRootNode = document.createElement('div')
    this.infoWindow.setContent(this.infoWindowRootNode)
    this.infoWindow.open(marker.getMap(), marker)
    this.setState({ currentMarkerId: marker.get('id') })
  }

  private onShapeClick = (
    object: google.maps.MVCObject,
    position: google.maps.LatLng,
    map: google.maps.Map
  ) => {
    this.setState({ currentMarkerId: null })
    this.infoWindow.setContent(object.get('name'))
    this.infoWindow.setPosition(position)
    this.infoWindow.open(map)
  }

  private onGeofenceToggle = (value: boolean) => {
    this.props.actions.setFilter('showGeofences', value)
  }

  componentDidMount() {
    window.addEventListener('resize', this.resizeListener)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resizeListener)
  }

  componentDidUpdate(prevProps: IProps) {
    // Do not fit map when toggling geofences
    if (
      prevProps.filters !== this.props.filters &&
      prevProps.filters.showGeofences === this.props.filters.showGeofences
    ) {
      this.fitMap()
    }
  }

  render() {
    return (
      <>
        <MapDeviceMapModal />
        <div className="d-flex flex-column flex-nowrap">
          <div className="d-flex justify-content-between align-items-center">
            <div>
              <h1 style={{ fontSize: '20px', margin: 0 }}>Asset Summary Map</h1>
            </div>
            <div className="d-flex flex-justify-end flex-align-center">
              <Button onClick={() => this.fitMap()} variant="light">
                Show all
              </Button>
              <div style={{ marginRight: '16px' }} />
              <MapGeofenceToggle
                enabled={this.props.filters.showGeofences}
                onChange={this.onGeofenceToggle}
              />
              <div style={{ marginRight: '16px' }} />
              <MapTagFilter
                value={this.props.filters.tag}
                onChange={this.onTagSelect}
              />
              <div style={{ marginRight: '16px' }} />
              <DeviceLegend
                allowLegendFilter
                legendFilter={this.props.filters.legend}
                onChange={this.onLegendSelect}
              />
              <div style={{ marginRight: '16px' }} />
              <GeofenceSelector
                value={this.props.filters.geofence}
                onSelect={this.onGeofenceSelect}
              />
            </div>
          </div>
          <div className="mt-2">
            <VanillaMap
              ref={this.map}
              height={Math.max(Math.round(this.state.windowHeight * 0.8), 400)}
              center={this.defaultCenter}
              markers={this.state.markers}
              shapes={this.state.shapes}
              onMarkerClick={this.onMarkerClick}
              onShapeClick={this.onShapeClick}
            />
            {this.state.currentMarkerId && (
              <DevicePopup
                ref={this.devicePopupRef}
                device={this.props.devices[this.state.currentMarkerId]}
                userMetrics={this.props.userMetrics}
                timezone={this.props.timeZone}
                renderTo={this.infoWindowRootNode}
              />
            )}
          </div>
        </div>
      </>
    )
  }
}

export const FullMap = connect(
  mapStateToProps,
  mapDispatchToProps
)(MapComponent)
