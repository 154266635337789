import * as React from 'react'
import { Badge } from 'react-bootstrap'

interface Props {
  connected: boolean
}

export const ModalStatus: React.FC<Props> = ({ connected }) => {
  const variant = connected ? 'success' : 'danger'
  return <Badge variant={variant}>{connected ? 'Online' : 'Offline'}</Badge>
}
