import ApiCommon from './common'
import {
  UserResponse,
  Device,
  DeviceResponse,
  PartialUser,
  PartialGroup,
  GroupResponse,
  UserListResponse,
  PartialDevice,
} from 'rest-api'
import { PartialDeviceView } from 'client/types'
import { pick, omit } from 'lodash'

class Blueprint {
  devices: Devices
  organizations: Groups
  endUsers: Users

  constructor(comm: ApiCommon) {
    this.devices = new Devices(comm)
    this.organizations = new Groups(comm)
    this.endUsers = new Users(comm)
  }
}

class Users {
  comm: ApiCommon
  apiUrl: string

  constructor(comm: ApiCommon) {
    this.comm = comm
    this.apiUrl = process.env.API_BASE_URL
  }

  createUser(request: PartialUser): Promise<UserResponse> {
    const options = {
      url: `${this.apiUrl}/users`,
      method: 'POST',
      body: JSON.stringify(request),
    }
    return this.comm.getJson(options)
  }

  getUserById(userId: string): Promise<UserResponse> {
    return this.comm.getJson({
      url: `${this.apiUrl}/users/${userId}`,
      method: 'GET',
    })
  }

  getUserByIdAsAdmin(userId: string): Promise<UserResponse> {
    return this.comm.getJsonWithAdminToken({
      url: `${this.apiUrl}/users/${userId}`,
      method: 'GET',
    })
  }

  deleteUser(userId: string): Promise<UserResponse> {
    const options = {
      url: `${this.apiUrl}/users/${userId}`,
      method: 'DELETE',
    }

    return this.comm.getJson(options)
  }

  updateUser(id: string, data: PartialUser): Promise<UserResponse> {
    const options = {
      url: `${this.apiUrl}/users/${id}`,
      method: 'PUT',
      body: JSON.stringify(data),
    }

    return this.comm.getJson(options)
  }

  getUsersByGroup(groupId?: string): Promise<UserListResponse> {
    const options = {
      url: `${this.apiUrl}/users?groupId=${groupId}&recursive=true`,
      method: 'GET',
    }

    return this.comm.getJson(options)
  }
}

class Devices {
  comm: ApiCommon
  apiUrl: string

  constructor(comm: ApiCommon) {
    this.comm = comm
    this.apiUrl = process.env.API_BASE_URL
  }

  getDevice(deviceId: string): Promise<DeviceResponse> {
    const options = {
      url: this.apiUrl + '/devices/' + deviceId,
      method: 'GET',
    }

    return this.comm.getJson(options)
  }

  getDeviceWithToken(deviceId: string, jwt: string): Promise<DeviceResponse> {
    const options = {
      url: this.apiUrl + '/devices/' + deviceId,
      method: 'GET',
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    }

    return this.comm.getJson(options, false)
  }

  updateDevice(device: PartialDeviceView) {
    const options = {
      url: this.apiUrl + '/devices/' + device.id,
      method: 'PUT',
      body: this.getDeviceFromView(device),
    }

    return this.comm.getJson(options)
  }

  private getDeviceFromView(device: PartialDeviceView): PartialDevice {
    const baseProps: Array<
      keyof Omit<Device, 'properties'> | 'credentialsProvided' // HACK
    > = [
      'id',
      'serialNumber',
      'credentialsProvided',
      'groupId',
      'deviceType',
      'connected',
      'latitude',
      'longitude',
      'firmwareVersion',
      'ipAddress',
      'createdAt',
      'updatedAt',
    ]
    return { ...pick(device, baseProps), properties: omit(device, baseProps) }
  }
}

class Groups {
  comm: ApiCommon
  apiUrl: string

  constructor(comm: ApiCommon) {
    this.apiUrl = process.env.API_BASE_URL
    this.comm = comm
  }

  createGroup(group: PartialGroup): Promise<GroupResponse> {
    const options = {
      url: this.apiUrl + '/groups',
      method: 'POST',
      body: group,
    }

    return this.comm.getJson(options)
  }

  updateGroup(groupId: string, group: PartialGroup): Promise<GroupResponse> {
    const options = {
      url: this.apiUrl + '/groups/' + groupId,
      method: 'PUT',
      body: group,
    }

    return this.comm.getJson(options)
  }

  deleteGroup(id: string): Promise<void> {
    const options = {
      url: this.apiUrl + '/groups/' + id,
      method: 'DELETE',
    }

    return this.comm.getJson(options)
  }
}

export default Blueprint
