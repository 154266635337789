import * as React from 'react'
import { AppState, ISelect } from '../../types'
import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'
import Select from 'react-select'

interface OwnProps {
  placeholder: string
  value: ISelect | ISelect[]
  multi?: boolean
  options?: ISelect[]
  getOptionsDispatcher?: any
  clearable?: boolean
  className?: string
  onChange: (newValue: any) => void
  getOptions?: (state: AppState) => ISelect[]
  optionComponent?: (props: any) => any
}

interface ReduxStateProps {
  options?: ISelect[]
}

interface Props extends OwnProps, ReduxStateProps {
  actions: any
}

function mapStateToProps(state: AppState, ownProps: OwnProps): ReduxStateProps {
  const options =
    ownProps.options !== null && ownProps.options !== undefined
      ? ownProps.options
      : ownProps.getOptions
      ? ownProps.getOptions(state)
      : null
  return { options }
}

function mapDispatchToProps(dispatch: Dispatch, ownProps: OwnProps) {
  const actions: any = {}
  if (ownProps.getOptionsDispatcher) {
    actions.getOptions = ownProps.getOptionsDispatcher
  }
  return { actions: bindActionCreators(actions, dispatch) }
}

class SelectFilterContainerComponent extends React.PureComponent<Props> {
  componentDidMount() {
    if (
      (this.props.options === null || this.props.options === undefined) &&
      this.props.actions.getOptions
    ) {
      this.props.actions.getOptions()
    }
  }

  render() {
    return (
      <Select
        isMulti={this.props.multi}
        placeholder={this.props.placeholder}
        value={this.props.value}
        options={this.props.options}
        onChange={this.props.onChange}
        clearable={this.props.clearable}
        className={this.props.className}
        optionComponent={this.props.optionComponent}
        menuPortalTarget={document.body}
        styles={{
          control: (base, props) => ({
            ...base,
            minHeight: '34px',
          }),
          dropdownIndicator: (base, props) => ({ ...base, padding: '4px' }),
          clearIndicator: (base, props) => ({ ...base, padding: '4px' }),
          menuPortal: (base, props) => ({ ...base, zIndex: 1000 }),
        }}
      />
    )
  }
}

export const SelectFilterContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(SelectFilterContainerComponent)
export default SelectFilterContainer
