import Api from '../common/services/api'

const api = new Api()

setInterval(async () => {
  try {
    await api.comm.renewJwt()
  } catch (err) {
    console.error('Error renewing JWT:', err)
  }
}, 1000 * 60 * 60)

export default api
