import * as React from 'react'
import useDeviceEdition from '../../../../lib/hooks/useDeviceEdition'
import { updateDevice } from '../../../../store/actions/page-admin-devices'
import { AppState, DeviceView } from 'client/types'
import { useDispatch, useSelector } from 'react-redux'
import CreatableSelect from 'react-select/creatable'
import { OverlayText } from '../overlay-text'
import styles from './tags-formatter.module.scss'

interface Props {
  device: DeviceView
}

export const TagsFormatter: React.FC<Props> = ({ device }) => {
  const editedDevice = useDeviceEdition(device.id)
  const dispatch = useDispatch()
  const globalTags = useSelector<AppState, string[]>(
    (state) => state.blueprint?.devices?.tags
  )
  const [currentTags, setCurrentTags] = React.useState(device.Tags ?? '')
  const options = React.useMemo(
    () => globalTags.map((tag) => ({ value: tag, label: tag })),
    [globalTags]
  )
  const selectValue = React.useMemo(
    () =>
      currentTags
        .split(/, */)
        .filter((tag) => !!tag.trim())
        .map((tag) => ({ value: tag, label: tag })),
    [currentTags]
  )

  if (!editedDevice) {
    const displayValue = currentTags.split(/, */).join(', ')
    return (
      <OverlayText value={displayValue} placement="auto-start">
        {displayValue}
      </OverlayText>
    )
  }

  return (
    <CreatableSelect
      className={styles.root}
      isMulti
      options={options}
      value={selectValue}
      onChange={(options) => {
        const newTags = options
          .map((opt) => opt.value)
          .filter((opt) => !!opt.trim())
          .join(',')
        setCurrentTags(newTags)
        dispatch(
          updateDevice({
            id: device.id,
            key: 'Tags',
            value: newTags,
          })
        )
      }}
      menuPortalTarget={document.body}
      styles={{
        control: (base, props) => ({
          ...base,
          minHeight: '34px',
        }),
        dropdownIndicator: (base, props) => ({ ...base, padding: '4px' }),
        clearIndicator: (base, props) => ({ ...base, padding: '4px' }),
        menuPortal: (base, props) => ({ ...base, zIndex: 1000 }),
      }}
    />
  )
}
