import * as React from 'react'
import { createPortal } from 'react-dom'
import { default as Moment } from 'react-moment'
import 'moment-timezone'

import { DEVICE_FIELDS, USER_FIELDS } from '../../../../common/consts'
import MapDeviceMapButton from '../../map/device-map-button-container'
import { getMetricsAbbreviation, Metrics, getDistanceValue } from '../metrics'
import { DeviceView } from 'client/types'
import { MovementFormatter } from 'client/components/grid/formatters'

interface DevicePopupProps {
  device: DeviceView
  timezone?: string
  userMetrics?: Metrics
  individualTrailerMap?: boolean
  renderTo?: HTMLElement
}

const FieldWrapper: React.FC = ({ children }) => (
  <div className="mt-2">{children}</div>
)

export default class DevicePopup extends React.PureComponent<DevicePopupProps> {
  private renderLastReportTime = () => {
    return (
      <FieldWrapper>
        <span className="mr-2 font-weight-bold">Report: </span>
        <Moment tz={this.props.timezone} format="M/DD/YY h:mm A">
          {this.props.device.LastCommTime}
        </Moment>
      </FieldWrapper>
    )
  }

  private renderMovementLegend = () => {
    const { individualTrailerMap, device, userMetrics: metrics } = this.props

    let legend = device[DEVICE_FIELDS.MOVEMENT] || 'Unknown'

    if (legend === 'Driving') {
      const direction = device[DEVICE_FIELDS.HEADING] || ''
      const convertedSpeed = Math.ceil(getDistanceValue(device.Speed, metrics))
      const speedAbbreviation = getMetricsAbbreviation(
        USER_FIELDS.DISPLAY_UNITS_DISTANCE,
        metrics,
        '',
        'h'
      )

      legend = `${legend} ${direction} at ${convertedSpeed} ${speedAbbreviation}`
    }

    return (
      <FieldWrapper>
        {individualTrailerMap && <MovementFormatter dependentValues={device} />}
        {!individualTrailerMap && (
          <MapDeviceMapButton
            device={this.props.device}
            btnLabel={<MovementFormatter dependentValues={device} />}
            className="p-0"
          />
        )}
      </FieldWrapper>
    )
  }

  private renderIdentifier = () => {
    if (this.props.individualTrailerMap) {
      return null
    }

    return (
      <FieldWrapper>
        <span className="mr-2 font-weight-bold">Email Address: </span>
        {/* <Link to={`device/${this.props.device.id}`} key={this.props.device.id}>
          {this.props.device[DEVICE_FIELDS.EMAIL_ADDRESS]}
        </Link> */}
        {this.props.device[DEVICE_FIELDS.EMAIL_ADDRESS]}
      </FieldWrapper>
    )
  }

  render() {
    const root = (
      <div>
        {this.renderIdentifier()}
        {this.renderLastReportTime()}
        {this.renderMovementLegend()}
      </div>
    )
    return this.props.renderTo ? createPortal(root, this.props.renderTo) : root
  }
}
