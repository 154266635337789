import * as React from 'react'
import { Popover, Button, OverlayTrigger } from 'react-bootstrap'

interface IConfirmProps {
  onConfirm: () => any
  confirmText: string
  title: string
  body: string
  children: React.ReactElement
}

const ConfirmComponent: React.FC<IConfirmProps> = (props) => {
  const [show, setShow] = React.useState(false)

  const popover = (
    <Popover id="popover-confirm">
      <Popover.Title>{props.title}</Popover.Title>
      <Popover.Content>
        <div>{props.body}</div>
        <div className="mt-2">
          <Button
            variant="success"
            onClick={() => {
              props.onConfirm()
              setShow(false)
            }}
            className="mr-2"
          >
            {props.confirmText}
          </Button>
          <Button variant="danger" onClick={() => setShow(false)}>
            Cancel
          </Button>
        </div>
      </Popover.Content>
    </Popover>
  )
  return (
    <OverlayTrigger
      trigger="click"
      placement="right"
      overlay={popover}
      show={show}
      onToggle={(nextShow) => setShow(nextShow)}
    >
      {props.children}
    </OverlayTrigger>
  )
}

export const Confirm = ConfirmComponent
export default Confirm
