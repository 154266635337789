import * as React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'
import { GoogleMap, Circle, Rectangle, Polygon } from '@react-google-maps/api'
import { transform, isEqual, isObject } from 'lodash'

import { AppState } from 'client/types/index'
import { Geofence } from 'common/types/local-api'
import * as consts from 'common/consts'
import { saveGeofence } from 'client/store/actions/geofences'

interface OwnProps {
  editing: boolean
  geofence: Geofence
  startSave?: boolean
  height?: number
  step?: number
}

interface ReduxActions {
  saveGeofence: any
}

interface ReduxDispatchProps {
  actions: ReduxActions
}

interface ReduxStateProps {
  bounds: google.maps.LatLngBoundsLiteral
}

function mapStateToProps(state: AppState): ReduxStateProps {
  return {
    bounds: state.pageAdminGeofences.bounds,
  }
}

function mapDispatchToProps(dispatch: Dispatch): ReduxDispatchProps {
  return { actions: bindActionCreators({ saveGeofence }, dispatch) }
}

interface Props extends OwnProps, ReduxDispatchProps, ReduxStateProps {}

interface CirleState {
  center: google.maps.LatLngLiteral
  radius: number
}

interface RectangleState {
  x1: number
  x2: number
  y1: number
  y2: number
}

type PolygonState = Array<{
  x: number
  y: number
}>

interface GeofenceMapState {
  center?: google.maps.LatLngLiteral
  zoom?: number
  circle: CirleState
  rectangle: RectangleState
  polygon: PolygonState
  mapTypeId?: string
}

// Calculate the difference between objects
const difference = (object, base) => {
  const changes = (o, b) => {
    return transform(o, (result, value, key) => {
      if (!isEqual(value, b[key])) {
        result[key] =
          isObject(value) && isObject(b[key]) ? changes(value, b[key]) : value
      }
    })
  }
  return changes(object, base)
}

class GeofenceMapComponent extends React.Component<Props, GeofenceMapState> {
  map: any
  circle: any
  rectangle: any
  polygon: any
  dragging: boolean

  constructor(props) {
    super(props)
    this.handleMapMounted = this.handleMapMounted.bind(this)
    this.handleCircleMounted = this.handleCircleMounted.bind(this)
    this.handleRectangleMounted = this.handleRectangleMounted.bind(this)
    this.handlePolygonMounted = this.handlePolygonMounted.bind(this)

    this.state = {
      center: {
        lat: consts.ADMIN_GEOFENCE_DEFAULT_LATITUDE,
        lng: consts.ADMIN_GEOFENCE_DEFAULT_LONGITUDE,
      },
      zoom: consts.ADMIN_GEOFENCE_DEFAULT_ZOOM,
      circle: null,
      rectangle: null,
      polygon: null,
      mapTypeId: google.maps.MapTypeId.ROADMAP,
    }
  }

  /**
   * Grab the ref of the map
   * @param map
   */
  handleMapMounted(map) {
    this.map = map
    if (map && this.props.bounds) {
      map.fitBounds(this.props.bounds)
    }
  }

  /**
   * Mount the polygon and get a ref to the google object
   * @param polygon
   */
  handlePolygonMounted(polygon) {
    this.polygon = polygon
    this.circle = null
    this.rectangle = null
    if (polygon) {
      const path = polygon.getPath()
      google.maps.event.addListener(path, 'set_at', this.onPolygonPathChanged)
      google.maps.event.addListener(
        path,
        'insert_at',
        this.onPolygonPathChanged
      )
    }
  }

  /**
   * Mount the rectangle and get a ref to the google object
   * @param rectangle
   */
  handleRectangleMounted(rectangle) {
    this.rectangle = rectangle
    this.circle = null
    this.polygon = null
  }

  /**
   * Mount the circle and get a ref to the google object
   * @param circle
   */
  handleCircleMounted(circle) {
    this.circle = circle
    this.rectangle = null
    this.polygon = null
  }

  shouldComponentUpdate(nextProps: Props, nextState: GeofenceMapState) {
    // Don't re-render if we are just moving the shape around or changing zoom or dragging
    const stateDiff: any = difference(this.state, nextState)
    if (
      Object.keys(stateDiff).length === 1 &&
      (stateDiff.center ||
        stateDiff.zoom ||
        stateDiff.circle ||
        stateDiff.rectangle ||
        stateDiff.polygon)
    ) {
      return false
    }

    return (
      !isEqual(nextState, this.state) ||
      nextProps.bounds !== this.props.bounds ||
      nextProps.height !== this.props.height ||
      nextProps.editing !== this.props.editing ||
      nextProps.step !== this.props.step
    )
  }

  componentDidMount() {
    this.renderEmptyMap()
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    if (nextProps.geofence && nextProps.geofence.id) {
      // The user has clicked on an existing geofence
      if (
        this.props.geofence &&
        this.props.geofence.id === nextProps.geofence.id &&
        this.props.geofence.shapeId !== nextProps.geofence.shapeId
      ) {
        // We are editing the same geofence, but the shape has changed
        this.renderNewGeofence(nextProps)
      } else if (
        !this.props.geofence ||
        nextProps.geofence.circle !== this.props.geofence.circle ||
        nextProps.geofence.bounds !== this.props.geofence.bounds ||
        nextProps.geofence.polygon !== this.props.geofence.polygon
      ) {
        // We were not already editing a geofence, or we were editing a different geofence
        this.renderExistingGeofence(nextProps)
      }
    } else if (nextProps.editing && nextProps.step === 2) {
      // If shape of geofence changed, rerender new geofence
      if (
        !nextProps.geofence ||
        !this.props.geofence ||
        nextProps.geofence.shapeId !== this.props.geofence.shapeId
      ) {
        this.renderNewGeofence(nextProps)
      }
    } else {
      this.renderEmptyMap()
    }

    // Handle a save by setting up the shape objects
    if (nextProps.startSave) {
      if (this.circle) {
        this.props.actions.saveGeofence({
          circle: {
            x: this.circle.getCenter().lat(),
            y: this.circle.getCenter().lng(),
            radius: this.circle.getRadius(),
          },
        })
      } else if (this.rectangle) {
        const bounds = this.rectangle.getBounds()
        this.props.actions.saveGeofence({
          bounds: {
            x1: bounds.getNorthEast().lat(),
            y1: bounds.getNorthEast().lng(),
            x2: bounds.getSouthWest().lat(),
            y2: bounds.getSouthWest().lng(),
          },
        })
      } else if (this.polygon) {
        const points = []
        this.polygon.getPaths().forEach((path) => {
          path.forEach((point) => {
            points.push({
              x: point.lat(),
              y: point.lng(),
            })
          })
        })

        this.props.actions.saveGeofence({
          polygon: points,
        })
      }
    }
  }

  /**
   * Renders a new geofence object with either a circle, rectangle, or polygon
   * @param nextProps
   */
  renderNewGeofence(nextProps) {
    let circle = null
    let rectangle = null
    let polygon = null
    if (this.map && nextProps.geofence) {
      const bounds = this.map.getBounds()
      const center = this.map.getCenter()
      if (bounds && center) {
        if (nextProps.geofence.shapeId === consts.SHAPE_ID_CIRCLE) {
          const ne = bounds.getNorthEast()
          // Set the lat to be the same as the center so we get the middle of the bottom
          const radius =
            google.maps.geometry.spherical.computeDistanceBetween(
              center,
              new google.maps.LatLng(ne.lat(), center.lng())
            ) * 0.6
          circle = {
            center: this.map.getCenter(),
            radius,
          }
        } else if (nextProps.geofence.shapeId === consts.SHAPE_ID_POLYGON) {
          const ne = bounds.getNorthEast()
          const sw = bounds.getSouthWest()

          // calculate total map height and width
          const mapHeight = Math.abs(ne.lat() - sw.lat())
          const mapWidth = Math.abs(ne.lng() - sw.lng())

          // set geofence size to 60% of map size
          const geofenceHeight = mapHeight * 0.6
          const geofenceWidth = mapWidth * 0.6

          // calculate boundaries from center of map
          polygon = [
            {
              x: center.lat() + geofenceHeight / 2,
              y: center.lng() + geofenceWidth / 2,
            },
            {
              x: center.lat() - geofenceHeight / 2,
              y: center.lng() - geofenceWidth / 2,
            },
            {
              x: center.lat() + geofenceHeight / 2,
              y: center.lng() - geofenceWidth / 2,
            },
          ]
        } else if (nextProps.geofence.shapeId === consts.SHAPE_ID_RECTANGLE) {
          const ne = bounds.getNorthEast()
          const sw = bounds.getSouthWest()

          // calculate total map height and width
          const mapHeight = Math.abs(ne.lat() - sw.lat())
          const mapWidth = Math.abs(ne.lng() - sw.lng())

          // set geofence size to 60% of map size
          const geofenceHeight = mapHeight * 0.6
          const geofenceWidth = mapWidth * 0.6

          // calculate boundaries from center of map
          rectangle = {
            x1: center.lat() + geofenceHeight / 2,
            y1: center.lng() + geofenceWidth / 2,
            x2: center.lat() - geofenceHeight / 2,
            y2: center.lng() - geofenceWidth / 2,
          }
        }
      }
      this.setState({
        ...this.state,
        circle,
        rectangle,
        polygon,
        zoom: this.map.getZoom(),
        center: this.map.getCenter(),
      })
    }
  }

  /**
   * Render an empty map
   */
  renderEmptyMap() {
    this.setState((state) => {
      return {
        ...state,
        circle: null,
        rectangle: null,
        polygon: null,
        zoom: consts.ADMIN_GEOFENCE_DEFAULT_ZOOM,
      }
    })
  }

  /**
   * Render a chosen geofence
   * @param nextProps
   */
  renderExistingGeofence(nextProps) {
    let circle = null
    let rectangle = null
    let polygon = null
    let center
    let zoom
    // Set the center if the geofence is a circle
    if (
      nextProps.geofence.circle &&
      nextProps.geofence.shapeId === consts.SHAPE_ID_CIRCLE
    ) {
      const radius: number = nextProps.geofence.circle.radius

      center = {
        lat: nextProps.geofence.circle.x,
        lng: nextProps.geofence.circle.y,
      }

      circle = {
        center,
        radius,
      }

      // Set up the center/zoom based on the circle
      const gCircle = new google.maps.Circle()
      gCircle.setRadius(radius)
      gCircle.setCenter(new google.maps.LatLng(center.lat, center.lng))
      this.map.fitBounds(gCircle.getBounds())
      zoom = this.map.getZoom()
    } else if (
      nextProps.geofence.bounds &&
      nextProps.geofence.shapeId === consts.SHAPE_ID_RECTANGLE
    ) {
      // Set up the center/zoom based on the rectangle
      rectangle = nextProps.geofence.bounds
      const gRect = new google.maps.Rectangle({
        bounds: {
          north: rectangle.x1,
          south: rectangle.x2,
          east: rectangle.y1,
          west: rectangle.y2,
        },
      })
      this.map.fitBounds(gRect.getBounds())
      center = gRect.getBounds().getCenter()
      zoom = this.map.getZoom()
    } else if (
      nextProps.geofence.polygon &&
      nextProps.geofence.shapeId === consts.SHAPE_ID_POLYGON
    ) {
      // Set up the center/zoom based on the polgyon
      polygon = nextProps.geofence.polygon

      const bounds = new google.maps.LatLngBounds()
      polygon.forEach((point) => {
        bounds.extend(new google.maps.LatLng(point.x, point.y))
      })
      this.map.fitBounds(bounds)
      center = bounds.getCenter()
      zoom = this.map.getZoom()
    }
    this.setState((state) => {
      return { ...state, circle, rectangle, polygon, center, zoom }
    })
  }

  changeMapType = () => {
    if (this.map) {
      this.setState({
        ...this.state,
        mapTypeId: this.map.getMapTypeId(),
      })
    }
  }

  onCircleCenterChanged = () => {
    if (!this.dragging && this.circle) {
      const newCircle = {
        ...this.state.circle,
        center: this.circle.getCenter(),
      }
      this.setState({
        ...this.state,
        circle: newCircle,
      })
    }
  }

  onDragStart = () => {
    this.dragging = true
  }

  onDragEnd = () => {
    this.dragging = false
  }

  onCircleRadiusChanged = () => {
    if (this.circle) {
      const newCircle = {
        ...this.state.circle,
        radius: this.circle.getRadius(),
      }
      this.setState({
        ...this.state,
        circle: newCircle,
      })
    }
  }

  onRectangleBoundsChanged = () => {
    if (!this.dragging && this.rectangle) {
      const bounds = this.rectangle.getBounds()
      const newRectangle = {
        x1: bounds.getNorthEast().lat(),
        y1: bounds.getNorthEast().lng(),
        x2: bounds.getSouthWest().lat(),
        y2: bounds.getSouthWest().lng(),
      }
      this.setState({
        ...this.state,
        rectangle: newRectangle,
      })
    }
  }

  onPolygonPathChanged = () => {
    if (!this.dragging && this.polygon) {
      const paths = []
      this.polygon.getPaths().forEach((path) => {
        path.forEach((point) => {
          paths.push({
            x: point.lat(),
            y: point.lng(),
          })
        })
      })
      this.setState({
        ...this.state,
        polygon: paths,
      })
    }
  }

  render() {
    const GeofenceGoogleMapComponent = (props) => {
      return (
        <GoogleMap
          mapContainerStyle={{ width: '100%', height: props.height }}
          onLoad={props.onMapMounted}
          zoom={this.state.zoom}
          center={this.state.center}
          options={{ controlSize: 24, streetViewControl: false }}
          mapTypeId={this.state.mapTypeId}
          onMapTypeIdChanged={this.changeMapType}
          onDragEnd={() => {
            const center = this.map.getCenter()
            this.setState({
              center: {
                lat: center.lat(),
                lng: center.lng(),
              },
            })
          }}
          onZoomChanged={() => {
            const zoom = this.map?.getZoom()
            if (zoom) {
              this.setState({
                zoom,
              })
            }
          }}
        >
          {props.circle && (
            <Circle
              onLoad={this.handleCircleMounted}
              draggable={this.props.editing}
              editable={this.props.editing}
              center={props.circle.center}
              radius={props.circle.radius}
              onCenterChanged={this.onCircleCenterChanged}
              onDragStart={this.onDragStart}
              onDragEnd={() => {
                this.onDragEnd()
                this.onCircleCenterChanged()
              }}
              onRadiusChanged={this.onCircleRadiusChanged}
            />
          )}
          {props.rectangle && (
            <Rectangle
              onLoad={this.handleRectangleMounted}
              draggable={this.props.editing}
              editable={this.props.editing}
              bounds={{
                north: props.rectangle.x1,
                south: props.rectangle.x2,
                east: props.rectangle.y1,
                west: props.rectangle.y2,
              }}
              onBoundsChanged={this.onRectangleBoundsChanged}
              onDragStart={this.onDragStart}
              onDragEnd={() => {
                this.onDragEnd()
                this.onRectangleBoundsChanged()
              }}
            />
          )}
          {props.polygon && (
            <Polygon
              onLoad={this.handlePolygonMounted}
              draggable={this.props.editing}
              editable={this.props.editing}
              paths={props.polygon.map((path) => {
                return { lat: path.x, lng: path.y }
              })}
              onDragStart={this.onDragStart}
              onDragEnd={() => {
                this.onDragEnd()
                this.onPolygonPathChanged()
              }}
            />
          )}
        </GoogleMap>
      )
    }

    return (
      <GeofenceGoogleMapComponent
        onMapMounted={this.handleMapMounted}
        circle={this.state.circle}
        polygon={this.state.polygon}
        rectangle={this.state.rectangle}
        height="100%"
      />
    )
  }
}

export const GeofenceMap = connect(
  mapStateToProps,
  mapDispatchToProps
)(GeofenceMapComponent)
export default GeofenceMap
