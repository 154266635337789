import { AppState, DeviceView } from 'client/types'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'

const useDeviceList = () => {
  const devices = useSelector<AppState, Record<string, DeviceView>>(
    (state) => state.blueprint.devices?.data
  )
  const list = useMemo(() => (devices ? Object.values(devices) : []), [devices])
  return list
}

export default useDeviceList
