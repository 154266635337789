import { UserProfile } from '../../../client/types/user-state'
import * as constants from 'common/consts'
import { pick } from 'lodash'
import { User } from 'rest-api'

const KM_TO_MILE = 0.621371
const MILE_TO_KM = 1 / KM_TO_MILE
const KILOPASCAL_TO_PSI = 0.145038
const KILOPASCAL_TO_MILIBAR = 10
const PSI_TO_KILOPASCAL = 1 / KILOPASCAL_TO_PSI
const PSI_TO_MILIBAR = PSI_TO_KILOPASCAL * KILOPASCAL_TO_MILIBAR
const MILIBAR_TO_KILOPASCAL = 1 / KILOPASCAL_TO_MILIBAR
const MILIBAR_TO_PSI = MILIBAR_TO_KILOPASCAL * KILOPASCAL_TO_PSI
const LITER_TO_GALLON = 0.264172
const GALLON_TO_LITER = 1 / LITER_TO_GALLON
const KILOGRAM_TO_POUND = 2.20462
const POUND_TO_KILOGRAM = 1 / KILOGRAM_TO_POUND

export interface Metrics {
  displayUnitsDistance: string
  displayUnitsPressure: string
  displayUnitsTemperature: string
  displayUnitsVolume: string
  displayUnitsWeight: string
}

export const DEFAULT_METRICS: Metrics = {
  displayUnitsDistance: constants.USER_PROFILE_DISTANCE_ENGLISH,
  displayUnitsPressure: constants.USER_PROFILE_PRESSURE_KILOPASCALS,
  displayUnitsTemperature: constants.USER_PROFILE_TEMPERATURE_FAHRENHEIT,
  displayUnitsVolume: constants.USER_PROFILE_VOLUME_LITERS,
  displayUnitsWeight: constants.USER_PROFILE_WEIGHT_KILOGRAMS,
}

export function getMetricsSettings(userProfile: UserProfile | User): Metrics {
  const overridenSettings = pick(
    userProfile.properties,
    Object.keys(DEFAULT_METRICS)
  )
  return { ...DEFAULT_METRICS, ...overridenSettings } as Metrics
}

function getDefaultFormatAbbreviation(
  prefix: string,
  perTime: string
): (abbreviation: string) => string {
  return (abbreviation: string): string => {
    return `${prefix}${abbreviation}${perTime ? '/' : ''}${perTime}`
  }
}

export function getMetricsAbbreviation(
  metric: string,
  settings: Metrics,
  prefix = '',
  perTime = ''
): string {
  const defaultFormat = getDefaultFormatAbbreviation(prefix, perTime)
  switch (metric) {
    case constants.USER_FIELDS.DISPLAY_UNITS_DISTANCE:
      if (
        settings.displayUnitsDistance === constants.USER_PROFILE_DISTANCE_METRIC
      ) {
        return defaultFormat(constants.GRID_COLUMN_DISTANCE_METERS)
      }
      if (
        settings.displayUnitsDistance ===
        constants.USER_PROFILE_DISTANCE_ENGLISH
      ) {
        if (perTime) {
          return `${prefix}mp${perTime}`
        }
        return defaultFormat(constants.GRID_COLUMN_DISTANCE_MILES)
      }
      break
    case constants.USER_FIELDS.DISPLAY_UNITS_PRESSURE:
      if (
        settings.displayUnitsPressure ===
        constants.USER_PROFILE_PRESSURE_KILOPASCALS
      ) {
        return defaultFormat(constants.GRID_COLUMN_PRESSURE_KILOPASCALS)
      }
      if (
        settings.displayUnitsPressure ===
        constants.USER_PROFILE_PRESSURE_MILIBARS
      ) {
        return defaultFormat(constants.GRID_COLUMN_PRESSURE_MILIBARS)
      }
      if (
        settings.displayUnitsPressure === constants.USER_PROFILE_PRESSURE_PPS
      ) {
        return defaultFormat(constants.GRID_COLUMN_PRESSURE_PPS)
      }
      break
    case constants.USER_FIELDS.DISPLAY_UNITS_TEMPERATURE:
      if (
        settings.displayUnitsTemperature ===
        constants.USER_PROFILE_TEMPERATURE_CELSIUS
      ) {
        return defaultFormat(constants.GRID_COLUMN_TEMPERATURE_CELSIUS)
      }
      if (
        settings.displayUnitsTemperature ===
        constants.USER_PROFILE_TEMPERATURE_FAHRENHEIT
      ) {
        return defaultFormat(constants.GRID_COLUMN_TEMPERATURE_FAHRENHEIT)
      }
      break
    case constants.USER_FIELDS.DISPLAY_UNITS_VOLUME:
      if (
        settings.displayUnitsVolume === constants.USER_PROFILE_VOLUME_GALLONS
      ) {
        return defaultFormat(constants.GRID_COLUMN_VOLUME_GALLONS)
      }
      if (
        settings.displayUnitsVolume === constants.USER_PROFILE_VOLUME_LITERS
      ) {
        return defaultFormat(constants.GRID_COLUMN_VOLUME_LITERS)
      }
      break
    case constants.USER_FIELDS.DISPLAY_UNITS_WEIGHT:
      if (
        settings.displayUnitsWeight === constants.USER_PROFILE_WEIGHT_KILOGRAMS
      ) {
        return defaultFormat(constants.GRID_COLUMN_WEIGHT_KILOGRAMS)
      }
      if (
        settings.displayUnitsWeight === constants.USER_PROFILE_WEIGHT_POUNDS
      ) {
        return defaultFormat(constants.GRID_COLUMN_WEIGHT_POUNDS)
      }
      break
    default:
      return ''
  }
  return ''
}

export function getDistanceValue(value: number, settings: Metrics): number {
  return convertDistance(
    value,
    DEFAULT_METRICS.displayUnitsDistance,
    settings.displayUnitsDistance
  )
}

export function getPressureValue(value: number, settings: Metrics): number {
  return convertPressure(
    value,
    DEFAULT_METRICS.displayUnitsPressure,
    settings.displayUnitsPressure
  )
}

export function getTemperatureValue(value: number, settings: Metrics): number {
  return convertTemperature(
    value,
    DEFAULT_METRICS.displayUnitsTemperature,
    settings.displayUnitsTemperature
  )
}

export function getVolumeValue(value: number, settings: Metrics): number {
  return convertVolume(
    value,
    DEFAULT_METRICS.displayUnitsVolume,
    settings.displayUnitsVolume
  )
}

export function getWeightValue(value: number, settings: Metrics): number {
  return convertWeight(
    value,
    DEFAULT_METRICS.displayUnitsWeight,
    settings.displayUnitsWeight
  )
}

export function convertDistance(
  value: number,
  from: string,
  to: string
): number {
  if (from === constants.USER_PROFILE_DISTANCE_METRIC) {
    if (to === constants.USER_PROFILE_DISTANCE_ENGLISH) {
      return value * KM_TO_MILE
    }
  } else if (from === constants.USER_PROFILE_DISTANCE_ENGLISH) {
    if (to === constants.USER_PROFILE_DISTANCE_METRIC) {
      return value * MILE_TO_KM
    }
  }
  return value
}

export function convertPressure(
  value: number,
  from: string,
  to: string
): number {
  if (from === constants.USER_PROFILE_PRESSURE_KILOPASCALS) {
    if (to === constants.USER_PROFILE_PRESSURE_PPS) {
      return value * KILOPASCAL_TO_PSI
    } else if (to === constants.USER_PROFILE_PRESSURE_MILIBARS) {
      return value * KILOPASCAL_TO_MILIBAR
    }
  } else if (from === constants.USER_PROFILE_PRESSURE_PPS) {
    if (to === constants.USER_PROFILE_PRESSURE_KILOPASCALS) {
      return value * PSI_TO_KILOPASCAL
    } else if (to === constants.USER_PROFILE_PRESSURE_MILIBARS) {
      return value * PSI_TO_MILIBAR
    }
  } else if (from === constants.USER_PROFILE_PRESSURE_MILIBARS) {
    if (to === constants.USER_PROFILE_PRESSURE_KILOPASCALS) {
      return value * MILIBAR_TO_KILOPASCAL
    } else if (to === constants.USER_PROFILE_PRESSURE_PPS) {
      return value * MILIBAR_TO_PSI
    }
  }
  return value
}

export function convertTemperature(
  value: number,
  from: string,
  to: string
): number {
  if (from === constants.USER_PROFILE_TEMPERATURE_CELSIUS) {
    if (to === constants.USER_PROFILE_TEMPERATURE_FAHRENHEIT) {
      return value * 1.8 + 32
    }
  } else if (from === constants.USER_PROFILE_TEMPERATURE_FAHRENHEIT) {
    if (to === constants.USER_PROFILE_TEMPERATURE_CELSIUS) {
      return (value - 32) / 1.8
    }
  }
  return value
}

export function convertVolume(value: number, from: string, to: string): number {
  if (from === constants.USER_PROFILE_VOLUME_LITERS) {
    if (to === constants.USER_PROFILE_VOLUME_GALLONS) {
      return value * LITER_TO_GALLON
    }
  } else if (from === constants.USER_PROFILE_VOLUME_GALLONS) {
    if (to === constants.USER_PROFILE_VOLUME_LITERS) {
      return value * GALLON_TO_LITER
    }
  }
  return value
}

export function convertWeight(value: number, from: string, to: string): number {
  if (from === constants.USER_PROFILE_WEIGHT_KILOGRAMS) {
    if (to === constants.USER_PROFILE_WEIGHT_POUNDS) {
      return value * KILOGRAM_TO_POUND
    }
  } else if (from === constants.USER_PROFILE_WEIGHT_POUNDS) {
    if (to === constants.USER_PROFILE_WEIGHT_KILOGRAMS) {
      return value * POUND_TO_KILOGRAM
    }
  }
  return value
}

export function createConvertedTemperatureString(
  value: number,
  settings: Metrics
) {
  if (!value) {
    return null
  }

  const temperature = Math.round(getTemperatureValue(value, settings) * 10) / 10
  const abbreviation = getMetricsAbbreviation(
    constants.USER_FIELDS.DISPLAY_UNITS_TEMPERATURE,
    settings
  )
  return `${temperature} ${abbreviation}`
}
