import { forOwn, isString, isNumber } from 'lodash'

import { UserProfile, Column, GridState, DeviceView } from '../../types'
import { constants } from '../actions/grid'
import * as consts from 'common/consts'
import { convertMetrics } from '../../components/common/device-metrics'
import { FlatGroupHierarchy } from '../blueprint/groups/reducers'
import {
  sortAlarms,
  sortCityState,
  sortGeofence,
  sortDuration,
  sortNumber,
  sortString,
  sortDate,
  sortBoolean,
} from '../../components/grid/sorters'

export const initialState: GridState = {
  currentDeviceId: null,
  filterGeofence: null,
  filterModel: null,
  filterTags: [],
  filterTerm: '',
  models: null,
  showGridColumnsModal: false,
  showMapModal: false,
  showSpeedGraph: false,
  tags: null,
}

export function filterDevicesByTerm(
  devices: DeviceView[],
  term: string,
  userProfile: UserProfile,
  orgHierarchy: FlatGroupHierarchy[],
  pageGridColumns: Column[]
) {
  if (!term || !term.trim()) {
    return devices
  }

  term = term.toLowerCase()
  const filteredOrganizations = orgHierarchy.filter(
    (org) => String(org.name).toLowerCase().indexOf(term) >= 0
  )

  devices = devices.filter((device) => {
    // check for user profile and convert device metrics
    if (userProfile) {
      device = convertMetrics(device, userProfile)
    }

    // First try to match any device that is attached to an org that met the filter
    let match =
      filteredOrganizations.filter((org) => {
        return org.id === device.groupId
      }).length > 0

    // Handle Status column of 'offline' or 'online'
    if (
      !match &&
      'online'.indexOf(term) === 0 &&
      device[consts.DEVICE_FIELDS.CONNECTED]
    ) {
      match = true
    }
    if (
      !match &&
      'offline'.indexOf(term) === 0 &&
      !device[consts.DEVICE_FIELDS.CONNECTED]
    ) {
      match = true
    }

    // Handle city/state
    if (
      (!match && device[consts.DEVICE_FIELDS.CITY]) ||
      device[consts.DEVICE_FIELDS.STATE_PROVINCE]
    ) {
      const locCity = device[consts.DEVICE_FIELDS.CITY]
      const locState = device[consts.DEVICE_FIELDS.STATE_PROVINCE]
      const valueMatch =
        ('' + locCity).toLowerCase().indexOf(term) >= 0 ||
        ('' + locState).toLowerCase().indexOf(term) >= 0
      if (valueMatch) {
        match = true
      }
    }

    if (!match) {
      forOwn(device, (value, key) => {
        // only search columns active in the grid
        const gridColumns = {}
        pageGridColumns.forEach((column) => {
          gridColumns[column.key] = true
        })
        if (gridColumns[key] || key === consts.DEVICE_FIELDS.TRAILER_ID) {
          const isPrimitive = isString(value) || isNumber(value)
          const valueMatch = ('' + value).toLowerCase().indexOf(term) >= 0
          if (isPrimitive && valueMatch) {
            match = true
          }
        }
      })
    }
    return match
  })

  return devices
}

/**
 * Woah be to you brave soul who tries to make sense out of this...there is some crazy stuff going on here to match
 * the sorting of the columns to what is displayed.  Look at the /components/grid/formatters.ts file for how we display
 * certain fields based on the configuration of the device
 */

export function sortDevices<T extends Record<string, any>>(
  devices: T[],
  sortCol: string,
  sortDir: 'ASC' | 'DESC' | 'NONE' | null,
  columns: Column[]
): T[] {
  const out = Array.from(devices)

  const column = columns.find((col) => {
    return col.key === sortCol
  })

  if (sortDir !== consts.SORT_DIR_NONE) {
    const isDesc = sortDir === consts.SORT_DIR_DESC

    out.sort((deviceA, deviceB) => {
      const valA = column?.getValue
        ? column.getValue(deviceA)
        : deviceA[sortCol]
      const valB = column?.getValue
        ? column.getValue(deviceB)
        : deviceB[sortCol]

      // Handle city and state from the location cache
      if (
        sortCol === consts.DEVICE_FIELDS.CITY ||
        sortCol === consts.DEVICE_FIELDS.STATE_PROVINCE
      ) {
        return sortCityState(deviceA, deviceB, sortCol, isDesc)
        // Sort alarms
      } else if (sortCol === consts.DEVICE_FIELDS.ALARMS) {
        return sortAlarms(deviceA, deviceB, isDesc)
      } else if (sortCol === consts.DEVICE_FIELDS.CURRENT_GEOFENCES) {
        return sortGeofence(valA, valB, isDesc)
      } else if (column) {
        if (column.type === 'boolean') {
          return sortBoolean(valA, valB, isDesc)
        } else if (column.type === 'number') {
          return sortNumber(valA, valB, isDesc)
        } else if (column.type === 'date') {
          return sortDate(valA, valB, isDesc)
        } else if (column.type === 'duration') {
          return sortDuration(valA, valB, isDesc)
        } else if (!column.type || column.type === 'string') {
          return sortString(valA, valB, isDesc)
        }
      } else {
        return sortString(valA, valB, isDesc)
      }
    })
  }

  return out
}

export default function (
  state: GridState = initialState,
  action: ReduxActions.Action<any>
): GridState {
  let devices, filter

  switch (action.type) {
    /**
     * Hide the map modal
     */
    case constants.MODAL_MAP_HIDE:
      return Object.assign({}, state, {
        showMapModal: false,
      })

    /**
     * Show the map modal
     */
    case constants.MODAL_MAP_SHOW:
      return Object.assign({}, state, {
        currentDeviceId: action.payload,
        showMapModal: true,
      })

    /**
     * Load the unique set of tags from the list of devices
     */
    case constants.DEVICE_TAGS_LOAD: {
      devices = action.payload
      const allTags = []
      devices.forEach((device) => {
        const tags = device.Tags ? device.Tags.split(',') : []
        tags.forEach((tag) => {
          allTags.push(tag)
        })
      })
      // Have to cast set to array for typescript
      const tags = [...Array.from(new Set(allTags))]

      // The cast to array results in a single element array of undefined
      if (tags.length === 1 && !tags[0]) {
        return Object.assign({}, state, {})
      }
      return Object.assign({}, state, {
        tags,
      })
    }

    /**
     * Load the unique set of models from the list of devices
     */
    case constants.DEVICE_MODELS_LOAD: {
      devices = action.payload
      // Have to cast set to array for typescript
      const models = [
        ...Array.from(new Set(devices.map((device) => device.Model))),
      ]

      // The cast to array results in a single element array of undefined
      if (models.length === 1 && !models[0]) {
        return Object.assign({}, state, {})
      }
      return Object.assign({}, state, {
        models,
      })
    }

    /**
     * The user has selected a model from the dropdown model filter, limit the list of devices to only those that match the model
     */
    case constants.DEVICE_TABLE_FILTER_MODEL:
      filter = action.payload.filter

      return Object.assign({}, state, {
        filterModel: filter,
      })

    /**
     * The user has selected a geofence from the dropdown geofence filter, limit the list of devices to only those that match the geofence
     */
    case constants.DEVICE_TABLE_FILTER_GEOFENCE:
      filter = action.payload.filter

      return Object.assign({}, state, {
        filterGeofence: filter,
      })

    /**
     * The user has selected a tag from the dropdown tag filter, limit the list of devices to only those that match the tag
     */
    case constants.DEVICE_TABLE_FILTER_TAGS:
      filter = action.payload.filter

      return Object.assign({}, state, {
        filterTags: filter,
      })

    /**
     * The user has entered text into the search field, filter the list using the search term
     */
    case constants.DEVICE_TABLE_FILTER_TERM:
      filter = action.payload.filter

      return Object.assign({}, state, {
        filterTerm: filter,
      })

    case constants.MODAL_MAP_SPEED_GRAPH_SHOW:
      return { ...state, showSpeedGraph: action.payload }

    case constants.MODAL_GRID_COLUMNS_SHOW:
      return { ...state, showGridColumnsModal: true }

    case constants.MODAL_GRID_COLUMNS_HIDE:
      return { ...state, showGridColumnsModal: false }

    case constants.DEVICE_TABLE_CLEAR_FILTERS:
      return {
        ...state,
        filterModel: '',
        filterTags: [],
        filterTerm: '',
        filterGeofence: null,
      }

    default:
      return state
  }
}
