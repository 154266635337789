import * as React from 'react'
import { Dropdown } from 'react-bootstrap'
import { cloneDeep } from 'lodash'

import { LegendFilter } from '../../../types/map-state'

export interface ILegendSymbol {
  color: string
  label: string
  value: string
}

interface Props {
  symbols: ILegendSymbol[]
  legendFilter: LegendFilter
  open: boolean
  individualTrailerMap?: boolean
  toggleLegend: () => void
  updateLegendFilter: (filter: LegendFilter) => void
}

class MapLegendComponent extends React.Component<Props> {
  toggleOption = (option) => {
    let legendFilter = cloneDeep(this.props.legendFilter)
    const { value } = option
    if (!legendFilter || !legendFilter.activeStates) {
      legendFilter = {
        activeStates: [],
      }
    }
    const index = legendFilter.activeStates.indexOf(value)
    if (index >= 0) {
      legendFilter.activeStates.splice(index, 1)
    } else {
      legendFilter.activeStates.push(value)
    }
    this.props.updateLegendFilter(legendFilter)
  }

  render() {
    const activeStates = this.props.legendFilter?.activeStates ?? []

    return (
      <Dropdown
        alignRight
        show={this.props.open}
        onSelect={(eventKey, event) => event.preventDefault()}
        onToggle={() => null}
      >
        <Dropdown.Toggle onClick={this.props.toggleLegend} variant="light">
          Legend
        </Dropdown.Toggle>
        <Dropdown.Menu>
          {this.props.symbols.map((option) => {
            const isActive = activeStates.indexOf(option.value) > -1
            return (
              <Dropdown.Item
                eventKey={option.value}
                key={option.value}
                onClick={() => {
                  this.toggleOption(option)
                }}
                disabled={this.props.individualTrailerMap}
                active={isActive}
              >
                <img
                  src={`images/markers/${option.color}.png`}
                  width={30}
                  height={30}
                />{' '}
                <span>{option.label}</span>
              </Dropdown.Item>
            )
          })}
        </Dropdown.Menu>
      </Dropdown>
    )
  }
}

export const MapLegend = MapLegendComponent
export default MapLegend
