import React, { useState } from 'react'
import { useLocalApi } from 'client/lib/api'
import useSession from 'client/lib/hooks/useSession'
import { Alert, Pagination, Table } from 'react-bootstrap'
import { ManualPromotionEntry } from 'client/types'
import { ManualPromotionsForm } from './ManualPromotionsForm'
import { Loading } from '../common/Loading'
import Moment from 'react-moment'

import styles from './ManualPromotions.module.scss'
import { Button } from 'react-bootstrap'
import { HistoryDialog } from './HistoryDialog'
import { range } from 'lodash'

interface PromotionsHistoryResponse {
  data: ManualPromotionEntry[]
  meta: { page: number; pageSize: number; pageCount: number; count: number }
}

export const ManualPromotions: React.FC = () => {
  const { selectedGroupId, user } = useSession()

  const [selectedPromotionId, setSelectedPromotionId] = useState(0)
  const [page, setPage] = useState(1)

  const {
    data: history,
    mutate,
    error,
  } = useLocalApi<PromotionsHistoryResponse>(
    `/promotions/${selectedGroupId}/sent?page=${page}`
  )

  return (
    <div className={user.statsExpanded ? 'w-75' : 'w-50'}>
      <HistoryDialog
        show={selectedPromotionId > 0}
        promotionId={selectedPromotionId}
        onHide={() => setSelectedPromotionId(0)}
      />
      <ManualPromotionsForm
        onSend={() => {
          mutate()
        }}
      />
      <div className="mt-4">
        <h2 className={styles.subtitle}>
          Sent notifications{' '}
          {history?.data.length > 0 && `(${history.meta.count})`}
        </h2>
        {!history && !error && <Loading block />}
        {history?.data.length === 0 && (
          <Alert variant="info" className="text-center">
            List is empty
          </Alert>
        )}
        {history?.data.length > 0 && (
          <>
            <Table striped borderless responsive className="custom-table">
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Filters</th>
                  <th>Message</th>
                  <th className="text-nowrap">Sent notifications</th>
                </tr>
              </thead>
              <tbody>
                {history.data.map((entry, index) => {
                  return (
                    <tr key={index}>
                      <td className="text-nowrap">
                        <Moment format="M/DD/YY h:mm A">
                          {entry.createdAt}
                        </Moment>
                      </td>
                      <td>{entry.filters}</td>
                      <td>{entry.message}</td>
                      <td className="text-center">
                        {entry.sentCount > 0 ? (
                          <Button
                            variant="link"
                            onClick={() => setSelectedPromotionId(entry.id)}
                          >
                            {entry.sentCount}
                          </Button>
                        ) : (
                          0
                        )}
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </Table>
            <Pagination className="justify-content-end">
              {page > 1 && <Pagination.First onClick={() => setPage(1)} />}
              {range(1, history.meta.pageCount + 1).map((p) => (
                <Pagination.Item
                  key={p}
                  active={page === p}
                  onClick={() => setPage(p)}
                >
                  {p}
                </Pagination.Item>
              ))}
              {page < history.meta.pageCount && (
                <Pagination.Last
                  onClick={() => setPage(history.meta.pageCount)}
                />
              )}
            </Pagination>
          </>
        )}
      </div>
    </div>
  )
}
