import * as React from 'react'
import MapPath from '../../common/map/path'
import { AppState } from '../../../types/app-state'
import { connect } from 'react-redux'
import { LocationHistoryItem } from '../../../../common/types/local-api'
import { DeviceView } from 'client/types'

interface OwnProps {
  device: DeviceView
}

interface ReduxStateProps {
  showTravelLine: boolean
  locationHistory: LocationHistoryItem[]
}

interface Props extends OwnProps, ReduxStateProps {}

class DeviceTravelLineComponent extends React.PureComponent<Props> {
  render() {
    if (!this.props.locationHistory || !this.props.showTravelLine) {
      return null
    }
    return <MapPath path={this.getPath(this.props.locationHistory)} />
  }

  private getPath(
    locationHistory: LocationHistoryItem[]
  ): google.maps.LatLng[] {
    const path = []
    if (locationHistory) {
      locationHistory.forEach((locationHistoryItem: LocationHistoryItem) => {
        path.push(locationHistoryItem.position)
      })
    }
    return path
  }
}

function mapStateToProps(state: AppState, ownProps: OwnProps): ReduxStateProps {
  return {
    showTravelLine: state.map.showTravelLine,
    locationHistory: ownProps.device
      ? state.locationHistory.data[ownProps.device.id]
      : null,
  }
}

export const DeviceTravelLine = connect(mapStateToProps)(
  DeviceTravelLineComponent
)
export default DeviceTravelLine
