import { createAction } from 'redux-actions'

export const constants = {
  CONNECT: 'mqtt/CONNECT',
  ADD_DEVICES: 'mqtt/ADD_DEVICES',
  CONNECTED: 'mqtt/CONNECTED',
  DISCONNECTED: 'mqtt/DISCONNECTED',
  COMMAND: 'mqtt/COMMAND',
  REMOVE_ALL_DEVICES: 'mqtt/REMOVE_ALL_DEVICES',
}

// These actions are pertinent to only the MQTT middleware, no reducer or statespace
export const connected = createAction(constants.CONNECTED)
export const connect = createAction(constants.CONNECT)

export const command: (
  deviceId: string,
  command: string,
  meta: any
) => ReduxActions.Action<any> = createAction(
  constants.COMMAND,
  (deviceId, command, meta) => ({
    deviceId,
    command,
    meta,
  })
)

export const addDevices: (deviceIds: string[]) => ReduxActions.Action<any> =
  createAction(constants.ADD_DEVICES)
