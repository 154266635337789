import * as React from 'react'
import { Button, Col, Container, Modal, Row } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBan, faPlus, faSave } from '@fortawesome/free-solid-svg-icons'
import { Column } from 'client/types'
import { AvailableItems } from './available-items'
import { SelectedItems } from './selected-items'

interface Props {
  show: boolean
  availableColumns: Column[]
  selectedColumns: Column[]
  onCancel: () => void
  onSave: () => void
  onAdd: (item: Column) => void
  onRemove: (item: Column) => void
  onAddAllClick: () => void
  onRemoveAllClick: () => void
  onReorder: ({ from, to }: { from: number; to: number }) => void
}

export const ColumnSelectorModal: React.FC<Props> = (props) => {
  const {
    show,
    availableColumns,
    selectedColumns,
    onCancel,
    onSave,
    onAdd,
    onRemove,
    onAddAllClick,
    onRemoveAllClick,
    onReorder,
  } = props
  return (
    <Modal
      show={show}
      onHide={onCancel}
      size="lg"
      backdrop="static"
      centered
      className="column-select-modal-component device-modal-component"
    >
      <Modal.Header closeButton>
        <Modal.Title>Select columns</Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-4">
        <Container fluid className="p-0">
          <Row className="mb-4">
            <Col className="text-center">
              <Button
                variant="secondary"
                size="sm"
                onClick={() => onRemoveAllClick()}
              >
                <FontAwesomeIcon icon={faBan} /> Remove all
              </Button>
            </Col>
            <Col className="text-center">
              <Button
                variant="secondary"
                size="sm"
                onClick={() => onAddAllClick()}
              >
                <FontAwesomeIcon icon={faPlus} /> Add all
              </Button>
            </Col>
          </Row>
          <Row>
            <Col>
              <SelectedItems
                items={selectedColumns}
                removeItemHandler={onRemove}
                reorderHandler={(from, to) => onReorder({ from, to })}
              />
            </Col>
            <Col>
              <AvailableItems items={availableColumns} addItemHandler={onAdd} />
            </Col>
          </Row>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={() => onSave()}>
          <FontAwesomeIcon icon={faSave} /> Save
        </Button>
        <Button variant="secondary" onClick={() => onCancel()}>
          <FontAwesomeIcon icon={faBan} /> Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
