import * as React from 'react'
import { connect } from 'react-redux'
import { AppState, GroupMap, UserState } from '../../types/index'
import { TopPane } from './TopPane'
import * as mqtt from '../../store/actions/mqtt'
import { loadUser } from '../../store/actions/user'
import UserPrefContext from '../providers/user-preferences-context'
import OrgsContext from '../providers/organizations-context'
import { Route, Redirect, Switch } from 'react-router-dom'
import { Admin, DeviceView } from '..'
import { Home } from './Home'
import { Promotions } from '../promotions/Index'
import { FullMap } from '..'
import { Alert } from 'react-bootstrap'
import { Stats } from './Stats'
import { logout } from '../../store/actions/login'
import { Sidebar } from './Sidebar'
import { css } from 'client/lib/utilities'
import { Loading } from '../common/Loading'
import { GeofenceEditor } from '../geofences/GeofenceEditor'
import { FullGrid } from '../grid/full-grid'

import styles from './App.module.scss'

interface ReduxStateProps {
  loading: boolean
  loaded: boolean
  homePage: string | null
  user: UserState
  organizations: GroupMap
  selectedGroupId: string
}

interface ReduxDispatchProps {
  fetch: () => void
  logout: () => void
}

interface Props extends ReduxStateProps, ReduxDispatchProps {}

interface State {
  statsActive: boolean
  statsExpanded: boolean
}

export class App extends React.Component<Props, State> {
  state = {
    statsActive: true,
    statsExpanded: false,
  }

  componentDidMount() {
    if (this.props.user.jwt && !this.props.loaded && !this.props.loading) {
      this.props.fetch()
    }
  }

  componentDidUpdate() {
    if (this.props.user.jwt && !this.props.loaded && !this.props.loading) {
      this.props.fetch()
    }
  }

  render() {
    if (!this.props.user.jwt) {
      return <Redirect to="/login" />
    }

    if (this.props.loading) {
      return (
        <div style={{ paddingTop: '40vh' }}>
          <Loading block />
        </div>
      )
    }

    if (this.props.user.loadError) {
      return <Alert variant="danger">{this.props.user.loadError}</Alert>
    }

    if (!this.props.loaded) {
      return null
    }

    const defaultPageUrl = this.props.homePage.toLowerCase()

    return (
      <UserPrefContext.Provider
        value={{
          profile: this.props.user.profile,
          statsExpanded: this.state.statsExpanded,
        }}
      >
        <OrgsContext.Provider
          value={{
            organizations: this.props.organizations,
            selectedGroupId: this.props.selectedGroupId,
          }}
        >
          <TopPane />
          <section className={styles.container}>
            <div className={styles.sidebar}>
              <Sidebar
                onLogout={() => {
                  this.props.logout()
                }}
                statsActive={this.state.statsActive}
                onStatsClick={() => {
                  this.setState({ statsActive: !this.state.statsActive })
                }}
              />
            </div>
            <div
              className={css(
                styles.stats,
                this.state.statsActive && 'open',
                this.state.statsExpanded && 'expanded'
              )}
            >
              <Stats
                onExpandCollapseClick={() => {
                  this.setState({ statsExpanded: !this.state.statsExpanded })
                }}
                expanded={this.state.statsExpanded}
              />
            </div>
            <div className={`${styles.content}`}>
              <Switch>
                <Route path="/home" component={Home} />
                <Route path="/promotions" component={Promotions} />
                <Route path="/geofences" component={GeofenceEditor} />
                <Route path="/device/:deviceId" component={DeviceView} />
                <Route path="/grid">
                  <FullGrid />
                </Route>
                <Route path="/map" component={FullMap} />
                <Route path="/admin">
                  <Admin />
                </Route>
                <Redirect from="/" exact to={defaultPageUrl} />
              </Switch>
            </div>
          </section>
        </OrgsContext.Provider>
      </UserPrefContext.Provider>
    )
  }
}

function mapStateToProps(state: AppState): ReduxStateProps {
  return {
    loading:
      state.blueprint.devices.loading ||
      state.blueprint.organizations.loading ||
      state.user.loading,
    loaded:
      state.blueprint.devices.loaded &&
      state.blueprint.organizations.loaded &&
      state.user.loaded,
    homePage: '/home',
    user: state.user,
    organizations: state.blueprint.organizations.data,
    selectedGroupId: state.blueprint.organizations.current,
  }
}

function mapDispatchToProps(dispatch): ReduxDispatchProps {
  return {
    fetch: () => {
      dispatch(loadUser())
      dispatch(mqtt.connect())
    },
    logout: () => {
      dispatch(logout())
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(App)
