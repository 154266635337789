import { connect } from 'react-redux'
import * as React from 'react'
import { bindActionCreators } from 'redux'
import { AppState } from '../../types/index'
import { FormGroup, FormLabel, Alert, Button, Form } from 'react-bootstrap'
import { UserProfile } from '../../types/user-state'
import { editProfile, cleanStatusMessages } from '../../store/actions/user'
import { UserProperties } from 'rest-api'
import { faFloppyDisk } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import TimezoneSelect from 'react-timezone-select'

interface ReduxDispatchProps {
  actions: {
    editProfile: typeof editProfile
    cleanStatusMessages: typeof cleanStatusMessages
  }
}

interface ReduxStateProps {
  user?: UserProfile
  saving: boolean
  saved: boolean
}

function mapStateToProps(state: AppState): ReduxStateProps {
  return {
    user: state.user.profile,
    saving: state.user.saving,
    saved: state.user.saved,
  }
}

function mapDispatchToProps(dispatch): ReduxDispatchProps {
  const actions = { editProfile, cleanStatusMessages }
  return { actions: bindActionCreators(actions, dispatch) }
}

interface Props extends ReduxStateProps, ReduxDispatchProps {}

interface OwnState {
  fields: UserProperties
}

export class ProfileComponent extends React.Component<Props, OwnState> {
  state: OwnState = {
    fields: {
      ...this.props.user.properties,
    },
  }

  handleSubmit = (e) => {
    e.preventDefault()
    this.props.actions.editProfile(this.props.user.id, {
      properties: this.state.fields,
    })
  }

  componentWillUnmount() {
    this.props.actions.cleanStatusMessages()
  }

  render() {
    const fields = this.state.fields

    if (!fields) {
      return null
    }

    return (
      <div className="w-50">
        <Form onSubmit={this.handleSubmit}>
          {this.props.saved && (
            <Alert variant="success">Profile updated successfully.</Alert>
          )}
          <FormGroup>
            <FormLabel as="div" className="mb-2">
              Default timezone
            </FormLabel>
            <TimezoneSelect
              value={fields.defaultTimezone ?? ''}
              className="tz-selector"
              onChange={(timezone) => {
                this.setState((state) => {
                  state.fields.defaultTimezone = timezone.value
                  return state
                })
              }}
            />
            <p>(Used for alerts, reports and export)</p>
          </FormGroup>
          <div className="mt-2">
            <Button
              type="submit"
              variant="success"
              disabled={this.props.saving}
            >
              <FontAwesomeIcon icon={faFloppyDisk} /> Save
            </Button>
          </div>
        </Form>
      </div>
    )
  }
}
export const Profile = connect(
  mapStateToProps,
  mapDispatchToProps
)(ProfileComponent)
export default Profile
