import * as React from 'react'
import { Tab, Tabs } from 'react-bootstrap'
import { useLocation, useHistory, Route, Redirect } from 'react-router'
import { AutomaticPromotions } from './AutomaticPromotions'
import { CreateAutomaticPromotion } from './CreateAutomaticPromotion'
import { ManualPromotions } from './ManualPromotions'

import styles from './Index.module.scss'

export const Promotions: React.FC = () => {
  const location = useLocation()
  const history = useHistory()
  const activeKey = location.pathname.split('/').slice(0, 3).join('/')

  return (
    <div className="d-flex flex-column">
      <div className="d-flex flex-column mb-2">
        <div className="flex-grow-1 mb-4">
          <h1 style={{ fontSize: '20px' }}>Promotions</h1>
        </div>
        <Tabs activeKey={activeKey} onSelect={(k) => history.push(k)}>
          <Tab
            title="Automatic Notifications"
            eventKey="/promotions/auto"
            tabClassName={styles.tab}
          />
          <Tab
            title="Manual One - Time Notifications"
            eventKey="/promotions/manual"
            tabClassName={styles.tab}
          />
        </Tabs>
      </div>
      <div className="mt-4">
        <Route exact path="/promotions/auto">
          <AutomaticPromotions />
        </Route>
        <Route path="/promotions/auto/create">
          <CreateAutomaticPromotion />
        </Route>
        <Route exact path="/promotions/manual">
          <ManualPromotions />
        </Route>
        <Redirect from="/promotions" exact to="/promotions/auto" />
      </div>
    </div>
  )
}
