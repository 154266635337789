import { createSelector } from 'reselect'
import { sortBy } from 'lodash'
import { AppState } from '../../types/app-state'
import { IMarker, IShape } from '../../components/common/map/vanilla-map'
import { getDeviceIconColor } from '../../components/common/map/marker-icon'
import {
  SHAPE_ID_CIRCLE,
  SHAPE_ID_RECTANGLE,
  SHAPE_ID_POLYGON,
} from 'common/consts'
import { Geofence } from '../../lib/local-api'
import { DeviceView } from 'client/types'

export const markersFromDevices = createSelector(
  (devices: DeviceView[]) => devices,
  (devices) => {
    const markers: IMarker[] = devices
      .map((device) => {
        const imageColor = getDeviceIconColor(device)
        const iconUrl = `images/markers/${imageColor}.png`
        const overlayText =
          device.EmailAddress?.toLowerCase() ?? 'No Email Address'
        return {
          id: device.id,
          icon: {
            url: iconUrl,
            width: 40,
            height: 40,
          },
          position: {
            lat: device.latitude,
            lng: device.longitude,
          },
          overlayText,
          device,
        }
      })
      .sort((a, b) => {
        if (a.overlayText > b.overlayText) return 1
        else if (a.overlayText < b.overlayText) return -1
        return 0
      })
    return markers
  }
)

export const shapesFromGeofences = createSelector(
  (geofences: Geofence[]) => geofences,
  (geofences) => {
    return geofences.map((geofence) => {
      const id = String(geofence.id)
      const name = geofence.name
      switch (geofence.shapeId) {
        case SHAPE_ID_CIRCLE: {
          const center = {
            lat: geofence.circle.x,
            lng: geofence.circle.y,
          }
          return {
            id,
            name,
            shape: {
              type: 'circle',
              radius: geofence.circle.radius,
              ...center,
            },
          }
        }
        case SHAPE_ID_RECTANGLE: {
          const bounds = {
            north: geofence.bounds.x1,
            south: geofence.bounds.x2,
            east: geofence.bounds.y1,
            west: geofence.bounds.y2,
          }
          return {
            id,
            name,
            shape: {
              type: 'rectangle',
              ...bounds,
            },
          }
        }
        case SHAPE_ID_POLYGON:
          return {
            id,
            name,
            shape: {
              type: 'polygon',
              points: geofence.polygon.map((item) => ({
                lat: item.x,
                lng: item.y,
              })),
            },
          }
      }
    }) as IShape[]
  }
)

export const sortedGeofencesArray = createSelector(
  (state: AppState): Record<string, Geofence> =>
    (state.geofences && state.geofences.data) || {},
  (data) => {
    return sortBy(Object.values(data), [
      (geofence: Geofence) => !geofence.isPrimary,
      (geofence: Geofence) => geofence.name.toLowerCase(),
    ])
  }
)
