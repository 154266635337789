import * as React from 'react'
import Toggle from 'react-toggle'
import { bindActionCreators, Dispatch } from 'redux'
import { connect } from 'react-redux'

import { showBreadcrumbs } from '../../../store/actions/map'
import { AppState } from '../../../types/app-state'

interface ReduxStateProps {
  showBreadcrumbs
}

interface ReduxActions {
  showBreadcrumbs: (show: boolean) => void
}

interface ReduxDispatchProps {
  actions: ReduxActions
}

interface Props extends ReduxStateProps, ReduxDispatchProps {}

export const BreadcrumbsToggleComponent = (props: Props) => {
  function handleToggleBreadcrumbs(
    e: React.SyntheticEvent<HTMLInputElement>
  ): void {
    const checked = !!e.currentTarget.checked
    props.actions.showBreadcrumbs(checked)
  }

  return (
    <div className="toggle-wrapper">
      <Toggle
        id="map-show-breadcrumbs"
        icons={false}
        checked={props.showBreadcrumbs}
        onChange={handleToggleBreadcrumbs}
      />
      <span className="toggle-label">Breadcrumbs</span>
    </div>
  )
}

function mapStateToProps(state: AppState): ReduxStateProps {
  return {
    showBreadcrumbs: state.map.showBreadcrumbs,
  }
}

function mapDispatchToProps(dispatch: Dispatch): ReduxDispatchProps {
  const actions = {
    showBreadcrumbs,
  }
  return {
    actions: bindActionCreators(actions, dispatch),
  }
}

export const BreadcrumbsToggle = connect(
  mapStateToProps,
  mapDispatchToProps
)(BreadcrumbsToggleComponent)
export default BreadcrumbsToggle
