import { connect } from 'react-redux'
import * as React from 'react'
import { bindActionCreators } from 'redux'
import { AppState } from 'client/types/index'
import { Geofence } from 'common/types/local-api'
import { GeofenceItem } from './GeofenceItem'
import { select, edit } from 'client/store/actions/page-admin-geofences'
import { deleteGeofence } from 'client/store/actions/geofences'
import { getGeofencesArray } from 'client/store/reducers/geofences'
import { Alert, Table } from 'react-bootstrap'
import { Loading } from '../common/Loading'

interface ReduxActions {
  select: any
  edit: any
  deleteGeofence: any
}

interface ReduxDispatchProps {
  actions: ReduxActions
}

interface ReduxStateProps {
  geofences: Geofence[]
  loaded: boolean
  loading: boolean
  geofence: Geofence
}

function mapStateToProps(state: AppState): ReduxStateProps {
  return {
    geofences: getGeofencesArray(state),
    loaded: state.geofences.loaded,
    loading: state.geofences.loading,
    geofence: state.pageAdminGeofences.geofence,
  }
}

function mapDispatchToProps(dispatch): ReduxDispatchProps {
  const actions = { select, edit, deleteGeofence }
  return { actions: bindActionCreators(actions, dispatch) }
}

interface Props extends ReduxStateProps, ReduxDispatchProps {}

export class GeofenceControlPanelComponent extends React.Component<Props> {
  constructor(props) {
    super(props)
    this.handleControlClick = this.handleControlClick.bind(this)
    this.handleControlEdit = this.handleControlEdit.bind(this)
    this.handleControlDelete = this.handleControlDelete.bind(this)
  }

  handleControlClick(geofence: Geofence): void {
    this.props.actions.select(geofence)
  }

  handleControlEdit(geofence: Geofence): void {
    this.props.actions.edit(geofence)
  }

  handleControlDelete(geofence: Geofence): void {
    this.props.actions.deleteGeofence(geofence)
  }

  render() {
    const loading = this.props.loading

    return (
      <div>
        {loading && <Loading block />}
        {!loading && this.props.geofences.length === 0 && (
          <Alert variant="info" className="text-center">
            There are no geofences configured currently.
          </Alert>
        )}
        {this.props.geofences.length > 0 && (
          <Table striped borderless hover responsive className="custom-table">
            <thead>
              <tr>
                <th style={{ width: '90%' }}>Name</th>
                <th className="text-center">Actions</th>
              </tr>
            </thead>
            <tbody>
              {this.props.geofences.map((geofence, index) => {
                return (
                  <GeofenceItem
                    key={index}
                    geofence={geofence}
                    selected={
                      this.props.geofence &&
                      geofence.id === this.props.geofence.id
                    }
                    onClick={this.handleControlClick}
                    onEdit={this.handleControlEdit}
                    onDelete={this.handleControlDelete}
                  />
                )
              })}
            </tbody>
          </Table>
        )}
      </div>
    )
  }
}
export const GeofenceControlPanel = connect(
  mapStateToProps,
  mapDispatchToProps
)(GeofenceControlPanelComponent)
export default GeofenceControlPanel
