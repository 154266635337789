import { createAction } from 'redux-actions'
import { omit } from 'lodash'

import { constants } from '../reducers/page-admin-devices'
import * as deviceActions from '../blueprint/devices/actions'
import { AppState, PartialDeviceView } from '../../types'
import api from '../../api'
import { localApiError } from './local-api'
import { organizationSaveEnd } from './page-admin-organizations'
import { upsert as upsertGroup } from '../blueprint/groups/actions'

export const editDevice = createAction(constants.DEVICE_EDIT)

export const updateDevice = createAction(constants.DEVICE_UPDATE)

const startSaveDeviceAction = createAction(constants.DEVICE_SAVE_START)
const endSaveDeviceAction = createAction(
  constants.DEVICE_SAVE_END,
  (payload) => payload
)
const errorSaveDeviceAction = createAction(
  constants.DEVICE_SAVE_ERROR,
  (payload) => payload
)

export function saveDevice(id: string) {
  return async (dispatch, getState: () => AppState) => {
    dispatch(startSaveDeviceAction())

    const state: AppState = getState()
    const device = state.blueprint.devices.data[id]
    const editedDevice: PartialDeviceView = {
      ...state.pageAdminDevices.editedDevices[id],
    }
    try {
      // if only id field left don't update device
      if (Object.keys(editedDevice).length > 1) {
        if (editedDevice.parentGroupId && editedDevice.groupId) {
          // update parent group of the user wrapper group
          const updateGroupResponse =
            await api.blueprint.organizations.updateGroup(
              editedDevice.groupId,
              {
                parentId: editedDevice.parentGroupId,
              }
            )
          dispatch(upsertGroup([updateGroupResponse.group]))
          dispatch(organizationSaveEnd())
        }
        const updateDeviceResponse = await api.blueprint.devices.updateDevice(
          omit(editedDevice, 'groupId', 'parentGroupId')
        )
        dispatch(deviceActions.upsert([updateDeviceResponse.device]))
        dispatch(endSaveDeviceAction(updateDeviceResponse.device))
      } else {
        dispatch(endSaveDeviceAction(device))
      }
    } catch (err) {
      dispatch(localApiError(err))
      dispatch(errorSaveDeviceAction(err.message))
    }
  }
}

export const cancelDevice = createAction(constants.DEVICE_CANCEL)
