import { Promotion } from 'client/types'
import { capitalize } from 'lodash'
import * as React from 'react'
import { Button } from 'react-bootstrap'
import { DeleteButton } from '../common/DeleteButton'

interface Props {
  data: Promotion
  onSelect: () => void
  onDelete: (id: number) => void
  selected: boolean
}

export const AutomaticPromotionListItem: React.FC<Props> = ({
  data,
  onSelect,
  onDelete,
}) => {
  return (
    <tr>
      <td>{capitalize(data.geofenceTriggerType)}</td>
      <td>{data.geofenceName ?? '-'}</td>
      <td>{data.message}</td>
      <td>
        {data.sentCount > 0 ? (
          <Button variant="link" onClick={() => onSelect()}>
            {data.sentCount}
          </Button>
        ) : (
          0
        )}
      </td>
      <td className="align-middle text-nowrap">
        <DeleteButton
          confirmMessage="Are you sure you want to delete this notification?"
          title="Delete notification?"
          onClick={() => {
            onDelete(data.id)
          }}
        />
      </td>
    </tr>
  )
}
