import * as React from 'react'

import DeviceGMap, { DeviceMapComponent } from '../../common/map/device-gmap'
import MapDeviceMarker from '../../common/map/device-marker'
import { Metrics } from '../../common/metrics'
import DeviceTravelLine from './device-travel-line'
import DeviceBreadcrumbs from './device-breadcrumbs'
import { DeviceView } from 'client/types'

interface Props {
  device: DeviceView
  height: number
  timezone: string
  userMetrics: Metrics
  individualTrailerMap?: boolean
  showInfo?: boolean
  center: google.maps.LatLng | google.maps.LatLngLiteral
  onMarkerClick?: () => void
  onZoomChanged?: (zoom: number) => void
}

export default class DeviceMapContainer extends React.PureComponent<Props> {
  private deviceMapRef = React.createRef<DeviceMapComponent>()

  fitBounds(bounds: google.maps.LatLngBounds) {
    return this.deviceMapRef?.current.resizeMap(bounds)
  }

  render() {
    const { device } = this.props

    if (!device) {
      return null
    }

    const trackerMapProps = {
      deviceId: device.id,
      center: this.props.center,
      height: this.props.height,
      onZoomChanged: this.props.onZoomChanged,
      controlSize: 24,
      streetView: null,
      streetViewControl: false,
    }

    return (
      <DeviceGMap ref={this.deviceMapRef} {...trackerMapProps}>
        <MapDeviceMarker
          device={device}
          showInfo={this.props.showInfo}
          onClick={this.props.onMarkerClick}
          timezone={this.props.timezone}
          userMetrics={this.props.userMetrics}
          individualTrailerMap={this.props.individualTrailerMap}
        />
        <DeviceTravelLine device={device} />
        <DeviceBreadcrumbs
          device={device}
          userMetrics={this.props.userMetrics}
        />
      </DeviceGMap>
    )
  }
}
