import * as React from 'react'
import { bindActionCreators, Dispatch } from 'redux'
import { connect } from 'react-redux'

import { AppState } from '../../../types/app-state'
import { TimeSelect } from '../../common/time-select'
import { setTimeFrame } from '../../../store/actions/location-history'

interface ReduxStateProps {
  timeframe: string
}

interface ReduxActions {
  setTimeFrame: (timeframe: string) => void
}

interface ReduxDispatchProps {
  actions: ReduxActions
}

interface Props extends ReduxStateProps, ReduxDispatchProps {}

export const TimeframeSelectComponent = (props: Props) => {
  function handleTimeframeChange(value: string) {
    if (value) {
      props.actions.setTimeFrame(value)
    }
  }

  return <TimeSelect value={props.timeframe} onChange={handleTimeframeChange} />
}

function mapStateToProps(state: AppState): ReduxStateProps {
  return {
    timeframe: state.locationHistory.timeframe,
  }
}

function mapDispatchToProps(dispatch: Dispatch): ReduxDispatchProps {
  return {
    actions: bindActionCreators({ setTimeFrame }, dispatch),
  }
}

export const TimeframeSelect = connect(
  mapStateToProps,
  mapDispatchToProps
)(TimeframeSelectComponent)
export default TimeframeSelect
