import React from 'react'
import { faExternalLink } from '@fortawesome/free-solid-svg-icons'
import { useLocalApiFetch } from 'client/lib/api'
import { Alert, Form } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Loading } from '../common/Loading'

export const FileUpload: React.FC = () => {
  const [shareableLink, setShareableLink] = React.useState<string>('')
  const [uploading, setUploading] = React.useState(false)
  const [uploadError, setUploadError] = React.useState<string>('')

  const { apiPostForm } = useLocalApiFetch()

  const uploadFile = async (file: File) => {
    const body = new FormData()
    body.append('file', file, file.name)
    setUploading(true)
    try {
      const result = await apiPostForm('/promotions/upload-file', body)
      setShareableLink(result.url)
    } catch (err) {
      setUploadError(`Error uploading file: ${err.message}`)
    } finally {
      setUploading(false)
    }
  }

  return (
    <>
      <Form.Group className="mb-3">
        <Form.Label>Create shareable link:</Form.Label>
        <Form.File
          className="w-100"
          id="shareable-link"
          label="Choose file..."
          custom
          onChange={(e) =>
            e.target.files.length && uploadFile(e.target.files[0])
          }
        />
        <Form.Text>
          You can use the generated file link in the message box above.
        </Form.Text>
      </Form.Group>
      {uploading && <Loading />}
      {uploadError && <Alert variant="danger">{uploadError}</Alert>}
      {!uploading && shareableLink && (
        <>
          <Form.Group className="mb-3">
            <Form.Label>Link to file:</Form.Label>
            <Form.Control
              readOnly
              value={shareableLink}
              onFocus={(e) => e.target.select()}
            />
          </Form.Group>
          <Form.Text>
            <a href={shareableLink} target="_blank" rel="noreferrer">
              Open <FontAwesomeIcon icon={faExternalLink} />
            </a>
          </Form.Text>
        </>
      )}
    </>
  )
}
