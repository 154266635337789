import { constants } from './reducers'
import * as geofences from 'client/store/actions/geofences'
import * as devices from '../devices/actions'
import * as users from '../users/actions'
import * as map from 'client/store/actions/map'
import { editProfile } from '../../actions/user'
import * as consts from 'common/consts'
import { Group, UserProperties } from 'rest-api'
import { AppState } from 'client/types'

export function upsert(orgs: Group[]) {
  return {
    type: constants.UPSERT,
    payload: orgs,
  }
}

export function remove(orgId: string) {
  return {
    type: constants.REMOVE,
    payload: orgId,
  }
}

export function initialState() {
  return {
    type: constants.INITIAL_STATE,
  }
}

export function loadReset() {
  return {
    type: constants.LOAD_RESET,
  }
}

export function loadingStart() {
  return {
    type: constants.LOADING_START,
  }
}

export function loadingEnd() {
  return {
    type: constants.LOADING_END,
  }
}

export function setError(message: string) {
  return {
    type: constants.ERROR,
    payload: message,
  }
}

export function setFromArray(organizations: Group[]) {
  return {
    type: constants.SET,
    payload:
      organizations && organizations.length > 0 ? organizations[0].id : null,
  }
}

export function setGroup(organizationId: string) {
  return {
    type: constants.SET,
    payload: organizationId,
  }
}

/**
 * Clear out all of the state so we can reload it
 */
export function changeGroup(groupId: string) {
  return async (dispatch, getState: () => AppState) => {
    dispatch(setGroup(groupId))

    const newValues = {} as UserProperties
    newValues[consts.USER_FIELDS.SELECTED_FLEET] = groupId
    dispatch(editProfile(getState().user.profile.id, { properties: newValues }))
    dispatch(map.clearFilters())
    dispatch(geofences.clear())
    dispatch(users.clear())
    dispatch(devices.clearAndLoad(groupId))
  }
}
