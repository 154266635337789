import * as React from 'react'
import { Alert, Button } from 'react-bootstrap'

interface IConfirmProps {
  onClick: any
  shown: boolean
}

class NewDataAlertComponent extends React.Component<IConfirmProps, any> {
  render() {
    if (!this.props.shown) {
      return null
    }

    return (
      <Alert className="new-data-alert-component" variant="warning">
        <p>
          Updated information available.
          <Button variant="link" onClick={this.props.onClick}>
            Click here to refresh.
          </Button>
        </p>
      </Alert>
    )
  }
}

export const NewDataAlert = NewDataAlertComponent
export default NewDataAlert
