import * as ReduxActions from 'redux-actions'
import { isEmpty, isNull, omit } from 'lodash'

import { AppState, DeviceView } from '../../../types'
import { DevicesState } from '../../../types/devices-state'
import * as consts from 'common/consts'
import { Device } from 'rest-api'
import { DateTime } from 'luxon'

type Action = ReduxActions.Action<string> | ReduxActions.Action<Device[]>
type Reducer = (state: DevicesState, action: Action) => DevicesState

const nullDeviceField = (device: DeviceView, field: string) => {
  if (!isNull(device[field])) {
    device[field] = null
  }
}

/**
 * Reducer function that takes a device, transform it and adds it to the device IdMap.
 */
const transformDevice = (idMap: Record<string, DeviceView>, device: Device) => {
  const deviceView = getDeviceView(device)

  const isFuelSensorInstalled =
    deviceView[consts.DEVICE_FIELDS.FUEL_SENSOR_INSTALLED] === 'true'
  const isPower = deviceView[consts.DEVICE_FIELDS.POWER] === consts.ON
  const isDoorLockInstalled =
    deviceView[consts.DEVICE_FIELDS.DOOR_LOCK_INSTALLED] === 'true'
  const isTpmsInstalled =
    deviceView[consts.DEVICE_FIELDS.TPMS_INSTALLED] === 'true'
  const isSpareSensorInstalled =
    deviceView[consts.DEVICE_FIELDS.SPARE_SENSOR_INSTALLED] === 'true'
  const zoneCount = deviceView[consts.DEVICE_FIELDS.ZONE_COUNT]
  const doorCount = deviceView[consts.DEVICE_FIELDS.DOORS_INSTALLED]
  const isIbox = deviceView[consts.DEVICE_FIELDS.IBOX_INSTALLED] === 'true'
  const isDriving =
    deviceView[consts.DEVICE_FIELDS.MOVEMENT] ===
      consts.MOVEMENT.startOfDrive ||
    deviceView[consts.DEVICE_FIELDS.MOVEMENT] === consts.MOVEMENT.driving ||
    deviceView[consts.DEVICE_FIELDS.MOVEMENT] === consts.MOVEMENT.endOfDrive

  // AR-347
  // If trailer = iBox & Power = Off, then Operation, Ambient Temp,
  // and All Zone Mode/Setpoint/Temp columns that unit is configured for will show "---" (if single zone, then zone 2/3 columns show blank)
  // If trailer = non-iBox & Power = Off, then Operation, Ambient Temp, and All Zone Mode/Setpoint/Temp columns will show blank
  // If trailer = iBox & power = On, then Operation, Ambient Temp, and All Zone Mode/Setpoint/Temp columns that unit is
  // configured for will show latest value
  // If trailer = non-iBox & Power = On, then Operation, Zone 1 Setpoint, and all Zone 2/3 columns will show blank,
  // and Ambient, Zone 1 Temp, and Zone 1 Mode will show latest value
  if (!isPower) {
    nullDeviceField(deviceView, consts.DEVICE_FIELDS.OPERATION)
    nullDeviceField(deviceView, consts.DEVICE_FIELDS.AMBIENT_TEMP)
    nullDeviceField(deviceView, consts.DEVICE_FIELDS.ZONE_1_MODE)
    nullDeviceField(deviceView, consts.DEVICE_FIELDS.ZONE_2_MODE)
    nullDeviceField(deviceView, consts.DEVICE_FIELDS.ZONE_3_MODE)
    nullDeviceField(deviceView, consts.DEVICE_FIELDS.ZONE_1_SPT)
    nullDeviceField(deviceView, consts.DEVICE_FIELDS.ZONE_2_SPT)
    nullDeviceField(deviceView, consts.DEVICE_FIELDS.ZONE_3_SPT)
    nullDeviceField(deviceView, consts.DEVICE_FIELDS.ZONE_1_DOOR)
    nullDeviceField(deviceView, consts.DEVICE_FIELDS.ZONE_2_DOOR)
    nullDeviceField(deviceView, consts.DEVICE_FIELDS.ZONE_3_DOOR)
    nullDeviceField(deviceView, consts.DEVICE_FIELDS.ZONE_1_TEMP)
    nullDeviceField(deviceView, consts.DEVICE_FIELDS.ZONE_2_TEMP)
    nullDeviceField(deviceView, consts.DEVICE_FIELDS.ZONE_3_TEMP)
  }

  // If trailer is configured for Fuel Sensor, then Fuel column will show latest value, otherwise show blank
  if (!isFuelSensorInstalled) {
    nullDeviceField(deviceView, consts.DEVICE_FIELDS.FUEL_LEVEL)
  }

  if (!isSpareSensorInstalled) {
    nullDeviceField(deviceView, consts.DEVICE_FIELDS.SPARE_1_TEMP)
    nullDeviceField(deviceView, consts.DEVICE_FIELDS.SPARE_2_TEMP)
    nullDeviceField(deviceView, consts.DEVICE_FIELDS.SPARE_3_TEMP)
    nullDeviceField(deviceView, consts.DEVICE_FIELDS.SPARE_4_TEMP)
    nullDeviceField(deviceView, consts.DEVICE_FIELDS.SPARE_5_TEMP)
    nullDeviceField(deviceView, consts.DEVICE_FIELDS.SPARE_6_TEMP)
  }

  // Empty out any values on the zone if there is less than number of zones
  if (zoneCount < 3) {
    nullDeviceField(deviceView, consts.DEVICE_FIELDS.ZONE_3_MODE)
    nullDeviceField(deviceView, consts.DEVICE_FIELDS.ZONE_3_SPT)
    nullDeviceField(deviceView, consts.DEVICE_FIELDS.ZONE_3_DOOR)
    nullDeviceField(deviceView, consts.DEVICE_FIELDS.ZONE_3_TEMP)
  }
  if (zoneCount < 2) {
    nullDeviceField(deviceView, consts.DEVICE_FIELDS.ZONE_2_MODE)
    nullDeviceField(deviceView, consts.DEVICE_FIELDS.ZONE_2_SPT)
    nullDeviceField(deviceView, consts.DEVICE_FIELDS.ZONE_2_DOOR)
    nullDeviceField(deviceView, consts.DEVICE_FIELDS.ZONE_2_TEMP)
  }
  if (zoneCount < 1) {
    nullDeviceField(deviceView, consts.DEVICE_FIELDS.ZONE_1_MODE)
    nullDeviceField(deviceView, consts.DEVICE_FIELDS.ZONE_1_SPT)
    nullDeviceField(deviceView, consts.DEVICE_FIELDS.ZONE_1_DOOR)
    nullDeviceField(deviceView, consts.DEVICE_FIELDS.ZONE_1_TEMP)
  }

  // If trailer is configured for Door Lock, then Door Lock column will show latest value, otherwise blank
  if (!isDoorLockInstalled) {
    nullDeviceField(deviceView, consts.DEVICE_FIELDS.DOOR_LOCK)
  }

  if (doorCount < 4) {
    nullDeviceField(deviceView, consts.DEVICE_FIELDS.DOOR_4)
  }

  if (doorCount < 3) {
    nullDeviceField(deviceView, consts.DEVICE_FIELDS.DOOR_3)
  }

  if (doorCount < 2) {
    nullDeviceField(deviceView, consts.DEVICE_FIELDS.DOOR_2)
  }

  if (!isTpmsInstalled || !isIbox) {
    nullDeviceField(deviceView, consts.DEVICE_FIELDS.TIRE_1_PRESSURE)
    nullDeviceField(deviceView, consts.DEVICE_FIELDS.TIRE_1_STATUS)
    nullDeviceField(deviceView, consts.DEVICE_FIELDS.TIRE_1_TEMP)
    nullDeviceField(deviceView, consts.DEVICE_FIELDS.TIRE_2_PRESSURE)
    nullDeviceField(deviceView, consts.DEVICE_FIELDS.TIRE_2_STATUS)
    nullDeviceField(deviceView, consts.DEVICE_FIELDS.TIRE_2_TEMP)
    nullDeviceField(deviceView, consts.DEVICE_FIELDS.TIRE_3_PRESSURE)
    nullDeviceField(deviceView, consts.DEVICE_FIELDS.TIRE_3_STATUS)
    nullDeviceField(deviceView, consts.DEVICE_FIELDS.TIRE_3_TEMP)
    nullDeviceField(deviceView, consts.DEVICE_FIELDS.TIRE_4_PRESSURE)
    nullDeviceField(deviceView, consts.DEVICE_FIELDS.TIRE_4_STATUS)
    nullDeviceField(deviceView, consts.DEVICE_FIELDS.TIRE_4_TEMP)
  }

  if (isDriving) {
    nullDeviceField(deviceView, consts.DEVICE_FIELDS.IDLE)
  }

  idMap[device.id] = deviceView

  return idMap
}

const initialState: DevicesState = {
  loading: false,
  loaded: false,
  error: '',
  data: {},
  geofences: null,
  models: null,
  tags: [],
  loadingChild: false,
}

export const constants = {
  UPSERT: 'devices/UPSERT',
  REMOVE: 'devices/REMOVE',
  INITIAL_STATE: 'devices/INITIAL_STATE',
  LOADING_START: 'devices/LOADING_START',
  LOADING_ERROR: 'devices/LOADING_ERROR',
  LOADING_CHILD_START: 'devices/LOADING_CHILD_START',
  LOADING_CHILD_END: 'devices/LOADING_CHILD_END',
  LOADING_END: 'devices/LOADING_END',
  LOAD_RESET: 'devices/LOAD_RESET',
  ERROR: 'devices/ERROR',
  MODELS_LOAD: 'devices/LOAD_MODELS',
  GEOFENCES_LOAD: 'devices/LOAD_GEOFENCES',
  TAGS_LOAD: 'devices/LOAD_TAGS',
  CLEAR: 'devices/CLEAR',
}

const upsert: Reducer = (state, action) => {
  const devices = action.payload as Device[]
  const newDevices = devices.reduce(transformDevice, {})
  const oldDevices = state.data
  const data = { ...oldDevices, ...newDevices }
  return { ...state, data }
}

const remove: Reducer = (state, action) => {
  const deviceId = action.payload as string
  const devices = { ...state.data }
  delete devices[deviceId]
  return { ...state, data: devices }
}

const loadingStart: Reducer = (state, action) => {
  // Persists existing state, sets loading true, clears errors
  return { ...state, loading: true, loaded: false, error: '' }
}

const loadingFail: Reducer = (state, action) => {
  // Persists existing state, sets loading true, clears errors
  return {
    ...state,
    loading: false,
    loaded: true,
    error: action.payload as string,
  }
}

const loadingChildStart: Reducer = (state, action) => {
  return { ...state, loadingChild: true }
}

const loadingChildEnd: Reducer = (state, action) => {
  return { ...state, loadingChild: false }
}

const loadingEnd: Reducer = (state, action) => {
  const devices = getDeviceArray(state)
  const models: string[] = [
    ...Array.from(new Set(devices.map((device) => device.Model))),
  ].filter((model) => !isEmpty(model))
  const geofences: string[] = [
    ...Array.from(
      // FIXME: property Geofence does not exist
      new Set(devices.map((device) => (device as any).Geofence))
    ),
  ].filter((geofence) => !isEmpty(geofence))

  // tags
  let allTags: string[] = []
  devices.forEach((device) => {
    const t = device.Tags ? device.Tags.split(',') : []
    allTags = allTags.concat(t)
  })

  // Have to cast set to array for typescript
  let tags: string[] = [...Array.from(new Set(allTags))].filter(
    (tag) => !isEmpty(tag)
  )
  tags = tags.sort((tagA, tagB) => {
    return tagA.trim().toLowerCase().localeCompare(tagB.trim().toLowerCase())
  })

  return Object.assign({}, state, {
    loading: false,
    loaded: true,
    geofences: geofences.map((geofence) => geofence.toString()),
    tags,
    models: models.map((model) => model.toString()),
  })
}

const loadingError: Reducer = (state, action) => {
  const error = action.payload as string
  return { ...state, error }
}

const clear: Reducer = (state, action) => {
  return { ...state, loading: true, loaded: false, data: {} }
}

export const reducer: Reducer = (state = initialState, action) => {
  const actionMap = {
    [constants.UPSERT]: upsert,
    [constants.REMOVE]: remove,
    [constants.LOADING_CHILD_START]: loadingChildStart,
    [constants.LOADING_CHILD_END]: loadingChildEnd,
    [constants.LOADING_START]: loadingStart,
    [constants.LOADING_END]: loadingEnd,
    [constants.LOADING_ERROR]: loadingFail,
    [constants.CLEAR]: clear,
    [constants.ERROR]: loadingError,
    [constants.INITIAL_STATE]: () => initialState,
  }

  if (actionMap[action.type]) {
    return actionMap[action.type](state, action)
  }
  return state
}

export function getDeviceFromActiveOrgOnly(state: AppState, deviceId) {
  return state.blueprint.devices.data[deviceId]
}

export function getDevices(state: AppState) {
  const devices = state.blueprint.devices.data
    ? Object.keys(state.blueprint.devices.data).map(
        (key) => state.blueprint.devices.data[key]
      )
    : []
  return devices
}

export function getDeviceArray(state: DevicesState) {
  return state.data ? Object.values(state.data) : []
}

export function getDeviceView(device: Device): DeviceView {
  const deviceView: DeviceView = {
    ...device.properties,
    ...omit(device, 'properties'),
    online:
      device.connected ||
      (device?.properties?.LastCommTime
        ? Math.abs(
            DateTime.fromISO(device.properties.LastCommTime)
              .diffNow()
              .as('hours')
          ) <= 48
        : false),
  }
  return deviceView
}
