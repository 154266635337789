import { faXmark } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { AppState } from 'client/types'
import React, { useMemo } from 'react'
import { Dropdown, DropdownButtonProps } from 'react-bootstrap'
import { DropdownButton } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import styles from './geofence-selector.module.scss'

interface Props extends Partial<DropdownButtonProps> {
  value: string
  onSelect: (value: string) => void
}

export const GeofenceSelector: React.FC<Props> = ({
  value,
  onSelect,
  ...rest
}) => {
  const geofences = useSelector<AppState>(
    (state) => state.geofences?.data ?? {}
  )
  const geofenceArray = useMemo(() => Object.values(geofences), [geofences])

  const titleNode = value ? (
    <>
      <span className={styles.label}>Geofence: {value}</span>
      <FontAwesomeIcon
        icon={faXmark}
        title="Remove"
        onClick={(e) => {
          onSelect(value)
          e.stopPropagation()
        }}
        className={styles.closeIcon}
      />
    </>
  ) : (
    'Geofence'
  )

  return (
    <DropdownButton
      title={titleNode}
      variant="light"
      onSelect={onSelect}
      {...rest}
    >
      {geofenceArray.length === 0 && (
        <Dropdown.Item eventKey="" disabled>
          No geofences found
        </Dropdown.Item>
      )}
      {geofenceArray.map((entry) => {
        return (
          <Dropdown.Item
            eventKey={entry.name}
            key={entry.id}
            active={entry.name === value}
          >
            {entry.name}
          </Dropdown.Item>
        )
      })}
    </DropdownButton>
  )
}
