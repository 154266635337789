export const constants = {
  UPSERT: 'users/UPSERT',
  REMOVE: 'users/REMOVE',
  INITIAL_STATE: 'users/INITIAL_STATE',
  LOADING_START: 'users/LOADING_START',
  LOADING_END: 'users/LOADING_END',
  LOAD_RESET: 'users/LOAD_RESET',
  LOAD_ERROR: 'users/LOAD_ERROR',
  CLEAR: 'users/CLEAR',
}
