import * as React from 'react'
import Toggle from 'react-toggle'
import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'

import { showTravelLine } from '../../../store/actions/map'
import { AppState } from '../../../types/app-state'

interface ReduxStateProps {
  showTravelLine
}

interface ReduxActions {
  showTravelLine: (show: boolean) => void
}

interface ReduxDispatchProps {
  actions: ReduxActions
}

interface Props extends ReduxStateProps, ReduxDispatchProps {}

export const TravelLineToggleComponent = (props: Props) => {
  function handleToggleTravelLine(
    e: React.SyntheticEvent<HTMLInputElement>
  ): void {
    const checked = !!e.currentTarget.checked
    props.actions.showTravelLine(checked)
  }

  return (
    <div className="toggle-wrapper">
      <Toggle
        id="map-show-travel-line"
        icons={false}
        checked={props.showTravelLine}
        onChange={handleToggleTravelLine}
      />
      <span className="toggle-label">Trace line</span>
    </div>
  )
}

function mapStateToProps(state: AppState): ReduxStateProps {
  return {
    showTravelLine: state.map.showTravelLine,
  }
}

function mapDispatchToProps(dispatch: Dispatch): ReduxDispatchProps {
  const actions = {
    showTravelLine,
  }
  return {
    actions: bindActionCreators(actions, dispatch),
  }
}

export const TravelLineToggle = connect(
  mapStateToProps,
  mapDispatchToProps
)(TravelLineToggleComponent)
export default TravelLineToggle
