import * as React from 'react'
import { cloneDeep } from 'lodash'
import { bindActionCreators, Dispatch } from 'redux'
import { connect } from 'react-redux'
import Formsy from 'formsy-react'
import { Modal, Button, Alert, FormGroup, FormLabel } from 'react-bootstrap'

import { AppState } from '../../../types'
import {
  saveOrganization,
  cleanStatusMessages,
} from '../../../store/actions/page-admin-organizations'
import { modalHide } from '../../../store/actions/page-admin-organizations'
import { InputText } from '../../common/form/input-text'
import { ParentOrganizationSelect } from './parent-organization-select'
import { Group, PartialGroup } from 'rest-api'

interface ReduxActions {
  modalHide: typeof modalHide
  saveOrganization: typeof saveOrganization
  cleanStatusMessages: typeof cleanStatusMessages
}

interface ReduxDispatchProps {
  actions: ReduxActions
}

interface ReduxStateProps {
  showModal: boolean
  group: PartialGroup
  saving: boolean
  saved: boolean
  error?: string
}

function mapStateToProps(state: AppState): ReduxStateProps {
  const currentGroup = state.pageAdminOrganizations.currentOrganization
  const group = {
    ...currentGroup,
    parentId: state.blueprint.organizations.current,
  }
  return {
    group,
    showModal: state.pageAdminOrganizations.showModal,
    saving: state.pageAdminOrganizations.saving,
    saved: state.pageAdminOrganizations.saved,
    error: state.pageAdminOrganizations.saveError,
  }
}

function mapDispatchToProps(dispatch: Dispatch): ReduxDispatchProps {
  const actions = { modalHide, saveOrganization, cleanStatusMessages }
  return { actions: bindActionCreators(actions, dispatch) }
}

interface Props extends ReduxStateProps, ReduxDispatchProps {}

interface OwnState {
  invalidSubmit: boolean
  fields: Partial<Group>
  orgError: string
}

class OrganizationModalComponent extends React.PureComponent<Props, OwnState> {
  state: OwnState = {
    invalidSubmit: false,
    fields: {
      name: this.props.group.name,
      parentId: this.props.group.parentId,
    },
    orgError: null,
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps.group.id !== this.props.group.id) {
      this.setState({
        fields: {
          name: this.props.group.name,
          parentId: this.props.group.parentId,
        },
      })
    }
  }

  resetForm() {
    const fields = cloneDeep(this.props.group)
    this.setState({
      fields,
      invalidSubmit: false,
    })
  }

  handleCancel = () => {
    this.props.actions.cleanStatusMessages()
    this.props.actions.modalHide()
    this.resetForm()
  }

  handleSubmit = (e) => {
    if (this.state.fields.parentId) {
      this.props.actions.cleanStatusMessages()
      this.props.actions.saveOrganization(
        this.props.group.id,
        this.state.fields
      )
      this.resetForm()
    } else {
      this.setState({
        orgError: 'You must select a parent organization.',
      })
    }
  }

  handleOrgChange = (option) => {
    this.setValue('parentId', option ? option.value : null)
    if (option && this.state.orgError) {
      this.setState({
        orgError: null,
      })
    }
  }

  handleInputChange = (e) => {
    const target = e.target as HTMLInputElement
    this.setValue('name', target.value)
  }

  setValue = (field, value) => {
    this.setState((state) => {
      return {
        fields: { ...state.fields, [field]: value },
      }
    })
  }

  render() {
    const fields = this.state.fields

    const Error = () => {
      if (!this.props.error) {
        return null
      }

      return <Alert variant="danger">{this.props.error}</Alert>
    }

    const OrgError = () => {
      if (!this.state.orgError) {
        return null
      }

      return <Alert variant="danger">{this.state.orgError}</Alert>
    }

    return (
      <Modal show={this.props.showModal} onHide={this.handleCancel}>
        <Formsy
          className={this.state.invalidSubmit ? 'form invalid' : 'form'}
          onInvalidSubmit={() => {
            this.setState({
              invalidSubmit: true,
            })
          }}
          onValidSubmit={() => {
            this.setState({
              invalidSubmit: false,
            })
            this.handleSubmit(null)
          }}
        >
          <Modal.Header closeButton>Add or edit Dealership</Modal.Header>

          <Modal.Body>
            <Error />
            <FormGroup>
              <FormLabel>Name:</FormLabel>
              <InputText
                type="text"
                name="name"
                value={fields.name}
                required
                validations="minLength:1"
                validationErrors={{
                  isDefaultRequiredValue: 'Please enter a name',
                }}
                placeholder="Name"
                onChange={this.handleInputChange}
              />
            </FormGroup>

            <FormGroup>
              <FormLabel>Parent Fleet:</FormLabel>
              <ParentOrganizationSelect
                parentId={fields.parentId}
                placeholder="Choose Parent Organization"
                onChange={this.handleOrgChange}
              />
            </FormGroup>
            <OrgError />
          </Modal.Body>

          <Modal.Footer>
            <Button variant="danger" onClick={this.handleCancel}>
              Close
            </Button>
            <Button
              type="submit"
              variant="success"
              disabled={this.props.saving}
            >
              Save
            </Button>
          </Modal.Footer>
        </Formsy>
      </Modal>
    )
  }
}

export const EditModal = connect(
  mapStateToProps,
  mapDispatchToProps
)(OrganizationModalComponent)
export default EditModal
