import * as React from 'react'
import { MarkerOverlay } from './marker-overlay'
import {
  MarkerClusterer,
  Renderer,
  SuperClusterAlgorithm,
} from '@googlemaps/markerclusterer'
import { debounce, differenceWith } from 'lodash'
import { DEVICE_STATUS, MAP_MAX_INITIAL_ZOOM } from 'common/consts'
import { getClusterColor, getDeviceStatus } from './marker-icon'
import { DeviceView } from 'client/types'

export interface IMarker {
  id: string
  position: google.maps.LatLngLiteral
  icon: {
    url: string
    width: number
    height: number
  }
  title?: string
  overlayText?: string
  device: DeviceView
}

interface IRectangle extends google.maps.LatLngBoundsLiteral {
  type: 'rectangle'
}

interface ICircle extends google.maps.LatLngLiteral {
  type: 'circle'
  radius: number
}

interface IPolygon {
  type: 'polygon'
  points: google.maps.LatLngLiteral[]
}

export interface IShape {
  id: string
  name?: string
  shape: IRectangle | ICircle | IPolygon
}

interface IProps {
  height?: number
  markers?: IMarker[]
  shapes?: IShape[]
  center: google.maps.LatLngLiteral
  zoom?: number
  maxZoom?: number
  onMarkerClick?: (marker: google.maps.Marker) => void
  onShapeClick?: (
    object: google.maps.MVCObject,
    position: google.maps.LatLng,
    map: google.maps.Map
  ) => void
}

export class VanillaMap extends React.PureComponent<IProps> {
  static defaultProps = {
    height: 100,
    markers: [],
    shapes: [],
    zoom: 5,
    maxZoom: MAP_MAX_INITIAL_ZOOM,
    styles: [],
    onMarkerClick: () => void 0,
    onShapeClick: () => void 0,
  }

  private mapRef = React.createRef<HTMLDivElement>()
  private map: google.maps.Map = null
  private clusterer: MarkerClusterer
  private overlay: MarkerOverlay
  private idMap = new global.Map<string, google.maps.Marker>()
  private shapes = []
  private userInteraction = false
  private isProgrammaticZoom = false
  private removeFitBoundsTimer: ReturnType<typeof setTimeout>

  fitBoundsDebounced = debounce(this.fitBounds, 250)

  clusterRenderer: Renderer = {
    render: ({ count, position, markers }, _stats) => {
      const deviceStatuses: DEVICE_STATUS[] = markers
        .map((marker) => marker.get('device.status'))
        .filter((status) => Number.isInteger(status))
      const bestStatus = Math.min(...deviceStatuses)
      const color = getClusterColor(bestStatus)
      const svg = window.btoa(`
  <svg fill="${color}" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 240 240">
    <circle cx="120" cy="120" opacity="1" r="70" />
    <circle cx="120" cy="120" opacity=".7" r="82" />
    <circle cx="120" cy="120" opacity=".5" r="94" />
  </svg>`)
      const scaledSize = getScaledValue(
        Math.min(markers.length, 25),
        1,
        25,
        38,
        64
      )
      const marker = new google.maps.Marker({
        position,
        icon: {
          url: `data:image/svg+xml;base64,${svg}`,
          scaledSize: new google.maps.Size(scaledSize, scaledSize),
        },
        label: {
          text: String(count),
        },
        zIndex: Number(google.maps.Marker.MAX_ZINDEX) + count,
      })
      if (markers.length < 6) {
        google.maps.event.addListener(marker, 'mouseover', () => {
          const overlayText = markers
            .map((marker) => marker.get('overlayText'))
            .join('\n')
          this.removeOverlay()
          this.overlay = new MarkerOverlay(position, overlayText, 15, 15)
          this.overlay.setMap(this.map)
        })
        google.maps.event.addListener(marker, 'mouseout', () => {
          this.removeOverlay()
        })
      }

      return marker
    },
  }

  componentDidMount() {
    this.init()
  }

  componentWillUnmount() {
    if (this.removeFitBoundsTimer) {
      clearTimeout(this.removeFitBoundsTimer)
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.center !== this.props.center) {
      this.map.setCenter(this.props.center)
    }
    if (prevProps.zoom !== this.props.zoom) {
      this.map.setZoom(this.props.zoom)
    }
    if (prevProps.maxZoom !== this.props.maxZoom) {
      this.map.setOptions({ maxZoom: this.props.maxZoom })
    }
    if (prevProps.markers !== this.props.markers) {
      this.redraw(prevProps.markers, this.props.markers)
    }
    if (prevProps.shapes !== this.props.shapes) {
      this.clearShapes()
      this.drawShapes()
    }
  }

  render() {
    return (
      <div ref={this.mapRef} style={{ height: `${this.props.height}px` }}></div>
    )
  }

  fitBounds(bounds?: google.maps.LatLngBoundsLiteral) {
    this.isProgrammaticZoom = true
    if (bounds) {
      this.map.fitBounds(bounds)
    } else {
      if (this.props.markers.length > 0) {
        const bounds = new google.maps.LatLngBounds()
        this.props.markers.forEach((marker) => bounds.extend(marker.position))
        this.map.fitBounds(bounds)
      } else {
        this.map.setCenter(this.props.center)
        this.map.setZoom(this.props.zoom)
      }
    }
    this.isProgrammaticZoom = false
  }

  private init() {
    const { center, zoom, maxZoom } = this.props
    this.map = new google.maps.Map(this.mapRef.current, {
      center,
      zoom,
      controlSize: 24,
      streetView: null,
      streetViewControl: false,
      maxZoom,
      gestureHandling: 'cooperative',
    })
    this.map.addListener('zoom_changed', () => {
      if (!this.isProgrammaticZoom) {
        this.userInteraction = true
      }
    })
    this.map.addListener('idle', () => {
      this.removeOverlay()
    })
    this.map.addListener('dragstart', () => {
      this.userInteraction = true
    })
    this.clusterer = new MarkerClusterer({
      map: this.map,
      markers: [],
      renderer: this.clusterRenderer,
      algorithm: new SuperClusterAlgorithm({ maxZoom }),
    })
    this.props.markers.forEach((marker) => this.addMarker(marker))
    this.clusterer.render()
    this.drawShapes()

    let fitBoundsListener = google.maps.event.addListener(
      this.clusterer,
      'clusteringend',
      () => {
        if (this.userInteraction) {
          removeFitBoundsListener()
          return
        }
        this.fitBoundsDebounced()
      }
    )

    const removeFitBoundsListener = () => {
      fitBoundsListener.remove()
      fitBoundsListener = null
      if (this.removeFitBoundsTimer) {
        clearTimeout(this.removeFitBoundsTimer)
        this.removeFitBoundsTimer = null
      }
    }

    this.removeFitBoundsTimer = setTimeout(removeFitBoundsListener, 2000)
  }

  /**
   * Diff algorithm to update markers that actually changed
   */
  private redraw(oldMarkers: IMarker[], newMarkers: IMarker[]) {
    newMarkers.forEach((marker) => {
      const { lat, lng } = marker.position
      const gMarker = this.idMap.get(marker.id)
      if (gMarker) {
        // marker already exists, do update
        const oldPos = gMarker.getPosition()
        const oldIcon = gMarker.getIcon() as google.maps.Icon
        if (lat !== oldPos.lat() || lng !== oldPos.lng()) {
          gMarker.setPosition({ lat, lng })
        }
        if (marker.icon.url !== oldIcon.url) {
          gMarker.setIcon({ ...oldIcon, url: marker.icon.url })
        }
      } else {
        // marker is new, do add
        this.addMarker(marker)
      }
    })
    // find deleted markers
    const deletedMarkers = differenceWith(
      oldMarkers,
      newMarkers,
      (a, b) => a.id === b.id
    )
    deletedMarkers.forEach((marker) => {
      const gMarker = this.idMap.get(marker.id)
      this.clusterer.removeMarker(gMarker, true)
      this.idMap.delete(marker.id)
    })
    this.clusterer.render()
  }

  private addMarker(marker: IMarker) {
    const { id, position, icon, title, overlayText, device } = marker
    const { lat, lng } = position
    const gMarker = new google.maps.Marker({
      map: this.map,
      position: new google.maps.LatLng(lat, lng),
      icon: {
        url: icon.url,
        scaledSize: new google.maps.Size(icon.width, icon.height),
      },
      title,
    })
    gMarker.setValues({
      id,
      overlayText,
      'device.status': getDeviceStatus(device),
    })
    gMarker.addListener('click', () => this.props.onMarkerClick(gMarker))
    gMarker.addListener('mouseover', (e) => {
      this.removeOverlay()
      this.overlay = new MarkerOverlay(
        e.latLng,
        gMarker.get('overlayText'),
        5,
        5
      )
      this.overlay.setMap(this.map)
    })
    gMarker.addListener('mouseout', () => {
      this.removeOverlay()
    })
    this.idMap.set(id, gMarker)
    this.clusterer.addMarker(gMarker, true)
  }

  private drawShapes() {
    this.shapes = this.props.shapes.map((item) => {
      let gShape: google.maps.MVCObject
      switch (item.shape.type) {
        case 'circle':
          {
            const { lat, lng, radius } = item.shape
            gShape = new google.maps.Circle({
              center: { lat, lng },
              radius,
              map: this.map,
            })
          }
          break
        case 'rectangle':
          {
            const { north, east, south, west } = item.shape
            gShape = new google.maps.Rectangle({
              bounds: { north, east, south, west },
              map: this.map,
            })
          }
          break
        case 'polygon':
          {
            const { points } = item.shape
            gShape = new google.maps.Polygon({
              paths: points,
              map: this.map,
            })
          }
          break
      }
      gShape.set('name', item.name)
      gShape.addListener('click', (e) =>
        this.props.onShapeClick(gShape, e.latLng, this.map)
      )
      return gShape
    })
  }

  private clearShapes() {
    this.shapes.forEach((item) => item.setMap(null))
    this.shapes = []
  }

  private removeOverlay() {
    if (this.overlay) {
      this.overlay.setMap(null)
      this.overlay = null
    }
  }
}

function getScaledValue(
  value: number,
  sourceRangeMin: number,
  sourceRangeMax: number,
  targetRangeMin: number,
  targetRangeMax: number
): number {
  const targetRange = targetRangeMax - targetRangeMin
  const sourceRange = sourceRangeMax - sourceRangeMin
  return ((value - sourceRangeMin) * targetRange) / sourceRange + targetRangeMin
}

export default VanillaMap
