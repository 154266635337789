import * as formatters from './formatters'
import { DEVICE_FIELDS } from 'common/consts'
import { Column } from '../../types'
import {
  getMovementValue,
  getParkedTimeDuration,
  getSpeedValue,
} from './formatters'

const defaultWidth = 200
const dateTimeWidth = 160
const shortestWidth = 80
const shorterWidth = 90
const shortWidth = 110

export const gridColumns: Column[] = [
  {
    name: 'Report Time',
    key: DEVICE_FIELDS.LAST_REPORT_TIME,
    sortable: true,
    type: 'date',
    width: dateTimeWidth,
    formatter: formatters.ReportTimeFormatter,
    resizable: true,
  },
  {
    name: 'Status',
    key: DEVICE_FIELDS.ONLINE,
    sortable: true,
    type: 'boolean',
    width: shorterWidth,
    formatter: formatters.StatusFormatter,
    resizable: true,
  },
  {
    name: 'Groups',
    key: DEVICE_FIELDS.TAGS,
    sortable: true,
    type: 'string',
    width: defaultWidth,
    formatter: formatters.GroupsFormatter,
    resizable: true,
  },
  {
    name: 'Geofence',
    key: DEVICE_FIELDS.CURRENT_GEOFENCES,
    id: 4,
    sortable: true,
    type: 'string',
    width: defaultWidth,
    formatter: formatters.GeofenceFormatter,
    resizable: true,
  },
  {
    name: 'City',
    key: DEVICE_FIELDS.CITY,
    id: 5,
    sortable: true,
    type: 'string',
    width: shortWidth,
    formatter: formatters.CityAndStateFormatter,
    resizable: true,
  },
  {
    name: 'State',
    key: DEVICE_FIELDS.STATE_PROVINCE,
    sortable: true,
    type: 'string',
    width: shorterWidth,
    formatter: formatters.CityAndStateFormatter,
    resizable: true,
  },
  {
    name: 'Speed (miles)',
    key: DEVICE_FIELDS.SPEED,
    sortable: true,
    type: 'number',
    width: shortestWidth,
    formatter: formatters.SpeedFormatter,
    resizable: true,
    getValue: getSpeedValue,
  },
  {
    name: 'Battery Volts',
    key: DEVICE_FIELDS.BATTERY_VOLTS,
    sortable: true,
    type: 'number',
    width: shortestWidth,
    formatter: formatters.BatteryVoltsFormatter,
    resizable: true,
  },
  {
    name: 'Movement',
    key: DEVICE_FIELDS.MOVEMENT,
    sortable: true,
    type: 'string',
    width: shortWidth,
    formatter: formatters.MovementFormatter,
    resizable: true,
    getValue: getMovementValue,
  },
  {
    name: 'Parked Time',
    key: 'LastMovementStatusChangeTime',
    sortable: true,
    type: 'duration',
    width: shortWidth,
    formatter: formatters.IdleTimeFormatter,
    resizable: true,
    getValue: getParkedTimeDuration,
  },
  {
    name: 'Serial Number',
    key: DEVICE_FIELDS.SERIAL_NUMBER,
    width: shortWidth,
    sortable: true,
    type: 'string',
    resizable: true,
  },
  {
    name: 'IMEI',
    key: DEVICE_FIELDS.IMEI,
    width: defaultWidth,
    sortable: true,
    type: 'string',
    resizable: true,
  },
  {
    name: 'SIM Number',
    key: DEVICE_FIELDS.ICCID,
    width: defaultWidth,
    sortable: true,
    type: 'string',
    resizable: true,
  },
  {
    name: 'Customer Name',
    key: DEVICE_FIELDS.CUSTOMER_NAME,
    width: defaultWidth,
    sortable: true,
    type: 'string',
    resizable: true,
  },
  {
    name: 'Phone Number',
    key: DEVICE_FIELDS.PHONE_NUMBER,
    width: defaultWidth,
    sortable: false,
    type: 'string',
    formatter: formatters.PhoneNumberFormatter,
    resizable: true,
  },
  {
    name: 'Make',
    key: DEVICE_FIELDS.MAKE,
    width: defaultWidth,
    sortable: true,
    type: 'string',
    resizable: true,
  },
  {
    name: 'Model',
    key: DEVICE_FIELDS.MODEL,
    width: defaultWidth,
    sortable: true,
    type: 'string',
    resizable: true,
  },
  {
    name: 'Year',
    key: DEVICE_FIELDS.YEAR,
    width: shortWidth,
    sortable: true,
    type: 'number',
    resizable: true,
  },
  {
    name: 'Distance from dealership (miles)',
    key: DEVICE_FIELDS.DISTANCE_FROM_DEALERSHIP,
    width: defaultWidth,
    sortable: true,
    type: 'number',
    resizable: true,
    formatter: formatters.DistanceFormatter,
  },
  {
    name: 'VIN #',
    key: DEVICE_FIELDS.VIN,
    width: shortWidth,
    sortable: true,
    type: 'number',
    resizable: true,
  },
  {
    name: 'Mileage',
    key: DEVICE_FIELDS.MILEAGE,
    width: defaultWidth,
    sortable: true,
    type: 'number',
    resizable: true,
    formatter: formatters.MileageFormatter,
  },
  {
    name: 'Dealer Visits',
    key: DEVICE_FIELDS.DEALER_VISITS,
    width: shortWidth,
    sortable: true,
    type: 'number',
    resizable: true,
    formatter: formatters.DealerVisitsFormatter,
  },
  {
    name: 'Activation Status',
    key: DEVICE_FIELDS.ACTIVATION_STATUS,
    width: shortWidth,
    sortable: true,
    type: 'string',
    resizable: true,
    formatter: formatters.StringFormatter,
  },
].map((entry, index) => ({
  selectable: true,
  locked: false,
  id: index,
  ...entry,
}))

export const fixedColumns: Column[] = [
  {
    name: 'Email Address',
    key: DEVICE_FIELDS.EMAIL_ADDRESS,
    sortable: true,
    locked: true,
    type: 'string',
    width: defaultWidth,
    resizable: true,
  },
]
