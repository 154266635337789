import * as React from 'react'
import { useLocalApiFetch } from 'client/lib/api'
import useSession from 'client/lib/hooks/useSession'
import { Button, Col, Form } from 'react-bootstrap'
import { useHistory } from 'react-router'
import GeofenceSelector from '../common/GeofenceSelector'
import { FileUpload } from './FileUpload'

export const CreateAutomaticPromotion: React.FC = () => {
  const [geofenceId, setGeofenceId] = React.useState<number | null>(null)
  const [message, setMessage] = React.useState<string>('')
  const [triggerType, setTriggerType] = React.useState<string>('enter')

  const history = useHistory()
  const { selectedGroupId, user } = useSession()

  const { apiPost } = useLocalApiFetch()

  const onSubmit = async (e) => {
    e.preventDefault()
    await apiPost(`/promotions`, {
      groupId: selectedGroupId,
      geofenceId,
      triggerType,
      message,
    })
    history.push('/promotions/auto')
  }

  return (
    <Form className={user.statsExpanded ? 'w-75' : 'w-50'} onSubmit={onSubmit}>
      <Form.Group className="mb-3">
        <Form.Row className="align-items-center">
          <Form.Label column className="flex-grow-0 text-nowrap">
            Send to customer when:
          </Form.Label>
          <Col>
            <Form.Check
              custom
              type="radio"
              inline
              required
              value="enter"
              label="Entering"
              id="type-enter"
              name="geofence_trigger_type"
              onChange={(e) => setTriggerType(e.target.value)}
              checked={triggerType === 'enter'}
              className="ml-3"
            />
            <Form.Check
              custom
              type="radio"
              inline
              required
              value="exit"
              label="Exiting"
              id="type-exit"
              name="geofence_trigger_type"
              onChange={(e) => setTriggerType(e.target.value)}
              checked={triggerType === 'exit'}
              className="ml-3"
            />
          </Col>
        </Form.Row>
        <Form.Control.Feedback type="invalid">
          Please select a trigger type.
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group className="mb-3">
        <GeofenceSelector
          value={geofenceId}
          onChange={(value) => setGeofenceId(value)}
        />
        <Form.Control.Feedback type="invalid">
          Please select a geofence.
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group className="mb-3">
        <Form.Label>Message:</Form.Label>
        <Form.Control
          as="textarea"
          rows={5}
          className="w-100"
          custom
          required
          value={message}
          onChange={(e) => setMessage(e.target.value)}
        />
        <Form.Control.Feedback type="invalid">
          Please enter a message.
        </Form.Control.Feedback>
      </Form.Group>

      <FileUpload />

      <div className="text-right">
        <Button type="submit" variant="success" className="mr-2">
          Save
        </Button>
        <Button
          variant="secondary"
          onClick={() => history.push('/promotions/auto')}
        >
          Cancel
        </Button>
      </div>
    </Form>
  )
}
