import { createSelector } from 'reselect'
import { concat } from 'lodash'

import { AppState, ISelect } from 'client/types'
import {
  getAllChildren,
  GroupHeirarchy,
  FlatGroupHierarchy,
} from '../blueprint/groups/reducers'

export const getHierarchy = createSelector(
  (state: AppState) => state.user.profile,
  (state: AppState) => state.blueprint.organizations.data,
  (profile, groupMap): GroupHeirarchy[] => {
    if (!profile) {
      return []
    }
    const groups = []
    const userOrg = groupMap[profile.groupId]
    if (userOrg) {
      groups.push({
        organization: userOrg,
        children: getAllChildren(groupMap, userOrg.id),
      })
    }

    return groups
  }
)

export const getFlattenedHierarchy = createSelector(
  getHierarchy,
  (hierarchy): FlatGroupHierarchy[] => {
    let output = []
    hierarchy.forEach((entry) => {
      output = [...output, ...getFlatHierarchyNode(entry, 0)]
    })
    return output
  }
)

export const getOptions = createSelector(getHierarchy, (hierarchy): ISelect[] =>
  getGroupHierarchyOptions(hierarchy)
)

function getGroupHierarchyOptions(hierarchy: GroupHeirarchy[]): ISelect[] {
  let options = []
  hierarchy.forEach((organization: GroupHeirarchy) => {
    options = concat(options, getOptionsForHierarchy(organization, 0))
  })
  return options
}

function getOptionsForHierarchy(
  hierarchy: GroupHeirarchy,
  depth: number
): ISelect[] {
  let options = []

  if (hierarchy.organization.role !== 'device') {
    let label = hierarchy.organization.name
    if (depth > 0) {
      let pad = depth * 4
      while (pad > 0) {
        label = `-${label}`
        pad--
      }
    }

    options.push({
      label,
      value: hierarchy.organization.id,
    })

    hierarchy.children.forEach((child: GroupHeirarchy) => {
      options = concat(options, getOptionsForHierarchy(child, depth + 1))
    })
  }

  return options
}

function getFlatHierarchyNode(
  node: GroupHeirarchy,
  depth = 0
): FlatGroupHierarchy[] {
  const flatNode: FlatGroupHierarchy = {
    ...node.organization,
    depth,
    childCount: node.children.length,
  }
  let children: FlatGroupHierarchy[] = []
  if (node.children) {
    node.children.forEach((child) => {
      children = [...children, ...getFlatHierarchyNode(child, depth + 1)]
    })
  }
  return [flatNode, ...children]
}
