import { createAction } from 'redux-actions'
import { Dispatch } from 'redux'
import { JWT_KEY } from 'common/services/api/common'
import { localApiError } from './local-api'
import { statusHistory } from '../../lib/local-api'

export const actionTypes = {
  LOAD_REPORT_START: 'status_history/LOAD_REPORT',
  LOAD_REPORT_END: 'status_history/LOAD_REPORT_END',
  LOAD_REPORT_ERROR: 'status_history/LOAD_REPORT_ERROR',
}

export const loadStatusReportStart = createAction(
  actionTypes.LOAD_REPORT_START,
  (reportId) => ({ reportId })
)

export const loadStatusReportEnd = createAction(
  actionTypes.LOAD_REPORT_END,
  (reportId, data) => ({ reportId, data })
)

export const loadStatusReportError = createAction(
  actionTypes.LOAD_REPORT_ERROR,
  (reportId, message) => ({ reportId, message })
)

export const loadStatusForReport = (
  reportId: number,
  deviceId: string,
  timeframe: string
) => {
  return async (dispatch: Dispatch<any>) => {
    dispatch(loadStatusReportStart(reportId))
    try {
      const encodedJWT = localStorage.getItem(JWT_KEY)
      const response = await statusHistory.getStatusHistoryForReport(
        encodedJWT,
        reportId,
        deviceId,
        timeframe
      )
      dispatch(loadStatusReportEnd(reportId, response))
    } catch (error) {
      dispatch(localApiError(error))
      dispatch(loadStatusReportError(reportId, error.message))
    }
  }
}
