import * as React from 'react'
import useDeviceEdition from '../../../../lib/hooks/useDeviceEdition'
import { updateDevice } from '../../../../store/actions/page-admin-devices'
import { AppState, DeviceView } from 'client/types'
import { useDispatch, useSelector } from 'react-redux'
import { Group } from 'rest-api'
import DealerSelector from '../../form/dealer-selector'

interface Props {
  device: DeviceView
}

export const CellDealershipSelectorFormatter: React.FC<Props> = ({
  device,
}) => {
  const editedDevice = useDeviceEdition(device.id)
  const dispatch = useDispatch()
  const groups = useSelector<AppState, Record<string, Group>>(
    (state) => state.blueprint.organizations?.data
  )
  const [selectedDealershipId, setSelectedDealershipId] = React.useState(
    device.parentGroupId
  )

  if (!editedDevice) {
    return <span>{groups[device.parentGroupId].name}</span>
  }

  return (
    <DealerSelector
      value={selectedDealershipId}
      variant="secondary"
      size="sm"
      onChange={(groupId) => {
        setSelectedDealershipId(groupId)
        dispatch(
          updateDevice({
            id: device.id,
            key: 'groupId',
            value: device.groupId,
          })
        )
        dispatch(
          updateDevice({
            id: device.id,
            key: 'parentGroupId',
            value: groupId,
          })
        )
      }}
    />
  )
}
