import * as React from 'react'

import DeviceLegend from './device-legend'
import BreadcrumbsToggle from './breadcrumbs-toggle'
import TravelLineToggle from './travel-line-toggle'
import TimeframeSelect from './timeframe-select'

interface Props {
  individualTrailerMap?: boolean
}

export const DeviceMapControls = (props: Props): React.ReactElement<Props> => {
  return (
    <div className="d-flex flex-nowrap map-controls justify-content-between">
      <div>
        <TimeframeSelect />
      </div>
      <div>
        <TravelLineToggle />
      </div>
      <div>
        <BreadcrumbsToggle />
      </div>
      <div>
        <DeviceLegend
          allowLegendFilter={false}
          individualTrailerMap={props.individualTrailerMap}
          legendFilter={null}
          onChange={(legendFilter) => void 0} // TODO: implement filter as local state
        />
      </div>
    </div>
  )
}

export default DeviceMapControls
