import React from 'react'
import { Spinner, Button } from 'react-bootstrap'

interface Props extends React.ComponentPropsWithRef<typeof Button> {
  loading?: boolean
}

export const SpinnerButton: React.FC<Props> = ({
  loading = false,
  children,
  ...rest
}) => {
  return (
    <Button {...rest}>
      {children}
      {loading && (
        <Spinner
          as="span"
          animation="border"
          size="sm"
          role="status"
          aria-hidden="true"
          style={{ marginLeft: 5 }}
        />
      )}
    </Button>
  )
}
