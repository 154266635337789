import React, { useReducer, useState } from 'react'
import { useLocalApiFetch } from 'client/lib/api'
import useSession from 'client/lib/hooks/useSession'
import { Alert, Col, Form, Tab, Tabs } from 'react-bootstrap'
import { FileUpload } from './FileUpload'
import { DateTime } from 'luxon'
import { MinMaxValues, InputValues } from './MinMaxValues'
import GeofenceSelector from '../common/GeofenceSelector'
import { ExtraValues } from './ExtraValues'
import { SpinnerButton } from '../common/SpinnerButton'

import styles from './ManualPromotionsForm.module.scss'

interface FormValues extends InputValues {
  year: string
  make: string
  model: string
  distanceFromDealership: number
}

interface Props {
  onSend?: () => void
}

const defaultValues = (): FormValues => ({
  minValue: 1,
  maxValue: 5,
  minDate: '',
  maxDate: DateTime.local().toISODate(),
  year: '',
  make: '',
  model: '',
  distanceFromDealership: 0,
})

export const ManualPromotionsForm: React.FC<Props> = ({ onSend }) => {
  const [geofenceId, setGeofenceId] = useState<number | null>(null)
  const [allCustomers, setAllCustomers] = useState(true)
  const [triggerType, setTriggerType] = useState('dealer_visits')
  const [message, setMessage] = useState<string>('')
  const [values, setValues] = useReducer(
    (state: FormValues, action: Partial<FormValues>) => {
      return { ...state, ...action }
    },
    undefined,
    defaultValues
  )
  const [downloading, setDownloading] = useState(false)
  const [downloadError, setDownloadError] = useState<string>('')
  const [sending, setSending] = useState(false)
  const [sendError, setSendError] = useState<string>('')
  const [sendResult, setSendResult] = useState<string>('')

  const { selectedGroupId } = useSession()

  const { apiPost } = useLocalApiFetch()

  const downloadCsvFile = async () => {
    try {
      setDownloading(true)
      const data: Blob = await apiPost(`/promotions/send`, {
        ...getFormValues(),
        outputAs: 'csv',
      })
      const fileURL = URL.createObjectURL(data)
      const anchor = document.createElement('a')
      anchor.href = fileURL
      anchor.download = `${triggerType}-export-results-${DateTime.local().toSQL(
        { includeOffset: false }
      )}.csv`
      document.body.appendChild(anchor)
      anchor.click()
      document.body.removeChild(anchor)
      URL.revokeObjectURL(fileURL)
    } catch (err) {
      setDownloadError(`Error downloading file: ${err.message}.`)
    } finally {
      setDownloading(false)
    }
  }

  const {
    minValue,
    maxValue,
    minDate,
    maxDate,
    year,
    make,
    model,
    distanceFromDealership,
  } = values

  const getFormValues = () => ({
    groupId: selectedGroupId,
    allCustomers,
    geofenceId,
    triggerType,
    minValue,
    maxValue,
    minDate: minDate ? DateTime.fromSQL(minDate).toISO() : null,
    maxDate: maxDate ? DateTime.fromSQL(maxDate).toISO() : null,
    year,
    make,
    model,
    distanceFromDealership,
    message,
  })

  const onSubmit = async () => {
    try {
      setSending(true)
      const result = await apiPost(`/promotions/send`, getFormValues())
      let msg = `Customers found: ${result.count}. Notification successfully sent for ${result.sent} customers.`
      if (result.failed > 0) {
        msg += ` ${result.failed} failed.`
      }
      setSendResult(msg)
      onSend()
    } catch (err) {
      setSendError(`Error sending nofitication: ${err.message}.`)
    } finally {
      setSending(false)
    }
  }

  return (
    <Form
      onSubmit={(e) => {
        e.preventDefault()
        onSubmit()
      }}
    >
      <Form.Group className="mb-3 mt-3">
        <Form.Check
          type="switch"
          id="customer-switch"
          label="All customers"
          value="1"
          checked={allCustomers}
          onChange={(e) => setAllCustomers(e.target.checked)}
        />
      </Form.Group>

      <div style={{ display: allCustomers ? 'none' : 'block' }}>
        <Tabs
          defaultActiveKey={triggerType}
          onSelect={(key) => {
            setTriggerType(key)
            setValues(defaultValues())
          }}
        >
          <Tab
            title="Dealer visits"
            eventKey="dealer_visits"
            tabClassName={styles.tab}
          >
            <MinMaxValues
              values={{ minValue, maxValue, minDate, maxDate }}
              onChange={(key, value) => setValues({ [key]: value })}
            />
          </Tab>

          <Tab
            title="Geolocation visits"
            eventKey="geolocation_visits"
            tabClassName={styles.tab}
          >
            <Form.Group className="mb-3 mt-3">
              <GeofenceSelector
                value={geofenceId}
                onChange={(value) => setGeofenceId(value)}
              />
              <Form.Control.Feedback type="invalid">
                Please select a geofence.
              </Form.Control.Feedback>
            </Form.Group>
            <MinMaxValues
              values={{ minValue, maxValue, minDate, maxDate }}
              onChange={(key, value) => setValues({ [key]: value })}
            />
          </Tab>

          <Tab title="Mileage" eventKey="mileage" tabClassName={styles.tab}>
            <Form.Group className="mb-3 mt-3">
              <Form.Row>
                <Col lg="auto">
                  <Form.Label className="d-block">
                    Greater than or equal to:
                  </Form.Label>
                  <Form.Control
                    as="select"
                    custom
                    required
                    value={minValue}
                    onChange={(e) => setValues({ minValue: +e.target.value })}
                    style={{ width: 200 }}
                  >
                    <option value="5000">5000</option>
                    <option value="10000">10000</option>
                    <option value="15000">15000</option>
                    <option value="20000">20000</option>
                    <option value="30000">30000</option>
                  </Form.Control>
                </Col>
                <Col className="flex-column" lg="auto">
                  <Form.Label className="d-block">
                    Less than or equal to:
                  </Form.Label>
                  <Form.Control
                    as="select"
                    custom
                    required
                    value={maxValue}
                    onChange={(e) => setValues({ maxValue: +e.target.value })}
                    style={{ width: 200 }}
                  >
                    <option value="5000">5000</option>
                    <option value="10000">10000</option>
                    <option value="15000">15000</option>
                    <option value="20000">20000</option>
                    <option value="30000">30000</option>
                  </Form.Control>
                </Col>
              </Form.Row>
              <Form.Control.Feedback type="invalid">
                Please enter a valid range.
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label className="d-block">
                Date purchased (prior to):
              </Form.Label>
              <Form.Control
                type="date"
                value={minDate}
                onChange={(e) => setValues({ minDate: e.target.value })}
                style={{ width: 200 }}
              />
            </Form.Group>

            <ExtraValues
              values={{
                year,
                make,
                model,
                distanceFromDealership,
              }}
              onChange={(key, value) => setValues({ [key]: value })}
            />
          </Tab>

          <Tab
            title="Ride frequency"
            eventKey="ride_frequency"
            tabClassName={styles.tab}
          >
            <Form.Group className="mb-3 mt-3">
              <Form.Label className="d-block">Frequency:</Form.Label>
              <Form.Control
                as="select"
                custom
                required
                value={`${minValue},${maxValue ?? ''}`}
                onChange={(e) => {
                  const values = e.target.value.split(',').map(Number)
                  setValues({
                    minValue: values[0],
                    maxValue: values[1] ?? null,
                  })
                }}
                style={{ width: 200 }}
              >
                <option value="0,">No rides</option>
                <option value="1,5">1 - 5</option>
                <option value="6,15">6 - 15</option>
                <option value="16,30">16 - 30</option>
                <option value="31,">31+</option>
              </Form.Control>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label className="d-block">
                Date purchased (prior to):
              </Form.Label>
              <Form.Control
                type="date"
                value={minDate}
                onChange={(e) => setValues({ minDate: e.target.value })}
                style={{ width: 200 }}
              />
            </Form.Group>

            <ExtraValues
              values={{
                year,
                make,
                model,
                distanceFromDealership,
              }}
              onChange={(key, value) => setValues({ [key]: value })}
            />
          </Tab>
        </Tabs>

        <Form.Group className="mb-3">
          <Form.Label className="d-block">List of approved clients:</Form.Label>
          <SpinnerButton
            variant="primary"
            onClick={() => downloadCsvFile()}
            loading={downloading}
          >
            Download CSV file
          </SpinnerButton>
          {downloadError && (
            <Alert variant="danger" className="mt-2">
              {downloadError}
            </Alert>
          )}
        </Form.Group>
      </div>

      <Form.Group className="mb-3">
        <Form.Label>Message:</Form.Label>
        <Form.Control
          as="textarea"
          rows={5}
          className="w-100"
          maxLength={140}
          required
          value={message}
          onChange={(e) => setMessage(e.target.value)}
        />
        <Form.Control.Feedback type="invalid">
          Please enter a message.
        </Form.Control.Feedback>
      </Form.Group>

      <FileUpload />

      {sendError && (
        <Alert variant="danger" onClose={() => setSendError('')} dismissible>
          {sendError}
        </Alert>
      )}
      {sendResult && (
        <Alert variant="success" onClose={() => setSendResult('')} dismissible>
          {sendResult}
        </Alert>
      )}

      <div className="text-right">
        <SpinnerButton type="submit" variant="success" loading={sending}>
          Send
        </SpinnerButton>
      </div>
    </Form>
  )
}
