import * as React from 'react'

import { DeviceMap, Column } from '../../../../types'

export interface IProps {
  value?: any
  column?: Column
  dependentValues?: any
  rowIdx?: number
  prettyValue?: string
  editedDevices?: DeviceMap
  actions?: any
  options?: any[]
  noniBoxOnly?: boolean
  clearable?: boolean
  prettyFormatter?: (value: string, options?: any[]) => string
  cellFormatter?: (type: string, value: string) => any
  valueFormatter?: (type: string, value: string) => any
}

interface IState {
  value: any
}

class CellFormatterComponent extends React.Component<IProps, IState> {
  state = {
    value: this.props.value ?? '',
  }

  handleChange = (value) => {
    const field = this.props.column.key
    const deviceId = this.props.dependentValues.id
    this.props.actions.updateDevice({
      id: deviceId,
      key: field,
      value,
    })
  }

  isEditing() {
    return !!this.props.editedDevices[this.props.dependentValues?.id]
  }
}

export const CellFormatter = CellFormatterComponent
export default CellFormatter
