import { constants } from '../reducers/map'
import { createAction } from 'redux-actions'

export const showTravelLine = createAction(constants.TRAVEL_LINE_SHOW)

export const showBreadcrumbs = createAction(constants.BREADCRUMBS_SHOW)

export const showDeviceMapModal = createAction(
  constants.DEVICE_MAP_SHOW_DEVICE_MAP_MODAL,
  (deviceId: string) => deviceId
)

export const hideDeviceMapModal = createAction(
  constants.DEVICE_MAP_HIDE_DEVICE_MAP_MODAL
)

export const setFilter = createAction(
  constants.DEVICE_MAP_SET_FILTER,
  (name: string, value: any) => ({ name, value })
)

export const clearFilters = createAction(constants.DEVICE_MAP_CLEAR_FILTERS)
