import Blueprint from './blueprint'
import ApiCommon from './common'
import IDM from './idm'
import Acl from './acl'
import {
  GroupResponse,
  GroupListResponse,
  Group,
  User,
  Device,
  UserAuthRequest,
  UserAuthResponse,
} from 'rest-api'

class Api {
  blueprint: Blueprint
  idm: IDM
  comm: ApiCommon
  acl: Acl
  apiUrl: string

  constructor() {
    this.comm = new ApiCommon()
    this.blueprint = new Blueprint(this.comm)
    this.idm = new IDM(this.comm)
    this.acl = new Acl()
    this.apiUrl = process.env.API_BASE_URL
  }

  async login(opts: UserAuthRequest): Promise<UserAuthResponse> {
    const options = {
      url: this.apiUrl + '/users/auth',
      method: 'POST',
      body: {
        emailAddress: opts.emailAddress,
        password: opts.password,
        renewalType: opts.renewalType ? opts.renewalType : 'short',
      },
    }
    return this.comm.getJson(options, false)
  }

  logout() {
    this.comm.clearJwt()
  }

  async getGroup(id: string): Promise<GroupResponse> {
    const url = new URL(`${this.apiUrl}/groups/${id}`)
    return this.comm.getJson({
      url: url.toString(),
      method: 'GET',
    })
  }

  async getAllGroups(parentId: string | null = null): Promise<Group[]> {
    let page = 1
    let entries: Group[] = []
    let batch
    let maxPages

    do {
      batch = await this.getGroupsPage(parentId, page++, 1000)
      entries = entries.concat(batch.groups.results)
      maxPages = Math.ceil(batch.groups.meta.count / batch.groups.meta.pageSize)
    } while (batch.groups.meta.page < maxPages)

    return entries
  }

  async getGroupsPage(
    parentId: string | null,
    page: number,
    pageSize = 1000
  ): Promise<GroupListResponse> {
    const url = new URL(`${this.apiUrl}/groups`)
    url.searchParams.append('page', String(page))
    url.searchParams.append('pageSize', String(pageSize))
    url.searchParams.append('role', 'rever')
    if (parentId) {
      url.searchParams.append('parentId', parentId)
    }
    return this.comm.getJson({
      url: url.toString(),
      method: 'GET',
    })
  }

  async getAllUsers(groupId: string | null = null): Promise<User[]> {
    let page = 1
    let entries: User[] = []
    let batch
    let maxPages

    do {
      batch = await this.getUsersPage(groupId, page++, 1000)
      entries = entries.concat(batch.users.results)
      maxPages = Math.ceil(batch.users.meta.count / batch.users.meta.pageSize)
    } while (batch.users.meta.page < maxPages)

    return entries
  }

  async getUsersPage(groupId: string | null, page: number, pageSize = 1000) {
    const url = new URL(`${this.apiUrl}/users`)
    url.searchParams.append('page', String(page))
    url.searchParams.append('pageSize', String(pageSize))
    url.searchParams.append('recursive', 'true')
    if (groupId) {
      url.searchParams.append('groupId', groupId)
    }
    return this.comm.getJson({
      url: url.toString(),
      method: 'GET',
    })
  }

  async getAllDevices(groupId: string | null = null): Promise<Device[]> {
    let page = 1
    let entries: Device[] = []
    let batch
    let maxPages

    do {
      batch = await this.getDevicesPage(groupId, page++, 1000)
      entries = entries.concat(batch.devices.results)
      maxPages = Math.ceil(
        batch.devices.meta.count / batch.devices.meta.pageSize
      )
    } while (batch.devices.meta.page < maxPages)

    return entries
  }

  async getDevicesPage(groupId: string | null, page: number, pageSize = 1000) {
    const url = new URL(`${this.apiUrl}/devices`)
    url.searchParams.append('page', String(page))
    url.searchParams.append('pageSize', String(pageSize))
    url.searchParams.append('recursive', 'true')
    if (groupId) {
      url.searchParams.append('groupId', groupId)
    }
    return this.comm.getJson({
      url: url.toString(),
      method: 'GET',
    })
  }
}

export default Api
