import * as React from 'react'
import DeviceTable from './table'
import { ColumnSelectorModal } from '../common/grid/column-select/column-selector-modal'
import { useCustomGridColumns } from 'client/lib/hooks/useCustomGridColumns'
import { fixedColumns, gridColumns } from './columns'

const defaultSelectableColumns = gridColumns.slice()

export const FullGrid: React.FC = () => {
  const defaultColumns = React.useMemo(
    () => [...fixedColumns, ...defaultSelectableColumns],
    [fixedColumns, defaultSelectableColumns]
  )
  const [columnConfig, dispatch] = useCustomGridColumns(
    'mainGridColumnConfig',
    defaultColumns
  )

  return (
    <>
      <ColumnSelectorModal
        show={columnConfig.showColumnSelector}
        onCancel={() => dispatch({ type: 'hideSelector' })}
        onSave={() => dispatch({ type: 'saveColumns' })}
        onAdd={(item) => dispatch({ type: 'addColumn', payload: item })}
        onRemove={(item) => dispatch({ type: 'removeColumn', payload: item })}
        onAddAllClick={() => dispatch({ type: 'addAllColumns' })}
        onRemoveAllClick={() => dispatch({ type: 'removeAllColumns' })}
        onReorder={({ from, to }) =>
          dispatch({ type: 'reorderColumns', payload: { from, to } })
        }
        availableColumns={columnConfig.availableColumns}
        selectedColumns={columnConfig.selectedColumns}
      />
      <DeviceTable
        height={400}
        columns={columnConfig.columns}
        onEditColumnsClick={() => {
          dispatch({ type: 'showSelector' })
        }}
        onResizeColumn={(index, width) => {
          dispatch({
            type: 'resizeColumns',
            payload: { index, width },
          })
        }}
      />
    </>
  )
}
