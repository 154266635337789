import { combineReducers } from 'redux'
import { reducer as blueprint } from './blueprint/reducers'
import DeviceTable from './reducers/grid'
import DeviceReports from './reducers/device-reports'
import Map from './reducers/map'
import Geofences from './reducers/geofences'
import PageAdminGeofences from './reducers/page-admin-geofences'
import PageAdminDevices from './reducers/page-admin-devices'
import PageAdminUsers from './reducers/page-admin-users'
import PageAdminOrganizations from './reducers/page-admin-organizations'
import User from './reducers/user'
import * as login from './actions/login'
import LocationHistory from './reducers/location-history'
import Print from './reducers/print'
import StatusHistory from './reducers/status-history'
import Export from './reducers/export'

const appReducer = combineReducers({
  blueprint,
  deviceReports: DeviceReports,
  export: Export,
  geofences: Geofences,
  locationHistory: LocationHistory,
  map: Map,
  pageAdminDevices: PageAdminDevices,
  pageAdminGeofences: PageAdminGeofences,
  pageAdminOrganizations: PageAdminOrganizations,
  pageAdminUsers: PageAdminUsers,
  pageGrid: DeviceTable,
  print: Print,
  statusHistory: StatusHistory,
  user: User,
})

export default (state, action) => {
  // Destroy the state on logout
  if (action.type === login.constants.LOGOUT) {
    state = undefined
  }

  return appReducer(state, action)
}
