import * as freeze from 'redux-freeze'
import * as Redux from 'redux'
import { createLogger } from 'redux-logger'
import ReduxThunk from 'redux-thunk'
import { applyMiddleware, createStore, compose } from 'redux'

import createBlueprintMiddleware from './blueprint/middleware'
import reducers from './reducers'
import createMqttMiddleware from './middleware/mqtt'
import { AppState } from '../types/app-state'

// Middleware in Redux is not quite like Express. Middleware here are other libs that use the store in their own way.
// Middlewares and Store Enhancers we are using now are:
// - redux-logger - adds a pretty console.log statement for every state change in redux
// - redux-thunk - allows injection of the dispatcher into actions so that they can dispatch further actions
// - redux-freeze - freeze the state so no misterious modifications are performed

function configureStore(): Redux.Store<AppState> {
  const middlewareArray: Redux.Middleware[] = [
    ReduxThunk,
    createBlueprintMiddleware(),
    createMqttMiddleware(),
  ]

  let composeEnhancers = compose

  // Only log actions and freeze state when developing
  // cause they have runtime cost
  if (process.env.NODE_ENV === 'development') {
    const reduxDevToolsExtension =
      typeof window === 'object' &&
      window['__REDUX_DEVTOOLS_EXTENSION_COMPOSE__']

    if (reduxDevToolsExtension) {
      composeEnhancers = reduxDevToolsExtension
    } else {
      middlewareArray.push(
        createLogger({
          diff: true,
          collapsed: true,
        })
      )
    }

    middlewareArray.push(freeze)
  }

  const enhancer = composeEnhancers(applyMiddleware(...middlewareArray))

  const store = createStore(reducers, enhancer)

  return store
}

export const store = configureStore()
