import { createAction } from 'redux-actions'
import * as localAPI from '../../lib/local-api/index'
import { JWT_KEY } from 'common/services/api/common'
import * as geofenceMap from './page-admin-geofences'
import { Geofence } from 'common/types/local-api'
import { AppState } from '../../types'
import { localApiError } from './local-api'
import { getOrganizationId } from '../util'

export const constants = {
  LOADING_START: 'geofences/LOADING',
  LOADING_END: 'geofences/LOADING_END',
  LOADING_FAIL: 'geofences/LOADING_FAIL',
  SAVING_START: 'geofences/SAVING',
  SAVING_END: 'geofences/SAVING_END',
  SAVING_FAIL: 'geofences/SAVING_FAIL',
  DELETING_START: 'geofences/DELETING',
  DELETING_END: 'geofences/DELETING_END',
  DELETING_FAIL: 'geofences/DELETING_FAIL',
  SET_BY_RESPONSE: 'geofences/SET_BY_RESPONSE',
  REMOVE: 'geofences/REMOVE',
  UPSERT: 'geofences/UPSERT',
  CLEAR: 'geofences/CLEAR',
}

export const loading = createAction(constants.LOADING_START)
export const loadingEnd = createAction(constants.LOADING_END)
export const loadingFail = createAction(
  constants.LOADING_FAIL,
  (payload) => payload
)
export const saving = createAction(constants.SAVING_START)
export const savingEnd = createAction(constants.SAVING_END)
export const savingFail = createAction(
  constants.SAVING_FAIL,
  (payload) => payload
)
export const deleting = createAction(constants.DELETING_START)
export const deletingEnd = createAction(constants.DELETING_END)
export const deletingFail = createAction(
  constants.DELETING_FAIL,
  (payload) => payload
)
export const upsert = createAction(constants.UPSERT, (payload) => payload)
export const remove = createAction(constants.REMOVE, (payload) => payload)
export const clear = createAction(constants.CLEAR)

export function clearAndLoad(organizationId: string) {
  return async (dispatch) => {
    dispatch(clear())
    dispatch(getGeofences(organizationId))
  }
}

export function getGeofences(groupId: string) {
  return async (dispatch, getState) => {
    dispatch(loading())

    try {
      const encodedJWT = localStorage.getItem(JWT_KEY)

      if (!groupId) {
        groupId = await getOrganizationId(getState())
      }
      const response = await localAPI.geofences.getGeofences(
        encodedJWT,
        groupId
      )
      dispatch(upsert(response))
      dispatch(loadingEnd())
    } catch (err) {
      dispatch(localApiError(err))
      const payload = err.message
      dispatch(loadingFail(payload))
    }
  }
}

export function saveGeofence(shape: any) {
  return async (dispatch, getState) => {
    dispatch(saving())
    dispatch(geofenceMap.saveStarted())
    const state: AppState = getState()
    const user = state.user
    const geofenceToSave = state.pageAdminGeofences.geofenceToSave
    const geofence = Object.assign({}, geofenceToSave, shape)

    if (!geofence.organizationId) {
      if (user && user.profile && user.profile.properties.selectedFleet) {
        geofence.organizationId = user.profile.properties.selectedFleet
      } else {
        // If selectedFleet is undefined fallback to current organization
        geofence.organizationId = state.blueprint.organizations.current
      }
    }

    try {
      const encodedJWT = localStorage.getItem(JWT_KEY)
      const response = await localAPI.geofences.saveGeofence(
        encodedJWT,
        geofence
      )
      dispatch(savingEnd())
      dispatch(upsert([response]))
      dispatch(geofenceMap.geofenceSaved(response))
    } catch (err) {
      dispatch(localApiError(err))
      const payload = err.message
      dispatch(savingFail(payload))
    }
  }
}

export function deleteGeofence(geofence: Geofence) {
  return async (dispatch) => {
    dispatch(deleting())
    try {
      const encodedJWT = localStorage.getItem(JWT_KEY)
      await localAPI.geofences.deleteGeofence(encodedJWT, geofence.id)
      dispatch(deletingEnd())
      dispatch(remove(geofence.id))
    } catch (err) {
      dispatch(localApiError(err))
      const payload = err.message
      dispatch(savingFail(payload))
    }
  }
}
