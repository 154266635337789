import * as React from 'react'
import { LocationHistoryItem } from '../../../../common/types/local-api'
import { connect } from 'react-redux'
import { AppState } from '../../../types/app-state'
import {
  loadLocationHistory,
  setTimeFrame,
} from '../../../store/actions/location-history'
import { bindActionCreators, Dispatch } from 'redux'
import { DEFAULT_TIMEFRAME } from '../../../../common/consts'
import { DeviceView } from 'client/types'

interface OwnProps {
  device: DeviceView
  children?: React.ReactNode
  onUpdate?: (bounds: google.maps.LatLngBounds) => void
}

interface ReduxStateProps {
  timeframe: string
  loading: boolean
  error: string
  locationHistory: LocationHistoryItem[]
}

interface ReduxActions {
  loadLocationHistory: typeof loadLocationHistory
  setTimeFrame: typeof setTimeFrame
}

interface ReduxDispatchProps {
  actions: ReduxActions
}

interface Props extends OwnProps, ReduxStateProps, ReduxDispatchProps {}

class LocationHistoryContainerComponent extends React.Component<Props> {
  static defaultProps = {
    onUpdate: () => void 0,
  }

  componentDidMount() {
    if (
      this.props.device &&
      this.props.timeframe &&
      this.props.loading === false &&
      !this.props.error
    ) {
      this.props.actions.setTimeFrame(DEFAULT_TIMEFRAME)
      this.props.actions.loadLocationHistory(
        this.props.device.id,
        this.props.device.deviceType,
        DEFAULT_TIMEFRAME
      )
    }
  }

  async componentDidUpdate(prevProps: Props) {
    if (
      this.props.loading === false &&
      this.props.device &&
      (prevProps.device !== this.props.device ||
        prevProps.timeframe !== this.props.timeframe)
    ) {
      await this.props.actions.loadLocationHistory(
        this.props.device.id,
        this.props.device.deviceType,
        this.props.timeframe
      )
      this.props.onUpdate(this.getBounds())
    }
  }

  render() {
    return this.props.children
  }

  private getBounds() {
    const bounds = new google.maps.LatLngBounds()
    if (this.props.locationHistory.length > 0) {
      this.props.locationHistory.forEach((locationHistoryItem) => {
        bounds.extend(locationHistoryItem.position)
      })
    } else {
      bounds.extend({
        lat: this.props.device.latitude,
        lng: this.props.device.longitude,
      })
    }

    return bounds
  }
}

function mapStateToProps(state: AppState, ownProps: OwnProps): ReduxStateProps {
  return {
    timeframe: state.locationHistory.timeframe,
    loading: state.locationHistory.loading,
    error: state.locationHistory.error,
    locationHistory: ownProps.device
      ? state.locationHistory.data[ownProps.device.id]
      : null,
  }
}

function mapDispatchToProps(dispatch: Dispatch): ReduxDispatchProps {
  const actions = {
    loadLocationHistory,
    setTimeFrame,
  }
  return {
    actions: bindActionCreators(actions, dispatch),
  }
}

export const LocationHistoryContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(LocationHistoryContainerComponent)
export default LocationHistoryContainer
