import { DateTime } from 'luxon'
import React from 'react'
import { Form, Col } from 'react-bootstrap'

export interface InputValues {
  year: string
  make: string
  model: string
  distanceFromDealership: number
}

interface Props {
  values: InputValues
  onChange: (key: keyof InputValues, value: number | string) => void
}

export const ExtraValues: React.FC<Props> = ({ values, onChange }) => {
  const { year, make, model, distanceFromDealership } = values
  return (
    <>
      <h6>Unit characteristics:</h6>
      <Form.Group className="mb-3">
        <Form.Row>
          <Col lg="auto">
            <Form.Label className="d-block">Year:</Form.Label>
            <Form.Control
              type="number"
              min="1950"
              step="1"
              max={DateTime.local().year}
              value={year}
              onChange={(e) => onChange('year', e.target.value)}
              style={{ width: 200 }}
            />
          </Col>
          <Col lg="auto">
            <Form.Label className="d-block">Make:</Form.Label>
            <Form.Control
              value={make}
              onChange={(e) => onChange('make', e.target.value)}
              style={{ width: 200 }}
            />
          </Col>
          <Col lg="auto">
            <Form.Label className="d-block">Model:</Form.Label>
            <Form.Control
              value={model}
              onChange={(e) => onChange('model', e.target.value)}
              style={{ width: 200 }}
            />
          </Col>
        </Form.Row>
      </Form.Group>

      <Form.Group className="mb-3">
        <Form.Label className="d-block">Distance from dealership:</Form.Label>
        <Form.Control
          as="select"
          custom
          required
          value={distanceFromDealership}
          onChange={(e) => onChange('distanceFromDealership', +e.target.value)}
          style={{ width: 200 }}
        >
          <option value="0">Any</option>
          <option value="50">Less than 50</option>
          <option value="10">Less than 100</option>
          <option value="150">Less than 150</option>
          <option value="200">Less than 200</option>
        </Form.Control>
      </Form.Group>
    </>
  )
}
