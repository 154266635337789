import React from 'react'
import { Spinner } from 'react-bootstrap'

interface Props {
  block?: boolean
  height?: number | string
}

export const Loading: React.FC<Props> = ({ block, height = 'auto' }) => {
  return block ? (
    <div
      className="d-flex flex-column justify-content-center align-items-center m-auto text-center mt-2"
      style={{ width: '100%', height }}
    >
      <Spinner animation="border" variant="primary" />
    </div>
  ) : (
    <Spinner animation="border" variant="primary" />
  )
}
