import * as React from 'react'
import { Button } from 'react-bootstrap'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { AppState } from '../../../types/app-state'
import {
  editDevice,
  saveDevice,
  cancelDevice,
} from '../../../store/actions/page-admin-devices'
import { PartialDeviceView, DeviceView } from 'client/types'

interface IDeviceControlProps {
  rowIdx?: number
  value?: unknown
  dependentValues?: DeviceView
}

interface ReduxStateProps {
  editedDevices: Record<string, PartialDeviceView>
}

function mapStateToProps(state: AppState): ReduxStateProps {
  return {
    editedDevices: state.pageAdminDevices.editedDevices,
  }
}

interface Props extends IDeviceControlProps, ReduxStateProps {
  actions: {
    editDevice: typeof editDevice
    saveDevice: typeof saveDevice
    cancelDevice: typeof cancelDevice
  }
}

function mapDispatchToProps(dispatch) {
  const actions = {
    editDevice,
    saveDevice,
    cancelDevice,
  }
  return { actions: bindActionCreators(actions, dispatch) }
}

class DeviceControlComponent extends React.Component<Props> {
  handleEditDevice = () => {
    const deviceId = this.props.dependentValues.id
    this.props.actions.editDevice(deviceId)
  }

  handleSaveDevice = () => {
    const deviceId = this.props.dependentValues.id
    this.props.actions.saveDevice(deviceId)
  }

  handleCancelDevice = () => {
    const deviceId = this.props.dependentValues.id
    this.props.actions.cancelDevice(deviceId)
  }

  render() {
    const isEditing =
      this.props.editedDevices[this.props.dependentValues.id] !== undefined
    return (
      <div className="device-control-component m-2">
        {!isEditing && (
          <Button onClick={this.handleEditDevice} size="sm">
            Edit
          </Button>
        )}
        {isEditing && (
          <Button
            variant="success"
            onClick={this.handleSaveDevice}
            className="mr-2"
            size="sm"
          >
            Save
          </Button>
        )}
        {isEditing && (
          <Button variant="danger" onClick={this.handleCancelDevice} size="sm">
            Cancel
          </Button>
        )}
      </div>
    )
  }
}

export const DeviceControl = connect(
  mapStateToProps,
  mapDispatchToProps
)(DeviceControlComponent)
export default DeviceControl
