import * as React from 'react'
import { AppState } from '../../../types'
import { connect } from 'react-redux'
import { Button } from 'react-bootstrap'
import { bindActionCreators, Dispatch } from 'redux'
import { modalShow } from '../../../store/actions/page-admin-organizations'
import { Group } from 'rest-api'
import { faPenToSquare } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

interface OwnProps {
  organization: Group
}

interface ReduxStateProps {
  currentOrganization: Group
}

interface ReduxActions {
  modalShow: (organization: Group) => void
}

interface ReduxDispatchProps {
  actions: ReduxActions
}

interface Props extends OwnProps, ReduxStateProps, ReduxDispatchProps {}

function mapStateToProps(state: AppState): ReduxStateProps {
  return {
    currentOrganization: state.pageAdminOrganizations.currentOrganization,
  }
}

function mapDispatchToProps(dispatch: Dispatch): ReduxDispatchProps {
  return { actions: bindActionCreators({ modalShow }, dispatch) }
}

class OrganizationEditButtonComponent extends React.Component<Props> {
  handleClick = (): void => {
    this.props.actions.modalShow(this.props.organization)
  }

  render() {
    const disabled =
      this.props.currentOrganization?.id === this.props.organization?.id

    return (
      <Button
        variant="outline-secondary"
        onClick={this.handleClick}
        title="Edit"
        disabled={disabled}
      >
        <FontAwesomeIcon icon={faPenToSquare} />
      </Button>
    )
  }
}

export const OrganizationEditButton = connect(
  mapStateToProps,
  mapDispatchToProps
)(OrganizationEditButtonComponent)
export default OrganizationEditButton
