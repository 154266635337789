import * as React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Nav, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { useLocation } from 'react-router'
import { Link } from 'react-router-dom'
import {
  faBullhorn,
  faChartPie,
  faHouse,
  faList,
  faMapLocationDot,
  faMapPin,
  faRightFromBracket,
  faUser,
} from '@fortawesome/free-solid-svg-icons'

import styles from './Sidebar.module.scss'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import useSession from 'client/lib/hooks/useSession'
import api from 'client/api'
import { truncate } from 'lodash'

interface Props {
  statsActive: boolean
  onLogout: () => void
  onStatsClick: () => void
}

export const Sidebar: React.FC<Props> = ({
  statsActive,
  onLogout,
  onStatsClick,
}) => {
  const location = useLocation()
  const activeKey = location.pathname.split('/').slice(0, 2).join('/')
  const session = useSession()

  const hasMapAndGridAccess = api.acl.hasAccessToExtendedFeatures({
    role: session.user.profile.role,
  })

  const emailSplit = session.user.profile.email.split('@')
  const truncatedEmail =
    truncate(emailSplit[0], { length: 12 }) + '@' + emailSplit[1]

  return (
    <Nav
      activeKey={activeKey}
      className={styles.root}
      style={{ height: '100%' }}
    >
      <Nav.Item className="mb-2">
        <LinkItem href="/home" icon={faHouse} title="Home" />
      </Nav.Item>
      <Nav.Item className="mb-2">
        <LinkTrigger
          active={statsActive}
          href="/stats"
          onClick={onStatsClick}
          icon={faChartPie}
          title="Statistics"
        />
      </Nav.Item>
      {hasMapAndGridAccess && (
        <Nav.Item className="mb-2">
          <LinkItem href="/grid" icon={faList} title="Grid" />
        </Nav.Item>
      )}
      {hasMapAndGridAccess && (
        <Nav.Item className="mb-2">
          <LinkItem href="/map" icon={faMapLocationDot} title="Map" />
        </Nav.Item>
      )}
      <Nav.Item>
        <LinkItem href="/geofences" icon={faMapPin} title="Geofences" />
      </Nav.Item>
      <Nav.Item className="mb-2">
        <LinkItem href="/promotions" icon={faBullhorn} title="Promotions" />
      </Nav.Item>
      <Nav.Item className="mb-2">
        <LinkItem href="/admin" icon={faUser} title="Admin" />
      </Nav.Item>
      <Nav.Item className="mt-auto mb-4 text-center">
        <LinkTrigger
          href="/logout"
          onClick={onLogout}
          icon={faRightFromBracket}
          title={
            <div style={{ whiteSpace: 'nowrap' }}>
              Logout
              <br />({truncatedEmail})
            </div>
          }
        />
      </Nav.Item>
    </Nav>
  )
}

interface LinkItemProps {
  href?: string
  icon: IconProp
  title: React.ReactNode
  active?: boolean
  onClick?: () => void
}

const LinkItem: React.FC<LinkItemProps> = ({ href, icon, title }) => {
  return (
    <Nav.Link as="div" eventKey={href} className={styles.link}>
      <OverlayTrigger
        placement="right"
        delay={{ show: 250, hide: 250 }}
        overlay={<Tooltip id={`tooltip-${href}`}>{title}</Tooltip>}
      >
        <Link to={href}>
          <FontAwesomeIcon icon={icon} className={styles.icon} />
        </Link>
      </OverlayTrigger>
    </Nav.Link>
  )
}

const LinkTrigger: React.FC<LinkItemProps> = ({
  href,
  icon,
  title,
  active,
  onClick,
}) => {
  return (
    <Nav.Link as="div" eventKey={href} className={styles.link} active={active}>
      <OverlayTrigger
        placement="right"
        delay={{ show: 250, hide: 250 }}
        overlay={<Tooltip id={`tooltip-${href}`}>{title}</Tooltip>}
      >
        <a
          href={href}
          onClick={(e) => {
            e.preventDefault()
            onClick()
          }}
        >
          <FontAwesomeIcon icon={icon} className={styles.icon} />
        </a>
      </OverlayTrigger>
    </Nav.Link>
  )
}
