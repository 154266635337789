import * as React from 'react'
import { OverlayView } from '@react-google-maps/api'
import Moment from 'react-moment'

import {
  getDistanceValue,
  getMetricsAbbreviation,
  Metrics,
} from '../../common/metrics'
import { USER_FIELDS } from '../../../../common/consts'

interface Props {
  position: any
  direction?: string
  speed: number
  userMetrics: Metrics
  dateTime: string
}

function getOverlayString(
  direction: string,
  speed: number,
  metrics: Metrics
): string {
  const convertedSpeed = Math.ceil(getDistanceValue(speed, metrics))
  const speedAbbreviation = getMetricsAbbreviation(
    USER_FIELDS.DISPLAY_UNITS_DISTANCE,
    metrics,
    '',
    'h'
  )
  if (direction && speed) {
    return `Moving ${direction} at ${convertedSpeed} ${speedAbbreviation}, `
  } else {
    return `Stopped, `
  }
}

export const BreadcrumbHoverOverlay: React.SFC<Props> = (props: Props) => {
  return (
    <OverlayView position={props.position} mapPaneName={OverlayView.FLOAT_PANE}>
      <div className="map-dialog">
        <p>
          {getOverlayString(props.direction, props.speed, props.userMetrics)}
          <Moment format="M/D/YY h:mm A (z)">{props.dateTime}</Moment>
        </p>
      </div>
    </OverlayView>
  )
}

export default BreadcrumbHoverOverlay
