import * as React from 'react'
import { Modal, Row, Col, Container } from 'react-bootstrap'
import { modalMapHide } from '../../../store/actions/grid'
import DeviceMapContainer from './device-map'
import DeviceMapControls from './device-map-controls'
import SpeedGraphButton from './speed-graph-button'
import SpeedGraph from './speed-graph'
import LocationHistoryContainer from './location-history-container'
import { Metrics } from '../metrics'
import { DeviceView } from 'client/types'
import { ModalStatus } from '../modal-status'
import { DEVICE_FIELDS } from 'common/consts'

export interface ReduxActions {
  modalMapHide: typeof modalMapHide
}

interface IProps {
  device: DeviceView
  timezone?: string
  userMetrics?: Metrics
  windowHeight?: number
  actions: ReduxActions
}

interface IState {
  showInfo: boolean
}

export class DeviceMapModalComponent extends React.PureComponent<
  IProps,
  IState
> {
  static getDerivedStateFromProps(props) {
    if (!props.device) {
      return null
    }
    return {
      center: {
        lat: props.device.latitude,
        lng: props.device.longitude,
      },
    }
  }

  state = {
    showInfo: false,
    center: null,
  }

  private mapRef = React.createRef<DeviceMapContainer>()

  handleCancel = () => {
    this.props.actions.modalMapHide()
  }

  handleMarkerClick = () => {
    this.setState((prevState) => ({
      showInfo: !prevState.showInfo,
    }))
  }

  onLocationHistoryUpdate = (bounds: google.maps.LatLngBounds) => {
    this.mapRef.current.fitBounds(bounds)
  }

  render() {
    const windowHeight = this.props.windowHeight || window.innerHeight
    return (
      <Modal
        show
        centered
        backdrop="static"
        onHide={this.handleCancel}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title className="d-flex justify-content-between">
            <div>Asset History Map</div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-4">
          <Container fluid className="p-0">
            <Row>
              <Col className="align-middle">
                <h3 style={{ fontSize: '16px' }}>
                  <span className="align-middle">
                    Asset ID: {this.props.device[DEVICE_FIELDS.TRAILER_ID]}{' '}
                  </span>
                  <ModalStatus connected={this.props.device.online} />
                </h3>
              </Col>
            </Row>
            <Row className="mt-2">
              <Col>
                <DeviceMapControls individualTrailerMap />
              </Col>
            </Row>
            <Row className="mt-2">
              <Col>
                <DeviceMapContainer
                  individualTrailerMap
                  ref={this.mapRef}
                  height={windowHeight - 300}
                  device={this.props.device}
                  timezone={this.props.timezone}
                  center={this.state.center}
                  userMetrics={this.props.userMetrics}
                  onMarkerClick={this.handleMarkerClick}
                  showInfo={this.state.showInfo}
                />
              </Col>
            </Row>
            <Row className="mt-2">
              <Col className="text-center">
                <SpeedGraphButton />
                <LocationHistoryContainer
                  device={this.props.device}
                  onUpdate={this.onLocationHistoryUpdate}
                >
                  <SpeedGraph device={this.props.device} />
                </LocationHistoryContainer>
              </Col>
            </Row>
          </Container>
        </Modal.Body>
      </Modal>
    )
  }
}
