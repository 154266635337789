import { StatusHistoryItem } from 'common/types/local-api'
import { localApiFetchWithToken } from '../api'

const BASE_URL = '/status'

export async function getStatusHistoryForReport(
  jwt: string,
  reportId: number,
  deviceId: string,
  timeframe: string
): Promise<StatusHistoryItem[]> {
  const url = `${BASE_URL}/report/${deviceId}?id=${reportId}&timeframe=${timeframe}`
  const data = await localApiFetchWithToken(url, jwt)
  return data as StatusHistoryItem[]
}
