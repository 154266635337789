import * as React from 'react'
import { AppState } from '../../../types'
import { connect } from 'react-redux'
import { Button } from 'react-bootstrap'
import { bindActionCreators, Dispatch } from 'redux'
import { deleteOrganization } from '../../../store/actions/page-admin-organizations'
import { Confirm } from '../../common/confirm'
import { Group } from 'rest-api'
import { faTrash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

interface OwnProps {
  organization: Group
}

interface ReduxStateProps {
  currentOrganization: Group
}

interface ReduxActions {
  deleteOrganization: (organization: Group) => void
}

interface ReduxDispatchProps {
  actions: ReduxActions
}

interface Props extends OwnProps, ReduxStateProps, ReduxDispatchProps {}

function mapStateToProps(state: AppState): ReduxStateProps {
  return {
    currentOrganization: state.pageAdminOrganizations.currentOrganization,
  }
}

function mapDispatchToProps(dispatch: Dispatch): ReduxDispatchProps {
  const actions = {
    deleteOrganization,
  }
  return {
    actions: bindActionCreators(actions, dispatch),
  }
}

class OrganizationDeleteButtonComponent extends React.Component<Props> {
  render() {
    const disabled =
      this.props.currentOrganization?.id === this.props.organization?.id

    return (
      <Confirm
        body="Are you sure you want to delete this fleet?"
        title="Delete Fleet?"
        confirmText="Delete Fleet"
        onConfirm={() => {
          this.props.actions.deleteOrganization(this.props.organization)
        }}
      >
        <Button variant="outline-danger" title="Delete" disabled={disabled}>
          <FontAwesomeIcon icon={faTrash} />
        </Button>
      </Confirm>
    )
  }
}

export const OrganizationDeleteButton = connect(
  mapStateToProps,
  mapDispatchToProps
)(OrganizationDeleteButtonComponent)
export default OrganizationDeleteButton
