import * as React from 'react'

import moment from 'moment'
import Moment from 'react-moment'
import 'moment-timezone'
import 'moment-duration-format'

import { cssVar, getGeofenceString } from '../../lib/utilities'

import { DEVICE_FIELDS, MOVEMENT } from 'common/consts'
import { DeviceView, IFormatterProps, Row } from '../../types'
import UserPrefsContext from '../providers/user-preferences-context'
import { getMetricsSettings, getDistanceValue } from '../common/metrics'
import { Badge } from 'react-bootstrap'
import { OverlayText } from '../common/grid/overlay-text'
import DeviceMapButton from './device-map-button'
import api from 'client/api'
import useSession from 'client/lib/hooks/useSession'
import { useLocation } from 'react-router'
import { capitalize } from 'lodash'
import { DateTime, Duration } from 'luxon'

const PLACEHOLDER = '--'

export const getSpeedValue = (row: Row): number => {
  if (!row.online) {
    return -1
  }
  return row['Movement Status'] !== 'Idle' ? row.Speed : 0
}

export const getMovementValue = (row: Row): string => {
  const propMap = {
    Idle: 'Parked',
    Driving: 'Riding',
    'Start of Drive': 'Riding',
    'End of Drive': 'Parked',
  }
  const value =
    row.online && propMap[row['Movement Status']]
      ? propMap[row['Movement Status']]
      : 'Unknown'
  return value
}

export const getParkedTimeDuration = (row: Row): string => {
  const isDriving =
    row[DEVICE_FIELDS.MOVEMENT] === MOVEMENT.startOfDrive ||
    row[DEVICE_FIELDS.MOVEMENT] === MOVEMENT.driving

  const value = row.LastMovementStatusChangeTime

  if (isDriving || !row.online || !value) {
    return Duration.fromMillis(0).toISO()
  }

  return DateTime.local()
    .diff(DateTime.fromISO(value), ['days', 'hours', 'seconds'])
    .toISO()
}

abstract class DefaultDeviceFormatter<
  P extends IFormatterProps = IFormatterProps
> extends React.PureComponent<P> {
  static defaultProps = {
    dependentValues: {},
  }

  get device(): DeviceView {
    return (this.props.dependentValues || {}) as DeviceView
  }
}

function hasValue(value: unknown) {
  return value !== null && value !== undefined && value !== ''
}

export class StatusFormatter extends DefaultDeviceFormatter {
  render() {
    return this.device.online ? 'Online' : 'Offline'
  }
}

export class ReportStatusFormatter extends DefaultDeviceFormatter {
  render() {
    const variant = this.props.value ? 'success' : 'info'
    return (
      <Badge variant={variant}>{this.props.value ? 'Online' : 'Cached'}</Badge>
    )
  }
}

export class GroupsFormatter extends DefaultDeviceFormatter {
  render() {
    const groups = this.props.value
      ? this.props.value.split(/, */).join(', ')
      : ''
    return <OverlayText value={groups}>{groups}</OverlayText>
  }
}

export class GeofenceFormatter extends DefaultDeviceFormatter {
  render() {
    const geofences = getGeofenceString(this.props.value)
    return <OverlayText value={geofences}>{geofences}</OverlayText>
  }
}

export class SpeedFormatter extends DefaultDeviceFormatter {
  render() {
    return (
      <UserPrefsContext.Consumer>
        {(prefs) => {
          const metrics = getMetricsSettings(prefs.profile)
          if (!this.props.dependentValues.online) {
            return <span>{PLACEHOLDER}</span>
          }
          const value =
            hasValue(this.props.value) &&
            this.props.dependentValues['Movement Status'] !== 'Idle'
              ? Math.ceil(getDistanceValue(Number(this.props.value), metrics))
              : 0
          return <span>{value}</span>
        }}
      </UserPrefsContext.Consumer>
    )
  }
}

export class DistanceFormatter extends DefaultDeviceFormatter {
  render() {
    const { value } = this.props
    if (hasValue(value)) {
      return value > 0.1 ? value.toFixed(1) : 0
    }
    return PLACEHOLDER
  }
}

export class BatteryVoltsFormatter extends DefaultDeviceFormatter {
  render() {
    return (
      <span
        className={
          typeof this.props.value === 'number' && this.props.value <= 11.8
            ? 'text-danger'
            : ''
        }
      >
        {hasValue(this.props.value) ? this.props.value : PLACEHOLDER}
      </span>
    )
  }
}

export class IdleTimeFormatter extends DefaultDeviceFormatter {
  interval = null

  componentDidMount() {
    this.interval = setInterval(() => {
      this.forceUpdate()
    }, 60000)
  }

  componentWillUnmount(): void {
    clearInterval(this.interval)
    this.interval = null
  }

  render() {
    const value = getParkedTimeDuration(this.props.dependentValues)

    if (Duration.fromISO(value).as('seconds') === 0) {
      return <span>{PLACEHOLDER}</span>
    }

    return <span>{moment.duration(value).humanize()}</span>
  }
}

export class BooleanFormatter extends DefaultDeviceFormatter {
  render() {
    return <span>{this.props.value === 'true' ? 'Yes' : 'No'}</span>
  }
}

export class CustomerModelFormatter extends DefaultDeviceFormatter {
  render() {
    return <span>{this.props.value ? this.props.value : PLACEHOLDER}</span>
  }
}

export function headerWrapFormatter() {
  return (
    <div className="header-wrap" style={{ lineHeight: '20px' }}>
      {this.column.name}
    </div>
  )
}

export class ReportTimeFormatter extends DefaultDeviceFormatter {
  render() {
    return (
      <UserPrefsContext.Consumer>
        {(prefs) => {
          const tz = prefs.profile.properties.defaultTimezone
          if (this.props.value) {
            if (!tz) {
              return <Moment format="M/DD/YY h:mm A">{this.props.value}</Moment>
            }
            return (
              <Moment tz={tz} format="M/DD/YY h:mm A">
                {this.props.value}
              </Moment>
            )
          }
          return PLACEHOLDER
        }}
      </UserPrefsContext.Consumer>
    )
  }
}

export class MovementFormatter extends DefaultDeviceFormatter {
  render() {
    const value = getMovementValue(this.props.dependentValues)
    const variants = {
      Parked: cssVar('--movement-status-parked'),
      Riding: cssVar('--movement-status-riding'),
      Unknown: cssVar('--movement-status-unknown'),
    }
    return (
      <Badge style={{ color: '#fff', backgroundColor: variants[value] }}>
        {value}
      </Badge>
    )
  }
}

export class PhoneNumberFormatter extends DefaultDeviceFormatter {
  render() {
    return this.props.value ? this.props.value.replace(/^\+/, '') : PLACEHOLDER
  }
}

export class MileageFormatter extends DefaultDeviceFormatter {
  render() {
    return hasValue(this.props.value)
      ? Math.round(Number(this.props.value) * 100) / 100
      : PLACEHOLDER
  }
}

export class DealerVisitsFormatter extends DefaultDeviceFormatter {
  render() {
    return <span>{this.props.value ?? 0}</span>
  }
}

export class StringFormatter extends DefaultDeviceFormatter {
  render() {
    return hasValue(this.props.value)
      ? capitalize(this.props.value)
      : PLACEHOLDER
  }
}

export const CityAndStateFormatter: React.FC<IFormatterProps> = ({
  value: displayValue,
  column,
  dependentValues,
}) => {
  const session = useSession()
  const location = useLocation()

  if (!displayValue) {
    return PLACEHOLDER
  }

  const hasExtendedAccess = api.acl.hasAccessToExtendedFeatures({
    role: session.user.profile.role,
  })
  const isHome = location.pathname.startsWith('/home')

  return hasExtendedAccess && !isHome ? (
    <DeviceMapButton
      btnLabel={displayValue}
      device={dependentValues}
      disableLink={column.isReport}
    />
  ) : (
    displayValue
  )
}
