import React from 'react'
import styles from './Legends.module.scss'

export const Legends: React.FC = () => {
  return (
    <div className={styles.root}>
      <div style={{ marginRight: '16px' }}>
        <span className={`${styles.circle} ${styles.green}`}></span>
        <span className={styles.label}>Riding</span>
      </div>
      <div style={{ marginRight: '16px' }}>
        <span className={`${styles.circle} ${styles.yellow}`}></span>
        <span className={styles.label}>Parked</span>
      </div>
      <div style={{ marginRight: '16px' }}>
        <span className={`${styles.circle} ${styles.gray}`}></span>
        <span className={styles.label}>Offline</span>
      </div>
    </div>
  )
}
