import React from 'react'
import { DEVICE_FIELDS } from '../../../../common/consts'
import {
  DistanceFormatter,
  MileageFormatter,
  StringFormatter,
} from '../../grid/formatters'
import { Column } from '../../../types'
import DeviceControl from './device-control'
import { CellDealershipSelectorFormatter } from 'client/components/common/grid/formatters/cell-dealership-selector'
import { TagsFormatter } from '../../common/grid/formatters/tags-formatter'
import { CellTextFormatter } from '../../common/grid/formatters/cell-text-formatter'
import { YearFormatter } from '../../common/grid/formatters/year-formatter'
import { TypeFormatter } from '../../common/grid/formatters/type-formatter'
import { MakeFormatter } from '../../common/grid/formatters/make-formatter'
import { ModelFormatter } from '../../common/grid/formatters/model-formatter'

export const getDeviceColumns = (): Column[] => {
  const defaultWidth = 150
  const shortWidth = 120
  return [
    {
      name: 'Email Address',
      key: DEVICE_FIELDS.EMAIL_ADDRESS,
      locked: true,
      sortable: true,
      type: 'string',
      formatter: CellTextFormatter,
      resizable: true,
      width: 220,
    },
    {
      name: 'Customer Name',
      key: DEVICE_FIELDS.CUSTOMER_NAME,
      locked: false,
      sortable: true,
      type: 'string',
      formatter: CellTextFormatter,
      resizable: true,
      width: defaultWidth,
    },
    {
      name: 'Dealership',
      key: 'parentGroupId',
      locked: false,
      sortable: true,
      type: 'string',
      formatter: ({ dependentValues: row }) => {
        return <CellDealershipSelectorFormatter device={row} />
      },
      resizable: true,
    },
    {
      name: 'Phone Number',
      key: DEVICE_FIELDS.PHONE_NUMBER,
      locked: false,
      sortable: false,
      type: 'string',
      formatter: CellTextFormatter,
      resizable: true,
    },
    {
      name: 'Serial Number',
      key: DEVICE_FIELDS.SERIAL_NUMBER,
      locked: false,
      sortable: true,
      type: 'string',
      resizable: true,
    },
    {
      name: 'IMEI',
      key: DEVICE_FIELDS.IMEI,
      locked: false,
      sortable: true,
      type: 'string',
      resizable: true,
    },
    {
      name: 'SIM Number',
      key: DEVICE_FIELDS.ICCID,
      locked: false,
      sortable: true,
      type: 'string',
      resizable: true,
    },
    {
      name: 'Type',
      key: 'Type',
      locked: false,
      sortable: true,
      type: 'string',
      formatter: ({ dependentValues: row }) => {
        return <TypeFormatter device={row} />
      },
      resizable: true,
    },
    {
      name: 'Year',
      key: DEVICE_FIELDS.YEAR,
      locked: false,
      sortable: true,
      type: 'string',
      formatter: ({ dependentValues: row }) => {
        return <YearFormatter device={row} />
      },
      resizable: true,
    },
    {
      name: 'Make',
      key: DEVICE_FIELDS.MAKE,
      locked: false,
      sortable: true,
      type: 'string',
      formatter: ({ dependentValues: row }) => {
        return <MakeFormatter device={row} />
      },
      resizable: true,
    },
    {
      name: 'Model',
      key: DEVICE_FIELDS.MODEL,
      locked: false,
      sortable: true,
      type: 'string',
      formatter: ({ dependentValues: row }) => {
        return <ModelFormatter device={row} />
      },
      resizable: true,
    },
    {
      name: 'Distance from dealership (miles)',
      key: DEVICE_FIELDS.DISTANCE_FROM_DEALERSHIP,
      locked: false,
      sortable: true,
      type: 'number',
      resizable: true,
      formatter: DistanceFormatter,
      width: defaultWidth,
    },
    {
      name: 'VIN #',
      key: DEVICE_FIELDS.VIN,
      locked: false,
      sortable: true,
      type: 'number',
      resizable: true,
    },
    {
      name: 'Mileage (miles)',
      key: DEVICE_FIELDS.MILEAGE,
      locked: false,
      sortable: true,
      type: 'number',
      resizable: true,
      formatter: MileageFormatter,
    },
    {
      name: 'Dealer Visits',
      key: DEVICE_FIELDS.DEALER_VISITS,
      locked: false,
      sortable: true,
      type: 'number',
      resizable: true,
    },
    {
      name: 'Groups',
      key: DEVICE_FIELDS.TAGS,
      locked: false,
      sortable: true,
      type: 'string',
      formatter: ({ dependentValues: row }) => {
        return <TagsFormatter device={row} />
      },
      resizable: true,
      width: 200,
    },
    {
      name: 'Activation Status',
      key: DEVICE_FIELDS.ACTIVATION_STATUS,
      sortable: true,
      type: 'string',
      formatter: StringFormatter,
      resizable: true,
    },
    {
      name: 'Actions',
      key: 'id',
      locked: false,
      formatter: DeviceControl,
      resizable: false,
      selectable: false,
      width: 200,
    },
  ].map((item: Column, index) => ({
    id: index,
    selectable: true,
    width: shortWidth,
    ...item,
  }))
}
