export class MarkerOverlay extends google.maps.OverlayView {
  private text: string
  private position: google.maps.LatLng
  private div: HTMLDivElement
  private offset: google.maps.Point

  constructor(
    position: google.maps.LatLng,
    text: string,
    offsetX = 0,
    offsetY = 0
  ) {
    super()
    this.text = text
    this.position = position
    this.offset = new google.maps.Point(offsetX, offsetY)
  }

  onAdd() {
    this.div = document.createElement('div')
    this.div.textContent = this.text
    this.div.className = 'map-device-id-overlay'
    const panes = this.getPanes()
    panes.floatPane.appendChild(this.div)
  }

  draw() {
    const overlayProjection = this.getProjection()
    const point = overlayProjection.fromLatLngToDivPixel(this.position)
    const x = point.x + this.offset.x
    const y = point.y + this.offset.y
    this.div.style.left = `${x}px`
    this.div.style.top = `${y}px`
  }

  onRemove() {
    this.div.parentNode.removeChild(this.div)
    this.div = null
  }
}

export default MarkerOverlay
