import { LocationHistoryItem } from 'common/types/local-api'
import { DeviceType } from 'rest-api'
import { localApiFetchWithToken } from '../api'

const BASE_URL = '/locations'

export async function getLocationHistory(
  jwt: string,
  deviceId: string,
  deviceType: DeviceType,
  timeframe: string
): Promise<LocationHistoryItem[]> {
  const url = `${BASE_URL}/${deviceId}?timeframe=${timeframe}&deviceType=${deviceType}`
  const data = await localApiFetchWithToken(url, jwt)
  return data as LocationHistoryItem[]
}
