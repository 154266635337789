import * as React from 'react'

import { connect } from 'react-redux'
import { sortedGeofencesArray } from 'client/store/selectors/map'
import { AppState } from 'client/types'
import { Geofence } from 'common/types/local-api'
import { ButtonProps, DropdownButton, Dropdown } from 'react-bootstrap'

import styles from './GeofenceSelector.module.scss'

interface OwnProps {
  value: number | null
  size?: ButtonProps['size']
  variant?: ButtonProps['variant']
  includePrimary?: boolean
  onChange: (value: number) => void
}

interface ReduxStateProps {
  geofences: Geofence[]
}

function mapStateToProps(state: AppState): ReduxStateProps {
  return {
    geofences: sortedGeofencesArray(state),
  }
}

const GeofenceSelectComponent: React.FC<OwnProps & ReduxStateProps> = ({
  onChange,
  geofences,
  value,
  size,
  includePrimary = true,
  variant = 'secondary',
}) => {
  const list = React.useMemo(() => {
    return includePrimary
      ? geofences
      : geofences.filter((item) => !item.isPrimary)
  }, [geofences])

  const selectedName = list.find((entry) => entry.id === value)?.name
  const buttonTitle = selectedName ? (
    <span className={styles.label}>{selectedName}</span>
  ) : (
    'Geofence'
  )
  return (
    <DropdownButton
      title={buttonTitle}
      onSelect={(key) => onChange(+key)}
      variant={variant}
      size={size}
    >
      {list.length === 0 && (
        <Dropdown.Item eventKey="" disabled>
          No geofences found
        </Dropdown.Item>
      )}
      {list.map((entry) => {
        return (
          <Dropdown.Item key={entry.id} eventKey={entry.id}>
            {entry.name}
          </Dropdown.Item>
        )
      })}
    </DropdownButton>
  )
}

export const GeofenceSelector = connect(mapStateToProps)(
  GeofenceSelectComponent
)
export default GeofenceSelector
