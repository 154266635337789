import * as React from 'react'
import { Image } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import DealerSelector from '../common/form/dealer-selector'
import { changeGroup } from 'client/store/blueprint/groups/actions'

import styles from './TopPane.module.scss'
import { AppState } from 'client/types'

export const TopPane = () => {
  const dispatch = useDispatch()
  const selectedGroupId = useSelector<AppState, string>(
    (state) => state.blueprint.organizations.current
  )
  return (
    <section className={styles.root}>
      <Link to="/home">
        <Image src="/images/logo.svg" className={styles.logo} />
      </Link>
      <DealerSelector
        value={selectedGroupId}
        onChange={(value) => dispatch(changeGroup(value))}
      />
    </section>
  )
}
export default TopPane
