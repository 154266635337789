import * as React from 'react'
import { withFormsy } from 'formsy-react'

interface OwnProps {
  validations?: any
  validationErrors?: any
  placeholder: string
  required?: boolean
  id?: string
  onChange?: any
  value?: string
  type: string
  disabled?: boolean
  className?: boolean
  name: string
  title?: string
  autoComplete?: string
}

interface InputTextProps extends OwnProps {
  // Formsy Props
  showRequired?: any
  getErrorMessage?: any
  showError?: any
  getValue?: any
  changeValue?: any
  setValue?: any
}

interface InputTextState {
  input?: string
  hasError?: boolean
}

export class InputTextComponent extends React.Component<
  InputTextProps,
  InputTextState
> {
  constructor(props: InputTextProps) {
    super(props)
  }

  render() {
    const className =
      'form-group' +
      (this.props.className || ' ') +
      (this.props.showRequired()
        ? 'required'
        : this.props.showError()
        ? 'error'
        : '')
    const errorMessage = this.props.getErrorMessage()
    return (
      <div className={className}>
        {this.props.title && (
          <label htmlFor={this.props.name}>{this.props.title}</label>
        )}
        <input
          className="form-control"
          disabled={this.props.disabled}
          placeholder={this.props.placeholder}
          autoComplete={this.props.autoComplete || undefined}
          type={this.props.type || 'text'}
          name={this.props.name}
          onChange={(e) => {
            this.props.setValue(e.target.value)
            if (this.props.onChange) {
              this.props.onChange(e)
            }
          }}
          value={this.props.getValue()}
        />
        <span className="validation-error">{errorMessage}</span>
      </div>
    )
  }
}

export const InputText = withFormsy(
  InputTextComponent
) as React.ComponentClass<OwnProps>
export default InputText
