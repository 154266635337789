import * as React from 'react'

import { UserProfile } from '../../types'

interface IConsumerValue {
  profile: UserProfile | null
  statsExpanded?: boolean
}

export default React.createContext<IConsumerValue>({
  profile: null,
  statsExpanded: false,
})
