import * as React from 'react'
import { DateTime, Duration } from 'luxon'

import {
  ButtonToolbar,
  ToggleButtonGroup,
  ToggleButton,
  FormControl,
  Button,
} from 'react-bootstrap'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendar } from '@fortawesome/free-solid-svg-icons'

interface Props {
  value?: string // Duration ISO
  onChange: (value: string | null) => void
}

const timeframes = [
  { name: '14d', value: Duration.fromObject({ days: 14 }).toISO() },
  { name: '30d', value: Duration.fromObject({ days: 30 }).toISO() },
  { name: '3m', value: Duration.fromObject({ months: 3 }).toISO() },
  { name: '6m', value: Duration.fromObject({ months: 6 }).toISO() },
  { name: '1y', value: Duration.fromObject({ years: 1 }).toISO() },
]

export const TimeframeSelector: React.FC<Props> = ({ value, onChange }) => {
  const inputRef = React.useRef(null)
  const btnGroupValue =
    timeframes.find((entry) => entry.value === value)?.value ?? null
  const customValue = btnGroupValue
    ? ''
    : DateTime.local().minus(Duration.fromISO(value)).toSQLDate()
  const formattedCustomValue = customValue
    ? `${Duration.fromISO(value).as('days')}d`
    : 'custom'

  return (
    <ButtonToolbar className="mb-2">
      <ToggleButtonGroup
        type="radio"
        name="Timeframe"
        value={btnGroupValue}
        onChange={onChange}
        size="sm"
      >
        {timeframes.map((entry) => {
          return (
            <ToggleButton key={entry.value} value={entry.value} variant="light">
              {entry.name}
            </ToggleButton>
          )
        })}
      </ToggleButtonGroup>
      <div style={{ position: 'relative' }}>
        <Button
          variant="light"
          size="sm"
          active={!!customValue}
          className="ml-1 text-nowrap"
          onClick={() => inputRef.current.showPicker()}
          style={{ position: 'absolute', width: 80 }}
        >
          {formattedCustomValue} <FontAwesomeIcon icon={faCalendar} />
        </Button>
        <FormControl
          type="date"
          size="sm"
          ref={inputRef}
          value={customValue}
          min={DateTime.local().minus({ years: 3 }).toSQLDate()}
          max={DateTime.local().minus({ days: 1 }).toSQLDate()}
          onChange={(e) => {
            const selectedDate = e.target.value ?? null
            onChange(
              selectedDate
                ? DateTime.local()
                    .startOf('day')
                    .diff(DateTime.fromSQL(selectedDate).startOf('day'))
                    .toISO()
                : null
            )
          }}
          style={{ width: 80, visibility: 'hidden' }}
        />
      </div>
    </ButtonToolbar>
  )
}
