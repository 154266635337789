import * as React from 'react'
import { Container, Row, Col, Image } from 'react-bootstrap'

export const Landing: React.FunctionComponent = (props) => {
  return (
    <Container fluid id="landing">
      <Row className="justify-content-center">
        <Col className="p-4 d-flex flex-column justify-content-center" lg={3}>
          <div className="m-auto w-100">
            <div className="text-center">
              <Image src="/images/logo.svg" fluid className="logo" />
            </div>
            <div className="mt-4">{props.children}</div>
          </div>
        </Col>
      </Row>
    </Container>
  )
}

export default Landing
