import React, { useEffect, useRef, useState } from 'react'
import { useLocalApiFetch } from 'client/lib/api'
import useSession from 'client/lib/hooks/useSession'
import { Alert, Button, Col, Form, Modal } from 'react-bootstrap'
import { FileUpload } from './FileUpload'
import { DateTime } from 'luxon'

import { SpinnerButton } from '../common/SpinnerButton'

export interface ManualPromotionsModalProps {
  show: boolean
  minDate: string // ISO
  minValue: number
  maxValue: number
  valueLabel: string
  triggerType:
    | 'dealer_visits'
    | 'geolocation_visits'
    | 'ride_frequency'
    | 'distance_from_dealership'
    | 'days_parked'
  onHide: () => void
}

export const ManualPromotionsModal: React.FC<ManualPromotionsModalProps> = ({
  show,
  minValue,
  maxValue,
  valueLabel,
  minDate,
  triggerType,
  onHide,
}) => {
  const [message, setMessage] = useState<string>('')
  const [downloading, setDownloading] = useState(false)
  const [downloadError, setDownloadError] = useState<string>('')
  const [sending, setSending] = useState(false)
  const [sendError, setSendError] = useState<string>('')
  const [sendResult, setSendResult] = useState<string>('')

  const textAreaRef = useRef<HTMLTextAreaElement>()

  const { selectedGroupId } = useSession()

  const { apiPost } = useLocalApiFetch()

  useEffect(() => {
    textAreaRef.current?.focus()
  }, [show])

  const downloadCsvFile = async () => {
    try {
      setDownloading(true)
      const data: Blob = await apiPost(`/promotions/send`, {
        ...getFormValues(),
        outputAs: 'csv',
      })
      const fileURL = URL.createObjectURL(data)
      const anchor = document.createElement('a')
      anchor.href = fileURL
      anchor.download = `${triggerType}-export-results-${DateTime.local().toSQL(
        { includeOffset: false }
      )}.csv`
      document.body.appendChild(anchor)
      anchor.click()
      document.body.removeChild(anchor)
      URL.revokeObjectURL(fileURL)
    } catch (err) {
      setDownloadError(`Error downloading file: ${err.message}.`)
    } finally {
      setDownloading(false)
    }
  }

  const getFormValues = () => ({
    groupId: selectedGroupId,
    allCustomers: false,
    geofenceId: null,
    triggerType,
    minValue,
    maxValue,
    minDate,
    maxDate: DateTime.local().toISO(),
    message,
  })

  const onSubmit = async () => {
    try {
      setSending(true)
      const result = await apiPost(`/promotions/send`, getFormValues())
      setSendResult(
        `Notification successfully sent for ${result.count} customers.`
      )
    } catch (err) {
      setSendError(`Error sending nofitication: ${err.message}.`)
    } finally {
      setSending(false)
    }
  }

  const resetAndHide = () => {
    setMessage('')
    setSendError('')
    setSendResult('')
    setDownloadError('')
    onHide()
  }

  return (
    <Modal
      show={show}
      onHide={() => resetAndHide()}
      size="lg"
      backdrop="static"
      centered
    >
      <Modal.Header closeButton>Send Notification</Modal.Header>
      <Modal.Body>
        <Form
          onSubmit={(e) => {
            e.preventDefault()
            onSubmit()
          }}
        >
          {sendError && (
            <Alert
              variant="danger"
              onClose={() => setSendError('')}
              dismissible
            >
              {sendError}
            </Alert>
          )}
          {sendResult && (
            <Alert
              variant="success"
              onClose={() => setSendResult('')}
              dismissible
            >
              {sendResult}
            </Alert>
          )}

          {triggerType === 'dealer_visits' && (
            <div className="mb-3">
              <Form.Row>
                <Col lg="auto">
                  <Form.Label>Dealer Visits:</Form.Label>
                </Col>
                <Col>{valueLabel}</Col>
              </Form.Row>
              <Form.Row>
                <Col className="flex-column" lg="auto">
                  <Form.Label>Since:</Form.Label>
                </Col>
                <Col>
                  {DateTime.fromISO(minDate).toLocaleString(
                    DateTime.DATETIME_SHORT
                  )}
                </Col>
              </Form.Row>
            </div>
          )}

          {triggerType === 'geolocation_visits' && (
            <div className="mb-3">
              <Form.Row>
                <Col lg="auto">
                  <Form.Label>Geofence Visits:</Form.Label>
                </Col>
                <Col>{valueLabel}</Col>
              </Form.Row>
              <Form.Row>
                <Col className="flex-column" lg="auto">
                  <Form.Label>Since:</Form.Label>
                </Col>
                <Col>
                  {DateTime.fromISO(minDate).toLocaleString(
                    DateTime.DATETIME_SHORT
                  )}
                </Col>
              </Form.Row>
            </div>
          )}

          {triggerType === 'distance_from_dealership' && (
            <div className="mb-3">
              <Form.Row>
                <Col lg="auto">
                  <Form.Label>Distance from dealership (in miles):</Form.Label>
                </Col>
                <Col>{valueLabel}</Col>
              </Form.Row>
            </div>
          )}

          {triggerType === 'ride_frequency' && (
            <div className="mb-3">
              <Form.Row>
                <Col lg="auto">
                  <Form.Label>Frequency:</Form.Label>
                </Col>
                <Col>{valueLabel}</Col>
              </Form.Row>
              <Form.Row>
                <Col className="flex-column" lg="auto">
                  <Form.Label>Since:</Form.Label>
                </Col>
                <Col>
                  {DateTime.fromISO(minDate).toLocaleString(
                    DateTime.DATETIME_SHORT
                  )}
                </Col>
              </Form.Row>
            </div>
          )}

          {triggerType === 'days_parked' && (
            <div className="mb-3">
              <Form.Row>
                <Col lg="auto">
                  <Form.Label>Days parked:</Form.Label>
                </Col>
                <Col>{valueLabel}</Col>
              </Form.Row>
            </div>
          )}

          <Form.Group className="mb-3">
            <Form.Label className="d-block">
              List of approved clients:
            </Form.Label>
            <SpinnerButton
              variant="primary"
              onClick={() => downloadCsvFile()}
              loading={downloading}
            >
              Download CSV file
            </SpinnerButton>
            {downloadError && (
              <Alert variant="danger" className="mt-2" dismissible>
                {downloadError}
              </Alert>
            )}
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Message:</Form.Label>
            <Form.Control
              as="textarea"
              rows={5}
              className="w-100"
              maxLength={140}
              required
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              ref={textAreaRef}
            />
            <Form.Control.Feedback type="invalid">
              Please enter a message.
            </Form.Control.Feedback>
          </Form.Group>

          <FileUpload />
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <SpinnerButton
          variant="success"
          className="mr-2"
          disabled={!message}
          loading={sending}
          onClick={() => onSubmit()}
        >
          Send
        </SpinnerButton>
        <Button variant="secondary" onClick={() => resetAndHide()}>
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
