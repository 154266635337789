import * as React from 'react'
import { User } from 'rest-api'
import { userRoles } from './user-role-select'

interface OwnProps {
  user: User | null
}

export const UserRole: React.FC<OwnProps> = ({ user }) => {
  if (!user) {
    return null
  }

  const role = userRoles.find((role) => {
    return role.key === user.role
  })

  if (!role) {
    return null
  }

  return <div>{role.label}</div>
}
