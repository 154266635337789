import { AppState } from '../types'
import { initialState as pageGridState } from 'client/store/reducers/grid'
import { JWT_KEY } from 'common/services/api/common'

export default function getInitialState(): AppState {
  const jwt = localStorage.getItem(JWT_KEY) || null

  return {
    blueprint: {},
    deviceReports: {},
    pageGrid: pageGridState,
    map: {
      showTravelLine: true,
      showBreadcrumbs: false,
      showLegend: false,
      showDeviceMapModal: false,
      currentDeviceId: null,
      filters: {
        showGeofences: true,
        geofence: null,
        tag: null,
        legend: null,
      },
    },
    geofences: {},
    pageAdminGeofences: {},
    pageAdminDevices: {
      editedDevices: {},
    },
    pageAdminUsers: {},
    pageAdminOrganizations: {},
    user: {
      jwt,
      loading: false,
      loaded: false,
      profile: null,
    },
    locationHistory: {
      timeframe: '30',
      loading: false,
      data: {},
    },
    print: {
      showPrintOnly: false,
    },
    statusHistory: {},
    export: {
      shouldExport: false,
    },
  } as AppState
}
