import { DateTime } from 'luxon'
import * as comm from '../../lib/comm'

export const JWT_KEY = 'user-jwt'
export const JWT_LAST_UPDATED_KEY = 'user-jwt-updated'

interface LoginResponse {
  jwt: string
}

interface CommonOptions {
  url: string
  method: string
  headers?: any
  body?: any
}

export const getJwt = () => localStorage.getItem(JWT_KEY)

class ApiCommon {
  apiUrl: string

  constructor() {
    this.apiUrl = process.env.API_BASE_URL
  }

  async getJson<R = any>(
    options: CommonOptions,
    requireJwt = true
  ): Promise<R> {
    if (requireJwt) {
      if (!options.headers) {
        options.headers = {}
      }
      options.headers['Content-Type'] = 'application/json'

      const lastUpdatedDate = DateTime.fromMillis(
        +localStorage.getItem(JWT_LAST_UPDATED_KEY)
      )
      const savedJwt = localStorage.getItem(JWT_KEY)

      if (!savedJwt) {
        throw new Error('Must login first!')
      }

      if (DateTime.local().diff(lastUpdatedDate).as('minutes') > 10) {
        await this.renewJwt(savedJwt)
        return this.getJson<R>(options, requireJwt)
      }
      options.headers.Authorization = 'Bearer ' + savedJwt
      const res = await comm.getJson<R>(options)
      this.updateJWT(res)
      return res
    }

    const res = await comm.getJson<R>(options)
    this.updateJWT(res)
    return res
  }

  async getJsonWithAdminToken<T = any>(options: CommonOptions): Promise<T> {
    const headers = options.headers || {}
    headers.Authorization = `Bearer ${process.env.API_TOKEN}`
    return comm.getJson<T>({ ...options, headers })
  }

  clearJwt() {
    localStorage.setItem(JWT_KEY, '')
  }

  async renewJwt(jwtP?: string): Promise<LoginResponse> {
    const jwt = jwtP || localStorage.getItem(JWT_KEY)
    const options = {
      url: this.apiUrl + '/users/renew-jwt',
      method: 'POST',
      body: {},
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + jwt,
      },
    }

    const res = await comm.getJson<LoginResponse>(options)
    this.updateJWT(res)
    return res
  }

  private updateJWT(jwtResponse: any): void {
    if (jwtResponse?.jwt) {
      localStorage.setItem(
        JWT_LAST_UPDATED_KEY,
        new Date().getTime().toString()
      )
      localStorage.setItem(JWT_KEY, jwtResponse.jwt)
    }
  }
}

export default ApiCommon
