import React from 'react'
import { Form, Col } from 'react-bootstrap'

export interface InputValues {
  minValue: number
  maxValue: number | null
  minDate?: string
  maxDate?: string
}

interface Props {
  values: InputValues
  onChange: (key: keyof InputValues, value: number | string) => void
}

export const MinMaxValues: React.FC<Props> = ({ values, onChange }) => {
  const { minValue, maxValue, minDate, maxDate } = values
  return (
    <>
      <Form.Group className="mb-3 mt-3">
        <Form.Row>
          <Col lg="auto">
            <Form.Label className="d-block">
              Greater than or equal to:
            </Form.Label>
            <Form.Control
              type="number"
              min={0}
              step={1}
              required
              value={minValue}
              onChange={(e) => onChange('minValue', +e.target.value)}
              style={{ width: 200 }}
            />
          </Col>
          <Col className="flex-column" lg="auto">
            <Form.Label className="d-block">Less than or equal to:</Form.Label>
            <Form.Control
              type="number"
              min={minValue}
              step={1}
              required
              value={maxValue}
              onChange={(e) => onChange('maxValue', e.target.value)}
              style={{ width: 200 }}
            />
          </Col>
        </Form.Row>
        <Form.Control.Feedback type="invalid">
          Please enter a valid range.
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group className="mb-3">
        <Form.Row>
          <Col lg="auto">
            <Form.Label className="d-block">From date:</Form.Label>
            <Form.Control
              type="date"
              value={minDate}
              onChange={(e) => onChange('minDate', e.target.value)}
              style={{ width: 200 }}
            />
          </Col>
          <Col className="flex-column" lg="auto">
            <Form.Label className="d-block">To date:</Form.Label>
            <Form.Control
              type="date"
              value={maxDate}
              onChange={(e) => onChange('maxDate', e.target.value)}
              style={{ width: 200 }}
            />
          </Col>
        </Form.Row>
        <Form.Control.Feedback type="invalid">
          Please enter a valid range.
        </Form.Control.Feedback>
      </Form.Group>
    </>
  )
}
