import * as React from 'react'
import useDeviceEdition from '../../../../lib/hooks/useDeviceEdition'
import { updateDevice } from '../../../../store/actions/page-admin-devices'
import { DeviceView } from 'client/types'
import { useDispatch } from 'react-redux'

import CreatableSelect from 'react-select/creatable'
import { useRestApi } from 'client/lib/api'
import { Loading } from '../../Loading'
import styles from './make-formatter.module.scss'

interface Props {
  device: DeviceView
}

export const MakeFormatter: React.FC<Props> = ({ device }) => {
  const editedDevice = useDeviceEdition(device.id)
  const dispatch = useDispatch()
  const currentValue = editedDevice?.Make ?? device.Make ?? ''
  const type = editedDevice?.Type ?? device.Type ?? ''
  const year = editedDevice?.Year ?? device.Year ?? ''
  const { data: response, error } = useRestApi<any>(
    editedDevice && `/devices/models?type=${type}&year=${year}`
  )

  if (!editedDevice) {
    return <span>{currentValue}</span>
  }

  if (error) {
    return null
  }

  if (!response) {
    return <Loading block />
  }

  const makeList: string[] =
    response.data.results.find((entry) => entry.key === 'make')?.options ?? []

  return (
    <CreatableSelect
      className={styles.root}
      options={makeList.map((v) => ({ value: v, label: v }))}
      value={{ value: currentValue, label: currentValue }}
      onChange={(option) => {
        console.log(option)
        dispatch(
          updateDevice({ id: device.id, key: 'Make', value: option.value })
        )
      }}
      menuPortalTarget={document.body}
      styles={{
        control: (base, props) => ({
          ...base,
          minHeight: '34px',
        }),
        dropdownIndicator: (base, props) => ({ ...base, padding: '4px' }),
        clearIndicator: (base, props) => ({ ...base, padding: '4px' }),
        menuPortal: (base, props) => ({ ...base, zIndex: 1000 }),
      }}
    />
  )
}
