import * as React from 'react'
import { Marker, InfoWindow } from '@react-google-maps/api'

import DevicePopup from './device-popup'
import { getDeviceIconColor } from './marker-icon'
import { Metrics } from '../metrics'
import { DeviceView } from 'client/types'

interface Props {
  device: DeviceView
  timezone?: string
  userMetrics?: Metrics
  onAlarmsClick?: () => void
  onClick?: () => void
  showInfo?: boolean
  individualTrailerMap?: boolean
}

interface DeviceMarkerState {
  showInfo: boolean
  showHoverOverlay: boolean
}

class MapDeviceMarker extends React.PureComponent<Props, DeviceMarkerState> {
  private zoomChangedListener?: google.maps.MapsEventListener

  constructor(props: Props) {
    super(props)
    this.state = {
      showInfo: props.showInfo,
      showHoverOverlay: false,
    }
  }

  componentDidMount() {
    const { map } = this.context

    if (map) {
      this.zoomChangedListener = google.maps.event.addListener(
        map,
        'zoom_changed',
        () => {
          this.handleMouseOut()
        }
      )
    }
  }

  componentWillUnmount() {
    if (this.zoomChangedListener) {
      this.zoomChangedListener.remove()
    }
  }

  handleClick = () => {
    this.setState((prevState) => ({
      showInfo: !prevState.showInfo,
      showHoverOverlay: false,
    }))

    if (this.props.onClick) {
      this.props.onClick()
    }
  }

  handleMouseOver = () => {
    this.setState({ showHoverOverlay: true })
  }

  handleMouseOut = () => {
    this.setState({ showHoverOverlay: false })
  }

  getMarkerIcon(): google.maps.Icon {
    const imageColor = getDeviceIconColor(this.props.device)
    const imageUrl = `images/markers/${imageColor}.png`

    return {
      scaledSize: new google.maps.Size(40, 40),
      url: imageUrl,
    }
  }

  render() {
    const position = {
      lat: this.props.device.latitude,
      lng: this.props.device.longitude,
    }

    return (
      <Marker
        key={this.props.device.id}
        position={position}
        icon={this.getMarkerIcon()}
        onClick={this.handleClick}
        onMouseOver={this.handleMouseOver}
        onMouseOut={this.handleMouseOut}
      >
        {this.state.showInfo && (
          <InfoWindow>
            <DevicePopup
              device={this.props.device}
              timezone={this.props.timezone}
              userMetrics={this.props.userMetrics}
              individualTrailerMap={this.props.individualTrailerMap}
            />
          </InfoWindow>
        )}
      </Marker>
    )
  }
}

export default MapDeviceMarker
