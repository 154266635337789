import * as consts from 'common/consts'
import { isString } from 'lodash'
import { DateTime } from 'luxon'
import moment from 'moment'

function hasValue(input: unknown) {
  return input !== undefined && input !== null && input !== ''
}

export function sortString(val1: string, val2: string, isDesc: boolean) {
  const a = hasValue(val1) ? String(val1).toLowerCase().trim() : ''
  const b = hasValue(val2) ? String(val2).toLowerCase().trim() : ''
  if (isDesc) {
    return b.localeCompare(a)
  } else {
    return a.localeCompare(b)
  }
}

export function sortBoolean(a: any, b: any, isDesc: boolean) {
  if (isString(a)) {
    a = a === 'true'
  }
  if (isString(b)) {
    b = b === 'true'
  }
  if (isDesc) {
    return a === b ? 0 : a ? -1 : 1
  } else {
    return a === b ? 0 : a ? 1 : -1
  }
}

export function sortDate(a: string, b: string, isDesc: boolean) {
  const dateA = DateTime.fromISO(a).toMillis()
  const dateB = DateTime.fromISO(b).toMillis()
  if (dateA === dateB) {
    return 0
  }
  if (isDesc) {
    return dateA < dateB ? 1 : -1
  }
  return dateA > dateB ? 1 : -1
}

export function sortNumber(valA: any, valB: any, isDesc: boolean) {
  const a = hasValue(valA) ? Number(valA) : -1
  const b = hasValue(valB) ? Number(valB) : -1
  if (a === b) {
    return 0
  } else if (!isDesc) {
    return a < b ? -1 : 1
  } else if (isDesc) {
    return a < b ? 1 : -1
  }
}

export function sortDuration(valA: unknown, valB: unknown, isDesc: boolean) {
  const a = hasValue(valA) ? moment.duration(valA) : -1
  const b = hasValue(valB) ? moment.duration(valB) : -1
  if (a === b) {
    return 0
  }
  if (isDesc) {
    return a < b ? 1 : -1
  }
  return a > b ? 1 : -1
}

export function sortCityState(deviceA, deviceB, sortCol, isDesc) {
  if (sortCol === consts.DEVICE_FIELDS.CITY) {
    const cityA = deviceA[consts.DEVICE_FIELDS.CITY]
      ? deviceA[consts.DEVICE_FIELDS.CITY]
      : null
    const cityB = deviceB[consts.DEVICE_FIELDS.CITY]
      ? deviceB[consts.DEVICE_FIELDS.CITY]
      : null
    return sortString(cityA, cityB, isDesc)
  } else {
    const stateA = deviceA[consts.DEVICE_FIELDS.STATE_PROVINCE]
      ? deviceA[consts.DEVICE_FIELDS.STATE_PROVINCE]
      : null
    const stateB = deviceB[consts.DEVICE_FIELDS.STATE_PROVINCE]
      ? deviceB[consts.DEVICE_FIELDS.STATE_PROVINCE]
      : null
    return sortString(stateA, stateB, isDesc)
  }
}

export function sortAlarms(deviceA, deviceB, isDesc) {
  const valA = deviceA[consts.DEVICE_FIELDS.ALARMS]
    ? parseInt(deviceA[consts.DEVICE_FIELDS.ALARMS].split(',')[0], 10)
    : null
  const valB = deviceB[consts.DEVICE_FIELDS.ALARMS]
    ? parseInt(deviceB[consts.DEVICE_FIELDS.ALARMS].split(',')[0], 10)
    : null

  return sortNumber(valA, valB, isDesc)
}

export function sortGeofence(valA, valB, isDesc) {
  if (valA) {
    valA = JSON.parse(valA)
    if (valA && Array.isArray(valA) && valA.length) {
      valA = valA[0].name
    } else {
      valA = ''
    }
  }

  if (valB) {
    valB = JSON.parse(valB)
    if (valB && Array.isArray(valB) && valB.length) {
      valB = valB[0].name
    } else {
      valB = ''
    }
  }

  return sortString(valA, valB, isDesc)
}
