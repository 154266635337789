import { DeviceView } from 'client/types'
import { UserProperties } from 'rest-api'

export const MQTT_CHANNEL_INCOMING = 'incoming'
export const MQTT_CHANNEL_OUTGOING = 'outgoing'
export const MQTT_CHANNEL_UPDATES_FIELDS = '_updates/fields'
export const MQTT_CHANNEL_UPDATES = '_updates'

export const ON = 'On'
export const OFF = 'Off'

export const USER_PROFILE_DEFAULT_HOME_PAGE_GRID = 'Grid'
export const USER_PROFILE_DEFAULT_HOME_PAGE_MAP = 'Map'

export const USER_PROFILE_DISTANCE_METRIC = 'Metric'
export const GRID_COLUMN_DISTANCE_METERS = 'km'

export const USER_PROFILE_DISTANCE_ENGLISH = 'English'
export const GRID_COLUMN_DISTANCE_MILES = 'mi'

export const USER_PROFILE_TEMPERATURE_CELSIUS = 'Celsius'
export const GRID_COLUMN_TEMPERATURE_CELSIUS = '°C'
export const USER_PROFILE_TEMPERATURE_FAHRENHEIT = 'Fahrenheit'
export const GRID_COLUMN_TEMPERATURE_FAHRENHEIT = '°F'

export const USER_PROFILE_PRESSURE_KILOPASCALS = 'Kilopascals'
export const GRID_COLUMN_PRESSURE_KILOPASCALS = 'kPa'
export const USER_PROFILE_PRESSURE_PPS = 'PPS'
export const GRID_COLUMN_PRESSURE_PPS = 'PSI'
export const USER_PROFILE_PRESSURE_MILIBARS = 'Milibars'

export const GRID_COLUMN_PRESSURE_MILIBARS = 'mbar'

export const USER_PROFILE_WEIGHT_KILOGRAMS = 'Kilograms'

export const GRID_COLUMN_WEIGHT_KILOGRAMS = 'kg'
export const USER_PROFILE_WEIGHT_POUNDS = 'Pounds'

export const GRID_COLUMN_WEIGHT_POUNDS = 'lbs'

export const USER_PROFILE_VOLUME_LITERS = 'Liters'
export const GRID_COLUMN_VOLUME_LITERS = 'L'
export const USER_PROFILE_VOLUME_GALLONS = 'Gallons'
export const GRID_COLUMN_VOLUME_GALLONS = 'gal'

export const SHAPE_ID_CIRCLE = 1
export const SHAPE_ID_RECTANGLE = 2
export const SHAPE_ID_POLYGON = 3

export const FULL_MAP_DEFAULT_ZOOM = 5
export const MAP_MAX_INITIAL_ZOOM = 19
export const MAP_DEFAULT_LATITUDE = 39.8282
export const MAP_DEFAULT_LONGITUDE = -98.5795
export const MAP_DEFAULT_NE = {
  lat: 48.200535,
  lng: -68.669693,
}
export const MAP_DEFAULT_SW = {
  lat: 27.407727,
  lng: -124.576307,
}

export const ADMIN_GEOFENCE_DEFAULT_ZOOM = 5
export const ADMIN_GEOFENCE_DEFAULT_LATITUDE = 39.8282
export const ADMIN_GEOFENCE_DEFAULT_LONGITUDE = -98.5795

export const CHART_COLORS = [
  '#88ab25',
  '#ec440b',
  '#990000',
  '#00A9E0',
  '#3f9447',
  '#e5b622',
  '#28b4a2',
  '#3d5965',
]

export const SPEED_FIELD_NAME = 'Speed'

export const SHOW_SPEED_GRAPH_TEXT = 'Show speed graph'

export const HIDE_SPEED_GRAPH_TEXT = 'Hide speed graph'

export const CUSTOMER_MODELS = [
  {
    id: 'No',
    key: 'No',
    value: '',
    title: 'No',
    text: 'No',
    showEmpty: true, // Indicates that we don't want to show 'No' in the grid when not editing
  },
  {
    id: 'B-100',
    key: 'B-100',
    value: 'B-100',
    title: 'B-100',
    text: 'B-100',
  },
  {
    id: 'B-101',
    key: 'B-101',
    value: 'B-101',
    title: 'B-101',
    text: 'B-101',
  },
  {
    id: 'B-201',
    key: 'B-201',
    value: 'B-201',
    title: 'B-201',
    text: 'B-201',
  },
  {
    id: 'B-300',
    key: 'B-300',
    value: 'B-300',
    title: 'B-300',
    text: 'B-300',
  },
  {
    id: 'B-301',
    key: 'B-301',
    value: 'B-301',
    title: 'B-301',
    text: 'B-301',
  },
  {
    id: 'B-500',
    key: 'B-500',
    value: 'B-500',
    title: 'B-500',
    text: 'B-500',
  },
  {
    id: 'B-501',
    key: 'B-501',
    value: 'B-501',
    title: 'B-501',
    text: 'B-501',
  },
  {
    id: 'I-305',
    key: 'I-305',
    value: 'I-305',
    title: 'I-305',
    text: 'I-305',
  },
  {
    id: 'I-306',
    key: 'I-306',
    value: 'I-306',
    title: 'I-306',
    text: 'I-306',
  },
  {
    id: 'P-300',
    key: 'P-300',
    value: 'P-300',
    title: 'P-300',
    text: 'P-300',
  },
  {
    id: 'P-301',
    key: 'P-301',
    value: 'P-301',
    title: 'P-301',
    text: 'P-301',
  },
  {
    id: 'P-550',
    key: 'P-550',
    value: 'P-550',
    title: 'P-550',
    text: 'P-550',
  },
  {
    id: 'P-551',
    key: 'P-551',
    value: 'P-551',
    title: 'P-551',
    text: 'P-551',
  },
  {
    id: 'I-505',
    key: 'I-505',
    value: 'I-505',
    title: 'I-505',
    text: 'I-505',
  },
  {
    id: 'I-506',
    key: 'I-506',
    value: 'I-506',
    title: 'I-506',
    text: 'I-506',
  },
  {
    id: 'P-500',
    key: 'P-500',
    value: 'P-500',
    title: 'P-500',
    text: 'P-500',
  },
  {
    id: 'P-501',
    key: 'P-501',
    value: 'P-501',
    title: 'P-501',
    text: 'P-501',
  },
  {
    id: 'Other',
    key: 'Other',
    value: 'Other',
    title: 'Other',
    text: 'Other',
  },
]

export const LEGEND_SYMBOLS = [
  {
    color: 'green',
    value: 'Driving',
    label: 'Riding',
  },
  {
    color: 'yellow',
    value: 'Idle',
    label: 'Parked',
  },
  {
    color: 'gray',
    value: 'Unknown',
    label: 'Unknown',
  },
]

export const MINIMUM_SPEED_TO_BE_CONSIDERED_MOVING = 3

export const USER_ROLES = {
  ADMIN: 'admin',
  DEALER_ADMIN: 'dealer_admin',
  DEALER_GROUP_ADMIN: 'dealer_group_admin',
  READ: 'read_only',
}

export const DEVICE_FIELDS: Record<string, keyof DeviceView> = {
  ACTIVATION_STATUS: 'ActivationStatus',
  BATTERY_VOLTS: 'BatteryLevel',
  CITY: 'City',
  CONNECTED: 'connected',
  CURRENT_GEOFENCES: 'Current Geofences',
  CUSTOMER_NAME: 'name',
  DEALER_VISITS: 'DealerVisits',
  DISTANCE_FROM_DEALERSHIP: 'DistanceFromDealership',
  DISTANCE: 'Drive Distance',
  EMAIL_ADDRESS: 'EmailAddress',
  GEO_LAT: 'latitude',
  GEO_LONG: 'longitude',
  HEADING: 'Heading',
  ICCID: 'ICCID',
  ID: 'id',
  IMEI: 'IMEI',
  LAST_EVENT: 'Last Report Type',
  LAST_REPORT_TIME: 'LastCommTime',
  MAKE: 'Make',
  MILEAGE: 'Mileage',
  MODEL: 'Model',
  MOVEMENT: 'Movement Status',
  ONLINE: 'online',
  ORGANIZATION_ID: 'groupId',
  PHONE_NUMBER: 'PhoneNumber',
  POWER: 'IgnitionStatus',
  SERIAL_NUMBER: 'serialNumber',
  SPEED: 'Speed',
  STATE_PROVINCE: 'State',
  TAGS: 'Tags',
  VIN: 'VIN',
  YEAR: 'Year',
}

export const USER_FIELDS: Record<string, keyof UserProperties> = {
  DEFAULT_HOME_PAGE: 'defaultHomePage',
  DEFAULT_TIME_ZONE: 'defaultTimezone',
  DISPLAY_UNITS_DISTANCE: 'displayUnitsDistance',
  DISPLAY_UNITS_PRESSURE: 'displayUnitsPressure',
  DISPLAY_UNITS_TEMPERATURE: 'displayUnitsTemperature',
  DISPLAY_UNITS_VOLUME: 'displayUnitsVolume',
  DISPLAY_UNITS_WEIGHT: 'displayUnitsWeight',
  SELECTED_FLEET: 'selectedFleet',
}

export const SORT_DIR_NONE = null
export const SORT_DIR_ASC = 'ASC'
export const SORT_DIR_DESC = 'DESC'

// Drive statuses based on Fleetlink implementation
export enum MOVEMENT {
  startOfDrive = 'Start of Drive', // deprecated
  driving = 'Driving',
  endOfDrive = 'End of Drive', // deprecated
  idle = 'Idle',
}

export const DEVICE_STATUS_NAMES = {
  DRIVING: 'Driving',
  IDLE: 'Idle',
  OFFLINE: 'Offline',
}

export enum DEVICE_STATUS {
  'Driving',
  'Idle',
  'Offline',
}

export const DEFAULT_TIMEFRAME = '30'

export const ALARM_TYPES: any = {
  '0': {
    description: 'No Alarms Exist',
    action: 'No action required.',
    level: 0,
  },
  '2': {
    description: 'Check Evaporator Coil Sensor (Zone)',
    action: 'Manually monitor temperature. Report alarm at end of day.',
    level: 7,
  },
  '3': {
    description: 'Check (Control) Return Air Sensor (Zone)',
    action: 'Manually monitor temperature. Report alarm at end of day.',
    level: 7,
  },
  '4': {
    description: 'Check (Control) Discharge Air Sensor (Zone)',
    action: 'Manually monitor temperature. Report alarm at end of day.',
    level: 7,
  },
  '5': {
    description: 'Check Ambient Temp Sensor',
    action: 'Report alarm at end of day.',
    level: 0,
  },
  '6': {
    description: 'Check Coolant Temp Sensor',
    action: 'Report alarm at end of day.',
    level: 0,
  },
  '7': {
    description: 'Check Engine RPM Sensor',
    action: 'Report alarm at end of day.',
    level: 0,
  },
  '8': {
    description: 'Unit Running on Coil Sensor (Zone)',
    action: 'Manually monitor temperature. Report alarm at end of day.',
    level: 7,
  },
  '9': {
    description: 'High Evaporator Temperature (Zone)',
    action:
      'If unit/zone is shut down repair immediately. Otherwise, report alarm at end of day.',
    level: 7,
  },
  '10': {
    description: 'High Discharge Pressure (or Temperature)',
    action:
      'If unit is shut down repair immediately. Otherwise, report alarm at end of day.',
    level: 14,
  },
  '11': {
    description: 'Unit or Zone Controlling on Alternate Sensor (Zone)',
    action: 'Manually monitor temperature. Report alarm at end of day.',
    level: 7,
  },
  '12': {
    description: 'Sensor or Digital Input Shutdown (Zone)',
    action: 'Unit/zone cannot operate and is shut down. Repair immediately.',
    level: 14,
  },
  '13': {
    description: 'Sensor Check (Zone)',
    action: 'Manually monitor temperature. Report alarm at end of day.',
    level: 7,
  },
  '14': {
    description: 'Defrost Terminated by Time',
    action: 'Report alarm at end of day.',
    level: 0,
  },
  '15': {
    description: 'Check Glow Plugs or Intake Air Heater',
    action:
      'If unit is shut down repair immediately. Otherwise, report alarm at end of day.',
    level: 7,
  },
  '16': {
    description: 'Manual Start Not Completed',
    action: 'Report alarm at end of day.',
    level: 0,
  },
  '17': {
    description: 'Engine Failed to Crank',
    action:
      'If unit is shut down repair immediately. Otherwise, report alarm at end of day.',
    level: 7,
  },
  '18': {
    description: 'High Engine Coolant Temperature',
    action:
      'If unit is shut down repair immediately. Otherwise, report alarm at end of day.',
    level: 7,
  },
  '19': {
    description: 'Low Engine Oil Pressure',
    action:
      'If unit is shut down repair immediately. Otherwise, report alarm at end of day.',
    level: 7,
  },
  '20': {
    description: 'Engine (Vapor Motor CR) Failed to Start',
    action:
      'If unit/zone is shut down repair immediately. Otherwise, report alarm at end of day.',
    level: 7,
  },
  '21': {
    description: 'Cooling Cycle Check (Zone)',
    action: 'Manually monitor temperature. Report alarm at end of day.',
    level: 7,
  },
  '22': {
    description: 'Heating Cycle Check (Zone)',
    action: 'Manually monitor temperature. Report alarm at end of day.',
    level: 7,
  },
  '23': {
    description: 'Cooling Cycle Fault (Zone)',
    action: 'Unit/zone cannot operate and is shut down. Repair immediately.',
    level: 14,
  },
  '24': {
    description: 'Heating Cycle Fault (Zone)',
    action: 'Unit/zone cannot operate and is shut down. Repair immediately.',
    level: 14,
  },
  '25': {
    description: 'Alternator Check',
    action:
      'If unit/zone is shut down repair immediately. Otherwise, report alarm at end of day.',
    level: 7,
  },
  '26': {
    description: 'Check Refrigeration Capacity (Zone)',
    action: 'Manually monitor temperature. Report alarm at end of day.',
    level: 7,
  },
  '27': {
    description: 'Vapor Motor RPM High (CR)',
    action: 'Unit cannot operate and is shut down. Repair immediately.',
    level: 14,
  },
  '28': {
    description: 'Pretrip or Self Check Abort',
    action: 'Report alarm at end of day.',
    level: 0,
  },
  '29': {
    description: 'Defrost Damper Circuit Check (Zone)',
    action: 'Manually monitor temperature. Report alarm at end of day.',
    level: 7,
  },
  '30': {
    description: 'Defrost Damper Stuck Closed (Zone)',
    action: 'Unit/zone cannot operate and is shut down. Repair immediately.',
    level: 14,
  },
  '31': {
    description: 'Check Oil Pressure Switch',
    action:
      'If unit is shut down repair immediately. Otherwise, report alarm at end of day.',
    level: 7,
  },
  '32': {
    description: 'Refrigeration Capacity Low (Zone)',
    action: 'Unit/zone cannot operate and is shut down. Repair immediately.',
    level: 14,
  },
  '33': {
    description: 'Check Engine RPM',
    action: 'Report alarm at end of day.',
    level: 0,
  },
  '34': {
    description: 'Check Modulation Circuit',
    action: 'Manually monitor temperature. Report alarm at end of day.',
    level: 7,
  },
  '35': {
    description: 'Check Run Relay Circuit',
    action: 'Unit cannot operate and is shut down. Repair immediately.',
    level: 14,
  },
  '36': {
    description: 'Electric Motor Failed to Run',
    action:
      'Unit cannot operate and is shut down. Repair immediately. Switch to Diesel Mode',
    level: 14,
  },
  '37': {
    description: 'Check Engine Coolant Level',
    action: 'Report alarm at end of day.',
    level: 0,
  },
  '38': {
    description: 'Electric Phase Reversed',
    action:
      'If unit/zone is shut down repair immediately. Otherwise, report alarm at end of day.',
    level: 14,
  },
  '39': {
    description: 'Check Water Valve Circuit',
    action: 'Manually monitor temperature. Report alarm at end of day.',
    level: 7,
  },
  '40': {
    description: 'Check High Speed Circuit',
    action:
      'If unit is shut down repair immediately. Otherwise, report alarm at end of day.',
    level: 7,
  },
  '41': {
    description: 'Check Engine Coolant Temp',
    action: 'Report alarm at end of day.',
    level: 7,
  },
  '42': {
    description: 'Unit Forced to Low Speed',
    action: 'Report alarm at end of day.',
    level: 7,
  },
  '43': {
    description: 'Unit Forced to Low Speed Modulation',
    action: 'Report alarm at end of day.',
    level: 0,
  },
  '44': {
    description: 'Check Fuel System',
    action: 'Add fuel as necessary. Otherwise, report alarm at end of day.',
    level: 14,
  },
  '45': {
    description: 'Check Hot Gas or Hot Gas Bypass Circuit',
    action:
      'If unit/zone is shut down repair immediately. Otherwise, report alarm at end of day.',
    level: 7,
  },
  '46': {
    description: 'Check Air Flow (Zone)',
    action: 'Manually monitor temperature. Report alarm at end of day.',
    level: 7,
  },
  '47': {
    description: 'Remote Sensor Shutdown',
    action: 'Unit/zone cannot operate and is shut down. Repair immediately.',
    level: 14,
  },
  '48': {
    description: 'Check Belts or Clutch',
    action:
      'If unit is shut down repair immediately. Otherwise, report alarm at end of day.',
    level: 14,
  },
  '49': {
    description: 'Check Spare Sensor 1',
    action: 'Report alarm at end of day.',
    level: 0,
  },
  '50': {
    description: 'Reset Clock',
    action: 'Report alarm at end of day.',
    level: 0,
  },
  '51': {
    description: 'Check Shutdown Circuit',
    action:
      'If unit/zone is shut down repair immediately. Otherwise, report alarm at end of day.',
    level: 14,
  },
  '52': {
    description: 'Check Heat Circuit (Zone)',
    action:
      'If unit/zone is shut down repair immediately. Otherwise, report alarm at end of day.',
    level: 7,
  },
  '53': {
    description: 'Check Economizer Valve Circuit',
    action:
      'If unit/zone is shut down repair immediately. Otherwise, report alarm at end of day.',
    level: 7,
  },
  '54': {
    description: 'Test Mode Timeout',
    action: 'Clear alarm and restart unit.',
    level: 0,
  },
  '55': {
    description: 'Check Engine Speeds',
    action: 'Report alarm at end of day.',
    level: 0,
  },
  '56': {
    description: 'Check Evaporator Fan Low Speed (Zone)',
    action:
      'If unit/zone is shut down repair immediately. Otherwise, report alarm at end of day.',
    level: 7,
  },
  '57': {
    description: 'Check Evaporator Fan High Speed (Zone)',
    action:
      'If unit/zone is shut down repair immediately. Otherwise, report alarm at end of day.',
    level: 7,
  },
  '61': {
    description: 'Low Battery Voltage',
    action:
      'If unit/zone is shut down repair immediately. Otherwise, report alarm at end of day.',
    level: 7,
  },
  '62': {
    description: 'Ammeter Out of Calibration',
    action: 'Unit cannot operate and is shut down. Repair immediately.',
    level: 14,
  },
  '63': {
    description: 'Engine or Vapor Motor Stopped',
    action:
      'If unit is shut down repair immediately. Otherwise, report alarm at end of day.',
    level: 14,
  },
  '64': {
    description: 'Pretrip Reminder',
    action: 'Report alarm at end of day.',
    level: 0,
  },
  '65': {
    description: 'Abnormal Temperature Differential (Zone)',
    action:
      'If unit/zone is shut down repair immediately. Otherwise, report alarm at end of day.',
    level: 7,
  },
  '66': {
    description: 'Low Engine Oil Level',
    action:
      'If unit is shut down repair immediately. Otherwise, report alarm at end of day.',
    level: 14,
  },
  '67': {
    description: 'Check Liquid Line Solenoid Circuit (Zone)',
    action:
      'If unit/zone is shut down repair immediately. Otherwise, report alarm at end of day.',
    level: 7,
  },
  '68': {
    description: 'Internal Controller Fault Code',
    action:
      'If unit is shut down repair immediately. Otherwise, report alarm at end of day.',
    level: 14,
  },
  '70': {
    description: 'Hourmeter Failure',
    action: 'Report alarm at end of day.',
    level: 0,
  },
  '71': {
    description: 'Hourmeter 4 Exceeds Set Time Limit',
    action: 'Report alarm at end of day.',
    level: 0,
  },
  '72': {
    description: 'Hourmeter 5 Exceeds Set Time Limit',
    action: 'Report alarm at end of day.',
    level: 0,
  },
  '73': {
    description: 'Hourmeter 6 Exceeds Set Time Limit',
    action: 'Report alarm at end of day.',
    level: 0,
  },
  '74': {
    description: 'Controller Reset to Defaults',
    action: 'Report alarm at end of day.',
    level: 0,
  },
  '75': {
    description: 'Controller RAM Failure',
    action:
      'If unit is shut down repair immediately. Otherwise, report alarm at end of day.',
    level: 14,
  },
  '76': {
    description: 'Controller EPROM Failure',
    action:
      'If unit is shut down repair immediately. Otherwise, report alarm at end of day.',
    level: 14,
  },
  '77': {
    description: 'Controller EPROM Checksum Failure',
    action:
      'If unit is shut down repair immediately. Otherwise, report alarm at end of day.',
    level: 14,
  },
  '78': {
    description: 'Data Log EPROM Failure',
    action:
      'If unit is shut down repair immediately. Otherwise, report alarm at end of day.',
    level: 14,
  },
  '79': {
    description: 'Internal Data Logger Overflow',
    action: 'Report alarm at end of day.',
    level: 0,
  },
  '80': {
    description: 'Check Compressor Temp Sensor',
    action: 'Report alarm at end of day.',
    level: 0,
  },
  '81': {
    description: 'High Compressor Temp',
    action:
      'If unit is shut down repair immediately. Otherwise, report alarm at end of day.',
    level: 14,
  },
  '82': {
    description: 'High Compressor Temp Shutdown',
    action: 'Unit cannot operate and is shut down. Repair immediately.',
    level: 14,
  },
  '83': {
    description: 'Low Engine Coolant Temp',
    action: 'Report alarm at end of day.',
    level: 0,
  },
  '84': {
    description: 'Restart Null',
    action: 'Report alarm at end of day.',
    level: 0,
  },
  '85': {
    description: 'Forced Unit Operation',
    action: 'Report alarm at end of day.',
    level: 0,
  },
  '86': {
    description: 'Check Discharge Pressure Sensor',
    action: 'Report alarm at end of day.',
    level: 0,
  },
  '87': {
    description: 'Check Suction Pressure Sensor',
    action: 'Report alarm at end of day.',
    level: 0,
  },
  '88': {
    description: 'Reserved for CR',
    action: null,
    level: 0,
  },
  '89': {
    description: 'Check Electronic Throttling Valve (ETV) Circuit',
    action:
      'If unit is shut down repair immediately. Otherwise, report alarm at end of day.',
    level: 0,
  },
  '90': {
    description: 'Electric Overload',
    action:
      'If unit is shut down repair immediately. Otherwise, report alarm at end of day.',
    level: 0,
  },
  '91': {
    description: 'Check Electric Ready Input',
    action:
      'If unit is shut down repair immediately. Otherwise, report alarm at end of day.',
    level: 0,
  },
  '92': {
    description: 'Sensor Grades Not Set (Zone)',
    action: 'Report alarm at end of day.',
    level: 0,
  },
  '93': {
    description: 'Low Compressor Suction Pressure',
    action:
      'If unit is shut down repair immediately. Otherwise, report alarm at end of day.',
    level: 0,
  },
  '94': {
    description: 'Check Loader #1 Circuit',
    action:
      'If unit is shut down repair immediately. Otherwise, report alarm at end of day.',
    level: 0,
  },
  '95': {
    description: 'Check Loader #2 Circuit',
    action:
      'If unit is shut down repair immediately. Otherwise, report alarm at end of day.',
    level: 0,
  },
  '96': {
    description: 'Low Fuel Level',
    action: 'Add fuel as required.',
    level: 7,
  },
  '97': {
    description: 'Failed Remote Return Air Sensor (CR)',
    action:
      'If unit/zone is shut down repair immediately. Otherwise, report alarm at end of day.',
    level: 14,
  },
  '98': {
    description: 'Check Fuel Level Sensor',
    action: 'Report alarm at end of day.',
    level: 0,
  },
  '99': {
    description: 'High Compressor Pressure Ratio',
    action:
      'If unit/zone is shut down repair immediately. Otherwise, report alarm at end of day.',
    level: 14,
  },
  '100': {
    description: 'Heater Fan Failure (CR)',
    action:
      'If unit/zone is shut down repair immediately. Otherwise, report alarm at end of day.',
    level: 7,
  },
  '101': {
    description: 'Controlling on Evap Coil Outlet Temp (CR)',
    action: 'Manually monitor temperature. Report alarm at end of day.',
    level: 14,
  },
  '102': {
    description: 'Low Evaporator Coil Temperature (CR)',
    action:
      'If unit/zone is shut down repair immediately. Otherwise, report alarm at end of day.',
    level: 7,
  },
  '103': {
    description: 'Low Heater Fuel Level (CR)',
    action: 'Manually monitor temperature. Report alarm at end of day.',
    level: 7,
  },
  '104': {
    description: 'Check Remote Fan Speed (Zone)',
    action: 'Manually monitor temperature. Report alarm at end of day.',
    level: 7,
  },
  '105': {
    description: 'Check Receiver Tank Press Sol Circuit',
    action:
      'If unit is shut down repair immediately. Otherwise, report alarm at end of day.',
    level: 7,
  },
  '106': {
    description: 'Check Purge Valve Circuit',
    action:
      'If unit is shut down repair immediately. Otherwise, report alarm at end of day.',
    level: 7,
  },
  '107': {
    description: 'Check Condenser Inlet Sol Circuit',
    action:
      'If unit is shut down repair immediately. Otherwise, report alarm at end of day.',
    level: 7,
  },
  '108': {
    description: 'Door Open Timeout (Zone)',
    action: 'Close doors. Report alarm at end of day.',
    level: 7,
  },
  '109': {
    description: 'Check High Discharge Pressure Sensor',
    action: 'Replaced by Alarm Code 86',
    level: null,
  },
  '110': {
    description: 'Check Suction Line Sol Circuit (Zone)',
    action:
      'If unit/zone is shut down repair immediately. Otherwise, report alarm at end of day.',
    level: 7,
  },
  '111': {
    description: 'Unit Not Configured Correctly (Zone)',
    action: 'Report alarm at end of day.',
    level: 0,
  },
  '112': {
    description: 'Check Remote Fans (Zone)',
    action:
      'If unit/zone is shut down repair immediately. Otherwise, report alarm at end of day.',
    level: 7,
  },
  '113': {
    description: 'Check Electric Heat Circuit (Zone)',
    action: 'Manually monitor temperature. Report alarm at end of day.',
    level: 7,
  },
  '114': {
    description: 'Multiple Alarms - Can Not Run (Zone)',
    action: 'Unit/zone cannot operate and is shut down. Repair immediately.',
    level: 14,
  },
  '115': {
    description: 'Check High Pressure Cut Out Switch',
    action:
      'If unit is shut down repair immediately. Otherwise, report alarm at end of day.',
    level: 7,
  },
  '116': {
    description: 'Check High Pressure Cut In Switch',
    action: 'Report alarm at end of day.',
    level: 0,
  },
  '117': {
    description: 'Auto Switch from Diesel to Electric',
    action: 'Normal operation – does not affect performance.',
    level: 0,
  },
  '118': {
    description: 'Auto Switch from Electric to Diesel',
    action: 'Normal operation – does not affect performance.',
    level: 0,
  },
  '119': {
    description: 'Reserved for CR',
    action: null,
    level: null,
  },
  '120': {
    description: 'Check Alternator Excite Circuit',
    action:
      'If unit is shut down repair immediately. Otherwise, report alarm at end of day.',
    level: 7,
  },
  '121': {
    description: 'Check PMW Liquid Injection Circuit',
    action:
      'If unit is shut down repair immediately. Otherwise, report alarm at end of day.',
    level: 7,
  },
  '122': {
    description: 'Check Diesel/Electric Circuit',
    action:
      'If unit is shut down repair immediately. Otherwise, report alarm at end of day.',
    level: 7,
  },
  '123': {
    description: 'Check Evap Coil Inlet Temp Sensor (CR)',
    action: 'Manually monitor temperature. Report alarm at end of day.',
    level: 7,
  },
  '124': {
    description: 'Check Evap Coil Outlet Temp Sensor (CR)',
    action: 'Report alarm at end of day.',
    level: 0,
  },
  '125': {
    description: 'Check Tank Level Sensor (CR)',
    action:
      'If unit is shut down repair immediately. Otherwise, report alarm at end of day.',
    level: 7,
  },
  '126': {
    description: 'Check Back Pressure Regulator (CR)',
    action:
      'Manually monitor load temperature. Report this alarm at the end of the day.',
    level: 7,
  },
  '127': {
    description: 'Setpoint Not Entered (Zone)',
    action: 'Be sure setpoint is adjusted to required temperature.',
    level: 7,
  },
  '128': {
    description: 'Engine Run Time Maint Reminder #1/Service Due',
    action: 'Report alarm at end of day.',
    level: 0,
  },
  '129': {
    description: 'Engine Run Time Maint Reminder #2',
    action: 'Report alarm at end of day.',
    level: 0,
  },
  '130': {
    description: 'Electric Run Time Maint Reminder #1',
    action: 'Report alarm at end of day.',
    level: 0,
  },
  '131': {
    description: 'Electric Run Time Maint Reminder #2',
    action: 'Report alarm at end of day.',
    level: 0,
  },
  '132': {
    description: 'Total Unit Run Time Maint Reminder #1',
    action: 'Report alarm at end of day.',
    level: 0,
  },
  '133': {
    description: 'Total Unit Run Time Maint Reminder #2',
    action: 'Report alarm at end of day.',
    level: 0,
  },
  '134': {
    description: 'Controller Power On Hours',
    action: 'Report alarm at end of day.',
    level: 0,
  },
  '135': {
    description: 'Check Spare Digital Inputs',
    action: 'Report alarm at end of day.',
    level: 0,
  },
  '136': {
    description: 'Check Spare Digital Outputs',
    action: 'Report alarm at end of day.',
    level: 0,
  },
  '137': {
    description: 'Check Damper Motor Heater Output',
    action:
      'If unit/zone is shut down repair immediately. Otherwise, report alarm at end of day.',
    level: 7,
  },
  '138': {
    description: 'Log DAS Real Time Clock Battery Failure (DAS)',
    action: 'Report alarm at end of day.',
    level: 0,
  },
  '139': {
    description: 'Abort Evacuation Mode',
    action: 'Report alarm at end of day.',
    level: 0,
  },
  '140': {
    description: 'Not currently used',
    action: null,
    level: null,
  },
  '141': {
    description: 'Auto Switch Diesel to Electric Disabled',
    action: 'Report alarm at end of day.',
    level: 7,
  },
  '142': {
    description: 'Check Thermax Valve',
    action:
      'If unit is shut down repair immediately. Otherwise, report alarm at end of day.',
    level: 7,
  },
  '143': {
    description: 'Check Remote Drain Hose Heater Output (Zone)',
    action: 'Report alarm at end of day.',
    level: 0,
  },
  '144': {
    description: 'Lost CAN Communications to Expansion Module',
    action: 'Unit cannot operate and is shut down. Repair immediately.',
    level: 14,
  },
  '145': {
    description: 'Lost Controller ON Feedback Signal',
    action:
      'If unit is shut down repair immediately. Otherwise, report alarm at end of day.',
    level: 7,
  },
  '146': {
    description: 'Software Version Mismatch',
    action: 'Report alarm at end of day.',
    level: 0,
  },
  '147': {
    description: 'Check Multi-Temp Fan Speed Control Output (Zone)',
    action:
      'Manually monitor load temperature. Report this alarm at the end of the day.',
    level: 7,
  },
  '148': {
    description: 'Auto Switch Electric to Diesel Disabled',
    action: 'Report alarm at end of day.',
    level: 7,
  },
  '149': {
    description: 'Alarm Not Identified',
    action:
      'If unit/zone is shut down repair immediately. Otherwise, report alarm at end of day.',
    level: 7,
  },
  '150': {
    description: 'Sensor Out of Range Low (DAS and HMI) (Zone)',
    action: 'Report alarm at end of day.',
    level: 0,
  },
  '151': {
    description: 'Sensor Out of Range High (DAS and HMI) (Zone)',
    action: 'Report alarm at end of day.',
    level: 0,
  },
  '152': {
    description: 'DAS Failed Sensor (DAS)',
    action: 'Report alarm at end of day.',
    level: 0,
  },
  '153': {
    description: 'Expansion Module Flash Load Failure',
    action: 'Report alarm at end of day.',
    level: 0,
  },
  '154': {
    description: 'Low Suction Pressure Switch Failure',
    action:
      'If unit is shut down repair immediately. Otherwise, report alarm at end of day.',
    level: 7,
  },
  '155': {
    description: 'Lost CAN Communications to HMI',
    action: 'Unit cannot operate and is shut down. Repair immediately.',
    level: 14,
  },
  '156': {
    description: 'Check Suction/Liquid Heat Exchanger Bypass Valve',
    action:
      'If unit is shut down repair immediately. Otherwise, report alarm at end of day.',
    level: 7,
  },
  '157': {
    description: 'OptiSet Plus Profile Mismatch',
    action: 'Report alarm at end of day.',
    level: 0,
  },
  '158': {
    description: 'Primary Software Failed to Load',
    action: 'Report alarm at end of day.',
    level: 0,
  },
  '159': {
    description: 'Check Battery Condition',
    action: 'Report alarm at end of day.',
    level: 0,
  },
  '160': {
    description: 'Lost CAN Communications to Radio Expansion Board',
    action: 'Report alarm at end of day.',
    level: 0,
  },
  '161': {
    description: 'Log DAS Real Time Clock Invalid (DAS)',
    action: 'Report alarm at end of day.',
    level: 0,
  },
  '163': {
    description: 'Emission Control Failure',
    action:
      'If unit is shut down repair immediately. Otherwise, report alarm at end of day.',
    level: 7,
  },
  '175': {
    description: 'Check Electronic Expansion Valve (CR)',
    action:
      'If unit is shut down repair immediately. Otherwise, report alarm at end of day.',
    level: 7,
  },
  '176': {
    description: 'Check Evaporator Pressure Sensor (CR)',
    action:
      'If unit is shut down repair immediately. Otherwise, report alarm at end of day.',
    level: 7,
  },
  '177': {
    description: 'Check CO2 Tank Pressure Sensor (CR)',
    action:
      'If unit is shut down repair immediately. Otherwise, report alarm at end of day.',
    level: 7,
  },
  '178': {
    description: 'Low CO2 Fuel Level (CR)',
    action:
      'If unit is shut down repair immediately. Otherwise, report alarm at end of day.',
    level: 7,
  },
  '180': {
    description: 'TriPac Compressor Alarm',
    action:
      'If unit is shut down repair immediately. Otherwise, report alarm at end of day.',
    level: 7,
  },
  '188': {
    description: 'Log DAS Microprocessor Fault (DAS)',
    action: 'Report alarm at end of day.',
    level: 7,
  },
  '203': {
    description: 'Check Display Return Air Sensor (Zone)',
    action: 'Manually monitor temperature. Report alarm at end of day.',
    level: 7,
  },
  '204': {
    description: 'Check Display Discharge Air Sensor (Zone)',
    action: 'Manually monitor temperature. Report alarm at end of day.',
    level: 7,
  },
  '216': {
    description: 'Check DAS Digital Inputs (DAS)',
    action: 'Report alarm at end of day.',
    level: 0,
  },
  '233': {
    description: 'REB Switching Off, Conservative to Full Null',
    action: 'Report alarm at end of day.',
    level: 0,
  },
  '234': {
    description: 'Check Relative Humidity Sensor Circuit',
    action: 'Report alarm at end of day.',
    level: 0,
  },
  '235': {
    description: 'High Engine Coolant Temperature Check',
    action: 'Report alarm at end of day.',
    level: 0,
  },
  '250': {
    description: 'DAS Clock Time Reset (DAS)',
    action: 'Report alarm at end of day.',
    level: 0,
  },
  '251': {
    description: 'Check Radio Expansion Board Configuration',
    action: 'Report alarm at end of day.',
    level: 0,
  },
  '252': {
    description: 'Check Auto Fresh Air Exchange Circuit',
    action: 'Report alarm at end of day.',
    level: 0,
  },
  '253': {
    description: 'REB On Back Up Battery, Alarms Disabled',
    action: 'Report alarm at end of day.',
    level: 0,
  },
  '254': {
    description: 'Check Auxiliary Coolant Temp Sensor',
    action: 'Report alarm at end of day.',
    level: 0,
  },
  '500': {
    description: 'Check Host Evaporator Blower Low Speed',
    action: 'Not implemented',
    level: 7,
  },
  '501': {
    description: 'Check Host Evaporator Blower High Speed',
    action: 'Not implemented',
    level: 7,
  },
  '505': {
    description: 'Check Roadside Condenser Fan Motor Speed Circuit',
    action:
      'If unit is shut down repair immediately. Otherwise, report alarm at end of day.',
    level: 7,
  },
  '506': {
    description: 'Check Curbside Condenser Fan Motor Speed Circuit',
    action:
      'If unit is shut down repair immediately. Otherwise, report alarm at end of day.',
    level: 7,
  },
  '507': {
    description: 'Check Digital Scroll Output Circuit (Scroll Only)',
    action:
      'If unit is shut down repair immediately. Otherwise, report alarm at end of day.',
    level: 0,
  },
  '508': {
    description: 'Speed Request Communication Error',
    action: 'Report alarm at end of day.',
    level: 0,
  },
  '509': {
    description: 'ECU Failed to Enable',
    action:
      'If unit is shut down repair immediately. Otherwise, report alarm at end of day.',
    level: 7,
  },
  '510': {
    description: 'ECU Run Signal Failed',
    action:
      'If unit is shut down repair immediately. Otherwise, report alarm at end of day.',
    level: 7,
  },
  '511': {
    description: 'Engine Wait to Start Time Delay Expired',
    action: 'Report alarm at end of day.',
    level: 0,
  },
  '512': {
    description: 'High Compressor Suction Pressure (Scroll Only)',
    action:
      'If unit is shut down repair immediately. Otherwise, report alarm at end of day.',
    level: 7,
  },
  '513': {
    description: 'Low Compressor Suction Ratio (Scroll Only)',
    action:
      'If unit is shut down repair immediately. Otherwise, report alarm at end of day.',
    level: 7,
  },
  '514': {
    description: 'Low Compressor Discharge Pressure (Scroll Only)',
    action:
      'If unit is shut down repair immediately. Otherwise, report alarm at end of day.',
    level: 7,
  },
  '515': {
    description: 'Minimum ETV Discharge Superheat Temp (Scroll)',
    action:
      'If unit is shut down repair immediately. Otherwise, report alarm at end of day.',
    level: 7,
  },
  '516': {
    description: 'I/O Controller to Application Controller Com Failure',
    action:
      'The unit is no longer able to operate and has been shut down. Repair immediately.',
    level: 14,
  },
  '518': {
    description: 'Generator Ground Fault',
    action:
      'If unit is shut down repair immediately. Otherwise, report alarm at end of day.',
    level: 7,
  },
  '519': {
    description: 'Check Battery Charger Input Power',
    action:
      'If unit is shut down repair immediately. Otherwise, report alarm at end of day.',
    level: 7,
  },
  '520': {
    description: 'Check Battery Charger Output Power',
    action:
      'If unit is shut down repair immediately. Otherwise, report alarm at end of day.',
    level: 7,
  },
  '521': {
    description: 'Battery Charger External/Environmental Fault',
    action:
      'If unit is shut down repair immediately. Otherwise, report alarm at end of day.',
    level: 7,
  },
  '522': {
    description: 'Battery Temperature Sensor Alarm',
    action:
      'If unit is shut down repair immediately. Otherwise, report alarm at end of day.',
    level: 7,
  },
  '523': {
    description: 'Battery Charger Indicated Conditions',
    action:
      'If unit is shut down repair immediately. Otherwise, report alarm at end of day.',
    level: 7,
  },
  '524': {
    description: 'Generator Op Limit Vout to Frequency Ration',
    action:
      'If unit is shut down repair immediately. Otherwise, report alarm at end of day.',
    level: 7,
  },
  '525': {
    description: 'Generator Frequency Range Fault',
    action:
      'If unit is shut down repair immediately. Otherwise, report alarm at end of day.',
    level: 7,
  },
  '526': {
    description: 'Generator Operational Limit Output Current',
    action:
      'If unit is shut down repair immediately. Otherwise, report alarm at end of day.',
    level: 7,
  },
  '528': {
    description: 'Failed J1939 CAN Comm Base Controller/Charger',
    action:
      'If unit is shut down repair immediately. Otherwise, report alarm at end of day.',
    level: 7,
  },
  '529': {
    description: 'Check Fuel Pump Circuit',
    action:
      'If unit is shut down repair immediately. Otherwise, report alarm at end of day.',
    level: 7,
  },
  '530': {
    description: 'Low Pressure Differential (Scroll only)',
    action:
      'If unit is shut down repair immediately. Otherwise, report alarm at end of day.',
    level: 7,
  },
  '531': {
    description: 'Check Economizer Pressure Sensor (Scroll only)',
    action:
      'If unit is shut down repair immediately. Otherwise, report alarm at end of day.',
    level: 7,
  },
  '538': {
    description: 'Engine J1939 CAN Data Link Degraded',
    action:
      'If unit is shut down repair immediately. Otherwise, report alarm at end of day.',
    level: 7,
  },
  '539': {
    description: 'Engine J1939 CAN Data Link Failed',
    action:
      'If unit is shut down repair immediately. Otherwise, report alarm at end of day.',
    level: 7,
  },
  '540': {
    description: 'Illegal Engine Operating State',
    action:
      'If unit is shut down repair immediately. Otherwise, report alarm at end of day.',
    level: 7,
  },
  '542': {
    description: 'Battery Charger Fault – Unit Forced to Low Speed',
    action: 'Maintenance information only. Report alarm at end of day.',
    level: 0,
  },
  '543': {
    description: 'Battery Charger Internal Short',
    action: 'Maintenance information only. Report alarm at end of day.',
    level: 0,
  },
  '544': {
    description: 'Battery Charger External Short',
    action: 'Maintenance information only. Report alarm at end of day.',
    level: 0,
  },
  '545': {
    description: 'Battery Charger Output Voltage Exceeded Limit',
    action: 'Maintenance information only. Report alarm at end of day.',
    level: 0,
  },
  '546': {
    description: 'Battery Charger Operating Bulk Voltage Out of Range',
    action: 'Maintenance information only. Report alarm at end of day.',
    level: 0,
  },
  '547': {
    description: 'AC Bus Phase Loss',
    action:
      'If unit is shut down repair immediately. Otherwise, report alarm at end of day.',
    level: 7,
  },
  '548': {
    description: 'Battery Charger Temperature Below Operating Range',
    action: 'Maintenance information only. Report alarm at end of day.',
    level: 0,
  },
  '549': {
    description: 'Battery Charger AC Input Overvoltage',
    action: 'Maintenance information only. Report alarm at end of day.',
    level: 0,
  },
  '550': {
    description: 'Battery Charger Internal Overvoltage Fault',
    action:
      'If unit is shut down repair immediately. Otherwise, report alarm at end of day.',
    level: 7,
  },
  '551': {
    description: 'Battery Charger Internal Temp Sensor Fault',
    action: null,
    level: 0,
  },
  '552': {
    description: 'Battery Charger Charging – Low Battery',
    action: null,
    level: 0,
  },
  '553': {
    description: 'Battery Charger Operating Derated Due to High temp',
    action: null,
    level: 0,
  },
  '599': {
    description: 'Engine Service Tool Connected',
    action: 'Report alarm at end of day.',
    level: 0,
  },
  '600': {
    description: 'Check Crankshaft Speed Sensor',
    action: 'Report alarm at end of day.',
    level: 0,
  },
  '601': {
    description: 'Check Camshaft Speed Sensor',
    action: 'Report alarm at end of day.',
    level: 0,
  },
  '603': {
    description: 'Check Exhaust Pressure Sensor',
    action: 'Report alarm at end of day.',
    level: 0,
  },
  '604': {
    description: 'Check Coolant Temp Sensor',
    action: 'Report alarm at end of day.',
    level: 0,
  },
  '605': {
    description: 'Check Fresh Air Temp Sensor',
    action: 'Report alarm at end of day.',
    level: 0,
  },
  '607': {
    description: 'Check Fuel Temperature Sensor',
    action: 'Report alarm at end of day.',
    level: 0,
  },
  '608': {
    description: 'Check Rail Pressure Sensor',
    action: 'Report alarm at end of day.',
    level: 0,
  },
  '609': {
    description: 'Check Intake Pressure Sensor',
    action: 'Report alarm at end of day.',
    level: 0,
  },
  '610': {
    description: 'Check Atmospheric Pressure Sensor',
    action: 'Report alarm at end of day.',
    level: 0,
  },
  '611': {
    description: 'Check Glow Plug Circuit',
    action: 'Report alarm at end of day.',
    level: 0,
  },
  '613': {
    description: 'Check Injectors',
    action: 'Report alarm at end of day.',
    level: 0,
  },
  '614': {
    description: 'Check High Pressure Fuel Pump',
    action: 'Report alarm at end of day.',
    level: 0,
  },
  '615': {
    description: 'Rail Pressure Fault',
    action:
      'If unit is shut down repair immediately. Otherwise, report alarm at end of day.',
    level: 7,
  },
  '616': {
    description: 'Engine Overspeed',
    action:
      'If unit is shut down repair immediately. Otherwise, report alarm at end of day.',
    level: 7,
  },
  '617': {
    description: 'Internal ECU Fault',
    action:
      'If unit is shut down repair immediately. Otherwise, report alarm at end of day.',
    level: 7,
  },
  '618': {
    description: 'Check EGR System',
    action: 'Report alarm at end of day.',
    level: 0,
  },
  '619': {
    description: 'ECU Main Relay Fault',
    action: 'Report alarm at end of day.',
    level: 0,
  },
  '620': {
    description: 'No RPM Detected During Start Attempt',
    action:
      'If unit is shut down repair immediately. Otherwise, report alarm at end of day.',
    level: 7,
  },
  '623': {
    description: 'TRUCAN Message Timeout',
    action: 'Report alarm at end of day.',
    level: 0,
  },
  '624': {
    description: 'Check EGR Temperature Sensor',
    action: 'Report alarm at end of day.',
    level: 0,
  },
  '625': {
    description: 'Check Intake Air Temperature Sensor',
    action: 'Report alarm at end of day.',
    level: 0,
  },
  '626': {
    description: 'Check Exhaust Temperature Sensor',
    action: 'Report alarm at end of day.',
    level: 0,
  },
  '642': {
    description: 'ECU Forced Low Speed',
    action: 'Report alarm at end of day.',
    level: 0,
  },
  '699': {
    description: 'Unknown ECU Fault',
    action:
      'If unit is shut down repair immediately. Otherwise, report alarm at end of day.',
    level: 7,
  },
  '800': {
    description: 'Crankshaft Position Sensor Error',
    action: 'Report alarm at end of day.',
    level: 0,
  },
  '801': {
    description: 'Camshaft Position Sensor Error',
    action: 'Report alarm at end of day.',
    level: 0,
  },
  '804': {
    description: 'Engine Coolant Temperature Sensor Error',
    action: 'Report alarm at end of day.',
    level: 0,
  },
  '807': {
    description: 'Fuel Temperature Sensor Error',
    action: 'Report alarm at end of day.',
    level: 0,
  },
  '808': {
    description: 'Rail Pressure Sensor Error',
    action: 'Report alarm at end of day.',
    level: 0,
  },
  '809': {
    description: 'Intake Air Pressure Sensor Error',
    action: 'Report alarm at end of day.',
    level: 0,
  },
  '810': {
    description: 'Atmospheric Pressure Sensor Error',
    action: 'Report alarm at end of day.',
    level: 0,
  },
  '811': {
    description: 'Glow Plug Circuit Error',
    action: 'Report alarm at end of day.',
    level: 0,
  },
  '813': {
    description: 'General Injector Error',
    action: 'Report alarm at end of day.',
    level: 0,
  },
  '815': {
    description: 'Fuel Pressure Regulator Error',
    action:
      'If unit is shut down repair immediately. Otherwise, report alarm at end of day.',
    level: 7,
  },
  '818': {
    description: 'EGR Valve Actuator Error',
    action: 'Report alarm at end of day.',
    level: 0,
  },
  '819': {
    description: 'ECU Power Relay Error',
    action: 'Report alarm at end of day.',
    level: 0,
  },
  '820': {
    description: 'Main ECU Relay Error',
    action: 'Report alarm at end of day.',
    level: 0,
  },
  '825': {
    description: 'T1 Air Intake Temperature Sensor Error',
    action: 'Report alarm at end of day.',
    level: 0,
  },
  '826': {
    description: 'T2 Air Intake Temperature Sensor Error',
    action: 'Report alarm at end of day.',
    level: 0,
  },
  '827': {
    description: 'EGR Valve Position Sensor Error',
    action: 'Report alarm at end of day.',
    level: 0,
  },
  '828': {
    description: 'Lambda System Error',
    action: 'Report alarm at end of day.',
    level: 0,
  },
  '829': {
    description: 'Engine Communication Error',
    action: 'Report alarm at end of day.',
    level: 0,
  },
  '830': {
    description: 'Air Flow Meter Error',
    action: 'Report alarm at end of day.',
    level: 0,
  },
  '832': {
    description: 'Engine Power Supply Error',
    action: 'Report alarm at end of day.',
    level: 0,
  },
  '833': {
    description: 'High Pressure Pump Fuel Metering Unit Error',
    action: 'Report alarm at end of day.',
    level: 0,
  },
  '834': {
    description: 'Water in Fuel Sensor Error',
    action: 'Report alarm at end of day.',
    level: 0,
  },
  '836': {
    description: 'Major ECU Failures',
    action: 'Report alarm at end of day.',
    level: 0,
  },
  '838': {
    description: 'Intake Air System Error',
    action: 'Report alarm at end of day.',
    level: 0,
  },
  '839': {
    description: 'Engine Speed Error',
    action: 'Report alarm at end of day.',
    level: 0,
  },
  '840': {
    description: 'Fuel Injection Energizing Error',
    action: 'Report alarm at end of day.',
    level: 0,
  },
  '841': {
    description: 'High Pressure Pump Wear',
    action: 'Report alarm at end of day.',
    level: 0,
  },
  '842': {
    description: 'Engine Running in Safe Mode – Low Speed',
    action: 'Report alarm at end of day.',
    level: 0,
  },
  '843': {
    description: 'Fuel Injection System Error',
    action: 'Report alarm at end of day.',
    level: 0,
  },
  '844': {
    description: 'Engine Failed to Crank',
    action: 'Report alarm at end of day.',
    level: 0,
  },
  '845': {
    description: 'Starter Relay Error',
    action: 'Report alarm at end of day.',
    level: 0,
  },
  '846': {
    description: 'T50 Switch Error',
    action: 'Report alarm at end of day.',
    level: 0,
  },
  '847': {
    description: 'Variant Coding Error',
    action: 'Report alarm at end of day.',
    level: 0,
  },
  '853': {
    description: 'Engine Overspeed (No DTC)',
    action:
      'If unit is shut down repair immediately. Otherwise, report alarm at end of day.',
    level: 7,
  },
  '854': {
    description: 'Engine Protection Self Shutdown',
    action:
      'If unit is shut down repair immediately. Otherwise, report alarm at end of day.',
    level: 7,
  },
  '899': {
    description: 'Unknown ECU Fault',
    action:
      'If unit is shut down repair immediately. Otherwise, report alarm at end of day.',
    level: 7,
  },
  P1E: {
    description: 'Return Air Temperature Sensor Fault',
    action: 'Report alarm at end of day.',
    level: 7,
  },
  P2E: {
    description: 'Remote Return Air Temperature Sensor Fault',
    action: 'Report alarm at end of day.',
    level: 7,
  },
  OL: {
    description: 'Electric Standby Overload',
    action:
      'If unit is shut down repair immediately. Otherwise, report alarm at end of day.',
    level: 14,
  },
  bAt: {
    description: 'Low Battery Voltage',
    action:
      'If unit is shut down repair immediately. Otherwise, report alarm at end of day.',
    level: 14,
  },
  HP: {
    description: 'High Discharge Pressure',
    action:
      'If unit is shut down repair immediately. Otherwise, report alarm at end of day.',
    level: 14,
  },
  LP: {
    description: 'Low Suction Pressure',
    action:
      'If unit is shut down repair immediately. Otherwise, report alarm at end of day.',
    level: 14,
  },
  PSE: {
    description: 'High Pressure Sensor Fault',
    action:
      'If unit is shut down repair immediately. Otherwise, report alarm at end of day.',
    level: 14,
  },
  tEP: {
    description: 'Electric Standby Motor Thermal Protection Alarm',
    action:
      'If unit is shut down repair immediately. Otherwise, report alarm at end of day.',
    level: 14,
  },
  tP4: {
    description: 'Power Supply Thermal Protection Alarm',
    action:
      'If unit is shut down repair immediately. Otherwise, report alarm at end of day.',
    level: 14,
  },
  dr1: {
    description: 'Door 1 Open/Door Switch 1 Failure',
    action: 'Close door. Report alarm at end of day.',
    level: 7,
  },
  dr2: {
    description: 'Door 2 Open/Door Switch 2 Failure',
    action: 'Close door. Report alarm at end of day.',
    level: 7,
  },
  tCO: {
    description: 'Electronic Control Module Internal Temperature Exceeds Limit',
    action:
      'If unit is shut down repair immediately. Otherwise, report alarm at end of day.',
    level: 14,
  },
  SOF: {
    description: 'Microprocessor Software Failure',
    action:
      'If unit is shut down repair immediately. Otherwise, report alarm at end of day.',
    level: 14,
  },
  '-C-': {
    description: 'Communications Failure, Microprocessor to In-Cab Control Box',
    action:
      'If unit is shut down repair immediately. Otherwise, report alarm at end of day.',
    level: 14,
  },
}
