import { connect } from 'react-redux'
import * as React from 'react'
import { bindActionCreators } from 'redux'
import { AppState } from 'client/types/index'
import { Geofence } from 'common/types/local-api'
import { Button, FormGroup, FormControl } from 'react-bootstrap'
import {
  cancelCreateEdit,
  createStep2,
  changeBounds,
} from 'client/store/actions/page-admin-geofences'

interface ReduxActions {
  cancelCreateEdit: any
  createStep2: any
  changeBounds: (bounds: google.maps.LatLngBoundsLiteral) => void
}

interface ReduxDispatchProps {
  actions: ReduxActions
}

interface ReduxStateProps {
  geofence: Geofence
}

function mapStateToProps(state: AppState): ReduxStateProps {
  return {
    geofence: state.pageAdminGeofences.geofence,
  }
}

function mapDispatchToProps(dispatch): ReduxDispatchProps {
  const actions = {
    cancelCreateEdit,
    createStep2,
    changeBounds,
  }
  return { actions: bindActionCreators(actions, dispatch) }
}

interface Props extends ReduxStateProps, ReduxDispatchProps {}

export class GeofenceCreatePanelComponent extends React.Component<Props> {
  autocomplete: google.maps.places.Autocomplete
  placeListener: google.maps.MapsEventListener

  handleNext = (): void => {
    this.props.actions.createStep2()
  }

  handleCancel = (): void => {
    this.props.actions.cancelCreateEdit()
  }

  handleSearch = (): void => {
    this.props.actions.createStep2()
  }

  bindSearchBox = (input: HTMLInputElement) => {
    if (input) {
      this.autocomplete = new google.maps.places.Autocomplete(input, {
        types: ['geocode'],
      })
      this.placeListener = this.autocomplete.addListener(
        'place_changed',
        this.handlePlacesChanged
      )
      input.focus()
    } else {
      google.maps.event.removeListener(this.placeListener)
      this.autocomplete = null
    }
  }

  handlePlacesChanged = () => {
    const place = this.autocomplete.getPlace()
    if (place) {
      const bounds = new google.maps.LatLngBounds()
      if (place.geometry.viewport) {
        // Only geocodes have viewport.
        bounds.union(place.geometry.viewport)
      } else {
        bounds.extend(place.geometry.location)
      }
      this.props.actions.changeBounds(bounds.toJSON())
    }
  }

  render() {
    return (
      <>
        <div className="p-4">
          <h4>Create Geofence</h4>
          <h5>Step 1 of 2: Choose map region</h5>
          <p>
            Use the map controls to display the region where you want to place
            the geofence.
          </p>
          <p>
            <span>(Optional)</span> Enter an address to center the map on that
            location:
          </p>
          <FormGroup>
            <FormControl
              type="text"
              ref={this.bindSearchBox}
              placeholder="Enter Address"
            />
          </FormGroup>
          <Button variant="secondary" onClick={this.handleSearch}>
            Find Address
          </Button>
        </div>
        <div className="mt-4 p-4 bg-light">
          <Button variant="danger" onClick={this.handleCancel} className="mr-2">
            Cancel
          </Button>
          <Button variant="success" onClick={this.handleNext}>
            Next Step
          </Button>
        </div>
      </>
    )
  }
}
export const GeofenceCreatePanel = connect(
  mapStateToProps,
  mapDispatchToProps
)(GeofenceCreatePanelComponent)
export default GeofenceCreatePanel
