import { createAction } from 'redux-actions'

import * as localAPI from '../../lib/local-api/index'
import { JWT_KEY } from 'common/services/api/common'
import { localApiError } from './local-api'
import { DEVICE_FIELDS } from 'common/consts'
import { DeviceView } from 'client/types'

export const constants = {
  LOADING_START: 'deviceReports/LOADING',
  LOADING_END: 'deviceReports/LOADING_END',
  LOADING_FAIL: 'deviceReports/LOADING_FAIL',
  SET_BY_RESPONSE: 'deviceReports/SET_BY_RESPONSE',
  SET_NEW_DATA_ALERT: 'deviceReports/SET_NEW_DATA_ALERT',
  DEVICE_REPORT_CLEAR_FILTERS: 'deviceReports/CLEAR_FILTERS',
  DEVICE_REPORT_FILTER_TERM: 'deviceReports/FILTER_TERM',
  ENABLE_NEW_DATA_REPORT: 'deviceReports/ENABLE_NEW_DATA_ALERT',
}

export const loading = createAction(
  constants.LOADING_START,
  (payload) => payload
)
export const loadingEnd = createAction(
  constants.LOADING_END,
  (payload) => payload
)
export const loadingFail = createAction(
  constants.LOADING_FAIL,
  (payload) => payload
)
export const setNewDataAlert = createAction(
  constants.SET_NEW_DATA_ALERT,
  (payload) => payload
)
export const enableNewDataReport = createAction(
  constants.ENABLE_NEW_DATA_REPORT,
  (payload) => payload
)

/**
 * Generic function for dispatching a filter devices action
 */
export function filterDeviceReportsTerm(term: string) {
  return (dispatch) => {
    dispatch({
      type: constants.DEVICE_REPORT_FILTER_TERM,
      payload: {
        term,
      },
    })
  }
}

export const clearDeviceReportsFilters = createAction(
  constants.DEVICE_REPORT_CLEAR_FILTERS
)

export function getForDevice(device: DeviceView, timeframe: string) {
  return async (dispatch) => {
    dispatch(loading({ deviceId: device.id }))

    try {
      const encodedJWT = localStorage.getItem(JWT_KEY)
      const response: any = await localAPI.deviceReports.getForDevice(
        encodedJWT,
        device.id,
        timeframe
      )
      const deviceView = device

      const tempDevice: any = {}

      tempDevice[DEVICE_FIELDS.ZONE_COUNT] =
        deviceView[DEVICE_FIELDS.ZONE_COUNT]
      tempDevice[DEVICE_FIELDS.DOORS_INSTALLED] =
        deviceView[DEVICE_FIELDS.DOORS_INSTALLED]
      tempDevice[DEVICE_FIELDS.IBOX_INSTALLED] =
        deviceView[DEVICE_FIELDS.IBOX_INSTALLED]
      tempDevice[DEVICE_FIELDS.DOOR_LOCK_INSTALLED] =
        deviceView[DEVICE_FIELDS.DOOR_LOCK_INSTALLED]
      tempDevice[DEVICE_FIELDS.FUEL_SENSOR_INSTALLED] =
        deviceView[DEVICE_FIELDS.FUEL_SENSOR_INSTALLED]
      tempDevice[DEVICE_FIELDS.TPMS_INSTALLED] =
        deviceView[DEVICE_FIELDS.TPMS_INSTALLED]
      tempDevice[DEVICE_FIELDS.SPARE_SENSOR_INSTALLED] =
        deviceView[DEVICE_FIELDS.SPARE_SENSOR_INSTALLED]
      tempDevice[DEVICE_FIELDS.SENSORS_INSTALLED] =
        deviceView[DEVICE_FIELDS.SENSORS_INSTALLED]
      tempDevice[DEVICE_FIELDS.MODEL] = deviceView[DEVICE_FIELDS.MODEL]
      tempDevice[DEVICE_FIELDS.LOCATION_CACHE] = ''

      // update empty fields on report with values from previous report
      // convert columns to numbers (zoneTemp, zoneSPT, fuelLevel, battVolts, spareTemps)
      for (let i = response.length - 1; i >= 0; i--) {
        // Delete any null values on the response so we can set them from the object.assign
        Object.keys(response[i]).forEach((key) => {
          if (response[i][key] === null) {
            delete response[i][key]
          }
        })

        if (response[i + 1]) {
          response[i] = Object.assign(
            {},
            tempDevice,
            response[i + 1],
            response[i]
          )
        } else {
          response[i] = Object.assign({}, tempDevice, response[i])
        }

        if (
          response[i][DEVICE_FIELDS.ZONE_1_TEMP] &&
          typeof response[i][DEVICE_FIELDS.ZONE_1_TEMP] !== 'number'
        ) {
          response[i][DEVICE_FIELDS.ZONE_1_TEMP] = Number(
            parseFloat(response[i][DEVICE_FIELDS.ZONE_1_TEMP]).toFixed(2)
          )
        }
        if (
          response[i][DEVICE_FIELDS.ZONE_1_SPT] &&
          typeof response[i][DEVICE_FIELDS.ZONE_1_SPT] !== 'number'
        ) {
          response[i][DEVICE_FIELDS.ZONE_1_SPT] = Number(
            parseFloat(response[i][DEVICE_FIELDS.ZONE_1_SPT]).toFixed(2)
          )
        }
        if (
          response[i][DEVICE_FIELDS.ZONE_2_TEMP] &&
          typeof response[i][DEVICE_FIELDS.ZONE_2_TEMP] !== 'number'
        ) {
          response[i][DEVICE_FIELDS.ZONE_2_TEMP] = Number(
            parseFloat(response[i][DEVICE_FIELDS.ZONE_2_TEMP]).toFixed(2)
          )
        }
        if (
          response[i][DEVICE_FIELDS.ZONE_2_SPT] &&
          typeof response[i][DEVICE_FIELDS.ZONE_2_SPT] !== 'number'
        ) {
          response[i][DEVICE_FIELDS.ZONE_2_SPT] = Number(
            parseFloat(response[i][DEVICE_FIELDS.ZONE_2_SPT]).toFixed(2)
          )
        }
        if (
          response[i][DEVICE_FIELDS.ZONE_3_TEMP] &&
          typeof response[i][DEVICE_FIELDS.ZONE_3_TEMP] !== 'number'
        ) {
          response[i][DEVICE_FIELDS.ZONE_3_TEMP] = Number(
            parseFloat(response[i][DEVICE_FIELDS.ZONE_3_TEMP]).toFixed(2)
          )
        }
        if (
          response[i][DEVICE_FIELDS.ZONE_3_SPT] &&
          typeof response[i][DEVICE_FIELDS.ZONE_3_SPT] !== 'number'
        ) {
          response[i][DEVICE_FIELDS.ZONE_3_SPT] = Number(
            parseFloat(response[i][DEVICE_FIELDS.ZONE_3_SPT]).toFixed(2)
          )
        }
        if (
          response[i][DEVICE_FIELDS.FUEL_LEVEL] &&
          typeof response[i][DEVICE_FIELDS.FUEL_LEVEL] !== 'number'
        ) {
          response[i][DEVICE_FIELDS.FUEL_LEVEL] = Number(
            parseFloat(response[i][DEVICE_FIELDS.FUEL_LEVEL]).toFixed(2)
          )
        }
        if (
          response[i][DEVICE_FIELDS.BATTERY_VOLTS] &&
          typeof response[i][DEVICE_FIELDS.BATTERY_VOLTS] !== 'number'
        ) {
          response[i][DEVICE_FIELDS.BATTERY_VOLTS] = Number(
            parseFloat(response[i][DEVICE_FIELDS.BATTERY_VOLTS]).toFixed(2)
          )
        }
        if (
          response[i][DEVICE_FIELDS.SPARE_1_TEMP] &&
          typeof response[i][DEVICE_FIELDS.SPARE_1_TEMP] !== 'number'
        ) {
          response[i][DEVICE_FIELDS.SPARE_1_TEMP] = Number(
            parseFloat(response[i][DEVICE_FIELDS.SPARE_1_TEMP]).toFixed(2)
          )
        }
        if (
          response[i][DEVICE_FIELDS.SPARE_2_TEMP] &&
          typeof response[i][DEVICE_FIELDS.SPARE_2_TEMP] !== 'number'
        ) {
          response[i][DEVICE_FIELDS.SPARE_2_TEMP] = Number(
            parseFloat(response[i][DEVICE_FIELDS.SPARE_2_TEMP]).toFixed(2)
          )
        }
        if (
          response[i][DEVICE_FIELDS.SPARE_3_TEMP] &&
          typeof response[i][DEVICE_FIELDS.SPARE_3_TEMP] !== 'number'
        ) {
          response[i][DEVICE_FIELDS.SPARE_3_TEMP] = Number(
            parseFloat(response[i][DEVICE_FIELDS.SPARE_3_TEMP]).toFixed(2)
          )
        }
        if (
          response[i][DEVICE_FIELDS.SPARE_4_TEMP] &&
          typeof response[i][DEVICE_FIELDS.SPARE_4_TEMP] !== 'number'
        ) {
          response[i][DEVICE_FIELDS.SPARE_4_TEMP] = Number(
            parseFloat(response[i][DEVICE_FIELDS.SPARE_4_TEMP]).toFixed(2)
          )
        }
        if (
          response[i][DEVICE_FIELDS.SPARE_5_TEMP] &&
          typeof response[i][DEVICE_FIELDS.SPARE_5_TEMP] !== 'number'
        ) {
          response[i][DEVICE_FIELDS.SPARE_5_TEMP] = Number(
            parseFloat(response[i][DEVICE_FIELDS.SPARE_5_TEMP]).toFixed(2)
          )
        }
        if (
          response[i][DEVICE_FIELDS.SPARE_6_TEMP] &&
          typeof response[i][DEVICE_FIELDS.SPARE_6_TEMP] !== 'number'
        ) {
          response[i][DEVICE_FIELDS.SPARE_6_TEMP] = Number(
            parseFloat(response[i][DEVICE_FIELDS.SPARE_6_TEMP]).toFixed(2)
          )
        }
      }

      dispatch(loadingEnd({ deviceId: device.id, response }))
    } catch (err) {
      dispatch(localApiError(err))
      const payload = err.message
      dispatch(loadingFail({ deviceId: device.id, payload }))
    }
  }
}
