import { UserState } from '../../types/index'
import { user } from '../actions'
import { constants as loginConastants } from '../actions/login'
import getInitialState from '../initial-state'

const { constants } = user
const initialState: UserState = getInitialState().user

export default function (
  state: UserState = initialState,
  action: ReduxActions.Action<any>
): UserState {
  let error
  switch (action.type) {
    case loginConastants.LOGOUT:
      return {
        ...state,
        jwt: null,
        profile: null,
      }

    case loginConastants.LOGIN:
      return {
        ...state,
        jwt: action.payload.jwt,
        profile: action.payload.user,
      }

    case constants.USER_SET_USER_ID:
      return Object.assign({}, state, {
        userId: action.payload,
      })

    case constants.USER_LOAD_START:
      return Object.assign({}, state, {
        loaded: false,
        loading: true,
      })

    case constants.USER_LOAD_END:
      return Object.assign({}, state, {
        profile: action.payload,
        loading: false,
        loaded: true,
      })

    case constants.USER_LOAD_FAIL:
      error = action.payload as string
      return Object.assign({}, state, {
        loading: false,
        loaded: true,
        loadError: error,
      })

    case constants.USER_SAVE_START:
      return Object.assign({}, state, {
        saving: true,
        saved: false,
      })

    case constants.USER_SAVE_END:
      return Object.assign({}, state, {
        profile:
          action.payload && action.payload.id ? action.payload : state.profile,
        saving: false,
        saved: true,
      })

    case constants.USER_SAVE_FAIL:
      error = action.payload as string
      return Object.assign({}, state, {
        saving: false,
        saveError: error,
      })

    case constants.USER_DELETE_START:
      return Object.assign({}, state, {
        deleting: true,
        deleted: false,
      })

    case constants.USER_DELETE_END:
      return Object.assign({}, state, {
        deleting: false,
        deleted: true,
      })

    case constants.USER_DELETE_FAIL:
      error = action.payload as string
      return Object.assign({}, state, {
        deleting: false,
        deleteError: error,
      })

    case constants.PROFILE_SAVE_START:
      return Object.assign({}, state, {
        saving: true,
        saved: false,
      })

    case constants.PROFILE_SAVE_END:
      return Object.assign({}, state, {
        profile: action.payload,
        saving: false,
        saved: true,
      })

    case constants.PROFILE_SAVE_FAIL:
      error = action.payload as string
      return Object.assign({}, state, {
        saving: false,
        saveError: error,
      })

    case constants.CLEAN_STATUS_MESSAGES:
      return Object.assign({}, state, {
        saved: false,
        saving: false,
        saveError: null,
      })

    default:
      return state
  }
}
